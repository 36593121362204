import React, { useContext, useState } from 'react'
import { Col, Row, Steps } from 'antd'
import client from 'apollo'

import './verification.css'
import { AppContext } from 'AppContext'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import checkCircleForStep from 'assets/images/new-images/checkCircleForStep.svg'
import InvestmentPlanningNew from './InvestmentPlanningNew'
import EMandateRegistrationNew from './EMandateRegistrationNew'
import CongratulationsNew from './CongratulationsNew'
import PendingBookedAdvisoryNew from './PendingBookedAdvisoryNew'
import { GET_ALL_NOTIFICATION_FOR_CLIENT } from 'auth/graphql/Queries'

const { Step } = Steps

const AuaNewSteps = () => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { newAuaAdvisoryCompletedSteps } = state?.currentUser

  // const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  // const [header, setHeader] = useState(true)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth)
  //   }

  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  // useEffect(() => {
  //   window.location.hash = 'no-back-button'
  //   window.location.hash = 'Again-No-back-button'
  //   window.onhashchange = function () {
  //     window.location.hash = 'no-back-button'
  //   }
  // }, [])

  let tempCurrent = 0
  switch (newAuaAdvisoryCompletedSteps) {
    case 'INVESTMENT_PLANNING':
      tempCurrent = 1
      break
    case 'ADVISORY_FEES':
      tempCurrent = 2
      break
    case 'CONGRATULATION':
      tempCurrent = 3
      break
    case 'DONE':
      tempCurrent = 4
      break
    default:
      break
  }
  const [current, setCurrent] = useState(tempCurrent)

  const next = (nextStep = 1) => {
    if (
      newAuaAdvisoryCompletedSteps === 'KYC_FORM' &&
      currentUser?.umrnNumber !== null
    ) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: { newAuaAdvisoryCompletedSteps: 'ADVISORY_FEES' },
            id: currentUser?.id,
          },
        })
        .then((res) => {
          setCurrent(2)
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setCurrent(current + nextStep)
    }
  }
  const back = () => {
    if (newAuaAdvisoryCompletedSteps === 'CONGRATULATION') {
      client
        .query({
          query: GET_ALL_NOTIFICATION_FOR_CLIENT,
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          dispatch({
            type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
            data: res?.data?.getNotifications,
          })
          setCurrent(0)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const steps = [
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanningNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
    {
      title: 'Advisory Fees',
      content: (
        <EMandateRegistrationNew
          next={next}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
    {
      title: 'Congratulations',
      content: (
        <CongratulationsNew next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Pending Advisory',
      content: (
        <PendingBookedAdvisoryNew
          next={next}
          back={back}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
  ]

  return (
    <>
      <div
      // className='.gx-app-login-content.content-wrapper'
      >
        <Row gutter={16}>
          <Col lg={6} sm={24} className='aua_New_Steps_Container'>
            <div
              className='menu-wrapper'
              // style={{ height: 'calc(100vh - 224px)', padding: '0px 32px' }}
            >
              <Steps
                direction='vertical'
                current={tempCurrent}
                status='process'
              >
                {steps?.map((step, index) => (
                  <Step
                    key={index}
                    title={step.title}
                    icon={
                      index < tempCurrent ? (
                        <img
                          src={checkCircleForStep}
                          alt='checkCircleForStep'
                        />
                      ) : null
                    }
                  />
                ))}
              </Steps>
            </div>
          </Col>
          <Col lg={18} sm={24}>
            <div className='steps-content' style={{ marginTop: '2dvh' }}>
              {steps[current]?.content}
            </div>
          </Col>
        </Row>
        {/* {header && windowWidth <= 480 ? (
          <div className='steps_collapse'>
            <Steps current={true} size='small'>
              <Step key={steps[current].title} title={steps[current].title} />
            </Steps>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <DownCircleOutlined className='steps_icon_span' />
            </div>
          </div>
        ) : (
          <div>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <UpCircleOutlined
                className='steps_icon_span'
                style={windowWidth <= 480 ? {} : { display: 'none' }}
              />
            </div>
            <Steps size='small' current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        )}
        <div className='progress'>
          <Progress
            percent={Math.round((100 / 5) * (tempCurrent + 1))}
            format={(p) => `${p}% Progress`}
          />
        </div>
        <div className='steps-content'>{steps[current]?.content}</div> */}
      </div>
    </>
  )
}

export default AuaNewSteps
