import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { AppContextProvider } from 'AppContext'
import * as serviceWorker from 'serviceWorker'
import { version } from '../package.json'
import client from 'apollo'
import App from 'App'
import 'react-quill/dist/quill.snow.css'

console.log(`Version: ${version}`)

const AutoRefresh = () => {
  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    let idleTime = 0
    const idleLimit = 180

    const resetIdleTimer = () => {
      idleTime = 0
    }

    const checkIdleTime = () => {
      idleTime++
      if (idleTime >= idleLimit) {
        refreshPage()
      }
    }

    window.addEventListener('mousemove', resetIdleTimer)
    window.addEventListener('keydown', resetIdleTimer)
    window.addEventListener('scroll', resetIdleTimer)
    window.addEventListener('touchstart', resetIdleTimer)

    const idleInterval = setInterval(checkIdleTime, 1000)

    return () => {
      clearInterval(idleInterval)
      window.removeEventListener('mousemove', resetIdleTimer)
      window.removeEventListener('keydown', resetIdleTimer)
      window.removeEventListener('scroll', resetIdleTimer)
      window.removeEventListener('touchstart', resetIdleTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      AutoRefresh.refreshPage()
    }
  },
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <AutoRefresh />
      <App />
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(configuration)
