import React, { Fragment, useContext } from 'react'
import { AppContext } from 'AppContext'
import { authLogout } from '.'

export default function () {
  const { dispatch } = useContext(AppContext)
  dispatch({ type: 'CURRENT_USER', data: {} })
  localStorage.removeItem('filter')
  localStorage.removeItem('updateFieldAdmin')
  localStorage.removeItem('pendingAMCModal')
  localStorage.removeItem('selectedRow')
  localStorage.removeItem('showFeedbackForm')
  localStorage.removeItem('selectAll')
  localStorage.clear()
  authLogout()
  return <Fragment />
}
