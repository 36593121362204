import React, { useState, useContext, useMemo, useEffect } from 'react'
import {
  EditTwoTone,
  PlusCircleOutlined,
  SearchOutlined,
  LinkOutlined,
  FilterFilled,
} from '@ant-design/icons'
import {
  Table,
  Tooltip,
  Popconfirm,
  Button,
  Switch,
  Input,
  Space,
  Modal,
  Upload,
  Form,
  DatePicker,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { useJsonToCsv } from 'react-json-csv'
// import debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'
import client from 'apollo'

import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import CreateSIPModal from 'modules/SIP/Modal'
import CreateAMCModal from 'modules/AMC/Modal'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { GET_USERS, GET_USERS_ID } from '../Users/graphql/Queries'
import SelectedFieldsModal from './SelectedFieldsModal'
import { UPDATE_USER } from '../Users/graphql/Mutations'
import { CREATE_SIP } from 'modules/Users/graphql/Mutations'
import { CREATE_AMC } from 'modules/Users/graphql/Mutations'
import { CREATE_GROUP } from 'modules/Groups/graphql/Mutations'
import {
  // ALL_FIELD_SELECTION,
  GET_DEFAULT_ADMIN_FIELD_SELECTION,
  GET_FIELD_SELECTION,
} from 'modules/Groups/graphql/Queries'
import { IMPORT_USERS } from '../Users/graphql/Mutations'
import { NavLink } from 'react-router-dom'
import { GET_PLANS } from 'modules/Plans/graphql/Query'

const { saveAsCsv } = useJsonToCsv()
const { RangePicker } = DatePicker

const months = [
  'All',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const userField = [
  { text: 'SELECT USER TYPE', value: 'SELECT_USER_TYPE' },
  { text: 'LOGIN CREATED', value: 'LOGIN_CREATED' },
  { text: 'KYC STATUS', value: 'KYC_STATUS' },
  { text: 'CLIENT PROFILE_1', value: 'CLIENT_PROFILE_1' },
  { text: 'CLIENT PROFILE_2', value: 'CLIENT_PROFILE_2' },
  { text: 'RISK PROFILE', value: 'RISK_PROFILE' },
  { text: 'SUMMARY', value: 'SUMMARY' },
  { text: 'KYC DOC', value: 'KYC_DOC' },
  { text: 'KYC FORM', value: 'KYC_FORM' },
  { text: 'CLIENT CONSENT', value: 'CLIENT_CONSENT' },
  { text: 'INVESTMENT PLANNING', value: 'INVESTMENT_PLANNING' },
  { text: 'AGREEMENT SIGN', value: 'AGREEMENT_SIGN' },
  { text: 'COURIER INSTRUCTION', value: 'COURIER_INSTRUCTION' },
  { text: 'E MANDATE', value: 'E_MANDATE' },
  { text: 'ADVISORY', value: 'ADVISORY' },
  { text: 'DONE', value: 'DONE' },
]

const userPanCardStatus = [
  { text: 'YES', value: true },
  { text: 'NO', value: false },
]

export default function ({ history }) {
  const [form] = Form.useForm()
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const { state } = useContext(AppContext)
  const { currentReport, currentUser } = state
  const [currentPage, setCurrentPage] = useState(1)
  // const [CSVLoading, setCSVLoading] = useState(false)
  const [switchLoading, setSwitchLoading] = useState('')
  const [clientData, setClientData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [filters, setFilters] = useState(
    localStorage.getItem('filter')
      ? JSON.parse(localStorage.getItem('filter'))
      : {
          role: 'CLIENT',
          mdate: '',
          strikerate: 0,
          cp: '',
          company: '',
        }
  )
  // const [open, setOpen] = useState(false)
  // const [exportAllClientLoading, setExportAllClientLoading] = useState(false)

  const { isMobile, isClient } = state

  const filterData = useMemo(() => {
    // setFilters(JSON.parse(localStorage.getItem('filter')))
    const userRole = {
      ...filters,
      role: 'CLIENT',
    }
    if (filters.mdate) {
      userRole.mdate = filters.mdate
    } else {
      delete userRole.mdate
    }
    if (filters.strikerate) {
      userRole.strikerate = filters.strikerate
    } else {
      delete userRole.strikerate
    }
    if (filters.cp) {
      userRole.cp = filters.cp
    } else {
      delete userRole.cp
    }
    if (filters.company) {
      userRole.company = filters.company
    } else {
      delete userRole.company
    }
    return userRole
  }, [filters])

  const [showFormLoading, setShowFormLoading] = useState(false)
  const [showAMCFormModal, setAMCShowFormModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectAll, setSelectAll] = useState(localStorage.getItem('selectAll'))
  const [visibleModal, setVisibleModal] = useState(undefined)
  const [visible, setVisible] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  const [csv, setCsv] = useState([])
  const [loader, setLoader] = useState(false)

  const { data, loading, error } = useQuery(GET_USERS, {
    variables: { where: filterData, limit, skip, sort },
    fetchPolicy: 'network-only',
  })
  const { data: getFieldData, refetch } = useQuery(GET_FIELD_SELECTION, {
    fetchPolicy: 'network-only',
  })
  const { data: getAdminFieldData } = useQuery(
    GET_DEFAULT_ADMIN_FIELD_SELECTION,
    {
      fetchPolicy: 'network-only',
      variables: {
        data: {
          role: currentUser?.role,
        },
      },
    }
  )

  const { data: getPlansUser, loading: planLoading } = useQuery(GET_PLANS, {
    fetchPolicy: 'network-only',
  })

  let getUserPlan = []
  let planData = []
  if (!planLoading && get(getPlansUser, 'getPlans')) {
    planData = getPlansUser?.getPlans.map((data) => {
      let obj = {
        text: data.name,
        value: data.id,
      }

      return getUserPlan.push(obj)
    })
  }

  if (error) return <Page404 error={error} />
  useEffect(() => {
    const savedSelectedRows =
      JSON.parse(localStorage.getItem('selectedRow')) || []
    setSelectedRow(savedSelectedRows)
  }, [])
  useEffect(() => {
    if (skip === 0 && limit === 1500 && !loading && _isEmpty(selectedRow)) {
      let newIds = data?.getUsers?.users?.map((user) => user.id)
      setSelectedRow(newIds)
    }
  }, [skip, limit, loading, data, selectedRow])

  let totalCount = 0
  if (!loading && get(data, 'getUsers.users')) {
    totalCount = data.getUsers.count
    tableData = data.getUsers.users.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }

  function handleSwitch(record) {
    setSwitchLoading(record.id)
    const queryVariables = {
      id: record.id,
      data: {
        isActive: !record.isActive,
        disableDate: record.disableDate
          ? record.disableDate.format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      },
    }
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: queryVariables,
        refetchQueries: [
          {
            query: GET_USERS,
            variables: { where: filters, limit, skip, sort },
          },
        ],
      })
      .then((res) => openNotification('success', 'Client Updated Successfully'))
      .catch((err) => console.log(err))
      .finally(() => setSwitchLoading(''))
  }

  async function handleSIPCreate(values, resetForm) {
    setShowFormLoading(true)
    if (values.multiple) {
      const { whichMonthFrom, whichMonthTo } = values
      const dateMoment = moment(values.date)
      const total =
        months.findIndex((m) => m === whichMonthTo) -
        months.findIndex((m) => m === whichMonthFrom) +
        1
      const monthsCount = []
      for (let index = 1; index <= total; index++) {
        monthsCount.push(months[index])
      }
      delete values.date
      delete values.multiple
      delete values.whichMonthTo
      delete values.whichMonthFrom
      try {
        for (const monthCount of monthsCount) {
          const date = `${moment().format('YYYY')}-${months.findIndex(
            (m) => m === monthCount
          )}-${dateMoment.format('D')}`
          const dataValues = { ...values, whichMonth: monthCount }
          dataValues.startDate = moment(new Date(date)).add(1, 'days')
          dataValues.endDate = moment(new Date(date)).add(1, 'months')
          await client
            .mutate({
              mutation: CREATE_SIP,
              variables: {
                data: { ...dataValues, clientId: parseInt(clientData.id) },
              },
            })
            .catch((err) => console.log(err))
        }
        openNotification('success', 'SIPs Created Successfully')
        setShowFormLoading(false)
        setShowFormModal(false)
        resetForm()
      } catch (e) {
        setShowFormLoading(false)
      }
    } else {
      delete values.multiple
      const dateMoment = moment(values.date)
      const date = `${moment().format('YYYY')}-${months.findIndex(
        (m) => m === values.whichMonth
      )}-${dateMoment.format('D')}`
      values.startDate = moment(new Date(date)).add(1, 'days')
      values.endDate = moment(new Date(date)).add(1, 'months')
      delete values.date
      client
        .mutate({
          mutation: CREATE_SIP,
          variables: { data: { ...values, clientId: parseInt(clientData.id) } },
        })
        .then((res) => {
          openNotification('success', 'SIP Created Successfully')
          setShowFormModal(false)
          resetForm()
        })
        .catch((err) => console.log(err))
        .finally(() => setShowFormLoading(false))
    }
  }

  // function handleCsvDownload() {
  //   if (_isEmpty(selectedRow)) {
  //     openNotification('error', 'Please select users first!')
  //   } else {
  //     setCSVLoading(true)
  //     client
  //       .mutate({
  //         mutation: GENERATE_CSV,
  //         variables: { data: { userIds: selectedRow } },
  //       })
  //       .then(
  //         (res) =>
  //           get(res, 'data.generateCSV') &&
  //           window.open(get(res, 'data.generateCSV'), '_blank')
  //       )
  //       .catch((err) => console.log(err))
  //       .finally(() => {
  //         setLimit(10)
  //         setSkip(0)
  //         setSelectedRow([])
  //         setCSVLoading(false)
  //       })
  //   }
  // }

  // function handleAllExportClientCsv() {
  //   setExportAllClientLoading(true)
  //   client
  //     .mutate({ mutation: GENERATE_CSV, variables: { data: { userIds: [] } } })
  //     .then((res) => {
  //       get(res, 'data.generateCSV') &&
  //         window.open(get(res, 'data.generateCSV'), '_blank')
  //       setExportAllClientLoading(false)
  //     })
  //     .catch((err) => console.logA({ err }))
  //     .finally(() => setExportAllClientLoading(false))
  // }

  function handleAMCCreate(values, resetForm) {
    setShowFormLoading(true)
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format(
      'M'
    )}-${dateMoment.format('D')}`
    values.endDate = moment(new Date(date)).add(1, 'years')
    delete values.date
    values.clientId = parseInt(clientData.id)
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    setAMCShowFormModal(true)
    client
      .mutate({
        mutation: CREATE_AMC,
        variables: { data: values },
      })
      .then((res) => {
        openNotification('success', 'AMC Created Successfully')
        setAMCShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {record.isDeleted ? (
          <Popconfirm
            title={`Are you sure you want to add this user again?`}
            onConfirm={() => handleSwitch(record)}
          >
            <Tooltip title={`Restore user`}>
              <PlusCircleOutlined />
            </Tooltip>
          </Popconfirm>
        ) : (
          <>
            {currentReport.includes('Update Client') && (
              <Tooltip title='Edit'>
                <EditTwoTone
                  onClick={() => history.push(`/clients/${record.id}/edit`)}
                />
              </Tooltip>
            )}
            {currentReport.includes('Disable Client') && (
              <Switch
                size='small'
                checked={record.isActive}
                loading={switchLoading === record.id}
                onClick={() => handleSwitch(record)}
                disabled={currentUser.id === record.id}
              />
            )}
          </>
        )}
        <Tooltip title='View Position'>
          <Button
            size='small'
            type='primary'
            style={{ margin: '0px 5px', lineHeight: '1px' }}
            onClick={() =>
              history.push(
                `/clients/${record.id}/position/${record.clientCode}`
              )
            }
          >
            View
          </Button>
        </Tooltip>
        {currentReport.includes('Create SIP') && (
          <Button
            size='small'
            type='primary'
            style={{ margin: '0px 5px', lineHeight: '1px' }}
            onClick={() => {
              setClientData(record)
              setShowFormModal(true)
            }}
          >
            + SIP
          </Button>
        )}
        {currentReport.includes('Create AMC') && (
          <Button
            size='small'
            type='primary'
            style={{ marginBottom: 0, marginRight: 0, lineHeight: '1px' }}
            onClick={() => {
              setClientData(record)
              setAMCShowFormModal(true)
            }}
          >
            + AMC
          </Button>
        )}
      </div>
    )
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setSort({
        field: sorter.field,
        sortingOrder: sorter.order === 'descend' ? 'DESC' : 'ASC',
      })
      setCurrentPage(1)
    }

    const tempFilters = { ...filters }

    if (filter.completedSteps) {
      tempFilters['completedSteps'] = filter.completedSteps
    } else {
      delete tempFilters['completedSteps']
    }

    if (filter.planId) {
      tempFilters['planId'] = filter.planId.map((item) => parseInt(item))
    } else {
      delete tempFilters['planId']
    }

    if (filter.isPanCardIndividual) {
      tempFilters['isPanCardIndividual'] = filter.isPanCardIndividual
    } else {
      delete tempFilters['isPanCardIndividual']
    }

    localStorage.setItem('filter', JSON.stringify(tempFilters))
    setFilters(tempFilters)

    setCurrentPage(1)
  }

  function handlePagination(page) {
    if (limit !== 1500) {
      setSkip((page - 1) * limit)
    }
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value =
      typeof value === 'object' && filedName !== 'clientName' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
    localStorage.setItem('filter', JSON.stringify(tempFilters))
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    delete tempFilters[filedName]
    localStorage.setItem('filter', JSON.stringify(tempFilters))
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          // value={selectedKeys[0]}
          defaultValue={
            JSON.parse(localStorage.getItem('filter'))
              ? JSON.parse(localStorage.getItem('filter'))[dataIndex]
              : ''
          }
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => {
      const filter = localStorage.getItem('filter')
      const parsedFilter = filter ? JSON.parse(filter) : {}

      return (
        <SearchOutlined
          style={{
            color:
              filtered || parsedFilter[dataIndex] !== undefined
                ? '#1890ff'
                : undefined,
          }}
        />
      )
    },

    sorter: (a, b) => a.age - b.age,
    // getPopupContainer: trigger => trigger.parentElement,
    // filterDropdownProps: { placement: 'bottomLeft' },
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              localStorage.setItem('filter', JSON.stringify(tempFilters))
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
    filterIcon: (filtered) => {
      return (
        <FilterFilled
          style={{
            color:
              filters?.[`${dataIndex}From`] || filters?.[`${dataIndex}TO`]
                ? '#1890ff'
                : undefined,
          }}
        />
      )
    },
    // getPopupContainer: trigger => trigger.parentElement,
    // filterDropdownProps: { placement: 'bottomLeft' },
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        client
          .mutate({
            mutation: IMPORT_USERS,
            variables: { data: { csv: values.csv[0].originFileObj } },
          })

          .then(async (res) => {
            // if (get(res, 'data.importUsers.errorUsers') && get(res, 'data.importUsers.importUsersCount') && get(res, 'data.importUsers.errorUsersCount')) {
            openNotification(
              'success',
              `${res.data.importUsers.importUsersCount} Users import successfully`
            )
            openNotification(
              'error',
              `${res.data.importUsers.errorUsersCount} Users not imported`
            )
            const fields = {
              address: 'address',
              cityId: 'cityId',
              clientCode: 'clientCode',
              countryId: 'countryId',
              createdById: 'createdById',
              dateOfBirth: 'dateOfBirth',
              email: 'email',
              firstName: 'firstName',
              isActive: 'isActive',
              lastName: 'lastName',
              password: 'password',
              phone: 'phone',
              role: 'role',
              stateId: 'stateId',
              strategyCode: 'strategyCode',
              userName: 'userName',
            }

            // converting json to csv
            if (res.data.importUsers.errorUsers.length > 0) {
              saveAsCsv({
                data: res.data.importUsers.errorUsers,
                fields,
                filename: 'Error Users',
              })
            }
            // }
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((info) => console.log('Validate Failed:', info))

    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e && e.fileList && e.fileList.length > 1 && e.file) {
      setCsv([e.file])
      return e && e.fileList
    }
    setCsv(e.fileList)
    return e && e.fileList
  }

  const customCsv = {
    multiple: false,
    onRemove: (file) => setCsv([]),
    beforeUpload: (file) => {
      setCsv([])
      return false
    },
    csv,
  }

  const rowSelection = {
    selectedRowKeys: selectAll ? tableData.map((row) => row.id) : selectedRow,
    onSelectAll: (selected) => {
      if (selected) {
        setSelectAll(true)
        localStorage.setItem('selectAll', true)
        localStorage.removeItem('selectedRow')
        const allIds = tableData.map((row) => row.id)
        setSelectedRow(allIds)
      } else {
        setSelectAll(false)
        localStorage.removeItem('selectAll')
        setSelectedRow([])
      }
    },
    onSelect: (record) => {
      const isTrue = selectedRow.findIndex((i) => i === record.id)
      if (isTrue > -1) {
        const newValues = selectedRow.filter((i) => i !== record.id)
        setSelectedRow(newValues)
        localStorage.setItem('selectedRow', JSON.stringify(newValues))
      } else {
        const updatedValues = [...selectedRow, record.id]
        setSelectedRow(updatedValues)
        localStorage.setItem('selectedRow', JSON.stringify(updatedValues))
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  let fieldData = getFieldData?.getFieldSelection

  const columns = []

  for (const key in fieldData) {
    const isAdminFieldSelected =
      getAdminFieldData?.getAllDefaultFieldSelection[0][key] === true
    const isFieldDataTrue = fieldData[key] === true
    if (isAdminFieldSelected && isFieldDataTrue) {
      if (key === 'firstName' || key === 'lastName') {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => (
            <NavLink to={`/clients/${record.id}`}>{record[key] || '-'}</NavLink>
          ),
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'userName') {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => (
            <NavLink
              to={{
                pathname: `/clients/${record.id}/dashboard`,
                clientDetails: record,
              }}
            >
              {text}
            </NavLink>
          ),
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'panCardNo') {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'email') {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          render: (text) => <a href={`mailto:${text}`}>{text}</a>,
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'phone') {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text) => <a href={`tel:${text}`}>{text}</a>,
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'dateOfBirth') {
        columns.push({
          title: 'Age',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text) =>
            text
              ? moment(text).format('DD-MM-YYYY').toString() !== 'Invalid Date'
                ? `${moment(text).format('DD-MM-YYYY')} (${
                    moment(text).fromNow().split(' ')[0]
                  } ${moment(text).fromNow().split(' ')[1]})`
                : '-'
              : '-',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'incomeProof') {
        columns.push({
          title: 'Income Proof',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) =>
            record?.kyc?.incomeProof ? (
              <a href={`${record?.kyc?.incomeProof}`}>
                <LinkOutlined />
              </a>
            ) : (
              '-'
            ),
          width: '120px',
        })
      } else if (key === 'netAvailableExposure') {
        columns.push({
          title: 'Net Available Exposure',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
        })
      } else if (key === 'hierarchy') {
        columns.push({
          title: 'Hierarchy',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
        })
      } else if (key === 'amcReceivedDate') {
        columns.push({
          title: 'Amc Received Date',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
        })
      } else if (key === 'plan') {
        columns.push({
          title: 'Plan Name',
          dataIndex: 'planId',
          key: 'planId',
          className: 'column-name-type',
          width: '120px',
          render: (text, record) => record?.plan?.name,
          filters: planData ? getUserPlan : null,
          filteredValue: filters?.planId
            ? filters?.planId?.map((item) => item?.toString())
            : null,
          filterIcon: (filtered) => (
            <FilterFilled
              style={{
                color: filters?.planId !== undefined ? '#1890ff' : undefined,
              }}
            />
          ),
        })
      } else if (key === 'clientCode') {
        columns.push({
          title: 'Client Code',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => record?.clientCode,
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'address') {
        columns.push({
          title: 'Address',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => record.address,
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'panCardNo') {
        columns.push({
          title: 'pan Card No',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => record.panCardNo,
          width: '120px',
          ...getColumnSearchProps(key),
        })
      } else if (key === 'sourceOfLead') {
        columns.push({
          title: 'Source Of Lead',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'amcInPer') {
        columns.push({
          title: 'Amc In Per',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'invoiceStatus') {
        columns.push({
          title: 'Invoice Status',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'discountStatus') {
        columns.push({
          title: 'Discount Status',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'initialExposure') {
        columns.push({
          title: 'Initial Exposure',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'agreementDataMailSent') {
        columns.push({
          title: 'Agreement Data Mail Sent',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'amcRecentAmount') {
        columns.push({
          title: 'Amc Recent Amount',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
        })
      } else if (key === 'amcInvoiceNo') {
        columns.push({
          title: 'Amc Invoice No',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
        })
      } else if (key === 'riskProfileType') {
        columns.push({
          title: 'Risk Profile Type',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'associateIntroducer') {
        columns.push({
          title: 'Associate Introducer',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'annualIncome') {
        columns.push({
          title: 'Annual Income',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          render: (text, record) => record.annualIncome,
          width: '120px',
          sorter: (a, b) => a.age - b.age,
        })
      } else if (key === 'completedSteps') {
        columns.push({
          title: 'Completed Steps',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          filters: userField,
          onFilter: (value, record) =>
            filters?.completedSteps?.includes(record.completedSteps),
          filterIcon: (filtered) => (
            <FilterFilled
              style={{
                color: filters?.completedSteps?.length ? '#1890ff' : undefined,
              }}
            />
          ),
          filteredValue: Array.isArray(filters?.completedSteps)
            ? filters.completedSteps
            : null,
        })
      } else if (key === 'registrationDate') {
        columns.push({
          title: 'Registration Date',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : '-'),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps(key),
        })
      } else if (key === 'disableDate') {
        columns.push({
          title: 'Disable Date',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps(key),
        })
      } else if (key === 'investmentDate') {
        columns.push({
          title: 'Investment Date',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps(key),
        })
      } else if (key === 'clientConsentTime') {
        columns.push({
          title: 'Client Consent Time',
          dataIndex: key,
          key: key,
          className: 'column-name-type',
          width: '120px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps(key),
        })
      } else if (key === 'clientScore') {
        columns.push({
          title: 'Client Score',
          dataIndex: key,
          key: key,
          width: '120px',
          render: (data) => (data ? data : '-'),
          className: 'column-name-type',
        })
      } else if (key === 'isPanCardIndividual') {
        columns.push({
          title: 'Pan Card Individual',
          dataIndex: key,
          key: key,
          width: '120px',
          render: (data) =>
            data === null ? '-' : data === true ? 'Yes' : 'No',
          filters: userPanCardStatus,
          className: 'column-name-type',
          filterIcon: (filtered) => (
            <FilterFilled
              style={{
                color:
                  filters?.isPanCardIndividual !== undefined
                    ? '#1890ff'
                    : undefined,
              }}
            />
          ),
          filteredValue: filters?.isPanCardIndividual
            ? filters?.isPanCardIndividual
            : null,
        })
      } else {
        columns.push({
          title: key?.split(/(?=[A-Z])/)?.join(' '),
          dataIndex: key,
          key: key,
          width: '120px',
          className: 'column-name-type',
        })
      }
    }
  }

  columns.push({
    title: 'Action',
    key: 'action',
    render: (text, record) => renderAction(record),
    width: '18%',
    fixed: !isMobile && 'right',
  })
  const handleSubmit = () => {
    setVisible(true)
  }
  // const dateFormat = 'DD-MMM-YYYY'

  // const handleDatePicker = (_value, dateString) => {
  //   setFilters((preData) => {
  //     return {
  //       ...preData,
  //       mdate: dateString,
  //     }
  //   })
  //   setOpen(false)
  // }

  // const onSearch = (event) => {
  //   const strike = parseInt(event.target.value)
  //   const debouncedSave = debounce(() => {
  //     setFilters((preData) => {
  //       return {
  //         ...preData,
  //         strikerate: strike,
  //       }
  //     })
  //   }, 1000)
  //   debouncedSave()
  // }

  // const handleOptionType = (value) => {
  //   setFilters((preData) => {
  //     return {
  //       ...preData,
  //       cp: value,
  //     }
  //   })
  //   setOpen(false)
  // }

  // const handleCompany = (value) => {
  //   setFilters((preData) => {
  //     return {
  //       ...preData,
  //       company: value,
  //     }
  //   })
  //   setOpen(false)
  // }

  const handleOpenModal = () => {
    refetch()
    setVisibleModal(!visibleModal)
    // setOpen(false)
  }

  const clearFilters = () => {
    localStorage.removeItem('filter')
    // localStorage.removeItem('selectedRow')
    localStorage.removeItem('selectAll')
    setFilters({
      role: 'CLIENT',
      mdate: '',
      strikerate: 0,
      cp: '',
      company: '',
    })
    setSort()
    setSelectAll(false)
    setSelectedRow([])
  }
  // useEffect(() => {
  //   if (getAdminFieldData) {
  //     clearFilters()
  //   }
  // }, [getAdminFieldData])
  // const showDrawer = () => {
  //   setOpen(true)
  // }

  // const onClose = () => {
  //   setOpen(false)
  // }

  return (
    <>
      <CustomMeta title='Clients' />
      {/* <PageHeader
        className='box'
        title='Clients'
        extra={[
          // <Button
          //   key='5'
          //   type='primary'
          //   loading={exportAllClientLoading}
          //   onClick={handleAllExportClientCsv}
          // >
          //   Export All Clients
          // </Button>,
          currentReport.includes('Create Client') && (
            <Button
              key='4'
              type='primary'
              disabled={_isEmpty(selectedRow)}
              onClick={handleSubmit}
            >
              Create Group
            </Button>
          ),
          // <Button
          //   key='1'
          //   disabled={_isEmpty(selectedRow)}
          //   type='primary'
          //   loading={CSVLoading}
          //   onClick={handleCsvDownload}
          // >
          //   Export Selected Clients
          // </Button>,

          currentReport.includes('Create Client') && (
            <Button key='2' type='primary' onClick={showModal}>
              Import Clients
            </Button>
          ),
          currentReport.includes('Create Client') && (
            <Button
              key='3'
              type='primary'
              onClick={() => history.push('/clients/create')}
            >
              Add Client
            </Button>
          ),
        ]}
      /> */}
      {isModalVisible && (
        <Modal
          className='feedback_form'
          title='Import Clients'
          visible
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              key='link'
              href='https://finideas-india.blr1.digitaloceanspaces.com/importClients/importClients/importClients.csv'
              type='secondary'
              loading={loading}
              style={{ marginRight: '35%' }}
            >
              Download demo CSV
            </Button>,
            <Button key='back' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={handleOk}
            >
              Ok
            </Button>,
          ]}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='csv'
              label='Please select CSV file'
              valuePropName='csv'
              getValueFromEvent={normFile}
            >
              <Upload
                name='csv-file'
                listType='text'
                {...customCsv}
                accept='.csv'
                multiple={false}
                fileList={csv}
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
              <u>
                <p>Instructions</p>
              </u>
              <ul>
                <li>Use Demo CSV to import User.</li>
                <li>
                  Please make sure import SelectedFieldsModal from
                  './SelectedFieldsModal'; User Name and email must be unique.
                </li>
                <li>
                  All the sensitive user details are valid like
                  createdById,cityId, stateId, countryId etc.
                </li>
              </ul>
            </Form.Item>
            <div className='footer-btn-div'>
              <Button
                key='link'
                href='https://finideas-india.blr1.digitaloceanspaces.com/importClients/importClients/importClients.csv'
                type='secondary'
                loading={loading}
                className='footer-cancel-btn'
              >
                Download demo CSV
              </Button>
              <Button className='footer-cancel-btn' onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className='footer-submit-btn'
                key='submit'
                type='primary'
                loading={loading}
                onClick={handleOk}
              >
                Ok
              </Button>
            </div>
          </Form>
        </Modal>
      )}

      {/* <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <Form
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <Form.Item name='mdate'>
            <DatePicker
              autoComplete='off'
              onChange={handleDatePicker}
              format={dateFormat}
            />
          </Form.Item>

          <Form.Item name='strikerate'>
            <Input
              type='text'
              allowClear
              maxLength={6}
              size={6}
              placeholder='Strike'
              autoComplete='off'
              onChange={onSearch}
              className='client-detail-input new-client-input'
              style={{ width: '100%', margin: '0' }}
            />
          </Form.Item>

          <Form.Item name='cp'>
            <Select
            getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              allowClear
              showSearch
              placeholder='Option Type'
              optionFilterProp='children'
              onChange={handleOptionType}
            >
              <Option value='CE'>CE</Option>
              <Option value='PE'>PE</Option>
              <Option value='XX'>XX</Option>
            </Select>
          </Form.Item>

          <Form.Item name='company'>
            <Select
            getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field'
              allowClear
              showSearch
              placeholder='Company'
              optionFilterProp='children'
              onChange={handleCompany}
            >
              <Option value='BANKNIFTY'>BANKNIFTY</Option>
              <Option value='QQQ'>QQQ</Option>
              <Option value='USDINR'>USDINR</Option>
              <Option value='NIFTY'>NIFTY</Option>
            </Select>
          </Form.Item>

          <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
            <Button
              type='primary'
              onClick={handleOpenModal}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Select Fields
            </Button>
            <Button
              type='primary'
              onClick={clearFilters}
              style={{
                width: '100%',
                borderRadius: '8px',
                padding: '16px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Clear All
            </Button>
          </div>
        </Form>
      </Drawer> */}

      {!!visibleModal && (
        <SelectedFieldsModal visible onCancel={handleOpenModal} />
      )}

      <div className='main_dashboard_div'>
        <div
          style={{
            display: isMobile ? 'grid' : 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div className='agreement_text'>Clients</div>
          <div
            className='option_btn_div'
            style={isMobile ? { marginTop: '10px' } : {}}
          >
            <Button className='export_btn' onClick={handleOpenModal}>
              Select Fields
            </Button>

            {currentReport.includes('Create Group') ? (
              <Button
                className='export_btn'
                disabled={
                  localStorage.getItem('selectAll') === 'true' ||
                  !_isEmpty(selectedRow)
                    ? false
                    : true
                }
                onClick={handleSubmit}
              >
                Create Group
              </Button>
            ) : (
              ''
            )}
            {currentReport.includes('Create Client') ? (
              <Button key='2' className='export_btn' onClick={showModal}>
                Import Clients
              </Button>
            ) : (
              ''
            )}
            {currentReport.includes('Create Client') ? (
              <Button
                key='3'
                className='export_btn'
                onClick={() => history.push('/clients/create')}
              >
                Add Client
              </Button>
            ) : (
              ''
            )}

            <Button onClick={clearFilters} className='export_btn'>
              Clear All
            </Button>
          </div>
        </div>
        {/* <ConfigProvider
          getPopupContainer={(trigger) => trigger.parentElement} // Ensures all dropdowns in the table (filters/search modals) scroll with the page
        > */}
        <Table
          rowSelection={
            !isClient && {
              type: 'checkbox',
              ...rowSelection,
            }
          }
          loading={loading}
          columns={columns}
          rowKey='id'
          dataSource={!loading && tableData}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          pagination={
            // isAdmin
            //   ?
            {
              pageSize: limit === 1500 ? 10 : limit,
              total: totalCount,
              onChange: handlePagination,
              current: currentPage,
              onShowSizeChange: handlePageSizeChange,
              showSizeChanger: false,
            }
            // : ''
          }
        />
        {/* </ConfigProvider> */}
      </div>

      <Modal
        className='feedback_form'
        okText='Create Group'
        title='Create Group'
        centered
        visible={visible}
        okButtonProps={{
          loading: loader,
        }}
        onOk={() => {
          form.validateFields().then((values) => {
            setLoader(true) // Start loading state
            client
              .mutate({
                mutation: CREATE_GROUP,
                variables: {
                  data: { userIds: selectedRow, name: values.name },
                },
              })
              .then((res) => {
                openNotification('success', 'Group Added Successfully')
                history.push('/groups')
                setVisible(false)
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setLoader(false)
              })
          })
        }}
        onCancel={() => setVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder='Name' />
          </Form.Item>
          <div className='footer-btn-div'>
            <Button
              className='footer-cancel-btn'
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              className='footer-submit-btn'
              loading={loader}
              onClick={() => {
                setLoader(true)
                form.validateFields().then((values) => {
                  setLoader(true)
                  client
                    .query({
                      query: GET_USERS_ID,
                      variables: {
                        isSelectAll: true,
                        where: filterData,
                        limit,
                        skip,
                        sort,
                      },
                      fetchPolicy: 'network-only',
                    })
                    .then((res) => {
                      const allIds =
                        res?.data?.getUsers?.users?.map((user) => user?.id) ||
                        []

                      client
                        .mutate({
                          mutation: CREATE_GROUP,
                          variables: {
                            data: {
                              userIds: localStorage.getItem('selectedRow')
                                ? JSON.parse(
                                    localStorage.getItem('selectedRow')
                                  ).map((id) => id)
                                : allIds,
                              name: values.name,
                            },
                          },
                        })
                        .then((res) => {
                          setLoader(false)
                          openNotification(
                            'success',
                            'Group Added Successfully'
                          )
                          history.push('/groups')
                        })
                        .catch((err) => console.log(err))
                        .finally(() => {
                          setVisible(false)
                          setLoader(false)
                        })
                    })
                    .catch((err) => {
                      console.error('Error fetching users:', err)
                      setLoader(false)
                    })
                })
              }}
              type='primary'
            >
              Ok
            </Button>
          </div>
        </Form>
      </Modal>

      {showFormModal && (
        <CreateSIPModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleSIPCreate}
          date={clientData.createdAt}
          amount={clientData.sipAmount}
          onCancel={() => {
            setClientData(undefined)
            setShowFormModal(false)
          }}
        />
      )}

      {showAMCFormModal && (
        <CreateAMCModal
          loading={showFormLoading}
          onCreate={handleAMCCreate}
          visible={showAMCFormModal}
          amount={clientData.amcAmount}
          onCancel={() => {
            setClientData(undefined)
            setAMCShowFormModal(false)
          }}
        />
      )}
    </>
  )
}
