import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      otherMailIds
      gstinNumber
      depositDate
      depositAmount
      bankAddress
      userName
      isActive
      role
      keyId
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      panCardNo
      completedSteps
      annualIncome
      cvlStatus
      clientScore
      exposure
      initialInvestment
      reEvaluate
      mandateId
      createdBy {
        id
        firstName
        lastName
        email
        phone
      }
      updatedBy {
        id
      }
      token
      disableDate
      isDisableDate
      createdAt
      updatedAt
      loginType
      entityStatus
      nameOfApplicant
      dateOfIncorporation
      placeOfIncorporation
      netWorth
      dateOfCommencement
      isPanCardIndividual
      gender
      nationality
      residentialStatus
      maritalStatus
      sourceOfIncome
      politicallyExposed
      countryOfTax
      plan {
        id
        name
      }
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
        agreementForNonIndividual
        aadharCardBack
      }
      isAmcPending
      isDueDateAmcPending
      totalDueAmcAmount
      isBookingAdvisoryDateSelected
      isNewAuaAdvisoryGenerate
      newAuaAdvisoryCompletedSteps
      umrnNumber
      isPaymentDone
      isReUploadKyc
      currentCompletedSteps
      isShowAddAua
      auaMaxLimit
      eMandateAmcAmount
      ifscCode
      bank
      accountNumber
      accountType
      mandateAmount
      currentType
      compareMandateAmount
      isFirstAdvisoryTaken
      payNowSetting {
        isMandate
        isNonMandate
      }
    }
  }
`

export const GET_EMAIL_FROM_USERNAME = gql`
  query getEmailFromUserName($where: UserNameInput!) {
    getEmailFromUserName(where: $where)
  }
`

export const SEND_PASSWORD_LINK = gql`
  query sendResetPasswordLink($userName: String!) {
    sendResetPasswordLink(userName: $userName)
  }
`
export const CHECK_PHONE_BELONGS_TO = gql`
  query checkPhoneBelongsTo($phone: String!) {
    checkPhoneBelongsTo(phone: $phone) {
      id
      firstName
      lastName
      clientCode
    }
  }
`

export const CHECK_EXIST_USERNAME = gql`
  query Query($where: UserNameExistUniqueInput!) {
    checkExistUserName(where: $where)
  }
`
export const SEND_OTP_FOR_FORGOT_PASSWORD = gql`
  mutation SendOtpForForgotPassword(
    $where: SendOtpForForgotPasswordWhereUniqueInput!
  ) {
    sendOtpForForgotPassword(where: $where)
  }
`
export const VERIFY_OTP_FOR_FORGOT_PASSWORD = gql`
  mutation VerifyOtpForForgotPassword($otp: String!) {
    verifyOtpForForgotPassword(otp: $otp)
  }
`

export const CHANGE_PASSWORD_V2 = gql`
  mutation ChangePasswordV2($newPassword: String!) {
    changePasswordV2(newPassword: $newPassword)
  }
`

export const CLIENT_SUMMARY_DETAILS = gql`
  query getCurrentUserSummary {
    getCurrentUserSummary
  }
`

export const GET_ALL_NOTIFICATION_FOR_CLIENT = gql`
  query GetNotifications($limit: Int) {
    getNotifications(limit: $limit) {
      count
      notReadCount
      notifications {
        id
        title
        isRead
        type
        createdAt
        updatedAt
      }
    }
  }
`
