import React, { useContext, useState } from 'react'
import { Button, Form, Input, Modal, Upload } from 'antd'
import { AppContext } from 'AppContext'
import { sum } from 'lodash'
import openNotification from 'utils/Notification'

const { TextArea } = Input

const ReplyTicketModal = ({
  visible,
  onClose,
  onReplyTicket,
  onCloseTicket,
  ticket,
  replyLoading,
  closeLoading,
  isReOpen,
  onReOpenTicket,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const {
    state: {
      currentUser: { id },
      isAdmin,
    },
  } = useContext(AppContext)

  const resetForm = () => {
    form.resetFields()
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    const newFileList = [...(e?.fileList || [])]
    if (newFileList.length > 6) {
      openNotification('error', 'Cannot upload more than 6 files')
      return newFileList.slice(0, 6)
    }
    return newFileList
  }

  const customProps = {
    multiple: true,
    onRemove: (file) => {
      const currentFileList = form.getFieldValue('fileUrl') || []
      const updatedFileList = currentFileList.filter(
        (item) => item.uid !== file.uid
      )
      form.setFieldsValue({ fileUrl: updatedFileList })
    },
    beforeUpload: (file, fileList) => {
      const currentFiles = fileList
      if (currentFiles.length > 6) {
        return Upload.LIST_IGNORE
      }
      setFileList(file)
      return false
    },
    fileList,
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          resetForm()
          onClose()
        }}
        title={isReOpen ? 'Re Open Ticket' : 'Reply Ticket'}
      >
        <Form form={form} layout='vertical' name='form_in_modal'>
          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                required: true,
                message: 'Description is require',
              },
            ]}
            hasFeedback
          >
            <TextArea placeholder='Description' />
          </Form.Item>

          <Form.Item
            name='fileUrl'
            label='Attachment'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            rules={[
              {
                validator: (_, value) =>
                  value?.length > 6
                    ? Promise.reject(
                        new Error('Cannot upload more than 6 files')
                      )
                    : Promise.resolve(),
              },
            ]}
          >
            <Upload
              className='reply_upload'
              style={{ width: '100% ' }}
              name='attachment'
              listType='text'
              {...customProps}
            >
              <Button style={{ width: '100% !important' }}>Select file</Button>
            </Upload>
          </Form.Item>
          <div className='create_ticket_upload_text_div'>
            <b className='create_ticket_upload_text'>
              Max 6 files allowed of 3MB each
            </b>
          </div>

          <div className='footer-btn-div'>
            <Button
              className='footer-cancel-btn'
              onClick={() => {
                resetForm()
                onClose()
              }}
            >
              Cancel
            </Button>

            {!isReOpen ? (
              <Button
                className='footer-cancel-btn'
                type='primary'
                loading={replyLoading}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      let mb = 0
                      if (values?.fileUrl) {
                        const totalSize = sum(
                          values?.fileUrl?.map((item) => item?.size)
                        )
                        mb = totalSize / 1024 / 1024
                      }
                      if (mb >= 3) {
                        openNotification(
                          'error',
                          `You can't upload more than 3 MB file`
                        )
                      } else {
                        onReplyTicket(values, resetForm)
                      }
                    })
                    .catch((info) => console.log('Validate Failed:', info))
                }}
              >
                Reply
              </Button>
            ) : (
              ''
            )}

            {(ticket?.assignUser?.id === id || isAdmin) &&
              ticket?.status === 'REVIEW' &&
              !isReOpen && (
                <Button
                  type='primary'
                  className='footer-submit-btn'
                  loading={closeLoading}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => onCloseTicket(values, resetForm))
                      .catch((info) => console.log('Validate Failed:', info))
                  }}
                >
                  Close Ticket
                </Button>
              )}

            {isReOpen ? (
              <Button
                className='footer-cancel-btn'
                type='primary'
                loading={replyLoading}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      let mb = 0
                      if (values?.fileUrl) {
                        const totalSize = sum(
                          values?.fileUrl?.map((item) => item?.size)
                        )
                        mb = totalSize / 1024 / 1024
                      }
                      if (mb >= 3) {
                        openNotification(
                          'error',
                          `You can't upload more than 3 MB file`
                        )
                      } else {
                        onReOpenTicket(values, resetForm)
                      }
                    })
                    .catch((info) => console.log('Validate Failed:', info))
                }}
              >
                Re Open
              </Button>
            ) : (
              ''
            )}
          </div>
        </Form>
      </Modal>
    </>
  )
}
export default ReplyTicketModal
