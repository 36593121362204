import gql from 'graphql-tag'
export const LOGIN = gql`
  mutation login($data: LoginCreateInput!) {
    login(data: $data) {
      token
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput) {
    resetPassword(data: $data)
  }
`

export const LOGIN_SIGNUP_WITH_GOOGLE = gql`
  mutation loginSignupWithGoogle {
    loginSignupWithGoogle
  }
`

export const SEND_SIGNUP_OTP = gql`
  mutation sendSignUpOtp($where: SignUpEmailWhereUniqueInput!) {
    sendSignUpOtp(where: $where)
  }
`

export const SEND_LOGIN_OTP = gql`
  mutation sendLoginOtp($id: ID!) {
    sendLoginOtp(id: $id)
  }
`
export const LOGIN_WITH_OTP = gql`
  mutation loginWithOtp($otp: String!) {
    loginWithOtp(otp: $otp) {
      token
    }
  }
`

export const RESEND_OTP = gql`
  mutation resendOtp($where: SignUpEmailWhereUniqueInput!) {
    resendOtp(where: $where)
  }
`

export const SEND_LOGIN_OTP_FOR_WEB = gql`
  mutation SendLoginOtpForWeb($phone: String, $email: String) {
    sendLoginOtpForWeb(phone: $phone, email: $email)
  }
`

export const LOGIN_WITH_OTP_FOR_WEB = gql`
  mutation LoginWithOtpForWeb(
    $otp: String!
    $signerUserId: String
    $newKycRegistration: Boolean
    $email: String
    $phone: String
  ) {
    loginWithOtpForWeb(
      otp: $otp
      signerUserId: $signerUserId
      newKycRegistration: $newKycRegistration
      email: $email
      phone: $phone
    )
  }
`

export const READ_NOTIFICATION = gql`
  mutation ReadNotification(
    $data: ReadNotificationInput
    $where: WhereReadNotificationInput
  ) {
    readNotification(data: $data, where: $where) {
      count
      notReadCount
      notifications {
        id
        title
        isRead
        type
        createdAt
        updatedAt
      }
    }
  }
`
