import { Modal, Descriptions, Checkbox, Button } from 'antd'
import React, { useContext, useState } from 'react'

import client from 'apollo'
import { AppContext } from 'AppContext'
import openNotification from 'utils/Notification'
import {
  ADD_LEAD,
  COMPLETE_STEP,
  FETCH_CVL_STATUS,
  UPDATE_UTM_DATA,
} from '../graphql/Mutations'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import VerificationModal from './VerificationModal'
import cities from 'utils/cities.json'
import moment from 'moment'

const VerifyDetailsShow = ({
  visible,
  onCancel,
  formValue,
  next,
  reEvaluate,
}) => {
  const { dispatch, state } = useContext(AppContext)
  const [otpModal, setOtpModal] = useState(false)
  const [agree, setAgree] = useState(false)
  const [modalClose, setModalClose] = useState(true)
  const [loadings, setLoadings] = useState()
  const {
    userName,
    email,
    phone,
    isPanCardIndividual,
    referenceBy,
    password,
    clientCode,
    nameOfApplicant,
    dateOfIncorporation,
    placeOfIncorporation,
    dateOfCommencement,
    entityStatus,
    address,
    annualIncome,
    cityId,
    netWorth,
    planId,
    panCardNo,
    stateId,
    countryId,
  } = formValue

  let { clientSummaryDetails } = state

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  const onNext = () => {
    setLoadings(true)
    const { id } = state.currentUser

    const currentIndex = clientSummaryDetails.findIndex(
      (item) => item?.name === 'Client Basic Details'
    )
    const updateSummaryUiDetails = {
      completedSteps: 'SELECT_USER_TYPE',
      name: 'Client Basic Details',
      isDisplayEdit: true,
      data: {
        'Name of Applicant': nameOfApplicant,
        'Pan of the Applicant': panCardNo,
        'Date of Incorporation':
          moment(dateOfIncorporation).format('DD-MMM-YYYY'),
        'Place of Incorporation': placeOfIncorporation,
        'Date of Commencemen': moment(dateOfCommencement).format('DD-MMM-YYYY'),
        City: cities.find((item) => item?.id === cityId)?.name,
        'Status of the entity': entityStatus,
        'Registered Address': address,
        'Mobile Number.': phone,
        'Email ID': email,
        'Annual Income': annualIncome,
        'Net Worth': netWorth,
        'Client Code': clientCode,
      },
    }
    if (currentIndex !== -1) {
      clientSummaryDetails[currentIndex] = updateSummaryUiDetails
    } else {
      clientSummaryDetails.push(updateSummaryUiDetails)
    }

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id,
          data: {
            userName,
            email,
            clientEmail: email,
            phone,
            isPanCardIndividual,
            panCardNo,
            password,
            clientCode,
            nameOfApplicant,
            dateOfIncorporation,
            placeOfIncorporation: placeOfIncorporation || null,
            dateOfCommencement: dateOfCommencement || null,
            entityStatus:
              entityStatus === 'Partnership Firm'
                ? 'partnershipFirm'
                : entityStatus,
            address: capitalAddress?.replaceAll('\n', ' '),
            annualIncome,
            cityId,
            netWorth: netWorth || null,
            planId,
            createdById: parseInt(referenceBy),
            completedSteps: 'CLIENT_PROFILE_1',
            stateId,
            countryId,
          },
        },
      })
      .then((res) => {
        client.mutate({ mutation: ADD_LEAD }).then((res) => {
          if (res?.data?.addLead) {
            client.mutate({
              mutation: UPDATE_UTM_DATA,
              variables: {
                data: {
                  isAlreadySubmitted: true,
                },
              },
            })
          }
        })
        client.mutate({ mutation: FETCH_CVL_STATUS })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_1',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        dispatch({ type: 'CLIENT_SUMMARY_DETAILS', data: clientSummaryDetails })
        openNotification('success', 'Client Basic Details Updated Successfully')
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  const onOk = () => {
    setLoadings(true)
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
        setLoadings(false)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }
  const capitalAddress = address
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
  return (
    <>
      {modalClose ? (
        <Modal
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button onClick={() => onOk()} type='primary' disabled={!agree}>
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          {' '}
          <Descriptions bordered className='client-basic-detail-table'>
            <Descriptions.Item
              label='Name of Applicant'
              span={3}
              // style={{ textTransform: 'capitalize' }}
            >
              {nameOfApplicant}
            </Descriptions.Item>
            <Descriptions.Item label='Date of Incorporation' span={3}>
              {dateOfIncorporation}
            </Descriptions.Item>
            {placeOfIncorporation && (
              <Descriptions.Item label='Place of Incorporation' span={3}>
                {placeOfIncorporation}
              </Descriptions.Item>
            )}
            {dateOfCommencement && (
              <Descriptions.Item label='Date of Commencement' span={3}>
                {dateOfCommencement}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='City' span={3}>
              {cities.filter((city) => city.id === cityId)[0]?.name}
            </Descriptions.Item>
            <Descriptions.Item label='Status of the entity' span={3}>
              {entityStatus}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {capitalAddress}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile Number' span={3}>
              +91 {phone}
            </Descriptions.Item>
            <Descriptions.Item label='E-mail' span={3}>
              {email}
            </Descriptions.Item>
            <Descriptions.Item label='Annual Income' span={3}>
              {annualIncome}
            </Descriptions.Item>
            {netWorth && (
              <Descriptions.Item label='Net Worth' span={3}>
                {netWorth}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='User Name' span={3}>
              {userName}
            </Descriptions.Item>
            <Descriptions.Item label='ClientCode' span={3}>
              {clientCode}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
          <div className='footer-btn-div'>
            <Button className='footer-cancel-btn' onClick={() => onCancel()}>
              {' '}
              Cancel{' '}
            </Button>
            <Button
              className='footer-submit-btn'
              onClick={() => onOk()}
              type='primary'
              disabled={!agree}
              loading={loadings}
            >
              Verify
            </Button>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ display: 'none' }}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered className='client-basic-detail-table'>
            <Descriptions.Item
              label='Name of Applicant'
              span={3}
              // style={{ textTransform: 'capitalize' }}
            >
              {nameOfApplicant}
            </Descriptions.Item>
            <Descriptions.Item label='Date of Incorporation' span={3}>
              {dateOfIncorporation}
            </Descriptions.Item>
            {placeOfIncorporation && (
              <Descriptions.Item label='Place of Incorporation' span={3}>
                {placeOfIncorporation}
              </Descriptions.Item>
            )}
            {dateOfCommencement && (
              <Descriptions.Item label='Date of Commencement' span={3}>
                {dateOfCommencement}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='City' span={3}>
              {cities.filter((city) => city.id === cityId)[0]?.name}
            </Descriptions.Item>
            <Descriptions.Item label='Status of the entity' span={3}>
              {entityStatus}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {capitalAddress}
            </Descriptions.Item>
            <Descriptions.Item label='Mobile Number' span={3}>
              +91 {phone}
            </Descriptions.Item>
            <Descriptions.Item label='E-mail' span={3}>
              {email}
            </Descriptions.Item>
            <Descriptions.Item label='Annual Income' span={3}>
              {annualIncome}
            </Descriptions.Item>
            {netWorth && (
              <Descriptions.Item label='Net Worth' span={3}>
                {netWorth}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='User Name' span={3}>
              {userName}
            </Descriptions.Item>
            <Descriptions.Item label='ClientCode' span={3}>
              {clientCode}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
          <div className='footer-btn-div'>
            <Button className='footer-cancel-btn' onClick={() => onCancel()}>
              {' '}
              Cancel{' '}
            </Button>
            <Button
              className='footer-submit-btn'
              onClick={() => onOk()}
              type='primary'
              disabled={!agree}
              loading={loadings}
            >
              Verify
            </Button>
          </div>
        </Modal>
      )}

      <VerificationModal
        visible={otpModal}
        onCancel={onCancel}
        onNext={onNext}
      />
    </>
  )
}

const ClientProfileModalNon1 = (props) => {
  return (
    <div>
      <VerifyDetailsShow {...props} />
    </div>
  )
}

export default ClientProfileModalNon1
