import React, { Fragment, useContext } from 'react'
import { Col, Row } from 'antd'
// import omit from 'lodash/omit'

// import Contact from './Contact'
// import About from 'components/About'
// import ProfileHeader from './ProfileHeader'
import { AppContext } from 'AppContext'
import AboutNew from 'components/About/AboutNew'
import CustomMeta from 'components/CustomMeta'
// import Steps from '../Clients/components/Steps'

export default function () {
  const {
    state: { currentUser, isClient },
  } = useContext(AppContext)

  return (
    <Fragment>
      <CustomMeta title='Profile' />
      {/* <ProfileHeader /> */}
      <div className='gx-profile-content'>
        <Row>
          {isClient && (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {/* <Steps clientId={currentUser.id} completedStep={currentUser.completedSteps} isClient={true} /> */}
            </Col>
          )}
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {/* <About isProfile details={omit(currentUser, 'email', 'phone', 'userName', 'clientCode', 'strategyCode')} /> */}
            <AboutNew isProfile details={currentUser} />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            {/* <Contact /> */}
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}
