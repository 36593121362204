import gql from 'graphql-tag'

export const UPDATE_TICKET = gql`
  mutation updateTicket($data: TicketUpdateInput!, $id: ID!) {
    updateTicket(data: $data, where: { id: $id }) {
      id
    }
  }
`

export const CREATE_TICKET = gql`
  mutation createTicket($data: TicketCreateInput!) {
    createTicket(data: $data) {
      id
      ticketNumber
    }
  }
`

export const REPLY_TICKET_MODULE = gql`
  mutation ReplyTicket(
    $where: TicketWhereUniqueInput!
    $data: TicketReplyWhere
  ) {
    replyTicket(where: $where, data: $data)
  }
`
