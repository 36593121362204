import React, { useState } from 'react'
import { Input, Modal, Button, Upload, Form } from 'antd' // , Button, message, Upload
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const { TextArea } = Input

// const propsData = {
//   name: 'file',
//   action: '//jsonplaceholder.typicode.com/posts/',
//   headers: {
//     authorization: 'authorization-text',
//   },
//   onChange(info) {
//     if (info.file.status !== 'uploading') {
//       console.log(info.file, info.fileList)
//     }
//     if (info.file.status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully`)
//     } else if (info.file.status === 'error') {
//       message.error(`${info.file.name} file upload failed.`)
//     }
//   },
// }

function ComposeMail(props) {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  // const [to, setTo] = useState(props.to || '')
  const [fileList, setFileList] = useState(undefined)
  const [form] = Form.useForm()
  const { to, onMailSend, onClose, composeMailLoading } = props

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onMailSend(values)
        // form.resetFields()
      })
      .catch((info) => console.log('Validate Failed:', info))
  }

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  return (
    <Modal
      onCancel={onClose}
      visible={props.open}
      title='New Message'
      style={{ zIndex: 2600 }}
      onOk={() => handleSubmit()}
      okText={composeMailLoading ? 'Sending...' : 'Send'}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{ toEmailAddress: to }}
      >
        <Form.Item
          label='Email'
          name='toEmailAddress'
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input disabled margin='normal' placeholder='Enter email' />
        </Form.Item>
        <Form.List name='toEmailsAddress'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Item
                    {...restField}
                    style={{
                      width: '95%',
                      marginRight: '5px',
                    }}
                    label={`Email (${key + 1})`}
                    name={[name, `toEmailAddress_${key}`]}
                    fieldKey={[fieldKey, `toEmailAddress_${key}`]}
                    rules={[
                      { required: true, message: 'Please input your email!' },
                    ]}
                  >
                    <Input margin='normal' placeholder='Enter email' />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Email
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          name='subject'
          label='Subject'
          rules={[
            { required: true, message: 'Please input your subject email!' },
          ]}
        >
          <Input
            margin='normal'
            value={subject}
            placeholder='Enter Your Subject'
            onChange={(event) => setSubject(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name='html'
          label='Message'
          rules={[
            { required: true, message: 'Please input your message email!' },
          ]}
        >
          <TextArea
            rows={4}
            margin='normal'
            value={message}
            placeholder='Enter Your Message'
            autosize={{ minRows: 3, maxRows: 6 }}
            onChange={(event) => setMessage(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          name='attachment'
          label='Attachment'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <Upload
            accept='.jpeg,.png,.jpg,.pdf,.docx,.doc,.txt'
            listType='text'
            {...customProps}
          >
            <Button type='primary'>
              <i
                className='icon icon-attachment'
                style={{ fontSize: '20px' }}
              />
              {' '}
              <span>Attach File</span>
            </Button>
          </Upload>
        </Form.Item>

        <Button type='primary' onClick={() => handleSubmit()}>
          {composeMailLoading ? 'Sending...' : 'Send'}
        </Button>
      </Form>
    </Modal>
  )
}

export default ComposeMail
