import React, { useEffect, useState } from 'react'
import { Button, Select } from 'antd'
import ReactQuill from 'react-quill'
import { UPDATE_TEMPLATE_DATA } from '../graphql/Mutations'
import 'react-quill/dist/quill.snow.css'
import client from 'apollo'
import { GET_TEMPLATE_DATA } from '../graphql/Queries'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'

const AmcMailFormat = () => {
  const [active, setActive] = useState('E_MANDATE')
  const [loading, setLoading] = useState(false)

  const [templateHtml, setTemplateHtml] = useState('')

  useEffect(() => {
    client
      .query({
        query: GET_TEMPLATE_DATA,
        variables: { type: active },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setTemplateHtml(res?.data?.getTemplateData?.htmlData)
      })
  }, [active])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleValue = (content, delta, source, editor) => {
    setTemplateHtml(editor.getHTML())
  }
  const updateCode = () => {
    setLoading(true)
    client
      .mutate({
        mutation: UPDATE_TEMPLATE_DATA,
        variables: {
          type: active,
          htmlData: templateHtml.replaceAll('${', '${'),
        },
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Mail Format Change Successfully')
      })
      .catch((err) => console.log(err))
  }

  const handleChange = (value) => {
    setActive(value)
  }

  const options = [
    {
      value: 'E_MANDATE',
      label: 'E_MANDATE',
    },
    {
      value: 'NON_E_MANDATE',
      label: 'NON_E_MANDATE',
    },
  ]

  return (
    <div className='main_dashboard_div'>
      <CustomMeta title='AMC Mail Format' />
      <div className='agreement_main_div'>
        <div className='agreement_text'>Mail Format</div>
      </div>

      <Select
        style={{
          width: 120,
          marginBottom: '20px',
        }}
        getPopupContainer={(trigger) => trigger.parentElement}
        value={active}
        placeholder='Tags Mode'
        onChange={handleChange}
        options={options}
      />

      <ReactQuill
        theme='snow'
        value={templateHtml}
        onChange={handleValue}
        modules={modules}
        formats={formats}
      />

      <div className='agreement_btn_div'>
        <Button
          onClick={updateCode}
          type='primary'
          className='agreement_submit_btn'
          loading={loading}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default AmcMailFormat
