import React, { useContext, useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Select, Upload, Button, Radio } from 'antd'
import '../../Verification/verification.css'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_CATEGORIES } from 'modules/Category/graphql/Queries'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import client from 'apollo'
import { GET_USERS } from 'modules/Users/graphql/Queries'
import { sum } from 'lodash'
import openNotification from 'utils/Notification'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  loading,
  fromFaq,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [isForClient, setIsForClient] = useState(true)
  const [where, setWhere] = useState({ role: 'CLIENT' })
  const [users, setUsers] = useState([])
  const [userLoading, setUserLoading] = useState(false)

  const {
    state: {
      currentUser: { firstName, lastName, clientCode, id },
      currentUser,
      isClient,
    },
  } = useContext(AppContext)

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (!isClient) {
      setUserLoading(true)
      client
        .query({
          query: GET_USERS,
          variables: { where, skip: 0, limit: 1000 },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          const userArray = res?.data?.getUsers?.users
          if (
            !isForClient &&
            !userArray.some((user) => user.id === currentUser?.id)
          ) {
            userArray.push(currentUser)
          }
          setUsers(userArray)
          setUserLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setUserLoading(false)
        })
    }
  }, [isClient, isForClient, where, currentUser])

  const {
    data: categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(GET_ALL_CATEGORIES, { fetchPolicy: 'network-only' })

  if (categoriesError) return <Page404 error={categoriesError} />

  function resetForm() {
    form.resetFields()
  }

  // const subjects = [
  //   { text: 'Report', value: 'Report' },
  //   { text: 'Staff', value: 'Staff' },
  //   { text: 'Technical', value: 'Technical' },
  //   { text: 'Others', value: 'Others' },
  // ]

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    const newFileList = [...(e?.fileList || [])]
    if (newFileList.length > 6) {
      openNotification('error', 'Cannot upload more than 6 files')
      return newFileList.slice(0, 6)
    }
    return newFileList
  }

  const customProps = {
    multiple: true,
    onRemove: (file) => {
      const currentFileList = form.getFieldValue('fileUrl') || []
      const updatedFileList = currentFileList.filter(
        (item) => item.uid !== file.uid
      )
      form.setFieldsValue({ fileUrl: updatedFileList })
    },
    beforeUpload: (file, fileList) => {
      const currentFiles = fileList
      if (currentFiles.length > 6) {
        return Upload.LIST_IGNORE
      }
      setFileList(file)
      return false
    },
    fileList,
  }

  const onFinish = (values) => {
    const assignUser = categories?.getCategories
      ?.find((item) => item?.id === values?.category)
      ?.subCategories?.find(
        (item) => item?.id === values?.subCategory
      )?.defaultAssigne

    const data = {
      description: values?.description,
      fileUrl: values?.fileUrl?.length ? values?.fileUrl : null,
      categoryId: +values?.category,
      subCategoryId: +values?.subCategory,
      generatedFor: isClient ? +id : +initialValues?.id,
      isForClient,
      assignUserId: assignUser ? +assignUser?.id : null,
    }

    form
      .validateFields()
      .then((values) => {
        let mb = 0
        if (data?.fileUrl) {
          const totalSize = sum(data?.fileUrl?.map((item) => item?.size))
          mb = totalSize / 1024 / 1024
        }
        if (mb >= 3) {
          openNotification('error', `You can't upload more than 3 MB file`)
        } else {
          onCreate(data, resetForm)
        }
      })
      .catch((info) => console.log('Validate Failed:', info))
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
          }}
        >
          <span>Generate Ticket</span>
          <img
            src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
            alt='Finideas'
            width={150}
            height={50}
          />
        </div>
      }
      okText={'Generate'}
      okButtonProps={{
        loading,
      }}
      className='create-ticket-modal'
      footer={null}
      onCancel={() => {
        resetForm()
        onCancel()
      }}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'start' }}>
        <img
          src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
          alt='Finideas'
          width={150}
          height={50}
        />
      </div> */}
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          clientName: isClient ? firstName + ' ' + lastName : '',
          clientCode: isClient ? clientCode : '',
        }}
        onFinish={onFinish}
      >
        {!isClient ? (
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setInitialValues({})
                setIsForClient(e.target.value)
                setUsers([])
                if (e.target.value) {
                  setWhere({ role: 'CLIENT' })
                } else {
                  setWhere({
                    role: [
                      'MASTER_ASSOCIATE',
                      'ASSOCIATE',
                      'STAFF',
                      'SALES_EXECUTIVE',
                    ],
                    createdById: +id,
                  })
                }
              }}
              value={isForClient}
            >
              <Radio value={true} disabled={userLoading}>
                Client
              </Radio>
              <Radio value={false} disabled={userLoading}>
                Associate
              </Radio>
            </Radio.Group>
          </Form.Item>
        ) : (
          <></>
        )}

        {isClient ? (
          <>
            <Form.Item
              name='clientName'
              label='Client Name'
              rules={[
                {
                  required: true,
                  message: 'Please write your client name!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder='Write your client name'
                value={`${firstName} ${lastName}`}
                disabled
              />
            </Form.Item>

            <Form.Item
              name='clientCode'
              label='Client Code'
              rules={[
                {
                  required: true,
                  message: 'Please write your client code!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder='Write your client code'
                value={clientCode}
                disabled
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label={isForClient ? 'Client Name' : 'Select user'}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please write your client name!',
                  whitespace: true,
                },
              ]}
            >
              <Select
                loading={userLoading}
                placeholder={isForClient ? 'Select Client' : 'Select User'}
                className='display-field'
                showSearch
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, option) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(e) =>
                  setInitialValues(users?.find((item) => item?.id === e))
                }
                style={{ width: '100%' }}
                value={initialValues?.id}
              >
                {users?.map((user, index) => {
                  return (
                    <Option value={user?.id} key={index}>{`${
                      user?.clientCode || user?.userName
                    } - ${user?.firstName} ${user?.lastName} `}</Option>
                  )
                })}
              </Select>
            </Form.Item>

            {!isForClient ? (
              <>
                <Form.Item label='User name'>
                  <Input
                    placeholder='Write your user name'
                    disabled
                    value={initialValues?.userName}
                  />
                </Form.Item>
                <Form.Item label='User Type'>
                  <Input
                    placeholder='Write your user type'
                    disabled
                    value={
                      initialValues?.role === 'SALES_EXECUTIVE'
                        ? 'AIF_SALES_EXECUTIVE'?.split('_')?.join(' ')
                        : initialValues?.role?.split('_')?.join(' ')
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item label='Client Code'>
                <Input
                  placeholder='Write your client code'
                  disabled
                  value={initialValues?.clientCode}
                />
              </Form.Item>
            )}
          </>
        )}

        <Form.Item
          name='category'
          label='Category'
          rules={[
            {
              required: true,
              message: 'Please choose your Category!',
              whitespace: true,
            },
          ]}
        >
          <Select
            className='display-field'
            loading={categoriesLoading}
            showSearch
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder='Choose Subject'
            filterOption={(input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(e) => {
              form.setFieldsValue({ subCategory: undefined })
              setSubCategories(
                categories?.getCategories?.find((item) => item?.id === e)
                  ?.subCategories ?? []
              )
            }}
          >
            {categories?.getCategories.map((subject) => (
              <Option key={subject.category} value={subject.id}>
                {subject.category}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='subCategory'
          label='Sub Category'
          rules={[
            {
              required: true,
              message: 'Please choose your sub category!',
              whitespace: true,
            },
          ]}
        >
          <Select
            className='display-field'
            showSearch
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder='Choose Subject'
            filterOption={(input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {subCategories.map((subject) => (
              <Option key={subject.subCategory} value={subject.id}>
                {subject.subCategory}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='description'
          label={fromFaq ? 'Describe your issue in detail' : 'Description'}
          rules={[
            {
              required: true,
              message: 'Please write your description!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input.TextArea
            placeholder='Please attach a screenshot/image to help answer your better.'
            rows={5}
          />
        </Form.Item>

        <Form.Item
          name='fileUrl'
          label='File'
          valuePropName='fileList'
          className='file_upload'
          getValueFromEvent={normFile}
          rules={[
            {
              validator: (_, value) =>
                value?.length > 6
                  ? Promise.reject(new Error('Cannot upload more than 6 files'))
                  : Promise.resolve(),
            },
          ]}
        >
          <Upload
            name='attachment'
            listType='text'
            {...customProps}
            accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
          >
            <Button>
              <UploadOutlined /> Select file
            </Button>
          </Upload>
        </Form.Item>
        <div className='create_ticket_upload_text_div'>
          <b className='create_ticket_upload_text'>
            Max 6 files allowed of 3MB each
          </b>
        </div>

        <Form.Item style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateTicketModal
