import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Col, DatePicker } from 'antd'
import get from 'lodash/get'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import { generateUserPerformanceData } from 'utils/misc'
import { GET_DASHBOARD_USER_PERFORMANCE } from 'modules/Dashboard/graphql/Queries'
import Loader from 'components/loaders/Loader'
import moment from 'moment'
import IconWithTextCardNew from '../IconWithTextCardNew'

import './index.css'

const { RangePicker } = DatePicker

export default function () {
  let userPerformanceCounter
  const [toDate, setToDate] = useState(undefined)
  const [fromDate, setFromDate] = useState(undefined)
  const {
    state: { selectedGroup, selectedAssociate, selectedStrategy },
  } = useContext(AppContext)
  const { data, loading } = useQuery(GET_DASHBOARD_USER_PERFORMANCE, {
    variables: {
      where: {
        groupId: selectedGroup,
        fromDate,
        toDate,
        associateId: selectedAssociate,
        strategy: selectedStrategy,
      },
    },
    fetchPolicy: 'network-only',
  })

  if (get(data, 'userPerformanceCounter')) {
    userPerformanceCounter = generateUserPerformanceData(
      get(data, 'userPerformanceCounter')
    )
  }

  return (
    <>
      <Col
        xl={24}
        lg={24}
        md={24}
        sm={24}
        xs={24}
        style={{
          textAlign: 'end',
          marginBottom: '1rem',
        }}
      >
        <RangePicker
          getPopupContainer={(trigger) => trigger.parentElement}
          style={{ borderRadius: '8px' }}
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            if (e) {
              setFromDate(moment(e[0]).format('YYYY-MM-DD'))
              setToDate(moment(e[1]).format('YYYY-MM-DD'))
            } else {
              setFromDate(undefined)
              setToDate(undefined)
            }
          }}
        />
      </Col>
      {loading ? (
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ margin: '2rem 0' }}
        >
          <Loader />
        </Col>
      ) : userPerformanceCounter ? (
        userPerformanceCounter.map((data, key) => (
          <Col key={key} xl={4} lg={4} md={4} sm={4} xs={24}>
            <IconWithTextCardNew {...data} />
          </Col>
        ))
      ) : (
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ textAlign: 'center' }}
        >
          <NoData />
        </Col>
      )}
    </>
  )
}
