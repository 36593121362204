import React, { useRef } from 'react'
import { ItemTypes } from 'modules/Questionnaires/ItemTypes'
import { useDrag, useDrop } from 'react-dnd'
import { UPDATE_CATEGORY } from './graphql/Mutations'
import client from 'apollo'

const style = {
  border: '1px dashed gray',
  borderRadius: '5px',
  padding: '0.5rem 1rem',
  marginBottom: '.8rem',
  backgroundColor: 'white',
  cursor: 'move',
}

export const Cards = (props) => {
  const { id, text, index, moveCard } = props
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.sections.forEach((section) => {
        client
          .mutate({
            mutation: UPDATE_CATEGORY,
            variables: {
              where: { id: section.id },
              data: { index: section.index },
            },
          })
          .catch((err) => console.log(err))
      })
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <>
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        {text}
      </div>
    </>
  )
}
