import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Form,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Drawer,
  Popover,
} from 'antd'

import '../index.css'
import { getName } from 'utils/User'
import Page404 from 'components/Page404'
import IltsShiftTable from './IltsShiftTable'
import {
  GET_ILTS_SHIFT,
  GET_NIFTY,
  GET_SCENARIO_ANALYSIS_PDF,
} from '../graphql/Queries'
import { GET_CLIENT_USERS_FOR_STAFF } from 'modules/Users/graphql/Queries'
import Loader from 'components/loaders/Loader'
import moment from 'moment'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { isEmpty, orderBy } from 'lodash'
import {
  GET_ANALYSIS_EXPIRY_DATE,
  GET_SP_ANALYSIS_EXPIRY_DATE,
} from 'modules/AnalysisExpiryDate/graphql/Queries'
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { CSVLink } from 'react-csv'
import words from 'utils/words'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import CustomMeta from 'components/CustomMeta'

const { Option } = Select

export default function IltsShift() {
  const [form] = Form.useForm()
  let [usersData, setUsersData] = useState([])
  const [userLoadingClient, setUserLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [AnalysisExpiryDate, setAnalysisExpiryDate] = useState({})
  const [niftyData, setNiftyData] = useState({})
  const [ieltsShift, setIeltsShift] = useState([])
  const [csvContent, setCsvContent] = useState([])

  const {
    state: { currentUser, isClient, isMobile },
  } = useContext(AppContext)

  const [newLevelData, setNewLevelData] = useState(
    !isClient ? undefined : 19000
  )

  const [filters, setFilters] = useState(null)
  const [selectedClientName, setSelectedClientName] = useState()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [error, setError] = useState()
  const [expiryDates, setExpiryDates] = useState([])

  useEffect(() => {
    if (!isEmpty(filters) && !userLoadingClient) {
      setLoading(true)
      client
        .query({
          query: GET_ILTS_SHIFT,
          variables: filters,
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setData(res?.data)
          setLoading(false)
        })
        .catch((err) => {
          setError(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
  }, [filters, userLoadingClient])

  let year = moment().format('YYYY')
  let month = moment().format('MM')
  let date = moment().format('DD')

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setUserLoading(true)
    client
      .query({ query: GET_ANALYSIS_EXPIRY_DATE, fetchPolicy: 'network-only' })
      .then((response) => {
        setAnalysisExpiryDate(response?.data)
        client
          .query({
            query: GET_SP_ANALYSIS_EXPIRY_DATE,
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setExpiryDates(res?.data?.getSPExpiryDate)
          })
          .catch((error) => {
            console.log(error)
            setError(error)
            setUserLoading(false)
          })
        client
          .query({ query: GET_NIFTY, fetchPolicy: 'network-only' })
          .then((newResponse) => {
            setNiftyData(newResponse?.data)
            client
              .query({
                query: GET_CLIENT_USERS_FOR_STAFF,
                variables: { where: {}, limit: 100000 },
              })
              .then((res) => {
                const users = res?.data?.getClientUsersForStaff?.users
                setUsersData(users)
                setUserLoading(false)
              })
              .catch((error1) => {
                setError(error1)
                console.log(error1)
                setUserLoading(false)
              })
          })
          .catch((error) => {
            console.log(error)
            setError(error)
            setUserLoading(false)
          })
      })
      .catch((err) => {
        setError(false)
        console.log(err)
        setUserLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(data?.ieltsShift) && !loading) {
      setIeltsShift(
        data?.ieltsShift?.response?.filter((item) => {
          const normalizedParticulars = item.particulars
            .replace(/\s+/g, ' ')
            .trim()
            .toLowerCase()
          return !normalizedParticulars.includes('shifted to ')
        })
      )
      const ieltsShift = data?.ieltsShift?.response?.filter((item) => {
        const normalizedParticulars = item.particulars
          .replace(/\s+/g, ' ')
          .trim()
          .toLowerCase()
        return !normalizedParticulars.includes('shifted to ')
      })
      const clientCode = data?.ieltsShift?.clientCode
      const clientName = data?.ieltsShift?.clientName

      let csvInput = ieltsShift?.map((obj) => {
        const newObj = {}
        for (const key in obj) {
          const newKey = key.charAt(0).toUpperCase() + key.slice(1)
          let value = obj[key]
          if (typeof value === 'number') {
            value = `"${convertNumberToCommaSeparate(value)}"`
          } else if (typeof value === 'string' && value.includes(',')) {
            value = `"${value}"`
          }

          newObj[newKey] = value
        }
        return newObj
      })

      const customData = [
        'Client Code:',
        `${clientCode ? clientCode : ''}`,
        '',
        '',
        'Client Name:',
        `${clientName ? clientName : ''}`,
      ].join(';')

      const keys = Object.keys(csvInput[0])
      const dataArray = csvInput.map((obj) => keys.map((key) => obj[key] ?? ''))
      const output = [keys, ...dataArray]

      const separator = ';'
      const csvContent = [
        customData,
        '',
        output.map((row) => row.join(separator)).join('\n'),
      ].join('\n')

      setCsvContent(csvContent)
    }
  }, [data, loading])

  // let selectedClient
  // if (
  //   !userLoading &&
  //   get(userData, 'getUsers.users') &&
  //   get(filters, 'where.clientCode')
  // ) {
  //   selectedClient = get(userData, 'getUsers.users').filter(
  //     (u) => u.clientCode === filters.where.clientCode
  //   )[0]
  // }

  useEffect(() => {
    if (AnalysisExpiryDate && niftyData && !userLoadingClient) {
      const expiryDate =
        AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate
      const nifty = Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000

      const defaultFilters = {
        where: {
          clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
          analysisLevel: nifty,
          analysisExp: expiryDate,
          final: new Date().toISOString(),
          step: 3000,
          intRate: 9,
          round: 1000,
        },
      }
      setFilters(defaultFilters)
    }
  }, [AnalysisExpiryDate, niftyData, isClient, currentUser, userLoadingClient])

  if (userLoadingClient || loading) return <Loader />

  const columns = [
    {
      title: 'Particulars',
      dataIndex: 'particulars',
      key: 'particulars',
      className: 'report-text',
    },
    {
      title: 'Level1',
      dataIndex: 'level1',
      key: 'level1',
      className: 'report-numeric',
      render: (text) => convertNumberToCommaSeparate(text),
    },
    {
      title: 'Level2',
      dataIndex: 'level2',
      key: 'level2',
      className: 'report-numeric',
      render: (text) => convertNumberToCommaSeparate(text),
    },
    {
      title: 'Level3',
      dataIndex: 'level3',
      key: 'level3',
      className: 'report-numeric',
      render: (text) => convertNumberToCommaSeparate(text),
    },
    {
      title: 'Level4',
      dataIndex: 'level4',
      key: 'level4',
      className: 'report-numeric',
      render: (text) => convertNumberToCommaSeparate(text),
    },
    {
      title: 'Level5',
      dataIndex: 'level5',
      key: 'level5',
      className: 'report-numeric',
      render: (text) => convertNumberToCommaSeparate(text),
    },
  ]

  const onFinish = (values) => {
    setNewLevelData(values?.analysisLevel)
    const data = {
      ...values,
      analysisExp: moment(values.analysisExp).format('YYYY-MM-DD'),
      final: new Date(moment(values.final).add(330, 'minutes')).toISOString(),
      intRate: values.intRate,
    }
    setFilters({ where: data })
    setOpen(false)
  }

  const handleDownloadPdf = () => {
    setPdfLoading(true)
    client
      .query({
        query: GET_SCENARIO_ANALYSIS_PDF,
        variables: { where: filters?.where },
      })
      .then((res) => {
        setPdfLoading(false)
        window.open(res?.data?.getScenarioAnalysisPDF, '_blank')
      })
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={csvContent}
        separator=';'
        className='csv-btn'
        filename={`ieltsShift-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Report | Scenario Analysis' />
      <Drawer
        // title='Filter'
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div className='Filter_Form'>
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div className='filter_panel_heading'>Filter</div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          initialValues={{
            step: 3000,
            intRate: 9,
            round: 1000,
            analysisLevel:
              niftyData &&
              Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000,
            clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
            analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate
              ? AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate
              : `${year}-12-26`,
            final: moment(`${year}-${month}-${date}`),
          }}
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <Form.Item
              name='clientCode'
              label='User'
              rules={[{ required: true, message: 'Please select users!' }]}
              hasFeedback
            >
              {!isClient ? (
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    const searchableValues = [option?.title, option?.value]
                    return searchableValues.some((value) =>
                      value?.toLowerCase().includes(input.toLowerCase())
                    )
                  }}
                  optionFilterProp='title'
                  placeholder='Please select users'
                  onChange={(value, option) => {
                    setSelectedClientName(`${value} - ${option?.title}`)
                  }}
                >
                  {!userLoadingClient &&
                    usersData.length &&
                    orderBy(usersData, 'id', 'desc').map((user, key) => {
                      return (
                        user.clientCode && (
                          <>
                            <Option
                              key={key}
                              value={user.clientCode}
                              title={`${getName(user)}`}
                            >
                              {user.clientCode} - {getName(user)}
                            </Option>
                          </>
                        )
                      )
                    })}
                </Select>
              ) : (
                <Input name='clientCode' readOnly />
              )}
            </Form.Item>
            <Form.Item
              name='analysisLevel'
              label='New Level'
              rules={[
                {
                  required: true,
                  message: 'Please Select Analysis Level!',
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                placeholder='Please Select Analysis Level'
                style={{ width: '100%', margin: '0' }}
              >
                {Array.from({ length: 9 }, (_, i) => {
                  const defaultLevel =
                    Math.round(niftyData?.getNifty50[0]?.CMP / 1000) * 1000
                  const value = defaultLevel + (i - 4) * 1000
                  return (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name='analysisExp'
              label='New Expiry'
              rules={[{ required: true, message: 'Please select date!' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                placeholder='Please select Expiry Date'
                style={{ width: '100%', margin: '0' }}
              >
                {expiryDates?.map((date) => (
                  <Option
                    key={date.ExpiryDate}
                    value={date.ExpiryDate}
                    title={moment(date.ExpiryDate, 'YYYY-MM-DD').format(
                      'YYYY-MM-DD'
                    )}
                  >
                    {moment(date.ExpiryDate).format('YYYY-MM-DD')}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='final'
              label='Reporting Date'
              rules={[{ required: true, message: 'Please select date!' }]}
            >
              <DatePicker
                // format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete='off'
                showToday={false}
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='step'
              label='Interval'
              rules={[{ required: true, message: 'Please Select step!' }]}
            >
              <InputNumber
                className='display-field-input-number'
                placeholder='Step'
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='intRate'
              label='Interest'
              rules={[{ required: true, message: 'Please Select intRate!' }]}
            >
              <InputNumber
                className='display-field-input-number'
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                style={{ width: '100%', margin: '0' }}
              />
            </Form.Item>
            <Form.Item
              name='round'
              label='Round'
              rules={[{ required: true, message: 'Please select users!' }]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                loading={userLoadingClient}
                optionFilterProp='title'
                placeholder='Please select users'
                style={{ width: '100%', margin: '0' }}
              >
                <Option value={1}>1</Option>
                <Option value={10}>10</Option>
                <Option value={100}>100</Option>
                <Option value={1000}>1000</Option>
                <Option value={10000}>10000</Option>
                <Option value={100000}>Lakhs</Option>
                <Option value={1000000}>Millions</Option>
                <Option value={10000000}>Crores</Option>
              </Select>
            </Form.Item>
          </div>
          <div className='filter_panel_button'>
            <Button
              onClick={() => {
                form.resetFields()
                setFilters({
                  where: {
                    clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
                    analysisLevel: 19000,
                    analysisExp: AnalysisExpiryDate?.getAnalysisExpiryDate
                      ? AnalysisExpiryDate?.getAnalysisExpiryDate[0]?.expiryDate
                      : '2024-12-26',
                    final: new Date().toISOString(),
                    step: 3000,
                    intRate: 9,
                    round: 1000,
                  },
                })
                setOpen(false)
              }}
            >
              Clear
            </Button>
            <Button loading={loading} type='primary' htmlType='submit'>
              Search Scenario Analysis
            </Button>
          </div>
        </Form>
      </Drawer>
      {!userLoadingClient && !loading && ieltsShift?.length ? (
        <div className='report_Container'>
          <div className='container_Header'>
            <div className='report_Heading'> Scenario Analysis</div>
            <div className='container_Export_Popover'>
              <Popover content={content} placement='bottom' trigger='click'>
                <Button loading={pdfLoading}>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </Popover>

              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',
                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>

          <div className='report_first_Container'>
            <div className='report_Inner_Container'>
              <div className='Client_Info_Container'>
                {currentUser?.role === 'CLIENT' && (
                  <div className='client_Info'>
                    Client Code:&nbsp;
                    <span>{currentUser.clientCode}</span>
                  </div>
                )}
                <div className='client_Info'>
                  Client Name:&nbsp;
                  <span>
                    {!isClient
                      ? selectedClientName
                      : `${currentUser.firstName} ${currentUser.lastName}`}
                  </span>
                </div>
              </div>
              <div className='sShift_Round_Client_Info'>
                <span>
                  {' '}
                  Value (₹ in{' '}
                  {(() => {
                    const roundMap = {
                      1: 'Ones',
                      10: 'Tens',
                      100: 'Hundreds',
                      1000: 'Thousands',
                      10000: 'Ten Thousands',
                      100000: 'Lakhs',
                      1000000: 'Millions',
                      10000000: 'Crores',
                    }

                    return (
                      roundMap[filters?.where?.round] ||
                      words(filters?.where?.round).replace(/\b\w/g, (char) =>
                        char.toUpperCase()
                      )
                    )
                  })()}
                  )
                </span>
              </div>
            </div>
          </div>
          {error && !loading && !userLoadingClient && !ieltsShift?.length ? (
            <Page404 error={error} isShow={false} />
          ) : (
            <div className='report-table-wrapper' id='ieltsShift-REPORT-PDF'>
              <IltsShiftTable
                reportData={ieltsShift || []}
                columns={columns}
                loading={loading}
                newLevelData={newLevelData}
              />
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}
