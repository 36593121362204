import React, { useContext, useState } from 'react'
import { Button, notification } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import PlayStore from 'assets/images/new-images/play-store.svg'
import AppStore from 'assets/images/new-images/app-store.svg'
import PhoneIcon from 'assets/images/new-images/phone-icon.svg'
// import RightArrowWhiteIcon from 'assets/images/new-images/right-arrow-white.svg'
// import ReSubmitIcon from 'assets/images/new-images/resubmit-icon.svg'
// import RightArrowIconBlack from 'assets/images/new-images/right-arrow-black.svg'
// import RightArrowIconWhite from 'assets/images/new-images/right-arrow-white.svg'
// import CheckIconWhite from 'assets/images/new-images/check-icon-white.svg'
import CreateTicketModal from 'modules/Tickets/CreateTicketModal'

import { AppContext } from 'AppContext'
import { get } from 'lodash'
import client from 'apollo'
import { CREATE_TICKET } from 'modules/Tickets/graphql/Mutations'

const Footer = ({ currentUser, handleSearchClick }) => {
  const {
    state: { isClient },
  } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    if (values.fileUrl) {
      let store = []
      values.fileUrl.forEach((data) => {
        if (data?.originFileObj) {
          const file = get(data, 'originFileObj')
          store.push(file)
        } else {
          store.push(data.url)
        }
      })
      values.fileUrl = store
    } else {
      values.fileUrl = null
    }
    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { data: { ...values } },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }
  return (
    <div className='footer-container'>
      <div className='load_ai_button' onClick={handleSearchClick}>
        <SearchOutlined /> Search
      </div>
      <div className='footer-content'>
        <div>
          <img src={AppStore} alt='AppStore' />
        </div>
        <div>
          <img src={PlayStore} alt='PlayStore' />
        </div>
        <div className='phone-info'>
          <div style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
            <div style={{ marginBottom: '2px' }}>
              <img src={PhoneIcon} alt='PhoneIcon' />
            </div>
            <div>
              <div
                style={{
                  textAlign: 'end',
                  color: '#AF0000',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                {currentUser?.createdBy &&
                  currentUser?.createdBy.firstName +
                    ' ' +
                    currentUser?.createdBy.lastName}
              </div>
              <div
                style={{
                  color: '#00000',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {currentUser?.createdBy && currentUser.createdBy.phone
                  ? currentUser.createdBy.phone
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            // type='primary'
            className='create-ticket-button'
            onClick={() => {
              if (currentUser?.completedSteps === 'DONE' || !isClient) {
                setShowFormModal(true)
              } else {
                window.location.replace('/tickets')
              }
            }}
          >
            Create Ticket
          </Button>
        </div>
        {showFormModal && (
          <CreateTicketModal
            visible={showFormModal}
            loading={showFormLoading}
            onCreate={handleCreateTicket}
            onCancel={() => setShowFormModal(false)}
            fromFaq={false}
          />
        )}
      </div>

      {/* {isClient && currentUser?.completedSteps !== 'DONE' && !showFAQScreen ? (
        currentUser?.completedSteps === 'CONGRATULATION' ? (
          <div>
            {(showFooterBtn.isConfirm ||
              showFooterBtn.isPendingAccept ||
              showFooterBtn.isAdvisoryAccept) && (
              <Button
                loading={isAcceptLoading}
                onClick={() => {
                  let ele = document.getElementById('confirm-btn')
                  ele.click()
                }}
                className='confirm-next-button'
              >
                {showFooterBtn.isConfirm && (
                  <img src={CheckIconWhite} alt='RightArrowIconBlack' />
                )}
                &nbsp;
                {showFooterBtn.isConfirm
                  ? 'Confirm'
                  : showFooterBtn.isPendingAccept
                  ? 'Accept'
                  : 'Submit'}
              </Button>
            )}
            {(showFooterBtn.isPendingReject ||
              showFooterBtn.isAdvisoryReject) && (
              <Button
                loading={isResubmitLoading}
                onClick={() => {
                  let ele = document.getElementById('cancel-btn')
                  ele.click()
                }}
                className={`${'yes-next-button'}`}
              >
                {showFooterBtn.isPendingReject ? 'Reject' : 'Cancel'}
              </Button>
            )}
          </div>
        ) : (
          <div>
            <Button
              loading={isResubmitLoading}
              onClick={() => {
                let ele = document.getElementById('resubmit-btn-click')
                ele.click()
              }}
              className={`${
                currentUser?.completedSteps === 'RISK_PROFILE'
                  ? 'resubmit-button'
                  : 'hide-resubmit-btn'
              }`}
            >
              Re-submit Risk Profile &nbsp;
              <img src={ReSubmitIcon} alt='ReSubmitIcon' />
            </Button>
            {((currentUser?.completedSteps === 'KYC_FORM' &&
              currentUser?.isPanCardIndividual) ||
              (currentUser?.completedSteps === 'SUMMARY' &&
                !currentUser?.isPanCardIndividual)) && (
              <Button
                loading={isResubmitLoading}
                onClick={() => {
                  let ele = document.getElementById('review-plan')
                  ele.click()
                }}
                className={`${
                  !showReviewPlan ? 'yes-next-button' : 'show-plan-button'
                }`}
                style={
                  !showReviewPlan
                    ? { background: '#AF0000' }
                    : { background: '#fff' }
                }
              >
                Review Plans &nbsp;
                <img
                  src={
                    !showReviewPlan ? RightArrowIconWhite : RightArrowIconBlack
                  }
                  alt='RightArrowIconBlack'
                />
              </Button>
            )}

            {currentUser?.completedSteps === 'AGREEMENT_SIGN' &&
              (currentUser?.umrnNumber || currentUser?.mandateId) && (
                <Button
                  loading={isResubmitLoading}
                  onClick={() => {
                    let ele = document.getElementById('skip-e-mandate')
                    ele.click()
                  }}
                  className='show-plan-button'
                  style={{ background: '#fff' }}
                >
                  Skip
                </Button>
              )}

            {(currentUser?.completedSteps !== 'KYC_FORM' &&
              currentUser?.isPanCardIndividual) ||
            (currentUser?.completedSteps !== 'SUMMARY' &&
              currentUser?.completedSteps !== 'INVESTMENT_PLANNING' &&
              !currentUser?.isPanCardIndividual) ? (
              <Button
                disabled={isFileUpload}
                loading={isAcceptLoading}
                onClick={() => {
                  let ele = document.getElementById('btn-click')
                  ele.click()
                }}
                className='yes-next-button'
              >
                {currentUser?.completedSteps === 'RISK_PROFILE'
                  ? 'Next'
                  : currentUser?.completedSteps === 'KYC_DOC'
                  ? 'Verify KYC Form'
                  : currentUser?.completedSteps === null
                  ? 'I Agree'
                  : 'Yes and Next'}{' '}
                &nbsp;
                <img src={RightArrowWhiteIcon} alt='RightArrowWhiteIcon' />
              </Button>
            ) : showReviewPlan ? (
              <Button
                loading={isAcceptLoading}
                onClick={() => {
                  let ele = document.getElementById('btn-click')
                  ele.click()
                }}
                className='yes-next-button'
              >
                Next &nbsp;
                <img src={RightArrowWhiteIcon} alt='RightArrowWhiteIcon' />
              </Button>
            ) : (
              <></>
            )}
          </div>
        )
      ) : (
        <>
          {currentUser?.isNewAuaAdvisoryGenerate &&
            currentUser?.newAuaAdvisoryCompletedSteps !== 'CONGRATULATION' && (
              <div>
                {currentUser?.newAuaAdvisoryCompletedSteps === 'KYC_FORM' ? (
                  <Button
                    loading={isResubmitLoading}
                    onClick={() => {
                      let ele = document.getElementById('review-plan')
                      ele.click()
                    }}
                    className={`${
                      !showReviewPlan ? 'yes-next-button' : 'show-plan-button'
                    }`}
                    style={
                      !showReviewPlan
                        ? { background: '#AF0000' }
                        : { background: '#fff' }
                    }
                  >
                    Review Plans &nbsp;
                    <img
                      src={
                        !showReviewPlan
                          ? RightArrowIconWhite
                          : RightArrowIconBlack
                      }
                      alt='RightArrowIconBlack'
                    />
                  </Button>
                ) : (
                  ''
                )}
                {showReviewPlan &&
                  !currentUser?.isBookingAdvisoryDateSelected && (
                    <Button
                      loading={isAcceptLoading}
                      onClick={() => {
                        let ele = document.getElementById('btn-click')
                        ele.click()
                      }}
                      className='yes-next-button'
                    >
                      Next &nbsp;
                      <img
                        src={RightArrowWhiteIcon}
                        alt='RightArrowWhiteIcon'
                      />
                    </Button>
                  )}
              </div>
            )}
          {((window?.location?.pathname?.includes('pending-advisory') &&
            isDisplayMultipleAdvisoryAction) ||
            currentUser?.newAuaAdvisoryCompletedSteps === 'CONGRATULATION') && (
            <div>
              {(showFooterBtn.isConfirm ||
                showFooterBtn.isPendingAccept ||
                showFooterBtn.isAdvisoryAccept) && (
                <Button
                  loading={isAcceptLoading}
                  onClick={() => {
                    let ele = document.getElementById('confirm-btn')
                    ele.click()
                  }}
                  className='confirm-next-button'
                >
                  {showFooterBtn.isConfirm && (
                    <img src={CheckIconWhite} alt='RightArrowIconBlack' />
                  )}
                  &nbsp;
                  {showFooterBtn.isConfirm
                    ? 'Confirm'
                    : showFooterBtn.isPendingAccept
                    ? 'Accept'
                    : 'Submit'}
                </Button>
              )}
              {(showFooterBtn.isPendingReject ||
                showFooterBtn.isAdvisoryReject) && (
                <Button
                  loading={isResubmitLoading}
                  onClick={() => {
                    let ele = document.getElementById('cancel-btn')
                    ele.click()
                  }}
                  className={`${'yes-next-button'}`}
                >
                  {showFooterBtn.isPendingReject ? 'Reject' : 'Cancel'}
                </Button>
              )}
            </div>
          )}
        </>
      )} */}
    </div>
  )
}

export default Footer
