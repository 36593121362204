import React from 'react'
import get from 'lodash/get'
import qs from 'query-string'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import { GET_MONTHLY_RETURN_REPORT } from 'modules/Reports/graphql/Queries'
import MonthlyReturnTable from 'modules/Reports/MonthlyReturn/MonthlyReturnTable'
import 'modules/Reports/index.css'
import { GET_USER } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'

export default function (props) {
  let {
    match: {
      params: { clientCode, clientId },
    },
    history: {
      location: { search },
    },
  } = props
  const parsed = qs.parse(search || {})
  const { strategy = 'ILTS' } = parsed

  const { data, loading, error } = useQuery(GET_MONTHLY_RETURN_REPORT, {
    variables: { where: { clientCode, strategy } },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spMonthlyReturnReport')) return <NoData />

  const {
    spMonthlyReturnReport: [spMonthlyReturnReport],
  } = data

  return (
    <>
      <CustomMeta title='Monthly Return' />
      <DownloadReport
        data={spMonthlyReturnReport}
        fileName='monthly-exposure-report'
        id='MONTHLY-RETURN-REPORT-PDF'
      />
      <div
        className='report-table-wrapper monthly-return'
        id='MONTHLY-RETURN-REPORT-PDF'
      >
        <MonthlyReturnTable
          reportData={spMonthlyReturnReport}
          ClientCode={clientCode}
          user={getUserData}
        />
      </div>
    </>
  )
}
