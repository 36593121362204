import gql from 'graphql-tag'

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: createCategoryInput) {
    createCategory(data: $data) {
      id
      category
      createdAt
      updatedAt
      index
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $where: whereCategoryUpdated
    $data: updateCategoryInput
  ) {
    updateCategory(where: $where, data: $data)
  }
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($where: whereCategoryUpdated) {
    deleteCategory(where: $where)
  }
`

export const CREATE_SUB_CATEGORY = gql`
  mutation CreateSubCategory($data: createSubCategoryInput) {
    createSubCategory(data: $data) {
      id
      subCategory
      categoryId
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_SUB_CATEGORY = gql`
  mutation UpdateSubCategory(
    $data: updateSubCategoryInput
    $where: whereSubCategoryUpdated
  ) {
    updateSubCategory(data: $data, where: $where)
  }
`

export const DELETE_SUB_CATEGORY = gql`
  mutation DeleteSubCategory($where: whereSubCategoryUpdated) {
    deleteSubCategory(where: $where)
  }
`
export const UPDATE_SUB_CATEGORY_SETTING = gql`
  mutation UpdateSubCategorySetting(
    $data: subCategorySettingInput
    $where: whereSubCategorySetting
  ) {
    updateSubCategorySetting(data: $data, where: $where) {
      isDefaultAssignee
    }
  }
`
