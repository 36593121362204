import React from 'react'
import Routes from 'Routes'
import useWebSocket from 'utils/useWebSocket'
import 'assets/css/index.css'
import './assets/css/App.css'
import './assets/css/Main.css'
import './assets/css/Custom.css'

function App() {
  useWebSocket(process.env.REACT_APP_SERVER_WSS_URL)
  return <Routes />
}

export default App
