import React, { useContext, useEffect, useState } from 'react'
import { Col, Collapse, Modal, Row, Space } from 'antd'
import DropDownForCollapse from 'assets/images/new-images/dropdownForCollapse.svg'
import DropUPForCollapse from 'assets/images/new-images/dropUPForCollapse.svg'
import EditIcon from 'assets/images/new-images/edit-icon.svg'
import { AppContext } from 'AppContext'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { FilePdfOutlined } from '@ant-design/icons'
const { Panel } = Collapse

const KeyInfo = ({ newEdit, advisoryData = {} }) => {
  const {
    state: { clientSummaryDetails, currentUser, isMobile },
  } = useContext(AppContext)

  const [itemKey, setItemKey] = useState()

  const nonIndividualSteps = [
    { title: 'SELECT_USER_TYPE', key: 0 },
    { title: 'LOGIN_CREATED', key: 0 },
    { title: 'CLIENT_PROFILE_1', key: 1 },
    { title: 'CLIENT_PROFILE_2', key: 2 },
    { title: 'RISK_PROFILE', key: 3 },
    { title: 'SUMMARY', key: 3 },
    { title: 'INVESTMENT_PLANNING', key: 4 },
  ]

  const IndividualSteps = [
    { title: 'SELECT_USER_TYPE', key: 0 },
    { title: 'LOGIN_CREATED', key: 0 },
    { title: 'CLIENT_PROFILE_1', key: 1 },
    { title: 'CLIENT_PROFILE_2', key: 2 },
    { title: 'RISK_PROFILE', key: 3 },
    { title: 'SUMMARY', key: 3 },
    { title: 'KYC_DOC', key: 4 },
    { title: 'KYC_FORM', key: 4 },
    { title: 'INVESTMENT_PLANNING', key: 5 },
    { title: 'AGREEMENT_SIGN', key: 6 },
    { title: 'E_MANDATE', key: 7 },
    { title: 'CONGRATULATION', key: 7 },
    { title: 'DONE', key: 7 },
  ]

  useEffect(() => {
    if (currentUser?.isPanCardIndividual) {
      IndividualSteps.forEach((i, index) => {
        if (
          (currentUser?.completedSteps === 'KYC_DOC' ||
            currentUser?.completedSteps === 'KYC_FORM') &&
          !currentUser?.kyc?.aadharCard
        ) {
          setItemKey(3)
        } else if (
          (currentUser?.completedSteps === 'INVESTMENT_PLANNING' ||
            currentUser?.completedSteps === 'AGREEMENT_SIGN' ||
            currentUser?.completedSteps === 'E_MANDATE' ||
            currentUser?.completedSteps === 'CONGRATULATION' ||
            currentUser?.completedSteps === 'DONE') &&
          !currentUser?.kyc?.aadharCard
        ) {
          setItemKey(
            IndividualSteps?.find(
              (item) => item?.title === currentUser?.completedSteps
            )?.key - 1
          )
        } else if (i?.title === currentUser?.completedSteps) {
          setItemKey(i.key)
        }
        // return i?.title === currentUser?.completedSteps && setItemKey(i.key)
      })
    } else {
      nonIndividualSteps.map((i, index) => {
        return i?.title === currentUser?.completedSteps && setItemKey(i?.key)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const showConfirmModal = (item) => {
    Modal.confirm({
      title: (
        <>
          <p>Are you sure you want to edit?</p>
        </>
      ),
      maskClosable: true,
      width: '300px',
      onOk: () => newEdit(item, advisoryData),
      okText: 'Yes',
      cancelText: 'No',
    })
  }

  const genExtra = (item) => {
    return (
      <div className='gen-extra-container'>
        <div>{item?.name}</div>

        {itemKey > item?.key && item?.isDisplayEdit && (
          <div
            className='gen-extra-edit'
            onClick={() => {
              showConfirmModal(item)
            }}
          >
            <img src={EditIcon} alt='EditIcon' />
            <span>Edit</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {isMobile ? <hr /> : ''}
      <div className='key-info'>Key Info</div>
      <div
        className='edit-collapse-container'
        style={{
          marginBottom: isMobile ? '15%' : '2px',
        }}
      >
        <div className='editCollapse'>
          <Space direction='vertical' style={{ width: '100%' }}>
            {clientSummaryDetails.length
              ? clientSummaryDetails?.map((item, index) => {
                  return (
                    <Collapse
                      collapsible='header'
                      defaultActiveKey={
                        itemKey === index + 1 ? index.toString() : null
                      }
                      key={itemKey === index + 1 ? index.toString() : null}
                      style={{ background: '#fff', marginBottom: '2px' }}
                      expandIconPosition={'end'}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <img
                            src={DropDownForCollapse}
                            alt='DropDownForCollapse'
                            style={{ userSelect: 'none' }}
                          />
                        ) : (
                          <img
                            src={DropUPForCollapse}
                            alt='DropUPForCollapse'
                            style={{ userSelect: 'none' }}
                          />
                        )
                      }
                    >
                      <Panel
                        header=''
                        key={index}
                        extra={genExtra({
                          ...item,
                          key: index + 1,
                        })}
                        className='key-info-panel'
                      >
                        <div>
                          {item?.name === 'Kyc Document' ? (
                            <Row gutter={16}>
                              {Object.keys(item?.data)?.map((label, index) => {
                                return (
                                  item?.data[label] !== null && (
                                    <Col
                                      span={6}
                                      style={{ margin: '10px 0' }}
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {typeof item?.data[label] ===
                                          'string' &&
                                        item?.data[label].endsWith('pdf') ? (
                                          <FilePdfOutlined
                                            style={{
                                              fontSize: ' 50px',
                                              color: '#af0000',
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={item?.data[label]}
                                            alt={'item?.data[label]'}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: '5px' }}
                                          />
                                        )}
                                      </div>
                                      <span className='kyc_doc_display'>
                                        {label}
                                      </span>
                                    </Col>
                                  )
                                )
                              })}
                            </Row>
                          ) : item?.name === 'Client Profile' &&
                            !currentUser?.isPanCardIndividual ? (
                            item?.data?.map((partner, index) => {
                              return (
                                <>
                                  <div className='panel-content'>
                                    <div className='right'>
                                      Partner {index + 1} Details
                                    </div>
                                  </div>
                                  {Object.keys(partner).map((label) => (
                                    <div className='panel-content'>
                                      <div className='left'>{label}</div>
                                      <div className='right'>
                                        {partner[label]}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )
                            })
                          ) : (
                            Object.keys(item?.data)?.map((label) => {
                              if (
                                label === 'Agreement' ||
                                label === 'description'
                              ) {
                                return (
                                  <div className='panel-content'>
                                    <div className='right'>
                                      {item?.data[label]}
                                    </div>
                                  </div>
                                )
                              } else if (label === 'Maximum Exposure') {
                                return (
                                  <div className='panel-content'>
                                    <div className='max-exposure-left'>
                                      {label}
                                    </div>
                                    <div className='max-exposure-right'>
                                      {item?.data[label]}
                                    </div>
                                  </div>
                                )
                              } else if (label === 'EMandate Amount') {
                                return (
                                  <div className='panel-content'>
                                    <div className='left'>{label}</div>
                                    <div className='right'>
                                      {convertNumberToCommaSeparate(
                                        +item?.data[label]
                                      )}
                                    </div>
                                  </div>
                                )
                              } else {
                                return (
                                  <div className='panel-content'>
                                    <div className='left'>{label}</div>
                                    <div
                                      className='right'
                                      style={{
                                        textTransform: 'none',
                                      }}
                                    >
                                      {typeof item?.data[label] === 'string'
                                        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                            item.data[label]
                                          )
                                          ? item.data[label].toLowerCase()
                                          : /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(
                                              item.data[label]
                                            )
                                          ? item.data[label].toUpperCase()
                                          : /^[A-Za-z]{4}0[A-Z0-9]{6}$/.test(
                                              item.data[label]
                                            )
                                          ? item.data[label].toUpperCase()
                                          : item?.name ===
                                            'Risk Profile Assessment'
                                          ? item.data[label]
                                              .split(/(\s+)/)
                                              .map((word) =>
                                                word.replace(/\b\w/g, (char) =>
                                                  char.toUpperCase()
                                                )
                                              )
                                              .join('')
                                              .replace(/,\s*/g, ', ')
                                          : item.data[label]
                                              .split(/(\s+)/)
                                              .map((word) =>
                                                word.replace(/\b\w/g, (char) =>
                                                  char.toUpperCase()
                                                )
                                              )
                                              .join('')
                                              .replace(/,\s*/g, ', ')
                                        : item.data[label]}
                                    </div>
                                  </div>
                                )
                              }
                            })
                          )}
                        </div>
                      </Panel>
                    </Collapse>
                  )
                })
              : ''}
          </Space>
        </div>
      </div>
    </div>
  )
}

export default KeyInfo
