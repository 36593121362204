import gql from 'graphql-tag'

export const GET_PERFORMANCE_SUMMARY_REPORT = gql`
  query Query($where: wherePerformanceSummaryReportGet) {
    getPerformanceSummaryReport(where: $where)
  }
`
export const GET_PERFORMANCE_SUMMARY_REPORT_PDF = gql`
  query Query($where: wherePerformanceSummaryReportGet) {
    getPerformanceSummaryReportPDF(where: $where)
  }
`
