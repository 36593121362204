import gql from 'graphql-tag'

export const GET_TICKETS = gql`
  query getTickets(
    $where: TicketsWhereInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getTickets(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      tickets {
        id
        ticketNumber
        subject
        description
        isRead
        generatedBy {
          id
          firstName
          lastName
          role
          clientCode
          userName
        }
        status
        fileUrl
        assignUser {
          id
          firstName
          lastName
          role
        }
        closedByUser {
          id
          firstName
          lastName
          role
        }
        closedDate
        createdAt
        updatedAt
        reason
        isForClient
        generateFor {
          id
          firstName
          lastName
          email
          role
          clientCode
          userName
        }
        categoryName
        subCategoryName
        ticketLogs {
          status
          description
          fileUrl
          createdAt
          updatedAt
          statusChanger {
            id
            firstName
            lastName
            email
            phone
            role
          }
        }
      }
      count
      counters {
        totalTicket
        openTicket
        inProcessTicket
        closedTicket
        reOpenTicket
        pendingTicket
      }
    }
  }
`

export const GET_TICKET = gql`
  query getTicket($id: ID!) {
    getTicket(where: { id: $id }) {
      id
      ticketNumber
      subject
      description
      status
      closedDate
      fileUrl
      reason
      isRead
      generatedBy {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        keyId
        isAuto
        isAssociate
        fundReceived
        isKycDone
        completedSteps
        amcStartDate
        sipStartDate
        closedDate
        initialExposure
        clientCode
        strategyCode
        createdBy {
          id
        }
        updatedBy {
          id
        }
        token
        createdAt
        updatedAt
      }
      assignUser {
        id
        firstName
        lastName
        role
      }
      closedByUser {
        id
        firstName
        lastName
        role
      }
      isForClient
      generateFor {
        id
        firstName
        lastName
        email
        role
        clientCode
        userName
      }
      categoryName
      subCategoryName
      ticketLogs {
        status
        description
        fileUrl
        createdAt
        updatedAt
        statusChanger {
          id
          firstName
          lastName
          email
          phone
          role
        }
      }
      createdAt
      updatedAt
    }
  }
`
