import gql from 'graphql-tag'

export const GET_ALL_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      id
      category
      index
      createdAt
      updatedAt
      subCategories {
        id
        subCategory
        index
        createdAt
        categoryId
        updatedAt
        defaultAssigne {
          id
          firstName
          lastName
          email
          role
        }
      }
    }
  }
`

export const GET_ALL_SUB_CATEGORIES = gql`
  query GetSubCategories {
    getSubCategories {
      id
      subCategory
      index
      categoryId
      createdAt
      updatedAt
      defaultAssigne {
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`
export const GET_SUB_CATEGORY_SETTING = gql`
  query GetSubCategorySetting {
    getSubCategorySetting {
      isDefaultAssignee
      id
    }
  }
`
