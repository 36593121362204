import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Select } from 'antd'
import ReactQuill from 'react-quill'

import './templateCSS.css'
import 'react-quill/dist/quill.snow.css'
import client from 'apollo'
import Loader from 'components/loaders/Loader'
import {
  GENERATE_NON_INDIVIDUAL_REPORT,
  GET_TEMPLATES_DATA_HTML,
  POST_TEMPLATES_DATA_HTML,
} from '../graphql/Queries'
import openNotification from 'utils/Notification'
import { Document, Page, pdfjs } from 'react-pdf'
import { get } from 'lodash'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

function EditTemplate() {
  const [form] = Form.useForm()

  const [value, setValue] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfPreview, setPdfPreview] = useState('')
  const [loader, setLoader] = useState(false)
  const [pdfLoading, setPDFLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(undefined)
  const [type, setType] = useState('HUF')

  let newValue = value
  const {
    state: { isMobile },
  } = useContext(AppContext)

  useEffect(() => {
    if (type) {
      client
        .query({
          query: GET_TEMPLATES_DATA_HTML,
          variables: { where: { type } },
        })
        .then((res) => {
          if (res) {
            let tempData = res?.data?.getTemplatesDataHtml?.data?.htmlData
              ? res?.data?.getTemplatesDataHtml?.data?.htmlData
              : res?.data?.getTemplatesDataHtml?.data
            tempData = tempData
              .replaceAll(/\r?\\n|\r/g, ' ')
              .replaceAll(`\\" `, '"')
              .replaceAll(`\\"`, '"') //eslint-disable-next-line
              .replaceAll(`\" `, '"') //eslint-disable-next-line
              .replaceAll(`\"`, '"')
              .replaceAll(`&lt;`, '<')
              .replaceAll(`&gt;`, '>')
              .replaceAll('%&gt;', '-%>')
              .replaceAll(`&quot;`, '"') //eslint-disable-next-line
              .replaceAll(`\" `, '" ') //eslint-disable-next-line
              .replaceAll(`\"`, '" ')
            // .replaceAll(`\"`, '')
            // .replaceAll('custom_center"', 'ql-align-center"')
            // .replaceAll('ql-align-left"', 'custom_left"')
            // .replaceAll('ql-align-right"', 'custom_right"')
            // .replaceAll('ql-align-justify"', 'custom_justify"')
            tempData = tempData.replaceAll(
              '<div class=" page-break" ></div>',
              '<span><--page-break--></span>'
            )
            setValue(tempData)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [loader, type])

  const updateCode = async () => {
    let data = {
      html: `<head>
  <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css">
  </head>
        <style>
            p,
            li,
            span {
              font-size: 10px;
              font-family: Arial !important;
            }
            .ql-container {
              box-sizing: border-box;
              font-family: Helvetica, Arial, sans-serif;
                font-size:${isMobile ? '10px' : '13px'} ;
              height: 100%;
              margin: 0px;
              position: relative;
            }

            .ql-container.ql-disabled .ql-tooltip {
              visibility: hidden;
            }

            .ql-container.ql-disabled  ul[data-checked] > li::before {
              pointer-events: none;
            }

            .ql-clipboard {
              left: -100000px;
              height: 1px;
              overflow-y: hidden;
              position: absolute;
              top: 50%;
            }

            .ql-clipboard p {
              margin: 0;
              padding: 0;
            }

            .ql-editor {
              box-sizing: border-box;
              line-height: 1.42;
              height: 100%;
              outline: none;
              overflow-y: auto;
              padding: 0px;
              tab-size: 4;
              -moz-tab-size: 4;
              text-align: left;
              white-space: pre-wrap;
              word-wrap: break-word;
            }

             > * {
              cursor: text;
            }

             p,
             .ql-editor ol,
             .ql-editor ul,
             pre,
             blockquote,
             .ql-editor h1,
             .ql-editor h2,
             .ql-editor h3,
             .ql-editor h4,
             .ql-editor h5,
             .ql-editor h6 {
              margin: 0;
              padding: 0;
              counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7
                list-8 list-9;
            }

             .ql-editor ol,
             .ql-editor ul {
              padding-left: 1.5em;
            }

             ol > li,
             ul > li {
              list-style-type: none;
            }

             ul > li::before {
            }

             ul[data-checked='true'],
             ul[data-checked='false'] {
              pointer-events: none;
            }

             ul[data-checked='true'] > li *,
             ul[data-checked='false'] > li * {
              pointer-events: all;
            }

             ul[data-checked='true'] > li::before,
             ul[data-checked='false'] > li::before {
              color: #777;
              cursor: pointer;
              pointer-events: all;
            }

             ul[data-checked='true'] > li::before {
            }

             ul[data-checked='false'] > li::before {
            }

             li::before {
              display: inline-block;
              white-space: nowrap;
              width: 1.2em;
            }

             li:not(.ql-direction-rtl)::before {
              margin-left: -1.5em;
              margin-right: 0.3em;
              text-align: right;
            }

             li.ql-direction-rtl::before {
              margin-left: 0.3em;
              margin-right: -1.5em;
            }

             ol li:not(.ql-direction-rtl),
             ul li:not(.ql-direction-rtl) {
              padding-left: 1.5em;
            }

             ol li.ql-direction-rtl,
             ul li.ql-direction-rtl {
              padding-right: 1.5em;
            }

             ol li {
              counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7
                list-8 list-9;
              counter-increment: list-0;
            }

             ol li:before {
              content: counter(list-0, decimal) '. ';
            }

             ol li.ql-indent-1 {
              counter-increment: list-1;
            }

             ol li.ql-indent-1:before {
              content: counter(list-1, lower-alpha) '. ';
            }

             ol li.ql-indent-1 {
              counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8
                list-9;
            }

             ol li.ql-indent-2 {
              counter-increment: list-2;
            }

             ol li.ql-indent-2:before {
              content: counter(list-2, lower-roman) '. ';
            }

             ol li.ql-indent-2 {
              counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
            }

             ol li.ql-indent-3 {
              counter-increment: list-3;
            }

             ol li.ql-indent-3:before {
              content: counter(list-3, decimal) '. ';
            }

             ol li.ql-indent-3 {
              counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
            }

             ol li.ql-indent-4 {
              counter-increment: list-4;
            }

             ol li.ql-indent-4:before {
              content: counter(list-4, lower-alpha) '. ';
            }

             ol li.ql-indent-4 {
              counter-reset: list-5 list-6 list-7 list-8 list-9;
            }

             ol li.ql-indent-5 {
              counter-increment: list-5;
            }

             ol li.ql-indent-5:before {
              content: counter(list-5, lower-roman) '. ';
            }

             ol li.ql-indent-5 {
              counter-reset: list-6 list-7 list-8 list-9;
            }

             ol li.ql-indent-6 {
              counter-increment: list-6;
            }

             ol li.ql-indent-6:before {
              content: counter(list-6, decimal) '. ';
            }

             ol li.ql-indent-6 {
              counter-reset: list-7 list-8 list-9;
            }

             ol li.ql-indent-7 {
              counter-increment: list-7;
            }

             ol li.ql-indent-7:before {
              content: counter(list-7, lower-alpha) '. ';
            }

             ol li.ql-indent-7 {
              counter-reset: list-8 list-9;
            }

             ol li.ql-indent-8 {
              counter-increment: list-8;
            }

             ol li.ql-indent-8:before {
              content: counter(list-8, lower-roman) '. ';
            }

             ol li.ql-indent-8 {
              counter-reset: list-9;
            }

             ol li.ql-indent-9 {
              counter-increment: list-9;
            }

             ol li.ql-indent-9:before {
              content: counter(list-9, decimal) '. ';
            }

             .ql-indent-1:not(.ql-direction-rtl) {
              padding-left: 3em;
            }

             li.ql-indent-1:not(.ql-direction-rtl) {
              padding-left: 4.5em;
            }

             .ql-indent-1.ql-direction-rtl.ql-align-right {
              padding-right: 3em;
            }

             li.ql-indent-1.ql-direction-rtl.ql-align-right {
              padding-right: 4.5em;
            }

             .ql-indent-2:not(.ql-direction-rtl) {
              padding-left: 6em;
            }

             li.ql-indent-2:not(.ql-direction-rtl) {
              padding-left: 7.5em;
            }

             .ql-indent-2.ql-direction-rtl.ql-align-right {
              padding-right: 6em;
            }

             li.ql-indent-2.ql-direction-rtl.ql-align-right {
              padding-right: 7.5em;
            }

             .ql-indent-3:not(.ql-direction-rtl) {
              padding-left: 9em;
            }

             li.ql-indent-3:not(.ql-direction-rtl) {
              padding-left: 10.5em;
            }

             .ql-indent-3.ql-direction-rtl.ql-align-right {
              padding-right: 9em;
            }

             li.ql-indent-3.ql-direction-rtl.ql-align-right {
              padding-right: 10.5em;
            }

             .ql-indent-4:not(.ql-direction-rtl) {
              padding-left: 12em;
            }

             li.ql-indent-4:not(.ql-direction-rtl) {
              padding-left: 13.5em;
            }

             .ql-indent-4.ql-direction-rtl.ql-align-right {
              padding-right: 12em;
            }

             li.ql-indent-4.ql-direction-rtl.ql-align-right {
              padding-right: 13.5em;
            }

             .ql-indent-5:not(.ql-direction-rtl) {
              padding-left: 15em;
            }

             li.ql-indent-5:not(.ql-direction-rtl) {
              padding-left: 16.5em;
            }

             .ql-indent-5.ql-direction-rtl.ql-align-right {
              padding-right: 15em;
            }

             li.ql-indent-5.ql-direction-rtl.ql-align-right {
              padding-right: 16.5em;
            }

             .ql-indent-6:not(.ql-direction-rtl) {
              padding-left: 18em;
            }

             li.ql-indent-6:not(.ql-direction-rtl) {
              padding-left: 19.5em;
            }

             .ql-indent-6.ql-direction-rtl.ql-align-right {
              padding-right: 18em;
            }

             li.ql-indent-6.ql-direction-rtl.ql-align-right {
              padding-right: 19.5em;
            }

             .ql-indent-7:not(.ql-direction-rtl) {
              padding-left: 21em;
            }

             li.ql-indent-7:not(.ql-direction-rtl) {
              padding-left: 22.5em;
            }

             .ql-indent-7.ql-direction-rtl.ql-align-right {
              padding-right: 21em;
            }

             li.ql-indent-7.ql-direction-rtl.ql-align-right {
              padding-right: 22.5em;
            }

             .ql-indent-8:not(.ql-direction-rtl) {
              padding-left: 24em;
            }

             li.ql-indent-8:not(.ql-direction-rtl) {
              padding-left: 25.5em;
            }

             .ql-indent-8.ql-direction-rtl.ql-align-right {
              padding-right: 24em;
            }

             li.ql-indent-8.ql-direction-rtl.ql-align-right {
              padding-right: 25.5em;
            }

             .ql-indent-9:not(.ql-direction-rtl) {
              padding-left: 27em;
            }

             li.ql-indent-9:not(.ql-direction-rtl) {
              padding-left: 28.5em;
            }

             .ql-indent-9.ql-direction-rtl.ql-align-right {
              padding-right: 27em;
            }

             li.ql-indent-9.ql-direction-rtl.ql-align-right {
              padding-right: 28.5em;
            }

             .ql-video {
              display: block;
              max-width: 100%;
            }

             .ql-video.ql-align-center {
              margin: 0 auto;
            }

             .ql-video.ql-align-right {
              margin: 0 0 0 auto;
            }

             .ql-bg-black {
              background-color: #000;
            }

             .ql-bg-red {
              background-color: #e60000;
            }

             .ql-bg-orange {
              background-color: #f90;
            }

             .ql-bg-yellow {
              background-color: #ff0;
            }

             .ql-bg-green {
              background-color: #008a00;
            }

             .ql-bg-blue {
              background-color: #06c;
            }

             .ql-bg-purple {
              background-color: #93f;
            }

             .ql-color-white {
              color: #fff;
            }

             .ql-color-red {
              color: #e60000;
            }

             .ql-color-orange {
              color: #f90;
            }

             .ql-color-yellow {
              color: #ff0;
            }

             .ql-color-green {
              color: #008a00;
            }

             .ql-color-blue {
              color: #06c;
            }

             .ql-color-purple {
              color: #93f;
            }

             .ql-font-serif {
              font-family: Georgia, Times New Roman, serif;
            }

             .ql-font-monospace {
              font-family: Monaco, Courier New, monospace;
            }

             .ql-size-small {
              font-size: 0.75em;
            }

             .ql-size-large {
              font-size: 1.5em;
            }

             .ql-size-huge {
              font-size: 2.5em;
            }

             .ql-direction-rtl {
              direction: rtl;
              text-align: inherit;
            }

             .ql-align-center {
              text-align: center;
            }

             .ql-align-justify {
              text-align: justify;
            }

             .ql-align-right {
              text-align: right;
            }

            .ql-blank::before {
              color: rgba(0, 0, 0, 0.6);
              content: attr(data-placeholder);
              font-style: italic;
              left: 15px;
              pointer-events: none;
              position: absolute;
              right: 15px;
            }

            .ql-snow.ql-toolbar:after,
            .ql-snow .ql-toolbar:after {
              clear: both;
              content: '';
              display: table;
            }

            .ql-snow.ql-toolbar button,
            .ql-snow .ql-toolbar button {
              background: none;
              border: none;
              cursor: pointer;
              display: inline-block;
              float: left;
              height: 24px;
              padding: 3px 5px;
              width: 28px;
            }

            .ql-snow.ql-toolbar button svg,
            .ql-snow .ql-toolbar button svg {
              float: left;
              height: 100%;
            }

            .ql-snow.ql-toolbar button:active:hover,
            .ql-snow .ql-toolbar button:active:hover {
              outline: none;
            }

            .ql-snow.ql-toolbar input.ql-image[type='file'],
            .ql-snow .ql-toolbar input.ql-image[type='file'] {
              display: none;
            }

            .ql-snow.ql-toolbar button:hover,
            .ql-snow .ql-toolbar button:hover,
            .ql-snow.ql-toolbar button:focus,
            .ql-snow .ql-toolbar button:focus,
            .ql-snow.ql-toolbar button.ql-active,
            .ql-snow .ql-toolbar button.ql-active,
            .ql-snow.ql-toolbar .ql-picker-label:hover,
            .ql-snow .ql-toolbar .ql-picker-label:hover,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active,
            .ql-snow .ql-toolbar .ql-picker-label.ql-active,
            .ql-snow.ql-toolbar .ql-picker-item:hover,
            .ql-snow .ql-toolbar .ql-picker-item:hover,
            .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
            .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
              color: #06c;
            }

            .ql-snow.ql-toolbar button:hover .ql-fill,
            .ql-snow .ql-toolbar button:hover .ql-fill,
            .ql-snow.ql-toolbar button:focus .ql-fill,
            .ql-snow .ql-toolbar button:focus .ql-fill,
            .ql-snow.ql-toolbar button.ql-active .ql-fill,
            .ql-snow .ql-toolbar button.ql-active .ql-fill,
            .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
            .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
            .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
            .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
            .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
            .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
            .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
            .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
            .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
            .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
            .ql-snow
              .ql-toolbar
              .ql-picker-item.ql-selected
              .ql-stroke.ql-fill {
              fill: #06c;
            }

            .ql-snow.ql-toolbar button:hover .ql-stroke,
            .ql-snow .ql-toolbar button:hover .ql-stroke,
            .ql-snow.ql-toolbar button:focus .ql-stroke,
            .ql-snow .ql-toolbar button:focus .ql-stroke,
            .ql-snow.ql-toolbar button.ql-active .ql-stroke,
            .ql-snow .ql-toolbar button.ql-active .ql-stroke,
            .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
            .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
            .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
            .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
            .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
            .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
            .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
            .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
            .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
            .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
            .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
            .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
            .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
            .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
            .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
            .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
            .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
            .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
            .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
            .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
              stroke: #06c;
            }

            @media (pointer: coarse) {
              .ql-snow.ql-toolbar button:hover:not(.ql-active),
              .ql-snow .ql-toolbar button:hover:not(.ql-active) {
                color: #444;
              }

              .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
              .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
              .ql-snow.ql-toolbar
                button:hover:not(.ql-active)
                .ql-stroke.ql-fill,
              .ql-snow
                .ql-toolbar
                button:hover:not(.ql-active)
                .ql-stroke.ql-fill {
                fill: #444;
              }

              .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
              .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
              .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
              .ql-snow
                .ql-toolbar
                button:hover:not(.ql-active)
                .ql-stroke-miter {
                stroke: #444;
              }
            }

            .ql-snow {
              box-sizing: border-box;
            }

            .ql-snow * {
              box-sizing: border-box;
            }

            .ql-snow .ql-hidden {
              display: none;
            }

            .ql-snow .ql-out-bottom,
            .ql-snow .ql-out-top {
              visibility: hidden;
            }

            .ql-snow .ql-tooltip {
              position: absolute;
              transform: translateY(10px);
            }

            .ql-snow .ql-tooltip a {
              cursor: pointer;
              text-decoration: none;
            }

            .ql-snow .ql-tooltip.ql-flip {
              transform: translateY(-10px);
            }

            .ql-snow .ql-formats {
              display: inline-block;
              vertical-align: middle;
            }

            .ql-snow .ql-formats:after {
              clear: both;
              content: '';
              display: table;
            }

            .ql-snow .ql-stroke {
              fill: none;
              stroke: #444;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 2;
            }

            .ql-snow .ql-stroke-miter {
              fill: none;
              stroke: #444;
              stroke-miterlimit: 10;
              stroke-width: 2;
            }

            .ql-snow .ql-fill,
            .ql-snow .ql-stroke.ql-fill {
              fill: #444;
            }

            .ql-snow .ql-empty {
              fill: none;
            }

            .ql-snow .ql-even {
              fill-rule: evenodd;
            }

            .ql-snow .ql-thin,
            .ql-snow .ql-stroke.ql-thin {
              stroke-width: 1;
            }

            .ql-snow .ql-transparent {
              opacity: 0.4;
            }

            .ql-snow .ql-direction svg:last-child {
              display: none;
            }

            .ql-snow .ql-direction.ql-active svg:last-child {
              display: inline;
            }

            .ql-snow .ql-direction.ql-active svg:first-child {
              display: none;
            }

            .ql-snow  h1 {
              font-size: 2em;
            }

            .ql-snow  h2 {
              font-size: 1.5em;
            }

            .ql-snow  h3 {
              font-size: 1.17em;
            }

            .ql-snow  h4 {
              font-size: 1em;
            }

            .ql-snow  h5 {
              font-size: 0.83em;
            }

            .ql-snow  h6 {
              font-size: 0.67em;
            }

            .ql-snow  a {
              text-decoration: underline;
            }

            .ql-snow  blockquote {
              border-left: 4px solid #ccc;
              margin-bottom: 5px;
              margin-top: 5px;
              padding-left: 16px;
            }

            .ql-snow  code,
            .ql-snow  pre {
              background-color: #f0f0f0;
              border-radius: 3px;
            }

            .ql-snow  pre {
              white-space: pre-wrap;
              margin-bottom: 5px;
              margin-top: 5px;
              padding: 5px 10px;
            }

            .ql-snow  code {
              font-size: 85%;
              padding: 2px 4px;
            }

            .ql-snow  pre.ql-syntax {
              background-color: #23241f;
              color: #f8f8f2;
              overflow: visible;
            }

            .ql-snow  img {
              max-width: 100%;
            }

            .ql-snow .ql-picker {
              color: #444;
              display: inline-block;
              float: left;
              font-size: 14px;
              font-weight: 500;
              height: 24px;
              position: relative;
              vertical-align: middle;
            }

            .ql-snow .ql-picker-label {
              cursor: pointer;
              display: inline-block;
              height: 100%;
              padding-left: 8px;
              padding-right: 2px;
              position: relative;
              width: 100%;
            }

            .ql-snow .ql-picker-label::before {
              display: inline-block;
              line-height: 22px;
            }

            .ql-snow .ql-picker-options {
              background-color: #fff;
              display: none;
              min-width: 100%;
              padding: 4px 8px;
              position: absolute;
              white-space: nowrap;
            }

            .ql-snow .ql-picker-options .ql-picker-item {
              cursor: pointer;
              display: block;
              padding-bottom: 5px;
              padding-top: 5px;
            }

            .ql-snow .ql-picker.ql-expanded .ql-picker-label {
              color: #ccc;
              z-index: 2;
            }

            .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
              fill: #ccc;
            }

            .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
              stroke: #ccc;
            }

            .ql-snow .ql-picker.ql-expanded .ql-picker-options {
              display: block;
              margin-top: -1px;
              top: 100%;
              z-index: 1;
            }

            .ql-snow .ql-color-picker,
            .ql-snow .ql-icon-picker {
              width: 28px;
            }

            .ql-snow .ql-color-picker .ql-picker-label,
            .ql-snow .ql-icon-picker .ql-picker-label {
              padding: 2px 4px;
            }

            .ql-snow .ql-color-picker .ql-picker-label svg,
            .ql-snow .ql-icon-picker .ql-picker-label svg {
              right: 4px;
            }

            .ql-snow .ql-icon-picker .ql-picker-options {
              padding: 4px 0px;
            }

            .ql-snow .ql-icon-picker .ql-picker-item {
              height: 24px;
              width: 24px;
              padding: 2px 4px;
            }

            .ql-snow .ql-color-picker .ql-picker-options {
              padding: 3px 5px;
              width: 152px;
            }

            .ql-snow .ql-color-picker .ql-picker-item {
              border: 1px solid transparent;
              float: left;
              height: 16px;
              margin: 2px;
              padding: 0px;
              width: 16px;
            }

            .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
              position: absolute;
              margin-top: -9px;
              right: 0;
              top: 50%;
              width: 18px;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-label]:not([data-label=''])::before,
            .ql-snow
              .ql-picker.ql-font
              .ql-picker-label[data-label]:not([data-label=''])::before,
            .ql-snow
              .ql-picker.ql-size
              .ql-picker-label[data-label]:not([data-label=''])::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-label]:not([data-label=''])::before,
            .ql-snow
              .ql-picker.ql-font
              .ql-picker-item[data-label]:not([data-label=''])::before,
            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-label]:not([data-label=''])::before {
              content: attr(data-label);
            }

            .ql-snow .ql-picker.ql-header {
              width: 98px;
            }

            .ql-snow .ql-picker.ql-header .ql-picker-label::before,
            .ql-snow .ql-picker.ql-header .ql-picker-item::before {
              content: 'Normal';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='1']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='1']::before {
              content: 'Heading 1';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='2']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='2']::before {
              content: 'Heading 2';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='3']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='3']::before {
              content: 'Heading 3';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='4']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='4']::before {
              content: 'Heading 4';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='5']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='5']::before {
              content: 'Heading 5';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-label[data-value='6']::before,
            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='6']::before {
              content: 'Heading 6';
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='1']::before {
              font-size: 2em;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='2']::before {
              font-size: 1.5em;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='3']::before {
              font-size: 1.17em;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='4']::before {
              font-size: 1em;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='5']::before {
              font-size: 0.83em;
            }

            .ql-snow
              .ql-picker.ql-header
              .ql-picker-item[data-value='6']::before {
              font-size: 0.67em;
            }

            .ql-snow .ql-picker.ql-font {
              width: 108px;
            }

            .ql-snow .ql-picker.ql-font .ql-picker-label::before,
            .ql-snow .ql-picker.ql-font .ql-picker-item::before {
              content: 'Sans Serif';
            }

            .ql-snow
              .ql-picker.ql-font
              .ql-picker-label[data-value='serif']::before,
            .ql-snow
              .ql-picker.ql-font
              .ql-picker-item[data-value='serif']::before {
              content: 'Serif';
            }

            .ql-snow
              .ql-picker.ql-font
              .ql-picker-label[data-value='monospace']::before,
            .ql-snow
              .ql-picker.ql-font
              .ql-picker-item[data-value='monospace']::before {
              content: 'Monospace';
            }

            .ql-snow
              .ql-picker.ql-font
              .ql-picker-item[data-value='serif']::before {
              font-family: Georgia, Times New Roman, serif;
            }

            .ql-snow
              .ql-picker.ql-font
              .ql-picker-item[data-value='monospace']::before {
              font-family: Monaco, Courier New, monospace;
            }

            .ql-snow .ql-picker.ql-size {
              width: 98px;
            }

            .ql-snow .ql-picker.ql-size .ql-picker-label::before,
            .ql-snow .ql-picker.ql-size .ql-picker-item::before {
              content: 'Normal';
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-label[data-value='small']::before,
            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='small']::before {
              content: 'Small';
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-label[data-value='large']::before,
            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='large']::before {
              content: 'Large';
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-label[data-value='huge']::before,
            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='huge']::before {
              content: 'Huge';
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='small']::before {
              font-size: 10px;
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='large']::before {
              font-size: 18px;
            }

            .ql-snow
              .ql-picker.ql-size
              .ql-picker-item[data-value='huge']::before {
              font-size: 32px;
            }

            .ql-snow .ql-color-picker.ql-background .ql-picker-item {
              background-color: #fff;
            }

            .ql-snow .ql-color-picker.ql-color .ql-picker-item {
              background-color: #000;
            }

            .ql-toolbar.ql-snow {
              border: 1px solid #ccc;
              box-sizing: border-box;
              font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
              padding: 8px;
            }

            .ql-toolbar.ql-snow .ql-formats {
              margin-right: 15px;
            }

            .ql-toolbar.ql-snow .ql-picker-label {
              border: 1px solid transparent;
            }

            .ql-toolbar.ql-snow .ql-picker-options {
              border: 1px solid transparent;
              box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
            }

            .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
              border-color: #ccc;
            }

            .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
              border-color: #ccc;
            }

            .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
            .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
              border-color: #000;
            }

            .ql-toolbar.ql-snow + .ql-container.ql-snow {
              border-top: 0px;
            }

            .ql-snow .ql-tooltip {
              background-color: #fff;
              border: 1px solid #ccc;
              box-shadow: 0px 0px 5px #ddd;
              color: #444;
              padding: 5px 12px;
              white-space: nowrap;
            }

            .ql-snow .ql-tooltip::before {
              content: 'Visit URL:';
              line-height: 26px;
              margin-right: 8px;
            }

            .ql-snow .ql-tooltip input[type='text'] {
              display: none;
              border: 1px solid #ccc;
              font-size: 13px;
              height: 26px;
              margin: 0px;
              padding: 3px 5px;
              width: 170px;
            }

            .ql-snow .ql-tooltip a.ql-preview {
              display: inline-block;
              max-width: 200px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              vertical-align: top;
            }

            .ql-snow .ql-tooltip a.ql-action::after {
              border-right: 1px solid #ccc;
              content: 'Edit';
              margin-left: 16px;
              padding-right: 8px;
            }

            .ql-snow .ql-tooltip a.ql-remove::before {
              content: 'Remove';
              margin-left: 8px;
            }

            .ql-snow .ql-tooltip a {
              line-height: 26px;
            }

            .ql-snow .ql-tooltip.ql-editing a.ql-preview,
            .ql-snow .ql-tooltip.ql-editing a.ql-remove {
              display: none;
            }

            .ql-snow .ql-tooltip.ql-editing input[type='text'] {
              display: inline-block;
            }

            .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
              border-right: 0px;
              content: 'Save';
              padding-right: 0px;
            }

            .ql-snow .ql-tooltip[data-mode='link']::before {
              content: 'Enter link:';
            }

            .ql-snow .ql-tooltip[data-mode='formula']::before {
              content: 'Enter formula:';
            }

            .ql-snow .ql-tooltip[data-mode='video']::before {
              content: 'Enter video:';
            }

            .ql-snow a {
              color: #06c;
            }

            .ql-container.ql-snow {
              border: 1px solid #ccc;
            }
        </style>
        <div style="padding: 20px;font-family: Arial;background-color: white;color: black;justify-content: center;text-align: justify;font-size: 10px;">${newValue}<p>

      </p>
      </div>`,
      type,
    }
    await client
      .query({
        query: POST_TEMPLATES_DATA_HTML,
        variables: { data },
      })
      .then((res) => {
        if (res.data.getTemplatesDataHtmlFromFrontEnd) {
          openNotification('success', 'SuccessFully Update Templates Formate')
        }
      })
      .catch((err) => openNotification('error', 'There was something wrong'))
      .finally(() => setLoader(false))

    window.location.reload()
  }

  const handleValue = (content, delta, source, editor) => {
    newValue = editor.getHTML()
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const showPreview = async () => {
    setIsModalVisible(!isModalVisible)
    await client
    client
      .query({
        query: GENERATE_NON_INDIVIDUAL_REPORT,
        variables: {
          where: {
            dontBuyEquity: 'YES',
            entityStatus:
              type === 'CORPORATE'
                ? 'Corporate'
                : type === 'TRUST'
                ? 'Trust'
                : type,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setPdfPreview(res.data.generateNonIndividualReport)
        get(res, 'data.generateNonIndividualReport')
        // setPDFLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setPDFLoading(false))
  }

  const modules = {
    // toolbar: [
    //   ['bold', 'italic', 'underline'],
    //   [{ align: [] }],
    //   [{ list: 'ordered' }, { list: 'bullet' }],
    //   [{ indent: '-1' }, { indent: '+1' }],
    // ],
    // toolbar: [
    //   [{ header: [1, 2, false] }],
    //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [
    //     { list: 'ordered' },
    //     { list: 'bullet' },
    //     { indent: '-1' },
    //     { indent: '+1' },
    //   ],
    //   [{ color: [] }, { background: [] }],
    //   ['clean'],
    // ],
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <>
      <CustomMeta title='Edit Template' />
      <div className='main_dashboard_div'>
        <div className='agreement_main_div'>
          <div className='agreement_text'>Templates Format</div>
        </div>
        <Form
          {...formItemLayout}
          form={form}
          name='register'
          initialValues={{
            type,
          }}
          labelAlign='left'
          scrollToFirstError
        >
          <FormItem name='type' label='Type of Investor'>
            <Select
              placeholder='Please Select Your Type of Investor'
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(e) => setType(e)}
              value={type}
              options={[
                {
                  value: 'HUF',
                  label: 'HUF',
                },
                {
                  value: 'LLP',
                  label: 'LLP',
                },
                {
                  value: 'CORPORATE',
                  label: 'Corporate',
                },
                {
                  value: 'TRUST',
                  label: 'Trust',
                },
              ]}
            />
          </FormItem>
        </Form>
        {!value ? (
          <Loader />
        ) : (
          <ReactQuill
            className='abcdef'
            theme='snow'
            value={value}
            onChange={handleValue}
            modules={modules}
            formats={formats}
          />
        )}

        <div>
          <div className='agreement_btn_div'>
            <Button
              onClick={updateCode}
              type='primary'
              loading={loader}
              className='agreement_submit_btn'
            >
              Save
            </Button>
            <Button
              onClick={showPreview}
              type='primary'
              className='agreement_submit_btn'
              loading={pdfLoading}
            >
              {/* Save &  */}
              Preview
            </Button>
          </div>
          {value && (
            <Alert
              message='Simply use the <--page-break--> tag to start content on a new page'
              type='warning'
              showIcon
            />
          )}
        </div>

        <div>
          {!pdfLoading && (
            <Modal
              visible={isModalVisible}
              width={700}
              closable={isModalVisible}
              onCancel={() => setIsModalVisible(!isModalVisible)}
              footer={[]}
            >
              <div className='pdfViewer'>
                <div className='pdf-viewer-button'>
                  <div>
                    <Button
                      className='buttons-viewer'
                      disabled={pageNumber === 1 && true}
                      onClick={() => setPageNumber(pageNumber - 1)}
                    >
                      &lt;
                    </Button>
                    <Button
                      disabled={numPages === pageNumber && true}
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      &gt;
                    </Button>
                  </div>
                  <span className='pdf-viewer-page-text'>
                    Page {pageNumber} of {numPages}
                  </span>
                </div>
                <Document
                  onLoadSuccess={onDocumentLoadSuccess}
                  file={pdfPreview}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}

export default EditTemplate
