import React, { Fragment, useState, useContext } from 'react'
import { Button, notification, Popover, DatePicker } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import { CREATE_TICKET } from './graphql/Mutations'
import CreateTicketModal from './CreateTicketModal'
import { useQuery } from '@apollo/react-hooks'
import { GET_TICKETS } from './graphql/Queries'
import { CUSTOM_CSV_DOWNLOAD } from 'modules/Reports/graphql/Queries'
import TabTicketsNew from './TabTickets/TabTicketsNew'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { ArrowLeftOutlined } from '@ant-design/icons'

import './index.css'
import history from 'CustomHistory'
import moment from 'moment'

const { RangePicker } = DatePicker

export default function () {
  const {
    state: {
      isClient,
      isAdmin,
      isVerificationDone,
      currentUser: { id },
      isMobile,
    },
  } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState(
    !isClient && !isAdmin ? { assignUserId: parseInt(id) } : {}
  )
  const [CSVLoading, setCSVLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  let tableData = []

  let variables = { where: filters, limit, skip, sort }
  const { data, loading, error } = useQuery(GET_TICKETS, {
    variables,
    fetchPolicy: 'network-only',
  })

  let totalCount = 0
  if (!loading && get(data, 'getTickets.tickets')) {
    totalCount = data.getTickets.count
    tableData = data.getTickets.tickets.map((ticket, key) => ({
      key: key.toString(),
      ...ticket,
    }))
  }

  // if (loading) return <Loader />

  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { data: { ...values } },
        refetchQueries: [
          {
            query: GET_TICKETS,
            variables,
          },
        ],
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  const handleCsvDownload = () => {
    setCSVLoading(true)
    client
      .query({
        query: GET_TICKETS,
        variables: { where: { ...filters }, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const tickets = res.data?.getTickets?.tickets?.map((ticket) => ({
          ...ticket,
          clientFullName:
            ticket?.generateFor?.firstName && ticket.generateFor?.lastName
              ? `${ticket?.generateFor?.firstName} ${ticket?.generateFor?.lastName}`
              : `${ticket?.generatedBy?.firstName || ''} ${
                  ticket?.generatedBy?.lastName || ''
                }`.trim(),

          generatedByFullName: `${ticket?.generatedBy?.firstName || ''} ${
            ticket?.generatedBy?.lastName || ''
          }`.trim(),
          assignUserFullName: `${ticket?.assignUser?.firstName || ''} ${
            ticket?.assignUser?.lastName || ''
          }`.trim(),
          closedByUserFullName: `${ticket?.closedByUser?.firstName || ''} ${
            ticket?.closedByUser?.lastName || ''
          }`.trim(),
          clientCodeUserName: ticket?.generateFor?.clientCode
            ? ticket?.generateFor?.clientCode || ticket?.generateFor?.userName
            : ticket?.generatedBy?.clientCode || ticket?.generatedBy?.userName,
          ticketLogs: ticket?.ticketLogs
            ?.map((item, index) => {
              const formattedDate = moment(item?.createdAt).format(
                'DD-MM-YYYY hh:mm A'
              )
              const attachment = item?.fileUrl?.length
                ? item?.fileUrl.join(',')
                : ''
              return `${index + 1}, ${item?.status}, ${item?.description}, ${
                item?.statusChanger?.firstName +
                ' ' +
                item?.statusChanger?.lastName
              }, ${formattedDate}, ${attachment}`
            })
            ?.join('\n'),
        }))

        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: tickets,
                fields: [
                  { value: 'ticketNumber', label: 'Ticket Number' },
                  {
                    value: 'clientFullName',
                    label: 'Client Name',
                  },
                  {
                    value: 'clientCodeUserName',
                    label: 'Client Code/User Name',
                  },
                  { value: 'status', label: 'Status' },
                  { value: 'categoryName', label: 'Category' },
                  { value: 'subCategoryName', label: 'Sub-Category' },
                  {
                    value: 'description',
                    label: 'Description',
                  },
                  { value: 'ticketLogs', label: 'Status Summary' },
                  {
                    value: 'assignUserFullName',
                    label: 'Assigned User',
                  },
                  {
                    value: 'generatedBy.role',
                    label: 'User Type',
                  },
                  {
                    value: 'generatedByFullName',
                    label: 'Created By',
                  },
                  {
                    value: 'closedByUserFullName',
                    label: 'Closed By',
                  },
                  { value: 'createdAt', label: 'Created At' },
                  { value: 'closedDate', label: 'Closed At' },
                  {
                    value: 'updatedAt',
                    label: 'Last Status Timestamp',
                  },
                  { value: 'fileUrl', label: 'Attachment' },
                ],
                fileName: 'Tickets',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLimit(10)
        setSkip(0)
        setCSVLoading(false)
      })
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        style={
          CSVLoading
            ? { cursor: 'progress' }
            : {
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: ' #000',
                cursor: 'pointer',
              }
        }
        onClick={handleCsvDownload}
      >
        <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
        Excel
      </div>
    </div>
  )

  return (
    <Fragment>
      <CustomMeta title='Tickets' />
      <div className='main_dashboard_div' style={{ padding: '20px 40px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
              display: 'flex',
              gap: '20px',
            }}
          >
            {isClient && !isVerificationDone ? (
              <div
                className='back_icon_for_advisory'
                onClick={() => history.goBack()}
              >
                <ArrowLeftOutlined />
              </div>
            ) : (
              ''
            )}
            Tickets
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {isAdmin ? (
              <Popover content={content} placement='bottom' trigger='click'>
                <Button
                  style={{
                    margin: '0',
                    padding: '8px 16px',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#AF0000',
                    background: '#AF00000D',
                    border: '1px solid #AF00000D',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                >
                  Export
                </Button>
              </Popover>
            ) : (
              <Button
                style={{
                  margin: '0',
                  padding: '8px 16px',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  background: '#AF0000',
                  border: '1px solid #AF0000',
                  height: '40px',
                  borderRadius: '8px',
                }}
                onClick={() => setShowFormModal(true)}
              >
                Generate Ticket{' '}
              </Button>
            )}
          </div>
        </div>

        {isAdmin ? (
          <div className='ticket_Action_Container'>
            <div className='ticket_counter_main_div'>
              <div className='ticket_counter_sub_div'>
                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#002060' }}
                  >
                    Total
                  </div>
                  <div className='Count' style={{ background: '#002060' }}>
                    {data?.getTickets?.counters?.totalTicket || 0}
                  </div>
                </div>

                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#df8f45' }}
                  >
                    Pending
                  </div>
                  <div className='Count' style={{ background: '#f1a983' }}>
                    {data?.getTickets?.counters?.pendingTicket || 0}
                  </div>
                </div>
              </div>

              <div className='ticket_counter_sub_div'>
                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#00B050' }}
                  >
                    Open
                  </div>
                  <div className='Count' style={{ background: '#00B050' }}>
                    {data?.getTickets?.counters?.openTicket || 0}
                  </div>
                </div>

                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#fc36b5' }}
                  >
                    In-progress
                  </div>
                  <div className='Count' style={{ background: '#fc36b5' }}>
                    {data?.getTickets?.counters?.inProcessTicket || 0}
                  </div>
                </div>
              </div>

              <div className='ticket_counter_sub_div'>
                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#3c7d22' }}
                  >
                    Close
                  </div>
                  <div className='Count' style={{ background: '#3c7d22' }}>
                    {data?.getTickets?.counters?.closedTicket || 0}
                  </div>
                </div>

                <div className='ticket_counter_div'>
                  <div
                    className='completed_status'
                    style={{ color: '#ff0000' }}
                  >
                    Re-open
                  </div>
                  <div className='Count' style={{ background: '#ff0000' }}>
                    {data?.getTickets?.counters?.reOpenTicket || 0}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <RangePicker
                value={[
                  filters[`fromCreatedAtDate`]
                    ? moment(filters[`fromCreatedAtDate`], 'YYYY-MM-DD')
                    : null,
                  filters[`toCreatedAtDate`]
                    ? moment(filters[`toCreatedAtDate`], 'YYYY-MM-DD')
                    : null,
                ]}
                format={'DD-MMM-YYYY'}
                onChange={(e) => {
                  const from = `fromCreatedAtDate`
                  const to = `toCreatedAtDate`
                  let tempFilters

                  if (e) {
                    tempFilters = JSON.parse(JSON.stringify(filters))
                    tempFilters[from] = e[0].format('YYYY-MM-DD')
                    tempFilters[to] = e[1].format('YYYY-MM-DD')
                    console.log({ tempFilters })
                  } else {
                    tempFilters = JSON.parse(JSON.stringify(filters))
                    delete tempFilters[from]
                    delete tempFilters[to]
                  }
                  setFilters(tempFilters)
                  setCurrentPage(1)
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {!isAdmin && showFormModal && (
          <CreateTicketModal
            visible={showFormModal}
            loading={showFormLoading}
            onCreate={handleCreateTicket}
            onCancel={() => setShowFormModal(false)}
            fromFaq={false}
          />
        )}
        {/* {isClient || isAdmin ? ( */}
        <>
          <TabTicketsNew
            setSort={setSort}
            setSkip={setSkip}
            setLimit={setLimit}
            setFilters={setFilters}
            limit={limit}
            filters={filters}
            data={data}
            loading={loading}
            error={error}
            tableData={tableData}
            totalCount={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
        {/* ) : (
          <Widget
            title='&nbsp;'
            styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'
          >
            <Tabs defaultActiveKey={tabKey} onChange={changeTab}>
              <TabPane tab='Assigned Tickets' key='1'>
                <div className='gx-mb-2'>
                  <TabTicketsNew
                    defaultFilters={{
                      assignUserId: parseInt(id),
                    }}
                    setSort={setSort}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    setFilters={setFilters}
                    limit={limit}
                    filters={filters}
                    data={data}
                    loading={loading}
                    error={error}
                    tableData={tableData}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </TabPane>
              <TabPane tab='Generated Tickets' key='2'>
                <div className='gx-mb-2'>
                  <TabTicketsNew
                    defaultFilters={{
                      generatedById: parseInt(id),
                    }}
                    setSort={setSort}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    setFilters={setFilters}
                    limit={limit}
                    filters={filters}
                    data={data}
                    loading={loading}
                    error={error}
                    tableData={tableData}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Widget>
        )} */}
      </div>
    </Fragment>
  )
}
