import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'
import { DownloadOutlined } from '@ant-design/icons'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
// import DownloadReport from '../DownloadReport'
import { SP_CURRENT_POSITION_JBALANCE } from '../graphql/Queries'
import '../index.css'
import JbalanceTable from './JbalanceTable'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import { CSVLink } from 'react-csv'
import { Button, Popover } from 'antd'
import CustomMeta from 'components/CustomMeta'

export default function () {
  const {
    state: {
      isAdmin,
      isStaff,
      currentUser: { clientCode, role },
      isMobile,
    },
  } = useContext(AppContext)
  const code = !isAdmin && !isStaff ? clientCode : ''

  const { data, loading, error } = useQuery(SP_CURRENT_POSITION_JBALANCE, {
    variables: { where: { clientCode: code } },
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!get(data, 'spCurrentPositionJBalance')) return <NoData />

  let currentPositionJBalanceData
  const { spCurrentPositionJBalance } = data
  currentPositionJBalanceData = spCurrentPositionJBalance.map((jBalance) => {
    return {
      ...jBalance,
      LedgerAll: Math.round(jBalance.LedgerAll),
      LedgerJCPL: Math.round(jBalance.LedgerJCPL),
      LedgerJFS: Math.round(jBalance.LedgerJFS),
      LedgerJSC: Math.round(jBalance.LedgerJSC),
    }
  })

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'j-balance-report',
      'J-BALANCE-REPORT-PDF',
      null,
      true
    ).finally(() =>
      console.log(' ====== ROLLOVER-TRACKING-report downloaded ====== ')
    )
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={currentPositionJBalanceData}
        className='csv-btn'
        filename={`j-balance-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Report | J-Balance' />
      {/* <DownloadReport
        data={currentPositionJBalanceData}
        fileName='j-balance-report'
        id='J-BALANCE-REPORT-PDF'
      /> */}
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>J-Balance</div>
          <div className='container_Export_Popover'>
            <Popover content={content} placement='bottom' trigger='click'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>
          </div>
        </div>
        <div className='report-table-wrapper' id='J-BALANCE-REPORT-PDF'>
          <JbalanceTable reportData={currentPositionJBalanceData} role={role} />
        </div>
      </div>
    </>
  )
}
