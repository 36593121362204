import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'

import { FilterOutlined, DownloadOutlined } from '@ant-design/icons'
import NoData from 'components/NoData'
import Loader from 'components/loaders/Loader'
// import DownloadReport from '../DownloadReport'
import NlvMisMatchTable from './NlvMisMatchTable'
import { GET_NLV_MISMATCH_REPORT } from '../graphql/Queries'
import '../index.css'
import { Button, Drawer, Form, Popover, Select } from 'antd'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { CSVLink } from 'react-csv'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import { exportGraphAsPdf } from 'utils/Graph'
import CustomMeta from 'components/CustomMeta'

export default function () {
  const { Option } = Select
  const {
    state: {
      currentUser: { role },
      isMobile,
    },
  } = useContext(AppContext)
  const [changeBod, setChangeBod] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)

  const handleChange = (e) => {
    setChangeBod(e)
    setOpen(false)
  }

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_NLV_MISMATCH_REPORT,
        variables: { where: { afterBod: changeBod, strategy: 'ILTS' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== 'NO_USER_FOUND') {
          setLoading(false)
        }
      })
  }, [changeBod])

  if (loading) return <Loader />
  if (!get(data, 'spNlvMisMatchReport')) return <NoData />
  const {
    spNlvMisMatchReport: [spNlvMisMatchReport],
  } = data

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleDownloadPdf = () => {
    exportGraphAsPdf('NLV-report', 'NLV-REPORT-PDF', null, true).finally(() =>
      console.log(' ====== NLV-report downloaded ====== ')
    )
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={spNlvMisMatchReport}
        className='csv-btn'
        filename={`NLV-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Report | NLV Mismatch' />
      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>
        <Form
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
        >
          <div
            style={{
              height: isMobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 12rem)',
              overflowY: 'auto',
            }}
          >
            <Form.Item label='Select BOD'>
              <Select
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className='display-field'
                value={changeBod}
                onChange={handleChange}
                placeholder='Select BOD'
              >
                <Option value={0}>Pre BOD</Option>
                <Option value={1}>Post BOD</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Drawer>

      {/* <DownloadReport
        data={spNlvMisMatchReport}
        fileName='NLV-report'
        id='NLV-REPORT-PDF'
      /> */}

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>NLV Mismatch</div>

          <div className='container_Export_Popover'>
            <Popover content={content} placement='bottom' trigger='click'>
              <div className='container_Export_Popover'>
                <Button>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            </Popover>

            {role === 'ADMIN' && (
              <Button
                style={{
                  color: '#000000',
                  border: '1px solid #919EAB52',
                  backgroundColor: 'white',
                }}
                onClick={showDrawer}
              >
                Filter{' '}
                <FilterOutlined color='#000000' style={{ fontSize: '16px' }} />
              </Button>
            )}
          </div>
        </div>

        <div className='report-table-wrapper' id='NLV-REPORT-PDF'>
          <NlvMisMatchTable reportData={spNlvMisMatchReport} />
        </div>
      </div>
    </>
  )
}
