import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import InterestTable from './InterestTable'
import Loader from 'components/loaders/Loader'
// import DownloadReport from '../DownloadReport'
import { GET_INTEREST_REPORT } from '../graphql/Queries'
import { GET_USER } from 'modules/Users/graphql/Queries'
import '../index.css'
import './index.css'
import { AppContext } from 'AppContext'
import { Button, Popover } from 'antd'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { DownloadOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv'
import { exportGraphAsPdf } from 'utils/Graph'
import CustomMeta from 'components/CustomMeta'

export default function (props) {
  let {
    match: {
      params: { clientCode, clientId },
    },
  } = props

  const {
    state: { currentUser, isMobile },
  } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_INTEREST_REPORT, {
    variables: {
      where: {
        clientCode: clientCode || currentUser?.clientCode,
        date: null,
        strategy: 'ILTS',
      },
    },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId || currentUser?.id } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spInterestReport')) return <NoData />

  const {
    spInterestReport: [spInterestReport],
  } = data

  const handleDownloadPdf = () => {
    exportGraphAsPdf(
      'interest-report',
      'INTEREST-REPORT-PDF',
      null,
      true
    ).finally(() => console.log('===== Download'))
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={JSON.parse(JSON.stringify(spInterestReport))?.map((item, key) => {
          if (key + 1 === JSON.parse(JSON.stringify(spInterestReport)).length) {
            return {
              Month: item.Type,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          } else {
            return {
              Month: item.Mnth,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          }
        })}
        className='csv-btn'
        filename={`interest-report`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Report | Interest' />
      {/* <DownloadReport
        data={JSON.parse(JSON.stringify(spInterestReport))?.map((item, key) => {
          if (key + 1 === JSON.parse(JSON.stringify(spInterestReport)).length) {
            return {
              Month: item.Type,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          } else {
            return {
              Month: item.Mnth,
              CumDPayment: item.CumDPayment,
              CumExposure: item.CumExposure,
              Interest: item.Interest,
              TotExposure: item.TotExposure,
            }
          }
        })}
        fileName='interest-report'
        id='INTEREST-REPORT-PDF'
      /> */}

      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Interest Report</div>

          <Popover content={content} placement='bottom' trigger='click'>
            <div className='container_Export_Popover'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </div>
          </Popover>
        </div>

        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              <div className='client_Info'>
                Client Code:&nbsp;
                <span>{currentUser?.clientCode}</span>
              </div>
              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {currentUser?.firstName} {currentUser?.lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className='report-table-wrapper interest-table-report'
          id='INTEREST-REPORT-PDF'
        >
          <InterestTable
            reportData={spInterestReport}
            ClientCode={clientCode || currentUser?.clientCode}
            user={getUserData}
          />
        </div>
      </div>
    </>
  )
}
