import React, { useContext, useEffect, useState } from 'react'
import get from 'lodash/get'

import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
// import DownloadReport from 'modules/Reports/DownloadReport'
import NlvTrackerTable from 'modules/Reports/NLVTracker/NlvTrackerTable'
import {
  GET_COMPARISON_OF_FUND_REPORT_DATA,
  GET_NLV_TRACKER_REPORT,
} from 'modules/Reports/graphql/Queries'
import 'modules/Reports/index.css'
import client from 'apollo'
import NoData from 'components/NoData'
import { Button, Popover, Select } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { CSVLink } from 'react-csv'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

const { Option } = Select

export default function (props) {
  const [selectPeriod, setSelectPeriod] = useState('Daily')
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const {
    state: { isMobile },
  } = useContext(AppContext)
  let {
    match: {
      params: { clientCode },
    },
  } = props

  useEffect(() => {
    setLoader(true)
    client
      .query({
        query: GET_NLV_TRACKER_REPORT,
        variables: { where: { clientCode, selectPeriod } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoader(false)
        setData(res?.data)
      })
      .catch((err) => {
        setError(err)
      })
  }, [clientCode, selectPeriod])

  if (error) return <Page404 error={error} />
  if (!get(data, 'spNLVTracker')) return <Loader />

  const {
    spNLVTracker: [spNLVTracker],
  } = data

  if (!spNLVTracker?.length) return <NoData />

  let spNLVTrackerFilterData = spNLVTracker

  const configuration = [
    {
      key: 'ReportDate',
      name: 'Report Date',
    },
    {
      key: 'NIFTY',
      name: 'NIFTY',
    },
    {
      key: 'NetAmt',
      name: 'Net Investment',
    },
    {
      key: 'NLV',
      name: 'NLV Net Liquidation Value',
    },
    {
      key: 'PnlWithInterest',
      name: 'P&L with Interest',
    },
    {
      key: 'PnlWithOutInterest',
      name: 'P&L without Interest',
    },
    {
      key: 'TotalExposure',
      name: 'Original Exposure',
    },
    {
      key: 'TotExposureWithNifty',
      name: 'Current Exposure',
    },
    {
      key: 'Interest',
      name: 'Interest per Day',
    },
    {
      key: 'CumInterest',
      name: 'Cumulative Interest',
    },
    {
      key: 'TotNLV',
      name: 'Total NLV',
    },
    {
      key: 'NIFTY%',
      name: 'NIFTY %',
    },
    {
      key: 'TotNLV%',
      name: 'Total NLV %',
    },
  ]

  function renameKeys(input, configuration) {
    return input.map((obj) => {
      const renamedObj = {}
      configuration.forEach((config) => {
        const { key, name } = config
        if (obj.hasOwnProperty(key)) {
          renamedObj[name] = obj[key]
        }
      })
      return renamedObj
    })
  }

  const spNLVTrackerFilterDataForCSV = renameKeys(
    spNLVTrackerFilterData,
    configuration
  )

  const handleDownloadPdf = () => {
    client
      .query({
        query: GET_COMPARISON_OF_FUND_REPORT_DATA,
        variables: { where: { clientCode, selectPeriod } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        get(res, 'data.getComparisonOfFundReport') &&
          window.open(get(res, 'data.getComparisonOfFundReport'), '_blank')
      })
      .catch((err) => console.log(err))
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>
      <CSVLink
        style={{ paddingRight: 0 }}
        data={spNLVTrackerFilterDataForCSV}
        className='csv-btn'
        filename={`NLV-Tracker-report.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Client Comparison' />
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>
            Comparison of Fund with Index Report
          </div>

          <div className='container_Export_Popover'>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className='display-field-report'
              value={selectPeriod}
              placeholder='Select Type'
              onChange={(e) => setSelectPeriod(e)}
              loading={loader}
              // style={{ width: '150px' }}
            >
              <Option value='Daily'>Daily</Option>
              <Option value='Monthly'>Monthly</Option>
              <Option value='Quarterly'>Quarterly</Option>
              <Option value='HalfYearly'>Half Yearly</Option>
              <Option value='Yearly'>Yearly</Option>
            </Select>
            <Popover content={content} placement='bottom' trigger='click'>
              <div className='container_Export_Popover'>
                <Button>
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </div>
            </Popover>
          </div>
        </div>
        {/* <DownloadReport
          isYearPeriod={true}
          data={spNLVTrackerFilterDataForCSV}
          fileName='NLV-Tracker-report'
          id='NLV-TRACKER-REPORT-DOWNLOAD-PDF'
          setSelectPeriod={setSelectPeriod}
          selectPeriod={selectPeriod}
          loadingSpData={loader}
          isComparisonReport={true}
          clientCode={clientCode}
        /> */}
        <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-PDF'>
          <NlvTrackerTable
            reportData={spNLVTrackerFilterData}
            loading={loader}
            id={'NLV-TRACKER-REPORT-PDF'}
          />
        </div>
        <div style={{ display: 'none' }}>
          <div
            className='report-table-wrapper'
            id='NLV-TRACKER-REPORT-DOWNLOAD-PDF'
          >
            <NlvTrackerTable
              reportData={spNLVTrackerFilterData}
              loading={loader}
              id={'NLV-TRACKER-REPORT-DOWNLOAD-PDF'}
            />
          </div>
        </div>
      </div>
    </>
  )
}
