import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Select,
  DatePicker,
  Form,
  Table,
  Space,
  Input,
  Drawer,
} from 'antd'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import { GET_ALL_CLIENT } from 'modules/Users/graphql/Queries'
import {
  SearchOutlined,
  FilterOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import {
  GET_ALL_FIELD_FOR_LOG,
  GET_ALL_LOGS,
  EXPORT_CLIENT_LOGS,
} from './graphql/Queries'
import client from 'apollo'
import moment from 'moment'
import { get } from 'lodash'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'
import './index.css'
import CustomMeta from 'components/CustomMeta'

const { Option } = Select
const { RangePicker } = DatePicker

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

export default function ClientLog() {
  const [formData] = Form.useForm()

  const dateFormat = 'DD-MMM-YYYY'

  const [load, setLoad] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [sort, setSort] = useState()
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filedData, setFiledData] = useState({})
  const [open, setOpen] = useState(false)

  const { data, loading, error } = useQuery(GET_ALL_CLIENT, {
    variables: { where: {}, limit: 10000 },
  })
  const {
    data: fieldData,
    loading: fieldLoading,
    error: fieldError,
  } = useQuery(GET_ALL_FIELD_FOR_LOG)
  const {
    data: logData,
    loading: logLoading,
    error: logError,
  } = useQuery(GET_ALL_LOGS, {
    variables: { where: filedData, sort, skip, limit },
    fetchPolicy: 'network-only',
  })
  if (error || fieldError || logError) return <Page404 />

  let totalCount = logData?.getAllLogs?.count
  const changeFormData = (value) => {
    if (value.date) {
      const createdAtFrom = value.date[0]
      const createdAtTo = value.date[1]

      value = {
        ...value,
        createdAtFrom,
        createdAtTo,
      }
      delete value.date
    } else if (!value.date) {
      value = { ...value }
      delete value.date
    }
    setFiledData(value)
    setLoad(false)
    setOpen(false)
    // client.query({ query: GET_ALL_LOGS, variables: { where: value }, sort, skip, limit })
    //   .then(res => {
    //     setLogsData(res.data.getAllLogs.log)
    //     setLoad(false)
    //   })
    //   .catch(err => console.log(err))
  }

  const exportClientLog = (value) => {
    setExportLoading(true)
    client
      .query({
        query: EXPORT_CLIENT_LOGS,
        variables: { where: filedData, sort, skip: 0, limit: totalCount },
      })
      .then((res) => {
        setExportLoading(false)
        window.open(get(res, 'data.exportLogs.url'), '_blank')
      })
      .catch((err) => {
        setExportLoading(false)
      })
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    }
  }

  const handlePagination = (page) => {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  const handlePageChangeSize = (current, size) => {
    setLimit(size)
  }

  const formDataReset = () => {
    formData.resetFields()
    setFiledData({})
    setOpen(false)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filedData))
    tempFilters[filedName] = value
    setFiledData({ fieldName: tempFilters[filedName] })
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filedData))
    delete tempFilters[filedName]
    setFiledData(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'client',
      key: 'client',
      className: 'client-log-table',
      render: (record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: 'Client Email',
      dataIndex: 'client',
      key: 'client',
      render: (record) => `${record?.email}`,
      sorter: (a, b) => a.record?.email - b.record?.email,
    },
    {
      title: 'Updated Field Name',
      dataIndex: 'fieldName',
      key: 'fieldName',
      sorter: (a, b) => a.fieldName - b.fieldName,
      ...getColumnSearchProps('fieldName'),
    },
    {
      title: 'Old Data',
      dataIndex: 'oldData',
      key: 'oldData',
      sorter: (a, b) => a.oldData - b.oldData,
    },
    {
      title: 'New Data',
      dataIndex: 'newData',
      key: 'newData',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
      sorter: (a, b) => a.createdAt - b.createdAt,
      width: 120,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedByUser',
      key: 'updatedByUser',
      render: (data) =>
        data?.userName
          ? data?.firstName + ' ' + data?.lastName + `(${data?.role==='SALES_EXECUTIVE'?'AIF_SALES_EXECUTIVE':data?.role})`
          : '-',
      width: 120,
    },
    // {
    //   title: 'Updated Date',
    //   dataIndex: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
    // }
  ]

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CustomMeta title='Client Logs' />
      {/* <PageHeader
        className='box'
        title='Client Log'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={exportClientLog}
            loading={exportLoading}
          >
            Export
          </Button>,
        ]}
      /> */}

      <Drawer
        title={null}
        headerStyle={{ display: 'none' }}
        closable={false}
        placement='right'
        onClose={onClose}
        visible={open}
        width={472}
      >
        <div
          style={{
            margin: '32px 0 50px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <div onClick={onClose}>
            <img src={FilterBackButton} alt='FilterBackButton' />
          </div>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '28px',
              textAlign: 'left',
              color: '#000000',
              marginLeft: '16px',
            }}
          >
            Filter
          </div>
        </div>

        <Form
          {...formItemLayout}
          form={formData}
          name='advanced_search'
          style={{ marginTop: '16px' }}
          layout='vertical'
          className='client-detail-form'
          onFinish={changeFormData}
        >
          {!loading ? (
            <>
              <Form.Item label='Select Client' name='clientId'>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder='Select Client'
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {!loading &&
                    !error &&
                    data?.getClientUsers?.users.map((ele, index) => {
                      return (
                        <Option value={ele.id} key={index}>
                          {ele.clientCode}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>

              <Form.Item label='Select Field' name='fieldName'>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  placeholder='Select Field'
                >
                  {!fieldLoading &&
                    !fieldError &&
                    fieldData?.getAllFieldsForLog.map((ele, index) => {
                      return (
                        <Option value={ele} key={index}>
                          {' '}
                          {ele}{' '}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>

              <Form.Item label='Date' name='date'>
                <RangePicker format={dateFormat} />
              </Form.Item>

              <div style={{ display: 'flex', gap: '20px', height: '56px' }}>
                <Button
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    padding: '16px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  htmlType='button'
                  onClick={formDataReset}
                >
                  Reset
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={load}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    padding: '16px',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Search
                </Button>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </Form>
      </Drawer>

      <div className='main_dashboard_div'>
        <div className='client_log_main_div'>
          <div className='client_log_text'>Client Log</div>

          <div className='option_btn_div'>
            <Button
              className='export_btn'
              onClick={exportClientLog}
              loading={exportLoading}
              disabled={!logData?.getAllLogs?.log.length}
            >
              Export csv
              <DownloadOutlined color='#AF0000' className='icon_style' />
            </Button>

            <Button className='filter_btn' onClick={showDrawer}>
              Filter <FilterOutlined color='#000000' className='icon_style' />
            </Button>
          </div>
        </div>

        {!loading && logData?.getAllLogs?.log ? (
          <Table
            columns={columns}
            loading={logLoading}
            onChange={handleTableChange}
            dataSource={logData?.getAllLogs?.log}
            scroll={{ x: true, y: true }}
            rowKey='id'
            pagination={{
              pageSize: limit,
              total: totalCount,
              current: currentPage,
              onChange: handlePagination,
              onShowSizeChange: handlePageChangeSize,
            }}
          />
        ) : (
          <Loader />
        )}
      </div>
    </>
  )
}
