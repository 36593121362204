import { useQuery } from '@apollo/react-hooks'
import { Button, DatePicker, Input, Modal, Space, Table, Tooltip } from 'antd'
import { AppContext } from 'AppContext'
import '../index.css'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import { GET_CC_EMAIL, GET_INVOICES } from 'modules/Settings/graphql/Queries'
import React, { useContext, useEffect, useState } from 'react'
import {
  LinkOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { CUSTOM_CSV_DOWNLOAD } from '../graphql/Queries'
import client from 'apollo'
import { get, uniq } from 'lodash'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import states from 'utils/states.json'
import { NavLink } from 'react-router-dom'
import { DELETE_INVOICE } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import MandateEmail from 'modules/Settings/InvoiceMaster/mandateMail'
import './index.css'

const { confirm } = Modal

const { RangePicker } = DatePicker
const InvoiceReport = () => {
  const { state } = useContext(AppContext)
  const {
    state: { isMobile },
  } = useContext(AppContext)
  const { isAdmin, invoiceSetting } = state
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState({})
  const [cSVLoading, setCSVLoading] = useState(false)

  const [openSendMail, setOpenSendMail] = useState(false)
  const [invoiceCreateData, setInvoiceCreateData] = useState()

  const [tableData, setTableData] = useState()
  const clientType = [
    { text: 'Manual', value: 'MANUAL' },
    { text: 'Client Code', value: 'CLIENT_CODE' },
    { text: 'Email Id', value: 'EMAIL_ID' },
  ]

  const deduction = [
    { text: 'Mandate', value: 'E_MANDATE' },
    { text: 'Non Mandate', value: 'NON_E_MANDATE' },
  ]

  const amcType = [
    { text: 'New', value: 'NEW' },
    { text: 'Renewal', value: 'RENEWAL' },
  ]

  const amcCalculateType = [
    { text: 'Nifty', value: 'NIFTY' },
    { text: 'AUA', value: 'AUA' },
  ]

  const { data, loading, error } = useQuery(GET_INVOICES, {
    variables: {
      where: {
        ...filters,
        discountInPer: filters.discountInPer && parseInt(filters.discountInPer),
        commissionInPer:
          filters.commissionInPer && parseFloat(filters.commissionInPer),
        assetUnderAdvisory:
          filters.assetUnderAdvisory && parseInt(filters.assetUnderAdvisory),
        amcInPer: filters.amcInPer && parseFloat(filters.amcInPer),
        amcAmount: filters.amcAmount && parseFloat(filters.amcAmount),
        discountAmcInPer:
          filters.discountAmcInPer && parseFloat(filters.discountAmcInPer),
      },
      limit,
      skip,
      sort,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setTableData({ data: data?.getInvoices, loading })
  }, [data, loading])

  if (error) return <Page404 error={error} />

  function createMarkup(__html) {
    return { __html }
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.clientType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['clientType'] = filter.clientType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.deduction) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['deduction'] = filter.deduction
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.amcType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['amcType'] = filter.amcType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else if (filter.amcCalculateType) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['amcCalculateType'] = filter.amcCalculateType
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters[
        'clientType' || 'deduction' || 'amcType' || 'amcCalculateType'
      ] = ''
      delete tempFilters.clientType
      delete tempFilters.deduction
      delete tempFilters.amcType
      delete tempFilters.amcCalculateType
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }
  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          // value={selectedKeys[0]}
          defaultValue={
            JSON.parse(localStorage.getItem('filters'))
              ? JSON.parse(localStorage.getItem('filters'))[dataIndex]
              : ''
          }
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const showConfirm = (id) => {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Are you sure you want to delete this invoice?`,
      content: `If you click the OK button, the invoice will be permanently removed along with the associated AMC.`,
      onOk() {
        setTableData({ ...tableData, loading: true })
        client
          .mutate({
            mutation: DELETE_INVOICE,
            variables: { where: { id } },
          })
          .then(() => {
            openNotification('success', 'Invoice deleted successfully!')
            client
              .query({
                query: GET_INVOICES,
                variables: {
                  where: {
                    ...filters,
                    discountInPer:
                      filters.discountInPer && parseInt(filters.discountInPer),
                    commissionInPer:
                      filters.commissionInPer &&
                      parseFloat(filters.commissionInPer),
                    assetUnderAdvisory:
                      filters.assetUnderAdvisory &&
                      parseInt(filters.assetUnderAdvisory),
                    amcInPer: filters.amcInPer && parseFloat(filters.amcInPer),
                    amcAmount:
                      filters.amcAmount && parseFloat(filters.amcAmount),
                    discountAmcInPer:
                      filters.discountAmcInPer &&
                      parseFloat(filters.discountAmcInPer),
                  },
                  limit: data?.getInvoices.count,
                  skip,
                  sort,
                },
                fetchPolicy: 'network-only',
              })
              .then((res) => {
                setTableData({ data: res?.data?.getInvoices, loading: false })
              })
          })
      },
    })
  }

  const renderSendMail = (data) => {
    client
      .query({
        query: GET_CC_EMAIL,
        variables: { clientCode: data?.clientCode },
      })
      .then((res) => {
        let ccMails = invoiceSetting?.otherMailId?.split(',')
        if (res?.data?.getCCEmails?.HKeyEmail) {
          ccMails.push(...res?.data?.getCCEmails?.HKeyEmail?.split(','))
        }
        ccMails = uniq(ccMails)
        ccMails = ccMails.filter((item) => item !== '')
        data.emailId = res?.data?.getCCEmails?.email?.split(',')
        data.ccMailIds = ccMails
        setInvoiceCreateData(data)
        setOpenSendMail(true)
      })
  }

  const renderAction = (record) => {
    return (
      <div className='action-icons'>
        <Tooltip title='Delete Invoice'>
          <DeleteOutlined onClick={() => showConfirm(record.id)} />
        </Tooltip>
        <Tooltip title='Send Mail'>
          <MailOutlined onClick={() => renderSendMail(record)} />
        </Tooltip>
      </div>
    )
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  let columns = [
    {
      title: 'Client Type',
      dataIndex: 'clientType',
      key: 'clientType',
      width: 150,
      sorter: (a, b) => a.clientType - b.clientType,
      filters: clientType,
    },
    {
      title: 'Deduction',
      dataIndex: 'deduction',
      key: 'deduction',
      width: 150,
      // render: (text, record) =>
      //   record.client.umrnNumber !== null && record.client.umrnNumber
      //     ? 'E_MANDATE'
      //     : 'NON_E_MANDATE',
      sorter: (a, b) => a.deduction - b.deduction,
      filters: deduction,
    },
    {
      title: 'AMC Type',
      dataIndex: 'amcType',
      key: 'amcType',
      width: 150,
      sorter: (a, b) => a.amcType - b.amcType,
      filters: amcType,
    },
    {
      title: 'AMC Calculation',
      dataIndex: 'amcCalculateType',
      key: 'amcCalculateType',
      width: 150,
      sorter: (a, b) => a.amcCalculateType - b.amcCalculateType,
      filters: amcCalculateType,
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      width: 150,
      sorter: (a, b) => a.clientName - b.clientName,
      render: (text, record) =>
        record?.client
          ? `${record?.client?.firstName} ${record?.client?.lastName}`
          : '-',
      ...getColumnSearchProps('clientName'),
    },
    {
      title: 'Client Code',
      dataIndex: 'clientCode',
      key: 'clientCode',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${record?.client?.id} `}>
          {record?.clientCode || '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.clientCode - b.clientCode,
      ...getColumnSearchProps('clientCode'),
    },
    {
      title: 'Email Id',
      key: 'emailId',
      dataIndex: 'emailId',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/clients/${record?.client?.id} `}>
          {record?.emailId || '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.emailId - b.emailId,
      ...getColumnSearchProps('emailId'),
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      width: 150,
      sorter: (a, b) => a.address - b.address,
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Associate Name',
      key: 'associateName',
      dataIndex: 'associateName',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/users/${record?.client?.createdBy?.id} `}>
          {record?.client?.createdBy
            ? `${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName}`
            : '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.associateName - b.associateName,
      ...getColumnSearchProps('associateName'),
    },
    {
      title: 'RM Name',
      key: 'rmName',
      dataIndex: 'rmName',
      width: 150,
      render: (text, record) => (
        <NavLink to={`/users/${record?.client?.createdBy?.createdBy?.id} `}>
          {record?.client?.createdBy?.createdBy
            ? `${record?.client?.createdBy?.createdBy?.firstName} ${record?.client?.createdBy?.createdBy?.lastName}`
            : '-'}
        </NavLink>
      ),
      sorter: (a, b) => a.rmName - b.rmName,
      ...getColumnSearchProps('rmName'),
    },
    {
      title: 'State',
      key: 'state',
      dataIndex: 'state',
      width: 150,
      render: (text, record) =>
        states.filter((state) => state.id === +record.state)[0]?.name || '-',
      sorter: (a, b) => a.state - b.state,
      ...getColumnSearchProps('state'),
    },
    {
      title: 'Discount%',
      key: 'discountInPer',
      dataIndex: 'discountInPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.discountInPer - b.discountInPer,
      ...getColumnSearchProps('discountInPer'),
    },
    {
      title: 'Commission%',
      key: 'commissionInPer',
      dataIndex: 'commissionInPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.commissionInPer - b.commissionInPer,
      ...getColumnSearchProps('commissionInPer'),
    },
    {
      title: 'Nifty Spot',
      key: 'niftySpot',
      dataIndex: 'niftySpot',
      width: 150,
      sorter: (a, b) => a.niftySpot - b.niftySpot,
      // ...getColumnSearchProps('niftySpot'),
    },
    {
      title: 'Nifty Lot',
      key: 'niftyLot',
      dataIndex: 'niftyLot',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.clientType - b.clientType,
      // ...getColumnSearchProps('niftyLot'),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.quantity - b.quantity,
      // ...getColumnSearchProps('quantity'),
    },
    {
      title: 'Asset Under Advisory',
      key: 'assetUnderAdvisory',
      dataIndex: 'assetUnderAdvisory',
      width: 150,
      sorter: (a, b) => a.assetUnderAdvisory - b.assetUnderAdvisory,
      ...getColumnSearchProps('assetUnderAdvisory'),
    },
    {
      title: 'AMC%',
      key: 'amcInPer',
      dataIndex: 'amcInPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.amcInPer - b.amcInPer,
      ...getColumnSearchProps('amcInPer'),
    },
    {
      title: 'Discount Amc%',
      key: 'discountAmcInPer',
      dataIndex: 'discountAmcInPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.discountAmcInPer - b.discountAmcInPer,
      ...getColumnSearchProps('discountAmcInPer'),
    },
    {
      title: 'created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      render: (text, record) =>
        record.createdAt
          ? moment(record.createdAt).format('DD MMM YYYY, h:mm:ss a')
          : '-',
      sorter: (a, b) => a.createdAt - b.createdAt,
      ...getColumnDateProps('createdAt'),
    },
    {
      title: 'Start Date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (text, record) =>
        record.startDate ? moment(record.startDate).format('Do MMM YYYY') : '-',
      sorter: (a, b) => a.startDate - b.startDate,
      ...getColumnDateProps('startDate'),
    },
    {
      title: 'End Date',
      key: 'endDate',
      dataIndex: 'endDate',
      width: 150,
      render: (text, record) =>
        record.endDate ? moment(record.endDate).format('Do MMM YYYY') : '-',
      sorter: (a, b) => a.endDate - b.endDate,
      ...getColumnDateProps('endDate'),
    },
    {
      title: 'Due Day',
      key: 'dueDay',
      dataIndex: 'dueDay',
      width: 150,
      sorter: (a, b) => a.dueDay - b.dueDay,
      render: (text, record) => `${record.dueDay} Days`,
      // ...getColumnSearchProps('dueDay'),
    },
    {
      title: 'AMC Amount',
      key: 'amcAmount',
      dataIndex: 'amcAmount',
      width: 150,
      sorter: (a, b) => a.amcAmount - b.amcAmount,
      // ...getColumnSearchProps('amcAmount'),
    },
    {
      title: 'Discount Amount',
      key: 'discountAmount',
      dataIndex: 'discountAmount',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.discountAmount - b.discountAmount,
      // ...getColumnSearchProps('discountAmount'),
    },
    {
      title: 'Commission Amount',
      key: 'commissionAmount',
      dataIndex: 'commissionAmount',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.commissionAmount - b.commissionAmount,
      // ...getColumnSearchProps('commissionAmount'),
    },
    {
      title: 'E-Mandate Discount%',
      key: 'eMandateDiscountPer',
      dataIndex: 'eMandateDiscountPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.eMandateDiscountPer - b.eMandateDiscountPer,
      // ...getColumnSearchProps('grossTotalAmount'),
    },
    {
      title: 'E-Mandate Discount Amount',
      key: 'eMandateDiscountAmount',
      dataIndex: 'eMandateDiscountAmount',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.eMandateDiscountAmount - b.eMandateDiscountAmount,
      // ...getColumnSearchProps('grossTotalAmount'),
    },
    {
      title: 'Additional Discount',
      key: 'additionalDiscountAmount',
      dataIndex: 'additionalDiscountAmount',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.additionalDiscountAmount - b.additionalDiscountAmount,
      // ...getColumnSearchProps('grossTotalAmount'),
    },
    {
      title: 'Gross Total Amount',
      key: 'grossTotalAmount',
      dataIndex: 'grossTotalAmount',
      width: 150,
      sorter: (a, b) => a.grossTotalAmount - b.grossTotalAmount,
      // ...getColumnSearchProps('grossTotalAmount'),
    },
    {
      title: 'CGST 9%',
      key: 'cgst9InPer',
      dataIndex: 'cgst9InPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.cgst9InPer - b.cgst9InPer,
      // ...getColumnSearchProps('cgst9InPer'),
    },
    {
      title: 'SGST 9%',
      key: 'sgst9InPer',
      dataIndex: 'sgst9InPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.sgst9InPer - b.sgst9InPer,
      // ...getColumnSearchProps('sgst9InPer'),
    },
    {
      title: 'IGST18%',
      key: 'igst18InPer',
      dataIndex: 'igst18InPer',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.igst18InPer - b.igst18InPer,
      // ...getColumnSearchProps('igst18InPer'),
    },
    {
      title: 'Total Net Amount',
      key: 'totalNetAmount',
      dataIndex: 'totalNetAmount',
      width: 150,
      render: (record) => (record ? record : '-'),
      sorter: (a, b) => a.totalNetAmount - b.totalNetAmount,
      // ...getColumnSearchProps('totalNetAmount'),
    },
    {
      title: 'Pdf Name',
      key: 'pdfFileName',
      dataIndex: 'pdfFileName',
      width: 150,
      sorter: (a, b) => a.pdfFileName - b.pdfFileName,
    },
    {
      title: 'Subject',
      key: 'subject',
      dataIndex: 'subject',
      width: 150,
      render: (record) => (record ? `Finideas Advisory Fees (${record})` : '-'),
      sorter: (a, b) => a.subject - b.subject,
    },
    {
      title: 'Amount In Word',
      key: 'amountInWord',
      dataIndex: 'amountInWord',
      width: 150,
      sorter: (a, b) => a.amountInWord - b.amountInWord,
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
      width: 150,
      sorter: (a, b) => a.invoiceNo - b.invoiceNo,
      ...getColumnSearchProps('invoiceNo'),
    },
    {
      title: 'To Mail Id',
      key: 'otherMailId',
      dataIndex: 'otherMailId',
      width: 150,
      render: (record) =>
        record &&
        record.map((item) => {
          return item + '\n'
        }),
      sorter: (a, b) => a.otherMailId - b.otherMailId,
    },
    // {
    //   title: 'To Mail Id',
    //   key: 'emailId',
    //   dataIndex: 'emailId',
    //   width: 150,
    //   render: (record) => (record ? record : '-'),
    //   sorter: (a, b) => a.emailId - b.emailId,
    // },
    {
      title: 'CC Mail Id',
      key: 'ccMailIds',
      dataIndex: 'ccMailIds',
      width: 150,
      render: (record) =>
        record &&
        record.map((item) => {
          return item + '\n'
        }),
      sorter: (a, b) => a.ccMailIds - b.ccMailIds,
    },
    {
      title: 'Mail Body',
      key: 'mailBody',
      dataIndex: 'mailBody',
      width: 200,
      render: (text, record) => {
        return (
          <>
            {text ? (
              <div>
                <Tooltip
                  placement='top'
                  title={
                    <div
                      className='tooltip_text'
                      dangerouslySetInnerHTML={createMarkup(text)}
                    />
                  }
                >
                  <span>Click Here To show Mail Body</span>
                </Tooltip>
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      title: 'Invoice Pdf',
      key: 'invoicePdf',
      dataIndex: 'invoicePdf',
      width: 150,
      render: (text, record) =>
        record?.invoicePdf ? (
          <a href={`${record?.invoicePdf}`}>
            <LinkOutlined />
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'AMC Explanation',
      key: 'amcExplanation',
      dataIndex: 'amcExplanation',
      width: 200,
      render: (text, record) => {
        return (
          <>
            {text ? (
              <Tooltip
                placement='leftTop'
                title={
                  <div
                    className='tooltip_text'
                    dangerouslySetInnerHTML={createMarkup(text)}
                  />
                }
              >
                <span>Click Here To show AMC Explanation</span>
              </Tooltip>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]

  if (isAdmin) {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '80px',
    })
  }

  function handleCsvDownload() {
    setCSVLoading(true)
    client
      .query({
        query: GET_INVOICES,
        variables: {
          where: filters,
          limit: data?.getInvoices.count,
          skip,
          sort,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const processedData = res.data.getInvoices.rows.map((row) => ({
          ...row,
          associateName:
            row?.client?.createdBy?.firstName ||
            row?.client?.createdBy?.lastName
              ? `${row.client.createdBy.firstName} ${row.client.createdBy.lastName}`
              : '-',
          rmName:
            row?.client?.createdBy?.createdBy?.firstName ||
            row?.client?.createdBy?.createdBy?.lastName
              ? `${row.client.createdBy.createdBy.firstName} ${row.client.createdBy.createdBy.lastName}`
              : '-',
        }))

        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: processedData,
                fields: [
                  { value: 'clientType', label: 'clientType' },
                  { value: 'deduction', label: 'deduction' },
                  { value: 'amcType', label: 'amcType' },
                  { value: 'clientName', label: 'clientName' },
                  { value: 'clientCode', label: 'clientCode' },
                  { value: 'emailId', label: 'emailId' },
                  { value: 'address', label: 'address' },
                  {
                    value: 'associateName',
                    label: 'Associate Name',
                  },
                  {
                    value: 'rmName',
                    label: 'RM Name',
                  },
                  { value: 'state', label: 'state' },
                  { value: 'discountInPer', label: 'discountInPer' },
                  { value: 'commissionInPer', label: 'commissionInPer' },
                  { value: 'niftySpot', label: 'niftySpot' },
                  { value: 'niftyLot', label: 'niftyLot' },
                  { value: 'quantity', label: 'quantity' },
                  {
                    value: 'assetUnderAdvisory',
                    label: 'assetUnderAdvisory',
                  },
                  { value: 'amcInPer', label: 'amcInPer' },
                  {
                    value: 'discountAmcInPer',
                    label: 'discountAmcInPer',
                  },
                  { value: 'createdAt', label: 'createdAt' },
                  { value: 'startDate', label: 'startDate' },
                  { value: 'endDate', label: 'endDate' },
                  { value: 'dueDay', label: 'dueDay' },
                  { value: 'amcAmount', label: 'amcAmount' },
                  { value: 'discountAmount', label: 'discountAmount' },
                  {
                    value: 'commissionAmount',
                    label: 'commissionAmount',
                  },
                  {
                    value: 'grossTotalAmount',
                    label: 'grossTotalAmount',
                  },
                  { value: 'cgst9InPer', label: 'cgst9InPer' },
                  { value: 'sgst9InPer', label: 'sgst9InPer' },
                  { value: 'igst18InPer', label: 'igst18InPer' },
                  { value: 'totalNetAmount', label: 'totalNetAmount' },
                  { value: 'mailBody', label: 'mailBody' },
                  { value: 'invoicePdf', label: 'invoicePdf' },
                  { value: 'amcExplanation', label: 'amcExplanation' },
                  { value: 'amcCalculationType', label: 'AMC Calculation' },
                ],
                fileName: 'AMCs',
              },
            },
          })
          .then((res) => {
            get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
            // setCSVLoading(false)
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
  }

  return (
    <>
      <div className='main_dashboard_div'>
        <CustomMeta title='Invoice' />
        {/* <PageHeader
        className='box'
        title='Invoice Report'
        extra={[
          currentReport.includes('Create User') && (
            <Button
              key='1'
              type='primary'
              onClick={handleCsvDownload}
              loading={cSVLoading}
            >
              Export Csv
            </Button>
          ),
        ]}
        />
         */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Invoice Report
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              onClick={handleCsvDownload}
              loading={cSVLoading}
              style={{
                margin: '0',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '40px',
                borderRadius: '8px',
              }}
            >
              Export Csv
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>
        <Table
          loading={tableData?.loading}
          rowKey='id'
          columns={columns}
          dataSource={tableData?.data?.rows}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          pagination={{
            pageSize: limit,
            total: tableData?.data?.count,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
          }}
        />

        {openSendMail && (
          <Modal
            visible={openSendMail}
            width={1200}
            onCancel={() => setOpenSendMail(false)}
            footer={null}
          >
            <MandateEmail
              invoiceCreateData={invoiceCreateData}
              setOpenReactQuill={setOpenSendMail}
              isSkip={false}
            />
          </Modal>
        )}
      </div>
    </>
  )
}

export default InvoiceReport
