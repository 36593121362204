import React, { Fragment, useContext, useEffect, useState } from 'react'
import LogoutIcon from 'assets/images/new-images/logout.svg'
import {
  Breadcrumb,
  Button,
  Col,
  Layout,
  Popover,
  Row,
  Select,
  Tag,
} from 'antd'
import { AppContext } from 'AppContext'
import {
  EyeOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import HomeNewIcon from 'assets/images/new-images/homeIcon.svg'
import client from 'apollo'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _get from 'lodash/get'
import NotificationIcon from 'assets/images/new-images/notification.svg'
import AdvisoryIcon from 'assets/images/new-images/advisory-notification-icon.png'
import PendingAdvisoryIcon from 'assets/images/new-images/pending-amc-icon.png'

import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import { GET_GROUPS } from 'modules/Groups/graphql/Queries'
import history from 'CustomHistory'
import openNotification from 'utils/Notification'
import { CHANGE_PASSWORD } from 'modules/Users/graphql/Mutations'
import ChangePassword from 'components/ChangePassword'
import { READ_NOTIFICATION } from 'auth/graphql/Mutations'
import { GET_ALL_NOTIFICATION_FOR_CLIENT } from 'auth/graphql/Queries'
import moment from 'moment'
import { GET_USERS } from 'modules/Users/graphql/Queries'
import { useQuery } from '@apollo/react-hooks'

const { Header } = Layout
const { Option, OptGroup } = Select

const HeaderNew = () => {
  const [groups, setGroups] = useState([])
  const [strategyList, setStrategyList] = useState([])
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [readAllLoading, setReadAllLoading] = useState(false)
  const [readLoading, setReadLoading] = useState({})
  const [userData, setUserData] = useState([])
  const [userDataLoading, setUserDataLoading] = useState(false)
  const {
    dispatch,
    state: {
      showFAQScreen,
      currentUser,
      clientRiskScore,
      MaintenanceStatus: { type, message, isActive },
      isClient,
      isMobile,
      isAdmin,
      allNotificationsForClient: { notReadCount, count, notifications },
    },
  } = useContext(AppContext)

  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [selectedUserName, setSelectedUserName] = useState('')
  const [open, setOpen] = useState(false)

  const IndividualSteps = {
    SELECT_USER_TYPE: 'Client Basic Details',
    LOGIN_CREATED: 'Select User Type',
    CLIENT_PROFILE_1: 'Client Profile',
    CLIENT_PROFILE_2: 'Risk Profile Assessment',
    RISK_PROFILE: 'Summary',
    SUMMARY: 'Kyc Document',
    KYC_DOC: 'Kyc E-Sign',
    KYC_FORM: 'Investment Planning',
    INVESTMENT_PLANNING: 'Agreement Sign',
    AGREEMENT_SIGN: 'E-Mandate Registration',
    E_MANDATE: 'Congratulations !!!!',
    CONGRATULATION: 'Pending Advisory',
    DONE: 'Dashboard',
  }

  const nonIndividualSteps = {
    LOGIN_CREATED: 'Select User Type',
    SELECT_USER_TYPE: 'Client Basic Details',
    CLIENT_PROFILE_1: 'Client Profile',
    CLIENT_PROFILE_2: 'Risk Profile Assessment',
    RISK_PROFILE: 'Summary',
    SUMMARY: 'Investment Planning',
    INVESTMENT_PLANNING: 'Courier Instruction',
    DONE: 'Dashboard',
  }

  const messageStyle = {
    textAlign: 'center',
    fontSize: '15px',
    marginTop: '10px',
    backgroundColor: '#fff',
    height: '20',
  }
  if (type === 'SUCCESS') {
    messageStyle.color = '#5b8c00'
  } else if (type === 'WARNING') {
    messageStyle.color = '#fa8c16'
  } else if (type === 'INFO') {
    messageStyle.color = '#1890ff'
  } else if (type === 'DANGER') {
    messageStyle.color = '#f5222d'
  }

  const showFilters = _get(history, 'location.pathname') === '/' && !isClient

  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch, showFilters])

  useEffect(() => {
    if (showFilters) {
      client
        .query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
        .then((res) => {
          if (_get(res, 'data.getStrategyList')) {
            setStrategyList(_get(res, 'data.getStrategyList'))
          }
        })
    }
  }, [showFilters])

  const { data } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  })
  const totalCount = data?.getUsers?.count
  useEffect(() => {
    setUserDataLoading(true)
    client
      .query({
        query: GET_USERS,
        variables: { limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setUserDataLoading(false)
        setUserData(res?.data?.getUsers?.users)
      })
  }, [totalCount])
  useEffect(() => {
    if (showFilters) {
      setUserDataLoading(true)
      client
        .query({
          query: GET_GROUPS,
          variables: { limit: 300, isFromDashBoard: true },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setUserDataLoading(false)
          if (_get(res, 'data.getGroups.groups')) {
            setGroups(_get(res, 'data.getGroups.groups'))
          }
        })
    }
  }, [showFilters])

  // function returnGroups() {
  //   return _map(groups, (group, key) => {
  //     const { id, name } = group
  //     return (
  //       <Option value={id} key={key}>
  //         {name}
  //       </Option>
  //     )
  //   })
  // }
  function returnGroups() {
    return userData && Object.keys(userData).length > 0
      ? _map(userData, (users, groupName) => (
          <OptGroup
            label={
              <div
                onClick={() => {
                  dispatch({
                    type: 'SELECTED_ASSOCIATE',
                    data: users?.id ? parseInt(users?.id) : undefined,
                  })
                  dispatch({
                    type: 'SELECTED_GROUP',
                    data: undefined,
                  })
                  setSelectedUserName(`${users?.firstName} ${users?.lastName}`)
                  setOpen(false)
                }}
                style={{
                  background: '#f0f0f0',
                  cursor: 'pointer',
                }}
              >
                <span style={{ paddingLeft: '5px' }}>
                  {`${users?.firstName} ${users?.lastName}`}
                </span>
              </div>
            }
            key={groupName}
          >
            {groups
              ?.filter((item) => item?.createdBy?.id === users?.id)
              ?.map((item) => (
                <Option title={item?.name} value={item?.id} key={item?.id}>
                  {item?.name}
                </Option>
              ))}
          </OptGroup>
        ))
      : groups
          ?.filter((item) => item?.createdBy?.id === currentUser?.id)
          ?.map((group) => (
            <Option title={group?.name} value={group?.id} key={group?.id}>
              {group?.name}
            </Option>
          ))
  }

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <li style={{ display: 'flex', gap: '10px' }}>
        <UserOutlined
          style={{
            fontSize: '20px',
            display: `${isMobile ? 'flex' : 'none'}`,
            gap: '10px',
          }}
        />
        <div className='header-new-user-info-mobile'>
          <div className='header-new-user-name'>
            {currentUser?.role === 'ADMIN'
              ? currentUser?.firstName
              : !currentUser?.isPanCardIndividual &&
                currentUser?.nameOfApplicant
              ? currentUser?.nameOfApplicant
              : currentUser?.firstName && currentUser?.lastName
              ? `${currentUser?.firstName} ${currentUser?.lastName}`
              : ''}
          </div>
          {currentUser?.clientCode ? (
            <div className='header-new-user-code'>
              {currentUser?.clientCode}
            </div>
          ) : currentUser?.currentType === 'Master Associate' ? (
            <div className='header-new-user-code'>
              {currentUser?.currentType}
            </div>
          ) : (
            <div className='header-new-user-code'>
              {currentUser?.role === 'SALES_EXECUTIVE'
                ? 'AIF_SALES_EXECUTIVE'.split('_')?.join(' ')
                : currentUser?.role.split('_')?.join(' ')}
            </div>
          )}
        </div>
      </li>
      <hr
        style={{ margin: '5px 0', display: `${isMobile ? 'flex' : 'none'}` }}
      />
      <li
        style={{ display: 'flex', gap: '10px' }}
        onClick={() => {
          setVisibleMenu(false)
          history.push('/profile')
        }}
      >
        <i className='icon icon-avatar' style={{ fontSize: '20px' }}></i>
        <span>Profile</span>
      </li>
      <li
        style={{ display: 'flex', gap: '10px' }}
        onClick={() => {
          setVisibleMenu(false)
          handleVisible()
        }}
      >
        <EyeOutlined style={{ fontSize: '20px' }} /> Change Password
      </li>
      {currentUser?.role === 'CLIENT' ? (
        <li
          style={{ display: `${isMobile ? 'flex' : 'none'}`, gap: '10px' }}
          onClick={() => {
            if (currentUser?.completedSteps !== 'DONE') {
              dispatch({ type: 'SHOW_FAQ_SCREEN', data: true })
            } else {
              history.push('/faqs')
            }
          }}
        >
          <QuestionCircleOutlined
            style={{ fontSize: '20px', height: '26px' }}
          />
          Help
        </li>
      ) : (
        ''
      )}
      <li
        style={{ display: `${isMobile ? 'flex' : 'none'}`, gap: '10px' }}
        onClick={() => (window.location.href = '/logout')}
      >
        <img src={LogoutIcon} style={{ height: '22px' }} alt='LogoutIcon' />{' '}
        Logout
      </li>
    </ul>
  )

  function handleVisible() {
    setVisible(!visible)
  }

  function handleChangePassword(values, resetForm) {
    const queryVariables = _omit(values, 'confirm')
    client
      .mutate({ mutation: CHANGE_PASSWORD, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'Password Changed Successfully')
        handleVisible()
        resetForm()
        history.push('/logout')
      })
      .catch((err) => console.log(err))
  }

  const readUpdateNotification = (isAllRead, id, index) => {
    let where = {}
    let data = { isRead: true, limit: notifications?.length }
    if (isAllRead) {
      setReadAllLoading(true)
      data = { ...data, isAllRead }
    } else {
      setReadLoading((curr) => {
        return { ...curr, [index]: true }
      })
      where = { ...where, id: parseInt(id) }
    }

    client
      .mutate({ mutation: READ_NOTIFICATION, variables: { data, where } })
      .then((res) => {
        dispatch({
          type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
          data: res?.data?.readNotification,
        })
        setReadAllLoading(false)
        setReadLoading((curr) => {
          return { ...curr, [index]: false }
        })
      })
      .catch((err) => {
        console.log(err)
        setReadAllLoading(false)
        setReadLoading((curr) => {
          return { ...curr, [index]: false }
        })
      })
  }

  const loadMoreNotifications = () => {
    setLoadMoreLoading(true)
    client
      .query({
        query: GET_ALL_NOTIFICATION_FOR_CLIENT,
        variables: { limit: notifications?.length + 10 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        dispatch({
          type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
          data: res?.data?.getNotifications,
        })
        setLoadMoreLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadMoreLoading(false)
      })
  }

  const notificationMenu = (
    <div>
      {notifications?.length ? (
        <div style={{ maxHeight: '700px', overflow: 'auto' }}>
          {notifications.map((item, index) => {
            return (
              <div
                onClick={() => {
                  if (!item?.isRead && !readLoading[index]) {
                    readUpdateNotification(false, item?.id, index)
                  }
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div
                  key={item.id}
                  className={
                    item?.isRead
                      ? 'notification-card-read'
                      : 'notification-card'
                  }
                >
                  <div className='notification-content'>
                    <img
                      src={
                        item?.type === 'ADVISORY'
                          ? AdvisoryIcon
                          : PendingAdvisoryIcon
                      }
                      alt='LogoutIcon'
                      className='company-logo'
                    />
                    <div>
                      <h4
                        className={
                          item?.isRead
                            ? 'notification-title-read'
                            : 'notification-title'
                        }
                      >
                        {item?.title}
                      </h4>
                    </div>
                  </div>
                  <div className='notification-footer'>
                    <span>
                      {moment().format('DD-MM-YYYY') !==
                      moment(item?.createdAt).format('DD-MM-YYYY')
                        ? moment(item?.createdAt).format('DD-MM-YYYY h:mm A')
                        : moment(item?.createdAt).format('LT')}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}

          <div className='notification_action_btns'>
            {notReadCount > 0 ? (
              <Button
                className='read_all_notification_btn'
                onClick={() => readUpdateNotification(true, null, null)}
                loading={readAllLoading}
              >
                Read all
              </Button>
            ) : (
              ''
            )}
            {count > notifications.length ? (
              <Button
                className='faq-btn'
                loading={loadMoreLoading}
                onClick={loadMoreNotifications}
              >
                Load more
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className='no_notification'>No Notification Found</div>
      )}
    </div>
  )

  return (
    <Fragment>
      <Header
        className='header-new'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: `${isMobile ? 'auto' : '100%'}`,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {isClient && currentUser?.completedSteps !== 'DONE' ? (
            <div
              className={`${
                showFAQScreen
                  ? 'header-new-text-with-tag-description'
                  : 'header-new-text-with-tag'
              }`}
            >
              <div className='header-new-title'>
                {currentUser?.completedSteps === 'DONE'
                  ? window?.location?.pathname.replace('/', '')
                  : currentUser?.completedSteps === null
                  ? 'Disclaimer'
                  : showFAQScreen
                  ? 'FAQ'
                  : currentUser?.completedSteps === 'E_MANDATE' &&
                    currentUser?.isBookingAdvisoryDateSelected
                  ? 'Booked Advisory Slot'
                  : currentUser?.isPanCardIndividual
                  ? IndividualSteps[currentUser?.completedSteps]
                  : nonIndividualSteps[currentUser?.completedSteps]}
              </div>
              {currentUser?.completedSteps === 'E_MANDATE' && showFAQScreen && (
                <div className='header-new-subtitle'>
                  Read some of he top queries asked by our customers
                </div>
              )}
              {currentUser?.completedSteps === 'CLIENT_PROFILE_2' && (
                <div className='user-score-status'>
                  <Tag
                    defaultValue={clientRiskScore}
                    color='red'
                    className='red-tag'
                  >
                    <div className='risk-score-div'>
                      <span className='risk-score-text'>Risk Score </span>
                      <span className='risk-score-total'>
                        {clientRiskScore}
                      </span>
                    </div>
                  </Tag>
                  <Tag
                    className={`${
                      currentUser?.cvlStatus ? 'green-tag' : 'red-tag'
                    }`}
                    // style={{
                    //   padding: '16px',
                    // }}
                  >
                    <div className='risk-score-div'>
                      <span
                        className={`${
                          currentUser?.cvlStatus
                            ? 'risk-score-text-green'
                            : 'risk-score-text'
                        }`}
                      >
                        PAN KYC Status
                      </span>{' '}
                      <span
                        className={`${
                          currentUser?.cvlStatus
                            ? 'risk-score-total-green'
                            : 'risk-score-total'
                        }`}
                      >
                        ({currentUser?.cvlStatus ? 'YES' : 'NO'})
                      </span>
                    </div>
                  </Tag>
                </div>
              )}
            </div>
          ) : (
            <Breadcrumb
              separator='>'
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Breadcrumb.Item>
                <img src={HomeNewIcon} alt='' />
              </Breadcrumb.Item>
              {window.location.pathname === '/' ? (
                <Breadcrumb.Item
                  href=''
                  style={{
                    textTransform: 'capitalize',
                    color: '#3F4254',
                    fontSize: `${isMobile ? '12px' : '14px'}`,
                    fontWeight: '600',
                    lineHeight: '14px',
                    textAlign: 'left',
                  }}
                >
                  Dashboard
                </Breadcrumb.Item>
              ) : (
                window.location.pathname.split('/')?.map((item) => {
                  return (
                    <Breadcrumb.Item
                      href=''
                      style={{
                        textTransform: 'capitalize',
                        color: '#3F4254',
                        fontSize: `${isMobile ? '12px' : '14px'}`,
                        fontWeight: '600',
                        lineHeight: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {item}
                    </Breadcrumb.Item>
                  )
                })
              )}
            </Breadcrumb>
          )}
          <div className='header-new-info'>
            {currentUser?.role !== 'CLIENT' ? (
              <div
                style={{
                  display: `${isMobile ? 'none' : 'flex'}`,
                  gap: '10px',
                }}
              >
                {showFilters && (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder='Select Group'
                    className='display-field'
                    onChange={(value, title) => {
                      if (value === undefined) {
                        dispatch({
                          type: 'SELECTED_ASSOCIATE',
                          data: undefined,
                        })
                        dispatch({
                          type: 'SELECTED_GROUP',
                          data: undefined,
                        })
                        setSelectedUserName(undefined)
                      } else {
                        setSelectedUserName(title?.title)
                        dispatch({
                          type: 'SELECTED_GROUP',
                          data: value ? parseInt(value) : undefined,
                        })
                        dispatch({
                          type: 'SELECTED_ASSOCIATE',
                          data: undefined,
                        })
                      }
                    }}
                    style={{ width: !isMobile ? 200 : 150 }}
                    loading={userDataLoading}
                    disabled={userDataLoading}
                    value={selectedUserName || undefined}
                    open={open}
                    onDropdownVisibleChange={(visible) => setOpen(visible)}
                    allowClear={true}
                  >
                    {returnGroups()}
                  </Select>
                )}
                {showFilters && (
                  <Select
                    getPopupContainer={(trigger) => trigger.parentElement}
                    placeholder='Select Strategy'
                    defaultValue={'ILTS'}
                    style={{ width: 94 }}
                    className='display-field'
                    onChange={(e) =>
                      dispatch({ type: 'SELECTED_STRATEGY', data: e })
                    }
                  >
                    {_map(strategyList, (strategy, key) => {
                      const { StrategyName } = strategy
                      return (
                        <Option value={StrategyName} key={key}>
                          {StrategyName}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </div>
            ) : (
              <div className='faqs-icon-div'>
                <Button
                  style={{ display: `${isMobile ? 'none' : 'flex'}` }}
                  className='faq-btn'
                  onClick={() => {
                    if (currentUser?.completedSteps !== 'DONE') {
                      dispatch({ type: 'SHOW_FAQ_SCREEN', data: true })
                    } else {
                      history.push('/faqs')
                    }
                  }}
                >
                  Help&nbsp;
                </Button>
              </div>
            )}
            <Popover
              placement='bottomRight'
              onVisibleChange={() => setVisibleMenu(!visibleMenu)}
              visible={visibleMenu}
              content={userMenuOptions}
              trigger='click'
              className='header-popover'
            >
              <div className='header-new-user-avatar-container'>
                <div className='header-new-user-avatar'>
                  {currentUser?.role === 'ADMIN'
                    ? currentUser?.firstName[0]
                    : !currentUser?.isPanCardIndividual &&
                      currentUser?.nameOfApplicant?.length
                    ? currentUser?.nameOfApplicant?.split(' ')?.length > 1
                      ? currentUser?.nameOfApplicant[0] +
                        currentUser?.nameOfApplicant?.split(' ')[1][0]
                      : currentUser?.nameOfApplicant[0]
                    : currentUser?.firstName && currentUser?.lastName
                    ? currentUser?.firstName[0] + currentUser?.lastName[0]
                    : '-'}
                </div>
                <div className='header-new-user-info'>
                  <div className='header-new-user-name'>
                    {currentUser?.role === 'ADMIN'
                      ? currentUser?.firstName
                      : !currentUser?.isPanCardIndividual &&
                        currentUser?.nameOfApplicant
                      ? currentUser?.nameOfApplicant
                      : currentUser?.firstName && currentUser?.lastName
                      ? `${currentUser?.firstName} ${currentUser?.lastName}`
                      : ''}
                  </div>
                  {currentUser?.clientCode ? (
                    <div className='header-new-user-code'>
                      {currentUser?.clientCode}
                    </div>
                  ) : currentUser?.currentType === 'Master Associate' ? (
                    <div className='header-new-user-code'>
                      {currentUser?.currentType}
                    </div>
                  ) : (
                    <div className='header-new-user-code'>
                      {currentUser?.role === 'SALES_EXECUTIVE'
                        ? 'AIF_SALES_EXECUTIVE'.split('_')?.join(' ')
                        : currentUser?.role.split('_')?.join(' ')}
                    </div>
                  )}
                </div>
                <i
                  className='icon icon-chevron-down gx-fs-xxs'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '15px',
                    color: '#000',
                  }}
                />
              </div>
            </Popover>
            <div
              style={{ display: `${isMobile ? 'none' : 'flex'}` }}
              className='header-new-logout-container'
              onClick={() => (window.location.href = '/logout')}
            >
              <img src={LogoutIcon} alt='LogoutIcon' />
              <div className='header-new-logout-text'>Logout</div>
            </div>
            {!isAdmin ? (
              <Popover
                placement='bottomLeft'
                onVisibleChange={() =>
                  setIsNotificationOpen(!isNotificationOpen)
                }
                trigger='click'
                visible={isNotificationOpen}
                content={notificationMenu}
                overlayClassName='notification-popover'
                overlayStyle={{ width: '350px' }}
              >
                <div className='header-new-logout-container'>
                  <img
                    className='notification_icon'
                    src={NotificationIcon}
                    alt='LogoutIcon'
                  />
                  {notReadCount > 0 && (
                    <div className='notification_counter'>
                      {notReadCount > 50 ? '50+' : notReadCount}
                    </div>
                  )}
                </div>
              </Popover>
            ) : (
              ''
            )}
          </div>
        </div>

        {currentUser?.completedSteps === 'CLIENT_PROFILE_2' && (
          <div className='user-score-status-mobile'>
            <Tag defaultValue={clientRiskScore} color='red' className='red-tag'>
              <div className='risk-score-div'>
                <span className='risk-score-text'>Risk Score </span>
                <span className='risk-score-total'>{clientRiskScore}</span>
              </div>
            </Tag>
            <Tag
              className={`${currentUser?.cvlStatus ? 'green-tag' : 'red-tag'}`}
              // style={{
              //   padding: '16px',
              // }}
            >
              <div className='risk-score-div'>
                <span
                  className={`${
                    currentUser?.cvlStatus
                      ? 'risk-score-text-green'
                      : 'risk-score-text'
                  }`}
                >
                  PAN KYC Status
                </span>{' '}
                <span
                  className={`${
                    currentUser?.cvlStatus
                      ? 'risk-score-total-green'
                      : 'risk-score-total'
                  }`}
                >
                  ({currentUser?.cvlStatus ? 'YES' : 'NO'})
                </span>
              </div>
            </Tag>
          </div>
        )}

        {currentUser?.role !== 'CLIENT' && (
          <div
            style={{
              display: `${isMobile ? 'flex' : 'none'}`,
              marginTop: '10px',
              gap: '20px',
            }}
          >
            {showFilters && (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                placeholder='Select Client'
                className='display-field'
                onChange={(value) =>
                  dispatch({
                    type: 'SELECTED_GROUP',
                    data: value ? parseInt(value) : undefined,
                  })
                }
                style={{ width: 133 }}
              >
                <Option value=''>All Clients</Option>
                {returnGroups()}
              </Select>
            )}
            {showFilters && (
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue='1005'
                style={{ width: 94 }}
                className='display-field'
                onChange={(e) =>
                  dispatch({ type: 'SELECTED_STRATEGY', data: e })
                }
              >
                {_map(strategyList, (strategy, key) => {
                  const { StrategyName, StrategyCode } = strategy
                  return (
                    <Option value={StrategyCode} key={key}>
                      {StrategyName}
                    </Option>
                  )
                })}
              </Select>
            )}
          </div>
        )}
      </Header>
      {visible && (
        <ChangePassword
          visible={visible}
          onCreate={handleChangePassword}
          onCancel={handleVisible}
        />
      )}
      {isClient && isActive && (
        <Row gutter={24}>
          <Col span={24}>
            <div style={messageStyle}>{message}</div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default HeaderNew
