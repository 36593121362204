import React, { useEffect, useState } from 'react'
import { Button, Switch } from 'antd'
import openNotification from 'utils/Notification'
import './index.css'
import { GET_SLOT_BOOKING_SETTING } from '../graphql/Queries'
import client from 'apollo'
import { UPDATE_SLOT_BOOKING_SETTING } from '../graphql/Mutations'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
export default function SlotBookingSetting() {
  const [showSlotBooking, setShowSlotBooking] = useState()
  const [loading, setLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_SLOT_BOOKING_SETTING,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        setShowSlotBooking(res?.data?.getSlotBookingSetting?.showSlotBooking)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  const updateData = () => {
    setUpdateLoading(true)
    client
      .mutate({
        mutation: UPDATE_SLOT_BOOKING_SETTING,
        variables: {
          data: {
            showSlotBooking,
          },
          where: {
            id: 1,
          },
        },
      })
      .then((res) => {
        setUpdateLoading(false)
        openNotification('success', 'Setting updated successfully')
      })
      .catch((err) => {
        console.log(err)
        setUpdateLoading(false)
        openNotification('error', 'An error occurred! Please try again')
      })
  }

  if (loading) return <Loader />
  return (
    <>
      <CustomMeta title='Slot Booking' />
      <div className='main_dashboard_div'>
        <div className='slot_booking_header'>
          <div className='slot_booking_header_title' style={{}}>
            Slot Booking setting
          </div>
        </div>
        <Switch
          checked={showSlotBooking}
          onChange={(e) => {
            setShowSlotBooking(e)
          }}
        />
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <Button
            type='primary'
            htmlType='submit'
            loading={updateLoading}
            onClick={updateData}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}
