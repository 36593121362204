import React, { useContext } from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'
import { Table, Checkbox, message } from 'antd'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_ALL_ACCESS_SETTINGS } from './graphql/Queries'
import { UPDATE_ACCESS_SETTING } from './graphql/Mutations'
import './index.css'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

export default function () {
  const { data, loading, error } = useQuery(GET_ALL_ACCESS_SETTINGS, {
    fetchPolicy: 'network-only',
  })
  const {
    state: { isMobile },
  } = useContext(AppContext)

  if (error) return <Page404 error={error} />

  function handleChange(e, id, type) {
    const data = {}
    const where = { id }
    data[type] = e.target.checked
    client
      .mutate({
        mutation: UPDATE_ACCESS_SETTING,
        variables: { where, data },
        refetchQueries: [
          { query: GET_ALL_ACCESS_SETTINGS, fetchPolicy: 'network-only' },
        ],
      })
      .then((res) => message.success('Setting Updated!'))
      .catch((err) => console.log(err))
  }

  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'reportName',
      width: '20%',
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'admin')}
        />
      ),
      width: '10%',
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'staff')}
        />
      ),
      width: '10%',
    },
    {
      title: 'Aif Sales Executive',
      dataIndex: 'salesExecutive',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'salesExecutive')}
        />
      ),
      width: '10%',
    },
    {
      title: 'Master Associate',
      dataIndex: 'masterAssociate',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'masterAssociate')}
        />
      ),
      width: '10%',
    },
    {
      title: 'Associate',
      dataIndex: 'associate',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'associate')}
        />
      ),
      width: '10%',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      render: (text, record) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => handleChange(e, record.id, 'client')}
        />
      ),
      width: '10%',
    },
  ]

  let tableData = []

  if (!loading && get(data, 'getAllAccessSettings')) {
    tableData = data.getAllAccessSettings.map((getAllAccessSetting) => {
      return {
        id: getAllAccessSetting.id,
        key: getAllAccessSetting.id,
        admin: getAllAccessSetting.admin,
        staff: getAllAccessSetting.staff,
        client: getAllAccessSetting.client,
        reportName: getAllAccessSetting.title,
        associate: getAllAccessSetting.associate,
        masterAssociate: getAllAccessSetting.masterAssociate,
        salesExecutive: getAllAccessSetting.salesExecutive,
      }
    })
  }

  return (
    <div
      className='main_dashboard_div'
      style={{
        marginBottom: isMobile ? '5vh !important' : '1px',
      }}
    >
      <CustomMeta title='Settings' />
      <Table
        style={{
          height: isMobile ? '100%' : '100%',
        }}
        loading={loading}
        columns={columns}
        dataSource={tableData}
        // title={() => 'Reports'}
        className='settings-table'
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
    </div>
  )
}
