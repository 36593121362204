import React from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'

const { Option } = Select

const CreateSubCategoryMOdal = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  subCategory = {},
  users,
}) => {
  const [form] = Form.useForm()
  const resetForm = () => {
    form.resetFields()
  }
  return (
    <Modal
      visible={visible}
      maskClosable={false}
      width={500}
      title={isEdit ? 'Update Sub Category' : 'Create Sub Category'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          subCategory: subCategory?.subCategory,
          defaultAssignUser: subCategory?.defaultAssigne?.id,
        }}
      >
        <Form.Item
          name='subCategory'
          label='Sub Category'
          rules={[
            {
              required: true,
              message: 'Sub category is require',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input placeholder='Enter Sub category' />
        </Form.Item>
        <Form.Item
          name='defaultAssignUser'
          label='Default Assign User'
          hasFeedback
        >
          <Select
            placeholder='Select User'
            allowClear
            showSearch
            getPopupContainer={(trigger) => trigger.parentElement}
            filterOption={(input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: '100%' }}
          >
            {users?.map((user, index) => {
              return (
                <Option value={user?.id} key={index}>{`${user?.firstName} ${
                  user?.lastName
                } (${
                  user?.role === 'SALES_EXECUTIVE'
                    ? 'AIF_SALES_EXECUTIVE'.split('_')?.join(' ')
                    : user?.role?.split('_')?.join(' ')
                })`}</Option>
              )
            })}
          </Select>
        </Form.Item>

        <div className='footer-btn-div'>
          <Button
            className='footer-cancel-btn'
            onClick={() => {
              resetForm()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            className='footer-submit-btn'
            type='primary'
            onClick={() => {
              form.validateFields().then((values) => {
                if (isEdit) {
                  onUpdate(values, resetForm)
                } else {
                  onCreate(values, resetForm)
                }
              })
            }}
          >
            {isEdit ? 'Update Sub Category' : 'Create Sub Category'}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateSubCategoryMOdal
