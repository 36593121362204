import gql from 'graphql-tag'

export const GET_ILTS_REPORT = gql`
  query spIltsreport($where: SpiltsreportReportInput!) {
    spIltsreport(where: $where)
  }
`

export const GET_AVERAGE_INDEX_REPORT = gql`
  query spAverageIndexreport($where: SpInterestAndspAverageReportInput) {
    spAverageIndexreport(where: $where)
  }
`

export const GET_INTEREST_REPORT = gql`
  query spInterestReport($where: SpInterestAndspAverageReportInput) {
    spInterestReport(where: $where)
  }
`

export const GET_MONTHLY_RETURN_REPORT = gql`
  query spMonthlyReturnReport($where: SpMonthlyReturnReportInput) {
    spMonthlyReturnReport(where: $where)
  }
`

export const GET_MONTHLY_EXPOSURE_REPORT = gql`
  query spMonthlyExposureReport($where: SpMonthlyExposureReportInput) {
    spMonthlyExposureReport(where: $where)
  }
`

export const GET_PERFORMANCE_REPORT = gql`
  query spPerformanceReport($where: SpPerformanceReportInput!) {
    spPerformanceReport(where: $where)
  }
`

export const GET_NLV_MISMATCH_REPORT = gql`
  query spNlvMisMatchReport($where: SpNlvMisMatchReportInput) {
    spNlvMisMatchReport(where: $where)
  }
`

export const GET_NLV_TRACKER_REPORT = gql`
  query spNLVTracker($where: SpNLVTrackerInput) {
    spNLVTracker(where: $where)
  }
`

export const GET_NLV_INDIVIDUAL_REPORT = gql`
  query nlvIndividualReport(
    $groupId: Int
    $associateId: Int
    $fieldName: String
  ) {
    nlvIndividualReport(
      where: {
        groupId: $groupId
        associateId: $associateId
        fieldName: $fieldName
      }
    )
  }
`

export const GET_UNHEDGE_REPORT = gql`
  query spUnhedgeReportInput($where: SpUnhedgeReportInput) {
    spUnhedgeReportInput(where: $where)
  }
`

export const GET_CURRENT_POSITION_REPORT = gql`
  query spCurrentPosition {
    spCurrentPosition
  }
`

export const GET_TRADER_EQ_REPORT = gql`
  query spTradeReportEQ($where: ReportGroupInput) {
    spTradeReportEQ(where: $where)
  }
`

export const GET_TRADER_FO_REPORT = gql`
  query spTradeReportFO($where: ReportGroupInput) {
    spTradeReportFO(where: $where)
  }
`

export const GET_LEDGER_REPORT = gql`
  query spLedgerReport($where: ReportGroupInput) {
    spLedgerReport(where: $where)
  }
`

export const GET_PL_CHANGE_REPORT = gql`
  query plChange($where: PlChangeWhereUniqueInput) {
    plChange(where: $where)
  }
`

export const GET_ROLL_OVER_REPORT = gql`
  query rollOver($where: RollOverWhereUniqueInput) {
    rollOver(where: $where)
  }
`
export const GET_CAPITAL_GAIN = gql`
  query capitalGain($where: CapitalGainWhereUniqueInput) {
    capitalGain(where: $where)
  }
`

export const GET_ROLL_OVER_OPPORTUNITY = gql`
  query rollOverOpportunity($where: RollOverOpportunityWhereUniqueInput!) {
    rollOverOpportunity(where: $where)
  }
`
export const GET_ILTS_SHIFT = gql`
  query ieltsShift($where: IeltsShiftWhereUniqueInput) {
    ieltsShift(where: $where)
  }
`
export const GET_SCENARIO_ANALYSIS_PDF = gql`
  query getScenarioAnalysisPDF($where: IeltsShiftWhereUniqueInput) {
    getScenarioAnalysisPDF(where: $where)
  }
`
export const GET_USER_PERFORMANCE_REPORT = gql`
  query spUserPerformanceReport($where: UserPerformanceInput) {
    spUserPerformanceReport(where: $where)
  }
`

export const GENERATE_PDF = gql`
  query generatePDF($data: generatePDFInput!) {
    generatePDF(data: $data)
  }
`

export const SEND_ME_PDF = gql`
  query sendMeReport($data: generatePDFInput!) {
    sendMeReport(data: $data)
  }
`

export const SEND_ME_PDF_V2 = gql`
  query sendMeReportV2($data: generatePDFInputV2!) {
    sendMeReportV2(data: $data)
  }
`
export const GET_FINANCIAL_YEAR = gql`
  query getFinancialYear {
    getFinancialYear
  }
`
export const PL_CHANGE_HISTORY_SEARCH = gql`
  query plChangeHistorySearch {
    plChangeHistorySearch
  }
`
export const SP_CURRENT_POSITION_JBALANCE = gql`
  query spCurrentPositionJBalance($where: SpCurrentPositionJBalanceInput) {
    spCurrentPositionJBalance(where: $where)
  }
`

export const SP_CURRENT_POSITIONFO = gql`
  query spCurrentPositionFO($where: SpCurrentPositionFOInput) {
    spCurrentPositionFO(where: $where)
  }
`
export const SP_CURRENT_POSITIONEQ = gql`
  query spCurrentPositionEQ($where: SpCurrentPositionEQInput) {
    spCurrentPositionEQ(where: $where)
  }
`
export const CUSTOM_CSV_DOWNLOAD = gql`
  query customCSVDownload($data: CustomCSVDownloadInput!) {
    customCSVDownload(data: $data)
  }
`
export const GET_NIFTY = gql`
  query {
    getNifty50
  }
`
export const GET_MAX_EXPOSURE = gql`
  query {
    getMaxExposure
  }
`

export const BULK_INVOICE_COUNTER = gql`
  query getBulkInvoiceCounter {
    getBulkInvoiceCounter
  }
`

export const GET_ALL_AMCS = gql`
  query getAllAmcs(
    $skip: Int
    $limit: Int
    $sort: SortInput
    $where: AmcWhereUniqueInput!
  ) {
    getAllAmcs(where: $where, skip: $skip, limit: $limit, sort: $sort)
    # {
    #   count
    #   amc {
    #     id
    #     status
    #     amount
    #     whichYear
    #     paymentType
    #     description
    #     amcQty
    #     amcPaidForPeriod
    #     nextDueDate
    #     paidAt
    #     startDate
    #     endDate
    #     createdAt
    #     updatedAt
    #     invoiceNo
    #     invoice {
    #       id
    #       dueDay
    #       assetUnderAdvisory
    #       discountAmount
    #       commissionAmount
    #       grossTotalAmount
    #       igst18InPer
    #       totalNetAmount
    #       quantity
    #       deduction
    #     }
    #     client {
    #       id
    #       firstName
    #       lastName
    #       role
    #       clientCode
    #       amcAmount
    #       email
    #       phone
    #       address
    #       exposure
    #       initialExposure
    #       amcInPer
    #       amcStartDate
    #       stateId
    #       createdBy {
    #         id
    #         firstName
    #         lastName
    #         email
    #         phone
    #         role
    #         createdBy {
    #           id
    #           firstName
    #           lastName
    #           email
    #           phone
    #           role
    #         }
    #       }
    #     }
    #   }
    #   total
    # }
  }
`
export const GET_ALL_DUE_AMCS = gql`
  query getAllDueAmcs(
    $where: AmcDueWhereUniqueInput!
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllDueAmcs(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      count
      amc {
        id
        startDate
        endDate
        status
        nextAmc
        amcGeneratedAt
        invoice {
          id
          assetUnderAdvisory
          amcInPer
          discountInPer
          commissionInPer
          grossTotalAmount
          cgst9InPer
          igst18InPer
          sgst9InPer
          totalNetAmount
          invoicePdf
          niftySpot
          niftyLot
          state
          startDate
          endDate
          amcType
          deduction
        }
        client {
          id
          firstName
          lastName
          clientCode
          email
          phone
          address
          stateId
          mandateId
          completedSteps
          discountStatus
          umrnNumber
          performance {
            TotalExposure
            NIFTY
          }
          amcInPer
          createdBy {
            id
            firstName
            lastName
            email
            phone
            role
            createdBy {
              id
              firstName
              lastName
              email
              phone
              role
              createdBy {
                id
                firstName
                lastName
                email
                phone
                role
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  phone
                  role
                  createdBy {
                    id
                    firstName
                    lastName
                    email
                    phone
                    role
                    createdBy {
                      id
                      firstName
                      lastName
                      email
                      phone
                      role
                      createdBy {
                        id
                        firstName
                        lastName
                        email
                        phone
                        role
                        createdBy {
                          id
                          firstName
                          lastName
                          email
                          phone
                          role
                          createdBy {
                            id
                            firstName
                            lastName
                            email
                            phone
                            role
                          }
                          createdBy {
                            id
                            firstName
                            lastName
                            email
                            phone
                            role
                            createdBy {
                              id
                              firstName
                              lastName
                              email
                              phone
                              role
                              createdBy {
                                id
                                firstName
                                lastName
                                email
                                phone
                                role
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        amount
        whichYear
        paymentType
        description
        updatedBy {
          firstName
          lastName
        }
        createdAt
        updatedAt
        startDate
        endDate
        status
        amcQty
        amcPaidForPeriod
        nextDueDate
        paidAt
        invoiceNo
      }
    }
  }
`
export const GET_ILTS_P_REPORT = gql`
  query Query($where: iltspreportInput) {
    getIltspreport(where: $where)
  }
`

export const GET_BULK_DATA = gql`
  query getBulkInvoiceData($where: BulkDataInput) {
    getBulkInvoiceData(where: $where)
  }
`

export const GET_COMPARISON_OF_FUND_REPORT_DATA = gql`
  query getComparisonOfFundReport($where: getComparisonOfFundReport) {
    getComparisonOfFundReport(where: $where)
  }
`

export const GET_STOCK_REPORT_DATA = gql`
  query getStockReport($where: stockReportDataInput) {
    getStockReport(where: $where)
  }
`

export const GET_FUTURE_AND_OPTIONS_REPORT_DATA = gql`
  query getFutureAndOptionReport($where: futureAndOptionReportDataInput) {
    getFutureAndOptionReport(where: $where)
  }
`

export const GET_LEDGER_REPORT_DATA = gql`
  query getLedgerReport($where: ledgerReportDataInput) {
    getLedgerReport(where: $where)
  }
`

export const GET_TAXATION_REPORT_PDF = gql`
  query Query($where: CapitalGainWhereUniqueInput) {
    getTaxationReportPdf(where: $where)
  }
`
