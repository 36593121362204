import gql from 'graphql-tag'

export const CREATE_UTM_PARAMETER = gql`
  mutation CreateUTMData($data: UTMParameter) {
    createUTMData(data: $data) {
      utmParameters
      userId
      isAlreadySubmitted
      id
    }
  }
`
