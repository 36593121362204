import React, { useCallback, useState } from 'react'
import update from 'immutability-helper'
import Section from './Section'
import { Cards } from './Cards'

const Container = ({ categories, refetch, users }) => {
  const [sections, setSections] = useState(categories)

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = sections[dragIndex]
      const updatedData = update(sections, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }).map((u, key) => {
        u.index = key
        return u
      })
      setSections(updatedData)
    },
    [sections]
  )

  const renderCard = (section, index) => {
    const { id, subCategories, category } = section
    return (
      <Cards
        key={id}
        index={index}
        id={id}
        text={
          <Section
            section={section}
            name={category}
            questions={subCategories}
            refetch={refetch}
            users={users}
          />
        }
        moveCard={moveCard}
        sections={sections}
      />
    )
  }
  return (
    <>
      <div style={{ width: '100%' }}>
        {sections.map((section, i) => renderCard(section, i))}
      </div>
    </>
  )
}

export default Container
