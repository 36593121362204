import { Select, Form, Row, Col, Input, Button, Switch } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'

import client from 'apollo'
import Page404 from 'components/Page404'
import { GET_MESSAGES } from './graphql/Queries'
import openNotification from 'utils/Notification'
import { UPDATE_MESSAGE } from './graphql/Mutations'
import './index.css'
import CustomMeta from 'components/CustomMeta'

const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

export default function () {
  const [form] = Form.useForm()
  const [selected, setSelected] = useState(undefined)
  const [formLoading, setFormLoading] = useState(false)
  const { data, loading, error } = useQuery(GET_MESSAGES, {
    fetchPolicy: 'network-only',
  })

  let messages
  if (error) return <Page404 error={error} />
  if (!loading && get(data, 'getAllMessages')) {
    messages = data.getAllMessages.map((msg, key) => ({
      key: key.toString(),
      ...msg,
    }))
  }

  const excludeMessages = ['TICKET', 'MONTHLY_REPORT']

  useEffect(() => {
    if (messages && !selected) {
      const newValues = messages[0]
      setSelected(newValues)
      form.setFieldsValue(newValues)
    }
  }, [form, messages, selected])

  function handleChange(value) {
    const newValues = messages.filter((message) => message.type === value)[0]
    setSelected(newValues)
    form.setFieldsValue(newValues)
  }

  const onFinish = (values) => {
    const where = { id: parseInt(selected.id), type: selected.type }
    setFormLoading(true)
    client
      .mutate({
        mutation: UPDATE_MESSAGE,
        variables: { data: values, where },
        refetchQueries: [{ query: GET_MESSAGES, fetchPolicy: 'network-only' }],
      })
      .then((res) => {
        openNotification('success', 'Message Updated Successfully')
      })
      .catch((err) => console.log(err))
      .finally(() => setFormLoading(false))
  }

  return (
    <div className='main_dashboard_div'>
      <CustomMeta title='Messages' />
      <div className='agreement_main_div'>
        <div className='agreement_text'>Messages</div>
      </div>

      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        value={selected?.type}
        onChange={handleChange}
        style={{ minWidth: 250 }}
        placeholder='Select Message'
      >
        {map(messages, (message, key) => {
          const { title, type } = message
          return (
            <Option value={type} key={key}>
              {title}
            </Option>
          )
        })}
      </Select>
      {selected && (
        <Form
          {...formItemLayout}
          form={form}
          name='message'
          onFinish={onFinish}
          className='user-form'
          scrollToFirstError
        >
          <Row gutter={16}>
            {!excludeMessages.includes(selected?.type) && (
              <Col className='gutter-row message-format' span={24}>
                <Form.Item
                  name='title'
                  label='Title'
                  rules={[
                    { required: true, message: 'Please input your Title!' },
                  ]}
                >
                  <Input placeholder='Enter Message Title' />
                </Form.Item>
              </Col>
            )}

            <Col className='gutter-row message-format' span={24}>
              <Form.Item label='Active' name='isActive' valuePropName='checked'>
                <Switch />
              </Form.Item>
            </Col>

            {!excludeMessages.includes(selected?.type) && (
              <Col className='gutter-row message-format' span={24}>
                <Form.Item
                  name='description'
                  label='description'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Description!',
                    },
                  ]}
                >
                  <Input.TextArea rows={5} placeholder='Enter Description' />
                </Form.Item>
              </Col>
            )}

            <Col className='gutter-row message-format' span={24}>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={formLoading}
                  className='agreement_submit_btn'
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  )
}
