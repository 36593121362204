import React, { useContext, useEffect, useState } from 'react'
import { Button, Table, Input, Space } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import {
  GET_PERFORMANCE_SUMMARY_REPORT,
  GET_PERFORMANCE_SUMMARY_REPORT_PDF,
} from './graphql/Queries'
import Page404 from 'components/Page404'
import client from 'apollo'
import { CUSTOM_CSV_DOWNLOAD } from '../graphql/Queries'
import { get, sumBy } from 'lodash'
import moment from 'moment'
import { AppContext } from 'AppContext'

import './../../Verification/PendingAdvisory/pendingAdvisory.css'
import CustomMeta from 'components/CustomMeta'

const PerformanceSummary = () => {
  const [csvLoading, setCSVLoading] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [filters, setFilters] = useState({})
  const [filteredDataSource, setFilteredDataSource] = useState([])
  const {
    state: {
      currentUser: { id },
    },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_PERFORMANCE_SUMMARY_REPORT, {
    variables: { where: { userId: id } },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.getPerformanceSummaryReport?.performanceSummaryReport) {
      const data = filterDataSource(false)
      setFilteredDataSource(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const data = filterDataSource(false)
    setFilteredDataSource(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])
  if (error) return <Page404 />

  const handleSearch = (value, confirm, fieldName) => {
    if (value) {
      confirm()
      const tempFilters = { ...filters, [fieldName]: value }
      setFilters(tempFilters)
    }
  }

  const handleReset = (clearFilters, fieldName) => {
    clearFilters()
    const tempFilters = { ...filters }
    delete tempFilters[fieldName]
    setFilters(tempFilters)
  }

  const filterDataSource = (isDownLoad) => {
    let filterData = data?.getPerformanceSummaryReport?.performanceSummaryReport
    if (Object.keys(filters).length > 0) {
      filterData = filterData.filter((item) => {
        for (const key in filters) {
          if (
            !item[key] ||
            !item[key]
              .toString()
              .toLowerCase()
              .includes(filters[key].toString().toLowerCase())
          ) {
            return false
          }
        }
        return true
      })
    }

    if (
      isDownLoad &&
      !filterData.filter((item) => item?.ClientName === 'Total').length
    ) {
      const totalExposure = sumBy(filterData, 'TotalExposure')
      const totalNetProfit = sumBy(filterData, 'NetProfit')
      const totalInterestSaved = sumBy(filterData, 'InterestSaved')
      const totalPLWithout = sumBy(filterData, 'PnlWithOutInterest')
      const totalNetInvestment = sumBy(filterData, 'NetInvestment')
      const totalNlv = sumBy(filterData, 'NLV')

      filterData.push({
        id: filterData?.length + 1,
        ClientName: 'Total',
        TotalExposure: totalExposure,
        NetProfit: totalNetProfit,
        InterestSaved: totalInterestSaved,
        PnlWithOutInterest: totalPLWithout,
        NetInvestment: totalNetInvestment,
        NLV: totalNlv,
      })
    }
    return filterData
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Client Code',
      dataIndex: 'ClientCode',
      key: 'ClientCode',
      className: 'text_left',
      width: 100,
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('ClientCode'),
    },
    {
      title: 'Client Name',
      dataIndex: 'ClientName',
      key: 'ClientName',
      className: 'text_left',
      width: 200,
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('ClientName'),
    },
    {
      title: 'Investment Date',
      dataIndex: 'InvestmentDate',
      key: 'InvestmentDate',
      className: 'text_align',
      width: 120,
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'Absolute Return (ILTS)%',
      dataIndex: 'AbsReturn',
      className: 'text_align',
      key: 'AbsReturn',
      width: 100,
      render: (text) => (text ? text?.toFixed(2) : '-'),
    },
    {
      title: 'Annual Return (ILTS)%',
      dataIndex: 'AnaualReturnInterest',
      key: 'AnaualReturnInterest',
      width: 100,
      className: 'text_align',
      render: (text) => (text ? text?.toFixed(2) : '-'),
    },
    {
      title: 'Absolute Return (Index)%',
      dataIndex: 'AbsReturnIdx',
      key: 'AbsReturnIdx',
      width: 100,
      className: 'text_align',
      render: (text) => (text ? text?.toFixed(2) : '-'),
    },
    {
      title: 'Annual Return (Index)%',
      dataIndex: 'AnualReturnIdx',
      key: 'AnualReturnIdx',
      width: 100,
      className: 'text_align',
      render: (text) => (text ? text?.toFixed(2) : '-'),
    },
    {
      title: 'Total Exposure',
      dataIndex: 'TotalExposure',
      key: 'TotalExposure',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('TotalExposure'),
    },
    {
      title: 'Net Profit',
      dataIndex: 'NetProfit',
      key: 'NetProfit',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('NetProfit'),
    },
    {
      title: 'Interest Saved',
      dataIndex: 'InterestSaved',
      key: 'InterestSaved',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'P&L Without Interest',
      dataIndex: 'PnlWithOutInterest',
      key: 'PnlWithOutInterest',
      // width: 100,
      className: 'text_align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Nifty Entry',
      dataIndex: 'NIFTYEntry',
      key: 'NIFTYEntry',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Nifty Exit',
      dataIndex: 'NIFTY',
      key: 'NIFTY',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
      width: 80,
    },
    {
      title: 'Net Investment',
      dataIndex: 'NetInvestment',
      key: 'NetInvestment',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
      width: 120,
    },
    {
      title: 'Net Liquidation Value NLV',
      dataIndex: 'NLV',
      key: 'NLV',
      className: 'text_align',
      render: (text) => (text ? text : '-'),
      ...getColumnSearchProps('NLV'),
      width: 150,
    },
  ]

  const handleCsvDownload = () => {
    setCSVLoading(true)
    const exportData = filterDataSource(true)
    client
      .query({
        query: CUSTOM_CSV_DOWNLOAD,
        variables: {
          data: {
            data: exportData,
            fields: [
              { value: 'id', label: 'Sr.No.' },
              { value: 'ClientCode', label: 'Client Code' },
              { value: 'ClientName', label: 'Client Name' },
              { value: 'InvestmentDate', label: 'Investment Date' },
              { value: 'AbsReturn', label: 'Absolute Return (ILTS)%' },
              { value: 'AnaualReturnInterest', label: 'Annual Return (ILTS)%' },
              { value: 'AbsReturnIdx', label: 'Absolute Return (Index)%' },
              { value: 'AnualReturnIdx', label: 'Annual Return (Index)%' },
              { value: 'TotalExposure', label: 'Total Exposure' },
              { value: 'NetProfit', label: 'Net Profit' },
              { value: 'InterestSaved', label: 'Interest Saved' },
              { value: 'PnlWithOutInterest', label: 'P&L Without Interest' },
              { value: 'NIFTYEntry', label: 'NIFTY Entry' },
              { value: 'NIFTY', label: 'Nifty Exit' },
              { value: 'NetInvestment', label: 'Net Investment' },
              { value: 'NLV', label: 'Net Liquidation Value NLV' },
            ],
            fileName: 'Associate wise ILTS Performance Summary Report',
          },
        },
      })
      .then((res) => {
        setCSVLoading(false)
        get(res, 'data.customCSVDownload') &&
          window.open(get(res, 'data.customCSVDownload'), '_blank')
      })
      .catch((err) => {
        console.log(err)
        setCSVLoading(false)
      })
  }

  const handlePdfDownload = () => {
    setPdfLoading(true)
    client
      .query({
        query: GET_PERFORMANCE_SUMMARY_REPORT_PDF,
        variables: {
          where: {
            userId: id,
          },
        },
      })
      .then((res) => {
        window.open(res?.data?.getPerformanceSummaryReportPDF, '_blank')
        setPdfLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setPdfLoading(false))
  }

  const clearAllFilters = () => {
    setFilters({})
  }
  return (
    <>
      <CustomMeta title='Reports | Performance Summary' />
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>
            Associate wise ILTS Performance summary report
          </div>
          <div className='container_Export_Popover'>
            <Button
              loading={pdfLoading}
              disabled={loading}
              onClick={handlePdfDownload}
              // style={{
              //   margin: '0',
              //   padding: '8px 16px',
              //   fontSize: '14px',
              //   fontWeight: '600',
              //   lineHeight: '24px',
              //   textAlign: 'left',
              //   color: '#AF0000',
              //   background: '#AF00000D',
              //   border: '1px solid #AF000080',
              //   height: '40px',
              //   borderRadius: '8px',
              // }}
              className='container_Export_Popover'
            >
              Export PDF
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>

            <Button
              loading={csvLoading}
              disabled={loading}
              onClick={handleCsvDownload}
              className='container_Export_Popover'
            >
              Export Csv
            </Button>

            <Button
              onClick={clearAllFilters}
              disabled={loading}
              className='container_Export_Popover'
            >
              Clear All
            </Button>
          </div>
        </div>
        <Table
          className='advisory_update_table'
          loading={loading}
          dataSource={filteredDataSource}
          columns={columns}
          // width={{ auto }}
          // scroll={{ x: true, y: true }}
        />
      </div>
    </>
  )
}
export default PerformanceSummary
