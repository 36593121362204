import React, { useState, useContext, useEffect, Fragment } from 'react'
import {
  Table,
  Button,
  Input,
  Space,
  Tag,
  DatePicker,
  Select,
  Modal,
  Tooltip,
} from 'antd'
import {
  LinkOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
// import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_TICKET, GET_TICKETS } from '../graphql/Queries'
import { getName, getUserNavLinkByObject } from 'utils/User'
import { GET_USERS } from 'modules/Users/graphql/Queries'
import client from 'apollo'
import CloseTicketModal from '../CloseTicketModal'
import { REPLY_TICKET_MODULE, UPDATE_TICKET } from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import { isArray, isEmpty, orderBy } from 'lodash'
import JSZip from 'jszip'
import ReplyTicketModal from '../ReplyTicketModal'
import Attachment from 'assets/images/new-images/attachment.png'
import NoAttachment from 'assets/images/new-images/no-attachment.png'

const { RangePicker } = DatePicker
const { Option } = Select
const { confirm } = Modal

const userField = [
  { text: 'PENDING', value: 'PENDING' },
  { text: 'OPEN', value: 'OPEN' },
  { text: 'INPROGRESS', value: 'INPROGRESS' },
  { text: 'REVIEW', value: 'REVIEW' },
  { text: 'CLOSED', value: 'CLOSED' },
  { text: 'REOPEN', value: 'REOPEN' },
]

const statusColor = {
  PENDING: '#f1a983',
  OPEN: '#00B050',
  CLOSED: '#3c7d22',
  REVIEW: 'warning',
  REOPEN: '#ff0000',
  INPROGRESS: '#fc36b5',
}

export default function TabTicketsNew({
  defaultFilters = {},
  setSort,
  setSkip,
  setLimit,
  setFilters,
  limit,
  filters,
  loading,
  error,
  tableData,
  totalCount,
  currentPage,
  setCurrentPage,
}) {
  const [showFormModal, setShowFormModal] = useState(false)
  // const [ticketId, setTicketId] = useState(0)

  const [openReplyModal, setOpenReplyModal] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState({})
  const [replyLoading, setReplyLoading] = useState(false)
  const [closeLoading, setCloseLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [userLoading, setUserLoading] = useState(false)
  const [userError, setUserError] = useState()
  const [ticketLoading, setTicketLoading] = useState(false)
  const [loadingTicketId, setLoadingTicketId] = useState(null)
  const [isSeeMore, setIsSeeMore] = useState({})
  const [showActionButton, setShowActionButton] = useState(false)
  const [newTableData, setNewTableData] = useState(tableData)
  const [isReOpen, setIsReOpen] = useState(false)
  const {
    state: { isClient, isAssociate, isAdmin, currentUser, isMasterAssociate },
  } = useContext(AppContext)

  useEffect(() => {
    if (tableData?.length) {
      setNewTableData(
        tableData.map((item) => {
          return { ...item, isRead: JSON.parse(item?.isRead) }
        })
      )
    }
  }, [tableData])

  useEffect(() => {
    if (!isAdmin && !isClient) {
      setShowActionButton(
        newTableData?.filter(
          (item) =>
            item?.assignUser?.id === currentUser?.id &&
            item?.status !== 'CLOSED'
        )?.length > 0
      )
    }
  }, [newTableData, currentUser, isAdmin, isClient])

  useEffect(() => {
    if (!isClient && !isAssociate && !isMasterAssociate) {
      client
        .query({
          query: GET_USERS,
          variables: {
            where: {
              role: ['STAFF', 'SALES_EXECUTIVE', 'ADMIN'],
              isForAssignee: true,
            },
            limit: 100000,
          },
        })
        .then((res) => {
          setUserData(res?.data?.getUsers?.users)
          setUserLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setUserLoading(false)
          setUserError(err)
        })
    }
  }, [isClient, isAssociate, isMasterAssociate])

  if (error || userError) return <Page404 error={error} />

  function handlePagination(page) {
    setSkip((page - 1) * 10)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        if (filter.status) {
          const tempFilters = JSON.parse(JSON.stringify(filters))
          tempFilters['status'] = filter.status
          setFilters(tempFilters)
        }
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        if (filter.status) {
          const tempFilters = JSON.parse(JSON.stringify(filters))
          tempFilters['status'] = filter.status
          setFilters(tempFilters)
        }
        setCurrentPage(1)
      }
    } else if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={isArray(selectedKeys) ? selectedKeys[0] : selectedKeys || ''}
          placeholder={`Search ${
            dataIndex === 'ticketNumber'
              ? 'Ticket Number'
              : dataIndex === 'clientName'
              ? 'Client Name'
              : dataIndex === 'clientCode'
              ? 'Client Code'
              : dataIndex
          }`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filteredValue: filters[dataIndex] || null,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          defaultValue={[
            filters[`from${dataIndex}Date`]
              ? moment(filters[`from${dataIndex}Date`], 'YYYY-MM-DD')
              : null,
            filters[`to${dataIndex}Date`]
              ? moment(filters[`to${dataIndex}Date`], 'YYYY-MM-DD')
              : null,
          ]}
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `from${dataIndex}Date`
            const to = `to${dataIndex}Date`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = moment(e[0]).format('YYYY-MM-DD')
              tempFilters[to] = moment(e[1]).format('YYYY-MM-DD')
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
            setCurrentPage(1)
          }}
        />
      </div>
    ),
    filtered: Boolean(
      filters[`from${dataIndex}Date`] || filters[`to${dataIndex}Date`]
    ),
  })
  const assignTicket = (id, data) => {
    const ticketId = data?.id
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data: { assignUserId: parseInt(id) }, id: ticketId },
        refetchQueries: [
          {
            query: GET_TICKETS,
            variables: { where: filters, limit, skip: 0 },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'Ticket Assigned Successfully')
      })
      .catch((err) => console.log({ err }))
  }
  const assignUser = (data, record) => {
    // if (!record?.assignUser) {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        value={
          record?.assignUser
            ? `${record?.assignUser?.firstName} ${record?.assignUser?.lastName}`
            : '-'
        }
        loading={userLoading}
        style={{
          width: '100%',
        }}
        showSearch
        filterOption={(input, option) =>
          (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onSearch={() => console.log('onSearch')}
        onChange={(e) => assignTicket(e, record)}
      >
        {orderBy(
          userData?.map((user) => {
            return { ...user, firstName: user?.firstName }
          }),
          'firstName',
          'asc'
        )?.map((user) => {
          return <Option value={user?.id}>{getName(user)}</Option>
        })}
      </Select>
    )
    // } else {
    //   return isAssociate
    //     ? getName(data)
    //     : getUserNavLinkByObject(data, isClient)
    // }
  }

  function showConfirm(text, status, id) {
    setLoadingTicketId(id)
    confirm({
      title: `Do you want to ${text} this ticket?`,
      icon: <ExclamationCircleOutlined />,
      content: `Clicking the OK button will, make this ticket ${text}`,
      onOk() {
        setTicketLoading(true)
        client
          .mutate({
            mutation: UPDATE_TICKET,
            variables: { data: { status }, id },
            refetchQueries: [
              {
                query: GET_TICKET,
                variables: { id: id },
                fetchPolicy: 'network-only',
              },
            ],
          })
          .then((res) => {
            openNotification('success', `Ticket ${text} Successfully`)
            setTicketLoading(false)
            setLoadingTicketId(null)
          })
          .catch((err) => {
            console.log(err)
            setTicketLoading(false)
            setLoadingTicketId(null)
          })
      },
      onCancel() {
        setTicketLoading(false)
        setLoadingTicketId(null)
      },
    })
  }

  // function closeTicketModal(id) {
  //   setTicketId(id)
  //   setShowFormModal(true)
  // }

  const changeStatus = (record, ticket) => {
    const isLoading = ticketLoading && loadingTicketId === ticket?.id
    if (isAdmin || ticket?.assignUser?.id === currentUser?.id) {
      switch (ticket.status) {
        case 'PENDING':
          return (
            <Button
              key='1'
              type='primary'
              onClick={() => showConfirm('open', 'OPEN', ticket?.id)}
              loading={isLoading}
            >
              Open
            </Button>
          )
        case 'OPEN':
          return (
            <Button
              key='1'
              type='primary'
              onClick={() =>
                showConfirm('in progress', 'INPROGRESS', ticket?.id)
              }
              loading={isLoading}
            >
              In Progress
            </Button>
          )
        case 'INPROGRESS':
          return (
            <Button
              loading={isLoading}
              key='1'
              type='primary'
              onClick={() => showConfirm('review', 'REVIEW', ticket?.id)}
            >
              Review
            </Button>
          )
        case 'REVIEW':
          return (
            <Button
              loading={isLoading}
              key='1'
              type='primary'
              onClick={() => {
                setOpenReplyModal(true)
                setSelectedTicket(ticket)
              }}
            >
              Close
            </Button>
          )
        case 'REOPEN':
          return (
            <Button
              loading={isLoading}
              key='1'
              type='primary'
              onClick={() => showConfirm('review', 'REVIEW', ticket?.id)}
            >
              Reviewed
            </Button>
          )
        default:
          return <Fragment />
      }
    }
  }

  function handleCloseTicket(values, resetForm) {
    setCloseLoading(true)

    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file?.originFileObj)
      })
    } else {
      delete values.fileUrl
    }

    const data = {
      reason: values.description,
      status: 'CLOSED',
      closeById: currentUser.id,
      fileUrl: files,
    }
    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: { data, id: selectedTicket?.id },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: selectedTicket?.id },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'Ticket Closed Successfully')
        setCloseLoading(false)
        setShowFormModal(false)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
      })
      .catch((err) => {
        setCloseLoading(false)
        console.log(err)
      })
  }

  const columns = [
    {
      title: 'Button',
      key: 'ticketStatus',
      render: (user, record) => changeStatus(user, record),
      width: 180,
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      // render: (text, record) => (
      //   <NavLink to={`/tickets/${record.id}`}>{text}</NavLink>
      // ),
      ...getColumnSearchProps('ticketNumber'),
      sorter: (a, b) => a.age - b.age,
      align: 'center',
      width: 150,
    },
    {
      title: 'Client Code/User Name',
      dataIndex: 'clientCode',
      key: 'clientCode',
      render: (user, record) => {
        const userData = record?.generateFor
          ? record?.generateFor
          : record?.generatedBy
        return userData?.clientCode ? userData?.clientCode : userData?.userName
      },
      ...getColumnSearchProps('clientCode'),
      align: 'center',
      width: 200,
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      render: (user, record) => {
        const userData = record?.generateFor
          ? record?.generateFor
          : record?.generatedBy
        return isAssociate
          ? getName(userData)
          : getUserNavLinkByObject(userData, isClient)
      },
      ...getColumnSearchProps('clientName'),
      align: 'center',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={statusColor[status]}>
          {status === 'REVIEW' ? 'REVIEWED' : status}
        </Tag>
      ),
      filters: userField,
      width: 150,
      sorter: (a, b) => a.age - b.age,
      align: 'center',
      filteredValue: filters.status || null,
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      render: (text) => (text ? text : '-'),
      width: 150,
      align: 'center',
    },
    {
      title: 'Sub Category',
      dataIndex: 'subCategoryName',
      key: 'subCategoryName',
      render: (text) => (text ? text : '-'),
      width: 150,
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      render: (status, record) =>
        !isSeeMore[record?.key] && record?.description?.length > 30 ? (
          <span>
            {record?.description?.slice(0, 30)} <br />
            <span
              style={{ fontWeight: '600', cursor: 'pointer' }}
              onClick={() =>
                setIsSeeMore((curr) => {
                  return { ...curr, [record.key]: true }
                })
              }
            >
              Show more...
            </span>
          </span>
        ) : isSeeMore[record?.key] && record?.description?.length > 30 ? (
          <span>
            {record?.description}
            <br />{' '}
            <span
              style={{ fontWeight: '600', cursor: 'pointer' }}
              onClick={() => {
                setIsSeeMore((curr) => {
                  return { ...curr, [record.key]: false }
                })
              }}
            >
              Show less...
            </span>
          </span>
        ) : (
          record?.description
        ),
      width: '200px',
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignUser',
      key: 'assignUser',
      render: (user, record) => assignUser(user, record),
      align: 'center',
      width: 200,
    },
    {
      title: 'User Type',
      dataIndex: 'generateFor',
      key: 'generateFor',
      render: (user, record) => {
        const userData = record?.generateFor
          ? record?.generateFor
          : record?.generatedBy
        return userData?.role?.split('_')?.join(' ')
      },
      align: 'center',
      width: 180,
    },
    {
      title: 'Created By',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      render: (user) =>
        user ? (
          <>
            <span>{`${getName(user)} `}</span>
            <br />
            <span>{`(${
              user?.role === 'SALES_EXECUTIVE'
                ? 'AIF_SALES_EXECUTIVE'.split('_')?.join(' ')
                : user?.role.split('_')?.join(' ')
            })`}</span>
          </>
        ) : (
          '-'
        ),
      align: 'center',
      width: 200,
      ...getColumnSearchProps('createdBy'),
    },
    {
      title: 'Closed By',
      dataIndex: 'closedByUser',
      key: 'closedByUser',
      render: (record) =>
        record ? (
          <>
            <span>{`${getName(record)} `}</span>
            <br />
            <span>{`(${
              record?.role === 'SALES_EXECUTIVE'
                ? 'AIF_SALES_EXECUTIVE'.split('_')?.join(' ')
                : record?.role.split('_')?.join(' ')
            })`}</span>
          </>
        ) : (
          '-'
        ),

      sorter: (a, b) => a.age - b.age,
      align: 'center',
      width: 200,
    },

    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (
        <>
          <span>{moment(date).format('DD-MMM-YYYY')}</span>
          <br />
          <span>{moment(date).format('hh:mm A')}</span>
        </>
      ),
      ...getColumnDateProps('CreatedAt'),
      sorter: (a, b) => a.age - b.age,
      width: 180,
      align: 'center',
    },
    {
      title: 'Closed Date',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (date) =>
        date ? (
          <>
            <span>{moment(date).format('DD-MMM-YYYY')}</span>
            <br />
            <span>{moment(date).format('hh:mm A')}</span>
          </>
        ) : (
          '-'
        ),
      ...getColumnDateProps('Closed'),
      sorter: (a, b) => a.age - b.age,
      width: 180,
      align: 'center',
    },
    {
      title: 'Last Status Timestamp',
      dataIndex: 'ticketLogs',
      key: 'ticketLogs',
      render: (logs) =>
        logs?.length ? (
          <>
            <span>
              {moment(logs[logs?.length - 1]?.createdAt).format('DD-MMM-YYYY')}
            </span>
            <br />
            <span>
              {moment(logs[logs?.length - 1]?.createdAt).format('hh:mm A')}
            </span>
          </>
        ) : (
          '-'
        ),
      width: 150,
      align: 'center',
    },
  ]

  const logsColumn = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 500,
    },
    {
      title: 'Status By',
      dataIndex: 'statusChanger',
      key: 'statusChanger',
      render: (record) => getName(record),
      width: 200,
    },
    {
      title: 'Status Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => moment(record).format('LLL'),
    },
    {
      title: 'Attachment',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      width: 50,
      render: (text, record) =>
        record?.fileUrl ? (
          <LinkOutlined
            className='multiple_download_icon'
            onClick={() => downloadInZip(record, 'log')}
          />
        ) : (
          '-'
        ),
    },
  ]

  const downloadInZip = (data, type) => {
    const files = data?.fileUrl || []
    if (type === 'main') {
      if (data?.ticketLogs?.length) {
        data.ticketLogs.forEach((item) => {
          if (item?.fileUrl?.length) {
            files.push(...item?.fileUrl)
          }
        })
      }
    }
    if (files?.length) {
      const pdfUrls = files

      const zip = new JSZip()
      const promises = []

      pdfUrls.forEach((url) => {
        const filename = url.substring(url.lastIndexOf('/') + 1)
        promises.push(
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => zip.file(filename, blob))
        )
      })

      Promise.all(promises)
        .then(() => zip.generateAsync({ type: 'blob' }))
        .then((content) => {
          const zipFilename =
            type === 'main'
              ? `Attachment of ${data?.ticketNumber}`
              : `Attachment of ${data?.ticketNumber} ticket log`
          const url = window.URL.createObjectURL(content)
          const a = document.createElement('a')
          a.href = url
          a.download = zipFilename
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((error) => console.log('Error zipping PDF files:', error))
    } else {
      openNotification('warning', 'No attachment found for this ticket.')
    }
  }

  const handleReplyTicket = (values, resetForm) => {
    setReplyLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file?.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }
    client
      .mutate({
        mutation: REPLY_TICKET_MODULE,
        variables: {
          data: {
            replyDescription: values?.description,
            fileUrl: values?.fileUrl,
          },
          where: { id: selectedTicket?.id },
        },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: selectedTicket?.id },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setReplyLoading(false)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
        openNotification('success', 'Reply sent successfully')
      })
      .catch((err) => {
        setReplyLoading(false)
        console.log(err)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
      })
      .finally(() => {
        setReplyLoading(false)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
      })
  }

  const updateTicketRead = (record) => {
    if (
      !record?.isRead?.isAdminRead ||
      !record?.isRead?.isClientRead ||
      !record?.isRead?.isAssigneeRead ||
      !record?.is?.isAnother
    ) {
      let data = {}
      if (isAdmin) {
        data = { ...record?.isRead, isAdminRead: true }
      } else if (isClient) {
        data = { ...record?.isRead, isClientRead: true }
      } else if (
        !isAdmin &&
        !isClient &&
        record?.assignUser?.id === currentUser?.id
      ) {
        data = { ...record?.isRead, isAssigneeRead: true }
      } else if (
        !isAdmin &&
        !isClient &&
        record?.generatedBy?.id === currentUser?.id
      ) {
        data = { ...record?.isRead, isAnother: true }
      }

      if (!isEmpty(data)) {
        client
          .mutate({
            mutation: UPDATE_TICKET,
            variables: {
              data: { isRead: JSON.stringify(data) },
              id: record?.id,
            },
            refetchQueries: [
              {
                query: GET_TICKETS,
                variables: { where: filters, limit, skip: 0 },
                fetchPolicy: 'network-only',
              },
            ],
          })
          .then((res) => {})
          .catch((err) => console.log({ err }))
      }
    }
  }

  const reOpenTicket = (values, resetForm) => {
    setReplyLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file?.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: UPDATE_TICKET,
        variables: {
          data: {
            replyDescription: values?.description,
            fileUrl: values?.fileUrl,
            status: 'REOPEN',
          },
          id: selectedTicket?.id,
        },
        refetchQueries: [
          {
            query: GET_TICKET,
            variables: { id: selectedTicket?.id },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setReplyLoading(false)
        setSelectedTicket({})
        setIsReOpen(false)
        setOpenReplyModal(false)
        resetForm()
        openNotification('success', 'Ticket Re-Open successfully')
      })
      .catch((err) => {
        setReplyLoading(false)
        console.log(err)
        setIsReOpen(false)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
      })
      .finally(() => {
        setReplyLoading(false)
        setIsReOpen(false)
        setSelectedTicket({})
        setOpenReplyModal(false)
        resetForm()
      })
  }

  return (
    <>
      {!isClient && showFormModal && (
        <CloseTicketModal
          visible={showFormModal}
          onCreate={handleCloseTicket}
          onCancel={() => setShowFormModal(false)}
        />
      )}
      <Table
        className='tickets-table'
        loading={loading}
        dataSource={newTableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: 550 }}
        rowClassName={(record) =>
          record?.isRead &&
          ((isAdmin && !record?.isRead?.isAdminRead) ||
            (isClient && !record?.isRead?.isClientRead) ||
            (!isAdmin &&
              !isClient &&
              !record?.isRead?.isAnother &&
              record?.generatedBy?.id === currentUser?.id) ||
            (!isAdmin &&
              !isClient &&
              !record?.isRead?.isAssigneeRead &&
              record?.assignUser?.id === currentUser?.id))
            ? 'bold_unread_row'
            : ''
        }
        columns={
          isAdmin
            ? columns
            : showActionButton
            ? columns?.filter((item) => item?.title !== 'User Type')
            : columns?.filter(
                (item) =>
                  item?.title !== 'Assigned To' &&
                  item?.title !== 'User Type' &&
                  item?.key !== 'ticketStatus'
              )
        }
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: '20px', width: '1200px' }}>
              <Table
                dataSource={
                  record?.ticketLogs?.map((item) => {
                    return {
                      ...item,
                      ticketNumber: record?.ticketNumber,
                      description: item.description?.replace(
                        '(SALES_EXECUTIVE)',
                        '(AIF_SALES_EXECUTIVE)'
                      ),
                    }
                  }) || []
                }
                columns={logsColumn}
                pagination={false}
                className='tickets-table-1'
                size='small'
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  gap: '10px',
                }}
              >
                {record?.status !== 'CLOSED' ? (
                  <Button
                    type='primary'
                    onClick={() => {
                      setOpenReplyModal(true)
                      setSelectedTicket(record)
                    }}
                  >
                    Reply
                  </Button>
                ) : (
                  ''
                )}

                {record?.status === 'CLOSED' &&
                (isClient ||
                  record?.generateFor?.id === currentUser?.id ||
                  record?.generatedBy?.id === currentUser?.id) ? (
                  <Button
                    type='primary'
                    onClick={() => {
                      setOpenReplyModal(true)
                      setIsReOpen(true)
                      setSelectedTicket(record)
                    }}
                  >
                    Re Open
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            const fileUrls = record?.fileUrl || []
            if (record?.ticketLogs) {
              record.ticketLogs.forEach((item) => {
                if (item?.fileUrl?.length) {
                  fileUrls.push(...item?.fileUrl)
                }
              })
            }
            return (
              <div style={{ display: 'flex', gap: '8px', width: '70px' }}>
                {expanded ? (
                  <Tooltip title='Close Description'>
                    <UpCircleOutlined
                      onClick={(e) => onExpand(record, e)}
                      className='expand_icon'
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title='Expand Description'>
                    <DownCircleOutlined
                      onClick={(e) => {
                        updateTicketRead(record)
                        onExpand(record, e)
                      }}
                      className='expand_icon'
                    />
                  </Tooltip>
                )}

                {fileUrls?.length ? (
                  <Tooltip title='Download Attachments'>
                    <img
                      src={Attachment}
                      className='attachment-icon'
                      alt='attachment'
                      onClick={() => downloadInZip(record, 'main')}
                    />
                  </Tooltip>
                ) : (
                  <img
                    src={NoAttachment}
                    className='attachment-icon'
                    alt='no-attachment'
                  />
                )}
                <Tooltip title='Send Reply'>
                  <SendOutlined
                    className='expand_icon'
                    onClick={() => {
                      setSelectedTicket(record)
                      setOpenReplyModal(true)
                    }}
                  />
                </Tooltip>
              </div>
            )
          },
        }}
      />

      {openReplyModal && (
        <ReplyTicketModal
          isReOpen={isReOpen}
          ticket={selectedTicket}
          visible={openReplyModal}
          onClose={() => {
            setSelectedTicket({})
            setOpenReplyModal(false)
          }}
          onReplyTicket={handleReplyTicket}
          onCloseTicket={handleCloseTicket}
          onReOpenTicket={reOpenTicket}
          replyLoading={replyLoading}
          closeLoading={closeLoading}
        />
      )}
    </>
  )
}
