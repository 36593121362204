import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, DatePicker, Modal, Row, Statistic } from 'antd'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_NSE_HOLIDAYS } from 'modules/NSEHoliday/graphql/Query'
import Page404 from 'components/Page404'
import './verification.css'
import BookAdvisoryModal from './BookAdvisory/BookAdvisoryModal'
import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  COMPLETE_STEP,
  CREATE_BOOK_ADVISORY,
  GENERATE_ADVISORY,
} from './graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import BookedAdvisorySlotNew from './BookAdvisory/BookedAdvisorySlotNew'
import { GET_ADVISORY_TIME } from 'modules/AdvisoryTime/graphql/Query'
import { uniq } from 'lodash'
import { GET_SP_AUTO_ADVISE } from './graphql/Queries'
import KeyInfo from './KeyInfo'
import calendarIcon from 'assets/images/new-images/calendar.svg'
import { GET_SLOT_BOOKING_SETTING } from 'modules/Settings/graphql/Queries'
import CongratulationsNewImage from 'assets/images/new-images/congratulations.png'
import CongratulationsImage from 'assets/images/new-images/booking.png'
import { GET_ALL_NOTIFICATION_FOR_CLIENT } from 'auth/graphql/Queries'

const CongratulationsNew = ({ next, newEdit }) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { Countdown } = Statistic
  const [date, setDate] = useState(moment())
  const [openModal, setOpenModal] = useState(false)
  const [isTimeUp, setIsTimeUp] = useState(false)
  const [clientData, setClientData] = useState({})
  const [advisoryLoading, setAdvisoryLoading] = useState(false)
  const [timezone, setTimezone] = useState({})

  const {
    state: { currentUser, isMobile },
    dispatch,
  } = useContext(AppContext)

  const [mandateId, setMandateId] = useState(currentUser?.mandateId)
  const [showSlotBooking, setShowSlotBooking] = useState()
  const [isAdvisoryBooked, setIsAdvisoryBooked] = useState(
    currentUser?.isBookingAdvisoryDateSelected ? true : false
  )
  const [userData, setUserData] = useState(currentUser)
  const [amcAmount, setAmcAmount] = useState(0)
  const { data, loading, error } = useQuery(GET_ALL_NSE_HOLIDAYS, {
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    client
      .query({
        query: GET_SLOT_BOOKING_SETTING,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setShowSlotBooking(res?.data?.getSlotBookingSetting?.showSlotBooking)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])
  const [advisoryId, setAdvisoryId] = useState()
  const [isDisableDate, setIsDisableDate] = useState(false)

  const {
    data: advisoryTime,
    loading: advisoryTimeLoading,
    error: advisoryTimeError,
  } = useQuery(GET_ADVISORY_TIME, { fetchPolicy: 'network-only' })

  useEffect(() => {
    setUserData(currentUser)
  }, [currentUser])

  useEffect(() => {
    getSpData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSpData = () => {
    client
      .query({
        query: GET_SP_AUTO_ADVISE,
        variables: {
          where: {
            dontBuyEquity: 'NO',
            exposure: currentUser?.initialExposure,
            planId: parseInt(currentUser?.plan?.id),
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: GET_ALL_NOTIFICATION_FOR_CLIENT,
            fetchPolicy: 'network-only',
          })
          .then((response) => {
            dispatch({
              type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
              data: response?.data?.getNotifications,
            })
            res.data.spAutoAdvise.forEach((item) => {
              if (Object.keys(item).includes('GrossAMC')) {
                if (
                  (!currentUser?.umrnNumber ||
                    !currentUser?.isFirstAdvisoryTaken) &&
                  currentUser?.completedSteps === 'E_MANDATE'
                ) {
                  const eMandateDiscount = item?.GrossAMC * (5 / 100)
                  const finalGrossAmount =
                    item?.GrossAMC - Math.round(eMandateDiscount)
                  let gst = 0
                  if (currentUser?.stateId === 4030) {
                    const cGst = finalGrossAmount * (9 / 100)
                    gst = Math.round(cGst) * 2
                  } else {
                    gst = finalGrossAmount * (18 / 100)
                  }
                  setAmcAmount(Math.round(finalGrossAmount + gst))
                } else {
                  let gst = 0
                  if (currentUser?.stateId === 4030) {
                    const cGst = item?.GrossAMC * (9 / 100)
                    gst = Math.round(cGst) * 2
                  } else {
                    gst = item?.GrossAMC * (18 / 100)
                  }
                  setAmcAmount(item?.GrossAMC + gst)
                }
              }
            })
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  let dates = []
  if (!loading && data?.getHoliday.length) {
    dates = data?.getHoliday?.map((item) => item?.date)
  }

  useEffect(() => {
    const current = momentTimezone().tz(currentTimezone)

    const startTime = momentTimezone.tz(
      advisoryTime?.getAdvisoryTime?.startTime,
      'HH:mm:ss',
      'Asia/Kolkata'
    )
    const endTime = momentTimezone.tz(
      advisoryTime?.getAdvisoryTime?.endTime,
      'HH:mm:ss',
      'Asia/Kolkata'
    )
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day')
    }

    if (
      (current.day() === 0 ||
        current.day() === 6 ||
        dates.some((date) =>
          momentTimezone.tz(date, currentTimezone).isSame(current, 'day')
        ) ||
        !moment().isBetween(
          moment(startTime, 'hh:mm A'),
          moment(endTime, 'hh:mm A')
        )) &&
      showSlotBooking
    ) {
      setIsTimeUp(true)
      setIsDisableDate(true)
    } else {
      setIsDisableDate(false)
      setIsTimeUp(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisoryTime, currentTimezone, timezone, showSlotBooking])

  let array = []

  const disabledDate = (current) => {
    const isWeekend = current.day() === 0 || current.day() === 6
    const holiday = dates.some((date) => moment(date).isSame(current, 'day'))
    const isToday = current.isSame(moment(), 'day')
    const beforeMarketOpen =
      isToday &&
      current < moment(advisoryTime?.getAdvisoryTime?.startTime, 'hh:mm A')
    const afterMarketClose =
      isToday &&
      current > moment(advisoryTime?.getAdvisoryTime?.endTime, 'hh:mm A')

    if (moment(current) >= moment() && array?.length < 5) {
      if (!holiday && !isWeekend && !beforeMarketOpen && !afterMarketClose) {
        array.push(moment(current).format('DD/MM'))
        array = uniq(array)
      }
    }
    return !array?.includes(moment(current).format('DD/MM'))
  }

  let nextAvailableDate
  const findNextAvailableDate = (currentDate = moment()) => {
    const marketCloseTime = moment(
      advisoryTime?.getAdvisoryTime?.endTime,
      'hh:mm A'
    )

    if (
      (moment(currentDate)?.format('DD-MM-YYYY') ===
        moment()?.format('DD-MM-YYYY') &&
        moment(marketCloseTime).format() <= moment().format()) ||
      // eslint-disable-next-line no-loop-func
      dates.filter((date) => date === currentDate?.format('YYYY-MM-DD'))
        ?.length > 0 ||
      currentDate.day() === 0 ||
      currentDate.day() === 6
    ) {
      currentDate = currentDate.add(1, 'days')
      findNextAvailableDate(currentDate)
    } else {
      nextAvailableDate = moment(currentDate)
    }

    if (nextAvailableDate) return nextAvailableDate
  }

  useEffect(() => {
    if (!advisoryTimeLoading) {
      const startTimeInIST = momentTimezone.tz(
        advisoryTime?.getAdvisoryTime?.startTime,
        'HH:mm:ss',
        'Asia/Kolkata'
      )
      const endTimeInIST = momentTimezone.tz(
        advisoryTime?.getAdvisoryTime?.endTime,
        'HH:mm:ss',
        'Asia/Kolkata'
      )

      const formattedStartTime = startTimeInIST.tz(currentTimezone).format('LT')
      const formattedEndTime = endTimeInIST.tz(currentTimezone).format('LT')

      setTimezone({ startTime: formattedStartTime, endTime: formattedEndTime })
    }
  }, [advisoryTime, currentTimezone, advisoryTimeLoading])

  useEffect(() => {
    if (advisoryTime && !advisoryLoading) {
      const defaultDate = findNextAvailableDate()
      setDate(moment(defaultDate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisoryTime, advisoryLoading])

  if (error || advisoryTimeError) return <Page404 />

  const handleDateChange = (e) => {
    setDate(moment(e))
  }

  const openAdvisoryModal = () => {
    setOpenModal(!openModal)
    setClientData({
      name: currentUser?.firstName + ' ' + currentUser?.lastName,
      date: moment(date).format('DD-MM-YYYY'),
    })
    if (
      !date.isBetween(
        moment(timezone.startTime, 'hh:mm A'),
        moment(timezone.endTime, 'hh:mm A')
      ) &&
      showSlotBooking
    ) {
      setIsTimeUp(true)
    } else {
      setIsTimeUp(false)
    }
  }

  const closeModal = () => {
    setIsTimeUp(false)
    setOpenModal(false)
  }

  const generate = async () => {
    if (!isTimeUp) {
      return client
        .mutate({
          mutation: GENERATE_ADVISORY,
          variables: {
            data: {
              clientCode: currentUser?.clientCode,
              plan: currentUser?.plan?.name,
              exposure: currentUser?.initialExposure,
              initialExposure: currentUser?.initialExposure,
              isNewClient: true,
            },
          },
        })
        .then((res) => {
          console.log('Advisory Generated')
          return true
        })
        .catch((err) => {
          console.log(err)
          setAdvisoryLoading(false)
          setOpenModal(false)
          return false
        })
    } else {
      return true
    }
  }

  const createAdvisory = async () => {
    setAdvisoryLoading(true)
    generate().then((res) => {
      if (res) {
        client
          .mutate({
            mutation: CREATE_BOOK_ADVISORY,
            variables: {
              data: {
                userId: userData?.id,
                advisoryDate: moment(date).format(),
                isAdvisoryBooked: true,
                isClientLogin: isTimeUp ? false : true,
                isClientAcceptAdvisory: false,
                isTimeUp,
              },
            },
          })
          .then((res) => {
            setAdvisoryId(res?.data?.CreateBookAdvisory?.id)
            let data = { isBookingAdvisoryDateSelected: true }
            if (!isTimeUp) {
              if (currentUser?.isNewAuaAdvisoryGenerate) {
                data.newAuaAdvisoryCompletedSteps = 'CONGRATULATION'
              } else {
                data.completedSteps = 'CONGRATULATION'
              }
            } else {
              setIsAdvisoryBooked(true)
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  data,
                  id: userData?.id,
                },
              })
              .then((response) => {
                setMandateId(response.data.updateUser?.mandateId)
                client
                  .query({
                    query: GET_ALL_NOTIFICATION_FOR_CLIENT,
                    fetchPolicy: 'network-only',
                  })
                  .then((newRes) => {
                    dispatch({
                      type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
                      data: newRes?.data?.getNotifications,
                    })
                    if (!isTimeUp) {
                      client.mutate({
                        mutation: COMPLETE_STEP,
                        variables: {
                          data: {
                            step: 'CONGRATULATION',
                            from: 'WEB',
                          },
                        },
                      })
                      Modal.info({
                        content: (
                          <div
                            style={{
                              display: 'grid',
                              justifyContent: 'center',
                            }}
                          >
                            <div className='info_modal_text'>
                              {'Dear' +
                                ' ' +
                                currentUser?.firstName +
                                ' ' +
                                currentUser?.lastName}
                            </div>
                            <span className='info_modal_text_description'>
                              Please wait for few seconds, we are prcoessing the
                              advisory.
                            </span>
                            <div
                              style={{
                                display: 'grid',
                                justifyContent: 'center',
                              }}
                            >
                              <Countdown
                                className='modal_counter'
                                format='ss'
                                value={Date.now() + 10 * 1000}
                              />
                            </div>
                            <span className='counter_text'>
                              Seconds remaining
                            </span>
                          </div>
                        ),
                        icon: null,
                        centered: true,
                        width: `${isMobile ? '100%' : '40%'}`,
                        okButtonProps: { style: { display: 'none' } },
                      })
                      setTimeout(() => {
                        setUserData(response.data.updateUser)
                        dispatch({
                          type: 'CURRENT_USER',
                          data: response.data.updateUser,
                        })
                        Modal.destroyAll()
                        setAdvisoryLoading(false)
                        setIsTimeUp(false)
                        setOpenModal(false)
                        next()
                      }, 10000)
                    } else {
                      dispatch({
                        type: 'CURRENT_USER',
                        data: response.data.updateUser,
                      })
                      setAdvisoryLoading(false)
                      setIsTimeUp(false)
                      setOpenModal(false)
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setAdvisoryLoading(false)
                    setIsTimeUp(false)
                    setOpenModal(false)
                  })
              })
              .catch((error) => {
                console.log(error)
                setAdvisoryLoading(false)
                setIsTimeUp(false)
                setOpenModal(false)
              })
          })
          .catch((err) => {
            console.log(err)
            setIsTimeUp(false)
            setAdvisoryLoading(false)
            setOpenModal(false)
          })
      }
    })
  }

  const submitAdvisory = () => {
    createAdvisory()
  }

  return (
    <>
      <Row className='row-style'>
        <Col
          lg={
            !currentUser?.isNewAuaAdvisoryGenerate &&
            window.location.pathname === '/verification'
              ? 16
              : 24
          }
          sm={24}
          className={`${
            !currentUser?.isNewAuaAdvisoryGenerate &&
            window.location.pathname === '/verification'
              ? 'col-left disclaimer-col'
              : 'disclaimer-col'
          }? `}
        >
          {(!loading || !advisoryTimeLoading || amcAmount > 0) && (
            <div
              className='congratulations_page'
              style={{
                marginBottom: isMobile ? '10dvh' : '10px',
              }}
            >
              <div>
                {isAdvisoryBooked &&
                userData &&
                userData?.isBookingAdvisoryDateSelected &&
                (userData?.completedSteps !== 'CONGRATULATION' ||
                  userData?.newAuaAdvisoryCompletedSteps !==
                    'CONGRATULATION') ? (
                  <BookedAdvisorySlotNew
                    mandateId={mandateId}
                    amcAmount={amcAmount}
                    dateData={dates}
                    setAdvisoryId={setAdvisoryId}
                    userData={userData}
                  />
                ) : (
                  <div
                    style={{
                      height: '100%',
                    }}
                  >
                    <div className='hand-shake-image'>
                      <img
                        src={
                          showSlotBooking
                            ? CongratulationsImage
                            : CongratulationsNewImage
                        }
                        alt='HandShakeImage'
                        // width={350}
                        height={200}
                      />
                    </div>
                    {/* <div className='congratulations_main_div'>
                      <b>*** !!! Congratulations !!! ***</b>
                    </div> */}
                    {/* <div className='congratulations_sub_div'>
                      Thank you for selecting Finideas Investment Advisor
                      Private Limited as your trusted partner for your financial
                      planning journey towards achieving financial freedom
                    </div> */}

                    <div
                      style={{
                        display: 'grid',
                        justifyContent: 'center',
                      }}
                    >
                      <div className='congratulations_main_div'>
                        Would you like to move forward with the advisory?
                        <br />
                      </div>
                      {showSlotBooking && (
                        <>
                          <div className='congratulations_date_select_sub_div'>
                            Kindly select the slot for advisory : &nbsp;
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <DatePicker
                              className='client-detail-date-picker'
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              style={{ width: 'fit-content' }}
                              disabledDate={disabledDate}
                              onChange={handleDateChange}
                              format={'DD-MM-YYYY'}
                              value={date}
                              allowClear={false}
                              dropdownClassName='custom-dropdown-class'
                              suffixIcon={
                                <img src={calendarIcon} alt='calendarIcon' />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {(!moment().isBetween(
                      moment(
                        advisoryTime?.getAdvisoryTime?.startTime,
                        'hh:mm A'
                      ),
                      moment(advisoryTime?.getAdvisoryTime?.endTime, 'hh:mm A')
                    ) ||
                      isDisableDate) &&
                      showSlotBooking && (
                        <div className='congratulations_error_div'>
                          Advisory sessions will only be available from &nbsp;
                          {moment(
                            advisoryTime?.getAdvisoryTime?.startTime,
                            'hh:mm A'
                          ).format('LT')}
                          &nbsp;to &nbsp;
                          {moment(
                            advisoryTime?.getAdvisoryTime?.endTime,
                            'hh:mm A'
                          ).format('LT')}
                          . Please choose a different slot for your advisory
                          session.
                        </div>
                      )}

                    <div className='congratulations_next_btn_div'>
                      <Button
                        // id='btn-click'
                        type='primary'
                        className='yes-next-button'
                        onClick={openAdvisoryModal}
                        style={{
                          width: '130px',
                          marginBottom: isMobile ? '10dvh' : '2px',
                        }}
                      >
                        Next
                      </Button>
                    </div>

                    {openModal && (
                      <BookAdvisoryModal
                        isModalOpen={openModal}
                        onCancel={closeModal}
                        isTimeUp={isTimeUp}
                        data={clientData}
                        onCreate={submitAdvisory}
                        loading={advisoryLoading}
                        advisoryTime={timezone}
                        amcAmount={amcAmount}
                        isMobile={isMobile}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
        {!currentUser?.isNewAuaAdvisoryGenerate &&
          window.location.pathname === '/verification' && (
            <Col lg={8} sm={24} className='col-right'>
              <KeyInfo newEdit={newEdit} advisoryData={{ id: advisoryId }} />
            </Col>
          )}
      </Row>
    </>
  )
}

export default CongratulationsNew
