import React, { useContext, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
  VERIFY_OTP_FOR_REEVALUATE,
} from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { useHistory } from 'react-router-dom'

import './Reevalute.css'
import CustomMeta from 'components/CustomMeta'

const New = () => {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [loader, setLoader] = useState(false)
  const [otp, setOtp] = useState()
  const {
    state: { currentUser, isMobile },
    dispatch,
  } = useContext(AppContext)

  const sentOtp = () => {
    setLoaded(true)
    client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
      setLoaded(false)
      openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
    })
  }

  const onFinish = (value) => {
    client
      .mutate({
        mutation: VERIFY_OTP_FOR_REEVALUATE,
        variables: { otp: value.otp },
      })
      .then((res) => {
        setLoader(true)
        if (res.data.verifyOtpForReEvaluate) {
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                data: {
                  reEvaluate: true,
                  completedSteps: 'LOGIN_CREATED',
                  isNewAuaAdvisoryGenerate: false,
                },
                id: parseInt(currentUser.id),
              },
            })
            .then((res) => {
              setLoader(true)
              dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
              dispatch({ type: 'IS_VERIFICATION_DONE', data: false })
              history.push('/verification')
              setLoader(false)
            })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false))
  }
  console.log(otp?.length)
  return (
    <>
      <CustomMeta title='Re-Evaluate' />
      <div className='re_evaluate_main_div'>
        <div className='re_evaluate_text'>Re-evaluate Risk Profile</div>
        <div className='re_evaluate_form'>
          <Form
            onFinish={onFinish}
            initialValues={{
              email: currentUser.email,
              phone: currentUser.phone,
            }}
            layout='vertical'
            className='client-detail-form'
          >
            <div className='re_evaluate_form_field_div'>
              <Form.Item
                name='email'
                label='Registered Email ID'
                rules={[{ required: true }]}
                style={isMobile ? {} : { width: '375px' }}
              >
                <Input
                  className='client-detail-input'
                  placeholder='Enter email'
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='phone'
                label='Registered Mobile Number'
                rules={[{ required: true }]}
                style={isMobile ? {} : { width: '375px' }}
              >
                <Input
                  type='number'
                  placeholder='Enter Mobile Number'
                  disabled
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className='submit_btn'
                  type='primary'
                  onClick={sentOtp}
                  loading={loaded}
                >
                  Sent OTP
                </Button>
              </Form.Item>
            </div>
            <div className='re_evaluate_divider' />
            <div className='otp_form_div'>
              <Form.Item
                name='otp'
                label='OTP'
                rules={[{ required: true }]}
                style={isMobile ? {} : { width: '375px' }}
              >
                <Input
                  className='client-detail-input'
                  placeholder='Enter OTP'
                  type='number'
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className='submit_btn'
                  disabled={otp?.length === undefined}
                  type='primary'
                  htmlType='submit'
                  loading={loader}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default New
