import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import { Col } from 'antd'

import { AppContext } from 'AppContext'
import { generateDashboardSummary } from 'utils/misc'
import {
  GET_DASHBOARD_SUMMARY,
  GET_REVENUE_INCOME_SUMMARY,
} from '../graphql/Queries'
import IconWithTextCardNew from './IconWithTextCardNew'
import TotalFund from 'assets/images/new-images/revenue-red.svg'

export default function () {
  let dashboardData = []
  let dashboardSpliceData = []
  const {
    state: {
      selectedGroup,
      selectedStrategy,
      selectedAssociate,
      currentUser: { id },
    },
  } = useContext(AppContext)
  console.log(selectedStrategy)
  const { data, loading } = useQuery(GET_DASHBOARD_SUMMARY, {
    variables: {
      where: {
        groupId: selectedGroup,
        strategyCode: selectedStrategy,
        associateId: selectedAssociate,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { data: revenueData } = useQuery(GET_REVENUE_INCOME_SUMMARY, {
    variables: { where: { userId: id } },
    fetchPolicy: 'network-only',
  })
  if (get(revenueData, 'getTotalRevenueIncome')) {
    dashboardData = [
      ...dashboardData,
      {
        cardColor: 'light-blue',
        icon: TotalFund,
        title: get(revenueData, 'getTotalRevenueIncome').toFixed(2) || '-',
        subTitle: 'Revenue Income',
      },
    ]
  }

  if (loading || !get(data, 'dashboardSummary[0]')) return <></>

  if (get(data, 'dashboardSummary[0]')) {
    dashboardData = [
      ...dashboardData,
      ...generateDashboardSummary(get(data, 'dashboardSummary[0]')),
    ]
    dashboardSpliceData = [...dashboardData.splice(3, 5)]
  }

  return get(dashboardData, 'length') ? (
    <>
      {dashboardData.map((data, key) => {
        return (
          <Col key={key} xl={4} lg={6} md={6} sm={6} xs={24}>
            <IconWithTextCardNew {...data} />
          </Col>
        )
      })}
      {dashboardSpliceData.map((data, key) => {
        return (
          <Col key={key} xl={4} lg={6} md={6} sm={6} xs={24}>
            <IconWithTextCardNew {...data} />
          </Col>
        )
      })}
    </>
  ) : (
    <></>
  )
}
