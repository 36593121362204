import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'antd'
import { AppContext } from 'AppContext'
import CreateCategoryModal from './CreateCategoryModal'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_CATEGORIES } from './graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import client from 'apollo'
import { CREATE_CATEGORY } from './graphql/Mutations'
import openNotification from 'utils/Notification'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Container from './Container'
import { isEmpty } from 'lodash'
import { GET_USERS } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'

const Category = () => {
  const { isMobile } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState({})

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useQuery(GET_USERS, {
    variables: {
      where: {
        role: ['ADMIN', 'STAFF', 'SALES_EXECUTIVE'],
        isActive: 'true',
      },
      limit: 1000000,
    },
  })

  const getAllCategories = () => {
    setLoading(true)
    client
      .query({ query: GET_ALL_CATEGORIES, fetchPolicy: 'network-only' })
      .then((res) => {
        setData(res?.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  if (loading || userDataLoading) return <Loader />

  if (error || userDataError) return <Page404 error={error} />

  const handleHideFormModal = () => {
    setShowFormModal(false)
  }

  const handleCreateCategory = (values, resetForm) => {
    const createData = { ...values, index: data?.getCategories?.length + 1 }
    client
      .mutate({
        mutation: CREATE_CATEGORY,
        variables: { data: createData },
        refetchQueries: [
          { query: GET_ALL_CATEGORIES, fetchPolicy: 'network-only' },
        ],
      })
      .then((res) => {
        openNotification('success', 'Section Added Successfully')
        getAllCategories()
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <CustomMeta title='Category' />
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Categories
          </div>
          <Button
            onClick={() => setShowFormModal(true)}
            style={{
              margin: '0',
              padding: isMobile ? '4px 10px' : '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#AF0000',
              background: '#AF00000D',
              border: '1px solid #AF000080',
              height: isMobile ? '35px' : '40px',
              borderRadius: '8px',
            }}
          >
            Add Category
          </Button>
        </div>
        {!loading && data?.getCategories?.length && isEmpty(error) ? (
          <>
            <div style={{ marginBottom: '15px' }}>
              <DndProvider backend={HTML5Backend}>
                <Container
                  categories={data?.getCategories}
                  refetch={getAllCategories}
                  users={userData?.getUsers?.users}
                />
              </DndProvider>
            </div>
          </>
        ) : (
          <div
            style={{
              color: '#000',
              marginTop: '300px',
              fontSize: '25px',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            No Category Found
          </div>
        )}

        {showFormModal && (
          <CreateCategoryModal
            visible={showFormModal}
            onCreate={handleCreateCategory}
            onCancel={() => handleHideFormModal()}
          />
        )}
      </div>
    </>
  )
}
export default Category
