import React, { useContext, useState } from 'react'
import { Button, DatePicker, Form, Input, Modal, Table } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_COMPANY_SUMMARY } from './graphql/Queries'
import Page404 from 'components/Page404'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { UPDATE_COMPANY_SUMMARY } from './graphql/Mutation'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

const CompanyDetails = () => {
  const [form] = Form.useForm()
  const [openModal, setOpenModal] = useState(false)
  const [updateData, setUpdateData] = useState({})
  const [btnLoading, setBtnLoading] = useState(false)

  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_ALL_COMPANY_SUMMARY, {
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  const renderAction = (record) => {
    return (
      <div className='action-icons'>
        {
          <EditTwoTone
            onClick={() => {
              setUpdateData(record)
              setOpenModal(!openModal)
            }}
          />
        }
      </div>
    )
  }

  const column = [
    {
      title: 'Years Market Experience',
      dataIndex: 'marketExperience',
      key: 'marketExperience',
    },
    {
      title: 'Advisory AUA',
      dataIndex: 'advisoryAua',
      key: 'advisoryAua',
      width: isMobile && 100,
    },
    {
      title: 'Advisory AUA Date',
      dataIndex: 'advisoryAuaDate',
      key: 'advisoryAuaDate',
      render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
      width: isMobile && 100,
    },
    {
      title: 'Referral Partner',
      dataIndex: 'referralPartner',
      key: 'referralPartner',
    },
    {
      title: 'Action',
      key: 'action',
      width: '110px',
      render: (text, record) => renderAction(record),
    },
  ]

  const onFinish = (values) => {
    setBtnLoading(true)
    client
      .mutate({
        mutation: UPDATE_COMPANY_SUMMARY,
        variables: { data: values, id: updateData?.id },
        refetchQueries: [{ query: GET_ALL_COMPANY_SUMMARY }],
      })
      .then((res) => {
        setBtnLoading(false)
        setOpenModal(false)
        openNotification('success', 'Company Summary Updated Successfully')
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        setOpenModal(false)
      })
  }

  return (
    <>
      <CustomMeta title='Company Details' />
      <div className='main_dashboard_div'>
        <div className='agreement_main_div'>
          <div className='agreement_text'>Company Summary</div>
        </div>
        <Table
          loading={loading}
          dataSource={data?.getAllCompanyDetails}
          columns={column}
          style={{ overflow: 'auto' }}
        />
      </div>

      {openModal && (
        <Modal
          title='Create Popular Blog'
          visible={openModal}
          footer={null}
          onCancel={() => setOpenModal(false)}
        >
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            onFinish={onFinish}
            initialValues={{
              ...updateData,
              advisoryAuaDate: updateData?.advisoryAuaDate
                ? moment(updateData?.advisoryAuaDate)
                : moment(),
            }}
          >
            <Form.Item
              name='marketExperience'
              label='Years Market Experience'
              rules={[
                { required: true, message: 'Please input market experience!' },
              ]}
            >
              <Input
                placeholder='Enter market experience'
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name='advisoryAua'
              label='Advisory AUA'
              rules={[
                { required: true, message: 'Please input advisory aua!' },
              ]}
            >
              <Input
                placeholder='Enter advisory AUA'
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name='advisoryAuaDate'
              label='Advisory AUA Date'
              rules={[
                { required: true, message: 'Please input advisory aua date!' },
              ]}
            >
              <DatePicker
                // format={'DD-MMM-YYYY'}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name='referralPartner'
              label='Referral Partner'
              rules={[
                { required: true, message: 'Please input refrerral partner!' },
              ]}
            >
              <Input
                placeholder='Enter referral partner'
                autoComplete='off'
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' loading={btnLoading}>
                Update Details
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default CompanyDetails
