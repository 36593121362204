import React, { useContext, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_RISK_PROFILE_SCORE } from './graphql/Queries'
import Page404 from 'components/Page404'
import CreateRiskProfileScoreSetting from './CreateRiskProfileScoreSetting'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

const RiskProfileScore = () => {
  const [openModal, setOpenModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const {
    state: { isMobile },
  } = useContext(AppContext)

  const { data, loading, error } = useQuery(GET_RISK_PROFILE_SCORE, {
    fetchPolicy: 'network-only',
  })
  if (error) return <Page404 />

  const renderAction = (data) => {
    return (
      <div className='action-icons'>
        <Tooltip title={isMobile ? '' : 'Edit'}>
          <EditTwoTone onClick={() => setOpenModal(!openModal)} />
        </Tooltip>
      </div>
    )
  }
  const columns = [
    {
      title: 'Low Risk',
      dataIndex: 'lowRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'lowRisk',
          render: (text, record) => record?.lowRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'lowRisk',
          render: (text, record) => record?.lowRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'Moderate Risk',
      dataIndex: 'moderateRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'moderateRisk',
          render: (text, record) => record?.moderateRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'moderateRisk',
          render: (text, record) => record?.moderateRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'High Risk',
      dataIndex: 'highRisk',
      className: 'hidden-client-data-th',
      children: [
        {
          title: 'Min Risk Score',
          dataIndex: 'highRisk',
          render: (text, record) => record?.highRisk?.minScore,
          className: 'report-numeric',
        },
        {
          title: 'Max Risk Score',
          dataIndex: 'highRisk',
          render: (text, record) => record?.highRisk?.maxScore,
          className: 'report-numeric',
        },
      ],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: 100,
    },
  ]
  const handleCancel = () => {
    setOpenModal(false)
  }

  return (
    <>
      <CustomMeta title='Risk Profile Score' />
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Risk Profile Score Setting
          </div>
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={
            data && data?.getRiskProfile ? [data?.getRiskProfile] : []
          }
        />
      </div>

      {openModal && (
        <CreateRiskProfileScoreSetting
          isModalOpen={openModal}
          handleCancel={handleCancel}
          setOpenModal={setOpenModal}
          setModalLoading={setModalLoading}
          modalLoading={modalLoading}
          data={data?.getRiskProfile}
        />
      )}
    </>
  )
}
export default RiskProfileScore
