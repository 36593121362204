import { Button, Checkbox, Col, Row, Spin } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Page404 from 'components/Page404'
import { get } from 'lodash'
import { GET_KYC } from './graphql/Queries'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { KYC_FILE_UPLOAD } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'
import { COMPLETE_STEP } from './graphql/Mutations'
import { Document, Page, pdfjs } from 'react-pdf'
import KeyInfo from './KeyInfo'
import DownloadIcon from 'assets/images/new-images/download-icon-red.svg'
import RightArrowIcon from 'assets/images/new-images/paginationArrows/right-arrow.svg'
import LeftArrowIcon from 'assets/images/new-images/paginationArrows/left-arrow.svg'

const KycFormNew = ({ next, newEdit }) => {
  const [pdfLoading, setPdfLoading] = useState(true)
  const [loadings, setLoadings] = useState(false)
  const [agree, setAgree] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(null)
  const [pdfPreview, setPdfPreview] = useState('')
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const {
    dispatch,
    state: { currentUser, isMobile },
  } = useContext(AppContext)
  const { id, email } = currentUser

  const divRef = useRef(null)

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
      window.scrollBy(0, -50)
    }
  }, [])
  useEffect(() => {
    setPdfLoading(true)
    client
      .query({
        query: GET_KYC,
        variables: { userId: parseInt(id) },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        console.log(res?.data?.kyc?.kycForm)
        setPdfLoading(false)
        setData(res.data)
        setPdfPreview(res?.data?.kyc?.kycForm)
      })
      .catch((err) => {
        setError(err)
        console.log(err)
      })
  }, [id])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  if (error) return <Page404 error={error} />

  const submitPdf = async () => {
    setLoadings(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    client
      .query({ query: KYC_FILE_UPLOAD, fetchPolicy: 'network-only' })
      .then((response) => {
        if (!get(response, 'data.kycFileUpload')) {
          openNotification('error', 'Something went wrong!')
          setLoadings(false)
          dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          return false
        }
        const options = {
          environment: process.env.REACT_APP_DIGIO_ENVIRONMENT,
          callback: function (response) {
            if (response.hasOwnProperty('error_code')) {
              setLoadings(false)
              dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
              return console.log('error occurred in process')
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: id,
                  data: {
                    completedSteps: 'KYC_FORM',
                  },
                },
              })
              .then((res) => {
                client.mutate({
                  mutation: COMPLETE_STEP,
                  variables: {
                    data: {
                      step: 'KYC_FORM',
                      from: 'WEB',
                    },
                  },
                })
                dispatch({
                  type: 'CURRENT_USER',
                  data: res.data.updateUser,
                })
                openNotification(
                  'success',
                  'Successfully Complete eSign on Kyc E-Sign'
                )
                setLoadings(false)
                dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
                next()
              })
          },
          logo: 'https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png',
          theme: {
            primaryColor: '#AB3498',
            secondaryColor: '#000000',
          },
          is_iframe: true,
          Is_redirection_approach: false,
        }
        const digio = new window.Digio(options)
        digio.init()
        digio.submit(
          response.data.kycFileUpload.id,
          email,
          response?.data?.agreementDataFileUpload?.access_token?.id
        )
      })
      .catch((err) => {
        console.log(err)
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <div ref={divRef}>
      <div>
        <Row className='row-style'>
          <Col lg={16} sm={24} className='col-left risk-profile-col'>
            <div>
              <CustomMeta title='Kyc Form' />
              <div className='modal'>
                <div className='modalContent'>
                  <div id='parent' style={{ position: 'relative' }}>
                    <div className='pdfViewer'>
                      <div className='btn-box'>
                        <div
                          className='pdf-btn'
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className='pdf-viewer-button'>
                            <div className='pdf-viewer-btns-block'>
                              <span className='pdf-viewer-page-text'>
                                Page {pageNumber} of {numPages}
                              </span>
                              <Button
                                className='buttons-viewer'
                                disabled={pageNumber === 1 && true}
                                onClick={() => setPageNumber(pageNumber - 1)}
                              >
                                <img src={LeftArrowIcon} alt='LeftArrowIcon' />
                              </Button>
                              <Button
                                className='buttons-viewer'
                                disabled={numPages === pageNumber && true}
                                onClick={() => setPageNumber(pageNumber + 1)}
                              >
                                <img
                                  src={RightArrowIcon}
                                  alt='RightArrowIcon'
                                />
                              </Button>
                            </div>
                          </div>
                          <div className='download-btn-block'>
                            <Button
                              icon={
                                <center>
                                  <img
                                    src={DownloadIcon}
                                    alt='DownloadIcon'
                                    style={{ marginBottom: '6px' }}
                                  />
                                </center>
                              }
                              onClick={() => window.open(data?.kyc?.kycForm)}
                              className='download-pdf-btn'
                            >
                              PDF
                            </Button>
                          </div>
                        </div>
                      </div>
                      {pdfLoading && !pdfPreview && <Spin size='large' />}
                      <Document
                        onLoadSuccess={onDocumentLoadSuccess}
                        file={pdfPreview}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <div className='agree-wrapper'>
                      <Checkbox onChange={handleAgree} checked={agree} />
                      <p className='agree-content'>
                        I/We hereby declare that the details furnished above are
                        true and correct to the best of my/our knowledge and
                        belief and I/We undertake to inform you of any changes
                        therein, immediately. In case any of the above
                        information is found to be false or untrue or misleading
                        or misrepresenting, I am/We are aware that I/We may be
                        held liable for it.
                      </p>
                    </div>
                    <div
                      className='verify_next_button'
                      style={{
                        marginRight: '18px',
                        marginTop: `${isMobile ? '20px' : '0px'}`,
                      }}
                    >
                      <Button
                        // id='btn-click'
                        type='primary'
                        loading={loadings}
                        onClick={() => submitPdf()}
                        className='yes-next-button'
                        // disabled={!agree}
                      >
                        Verify KYC Form
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8} sm={24} className='col-right risk-profile-col'>
            <KeyInfo newEdit={newEdit} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default KycFormNew
