import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import Loader from 'components/loaders/Loader'
import AuaNewSteps from 'modules/Verification/AuaNewSteps'
import { GET_SP_ACTIVE_ADVISORY } from 'modules/Settings/graphql/Queries'
import { Button, Modal } from 'antd'
import moment from 'moment'
import {
  CREATE_BOOK_ADVISORY,
  GENERATE_ADVISORY,
} from 'modules/Verification/graphql/Mutations'
import CustomMeta from 'components/CustomMeta'

const NewAuaAdvisory = () => {
  const {
    state: { currentUser, isClient, isMobile },
    dispatch,
  } = useContext(AppContext)
  const [loading, setLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [SpActiveData, setSpActiveData] = useState()
  const [newLoading, setNewLoading] = useState(false)

  let userData = currentUser

  const onReject = () => {
    setIsModalOpen(false)
  }
  const modalContent = (
    <Modal
      className='book_advisory_modal'
      visible={isModalOpen}
      footer={null}
      centered
      width={isMobile ? '100%' : '40%'}
    >
      <div>
        <div>
          <div className='book_advisory_text'>
            Dear {currentUser?.firstName + ' ' + currentUser?.lastName},
          </div>{' '}
          <div className='book_advisory_text test'>
            A new advisory is now available.
          </div>
          <div className='book_advisory_text_description'>
            Would you like to update it?
          </div>
        </div>
      </div>
      <div className='book_advisory_btn'>
        <Button className='advisory_cancel_btn' onClick={() => onReject()}>
          No
        </Button>
        <Button
          className='advisory_verify_btn'
          type='primary'
          onClick={() => submitAdvisory()}
          loading={newLoading}
        >
          Yes
        </Button>
      </div>
    </Modal>
  )

  useEffect(() => {
    if (
      currentUser?.newAuaAdvisoryCompletedSteps !== 'CONGRATULATION' &&
      !currentUser?.isBookingAdvisoryDateSelected
    ) {
      setLoading(true)
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              isNewAuaAdvisoryGenerate: true,
              newAuaAdvisoryCompletedSteps: 'KYC_FORM',
              isBookingAdvisoryDateSelected: false,
            },
            id: currentUser?.id,
          },
        })
        .then((res) => {
          dispatch({ type: 'SHOW_REVIEW_PLAN', data: false })
          dispatch({
            type: 'CURRENT_USER',
            data: res.data.updateUser,
          })
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    } else if (currentUser?.newAuaAdvisoryCompletedSteps === 'CONGRATULATION') {
      setLoading(true)
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              isNewAuaAdvisoryGenerate: true,
              isBookingAdvisoryDateSelected: true,
            },
            id: currentUser?.id,
          },
        })
        .then((res) => {
          dispatch({ type: 'SHOW_REVIEW_PLAN', data: false })
          dispatch({
            type: 'CURRENT_USER',
            data: res.data.updateUser,
          })
          if (isClient) {
            client
              .query({
                query: GET_SP_ACTIVE_ADVISORY,
                fetchPolicy: 'network-only',
              })
              .then((res) => {
                if (res.data?.sp_GetActiveAdvisory?.redirectTo === 'addAua') {
                  setIsModalOpen(true)
                  setSpActiveData(res.data?.sp_GetActiveAdvisory)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAdvisory = () => {
    createAdvisory()
  }
  const generate = async () => {
    return client
      .mutate({
        mutation: GENERATE_ADVISORY,
        variables: {
          data: {
            clientCode: currentUser?.clientCode,
            plan: currentUser?.plan?.name,
            exposure: currentUser?.initialExposure,
            initialExposure: currentUser?.initialExposure,
            isNewClient: true,
          },
        },
      })
      .then((res) => {
        console.log('Advisory Generated')
        return true
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  const createAdvisory = async () => {
    setNewLoading(true)
    generate().then((res) => {
      if (res) {
        client
          .mutate({
            mutation: CREATE_BOOK_ADVISORY,
            variables: {
              data: {
                userId: userData?.id,
                advisoryDate: moment().format(),
                isAdvisoryBooked: true,
                isClientLogin: true,
                isClientAcceptAdvisory: false,
                isTimeUp: false,
                isFromPopup: true,
              },
            },
          })
          .then((res) => {
            const data = {
              isBookingAdvisoryDateSelected: true,
              newAuaAdvisoryCompletedSteps: 'CONGRATULATION',
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  data,
                  id: userData?.id,
                },
              })
              .then((response) => {
                window.location.reload()
                setNewLoading(false)
                setIsModalOpen(false)
                dispatch({
                  type: 'CURRENT_USER',
                  data: response.data.updateUser,
                })
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  return (
    <>
      <CustomMeta title='New Aua Advisory' />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {isModalOpen &&
            SpActiveData !== null &&
            currentUser?.newAuaAdvisoryCompletedSteps === 'CONGRATULATION' &&
            modalContent}
          <AuaNewSteps />
        </div>
      )}
    </>
  )
}
export default NewAuaAdvisory
