import React, { useContext, useEffect, useState } from 'react'
import { Button, Collapse, Modal, Switch, Table, Tooltip } from 'antd'
import {
  CaretRightOutlined,
  MenuOutlined,
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import _sortBY from 'lodash/sortBy'
import { AppContext } from 'AppContext'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import client from 'apollo'
import {
  CREATE_SUB_CATEGORY,
  DELETE_CATEGORY,
  DELETE_SUB_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY_SETTING,
} from './graphql/Mutations'
import openNotification from 'utils/Notification'
import CreateCategoryModal from './CreateCategoryModal'
import CreateSubCategoryMOdal from './CreateSubCategoryModal'
import { GET_SUB_CATEGORY_SETTING } from './graphql/Queries'

const { Panel } = Collapse
const { confirm } = Modal

const Section = ({ section, name, questions, refetch, users }) => {
  const { isMobile } = useContext(AppContext)

  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false)
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined)

  const [ShowCategoryModal, setShowCategoryModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(undefined)
  const [subCategorySetting, setSubCategorySetting] = useState([])

  const SortableItem = SortableElement((props) => <tr {...props} />)
  const SortableBody = SortableContainer((props) => <tbody {...props} />)
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ))
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    client.query({ query: GET_SUB_CATEGORY_SETTING }).then((res) => {
      setSubCategorySetting(res?.data?.getSubCategorySetting)
    })
    setDataSource(_sortBY(questions, ['index']))
  }, [questions])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el)
      newData.forEach((question, key) => {
        client.mutate({
          mutation: UPDATE_SUB_CATEGORY,
          variables: {
            where: { id: question.id },
            data: {
              index: key,
            },
          },
        })
      })
      setDataSource(newData)
    }
  }

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      // disableAutoScroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }

  const handleSubCategorySwitch = (value, id) => {
    client
      .mutate({
        mutation: UPDATE_SUB_CATEGORY_SETTING,
        variables: {
          data: { isDefaultAssignee: value },
          where: { id: id },
        },
      })
      .then((res) => {
        openNotification('success', 'Updated successfully')
        client
          .query({
            query: GET_SUB_CATEGORY_SETTING,
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setSubCategorySetting(res?.data?.getSubCategorySetting)
          })
      })
  }

  const renderAction = (record) => {
    const filteredRecords = Object.values(record).filter(
      (record) => record?.id === subCategorySetting?.id
    )
    const matchedSubCategory = subCategorySetting.find(
      (item) => item.id === filteredRecords[0]
    )
    return (
      <div className='action-icons'>
        {
          <Tooltip title='Edit Sub Category'>
            <EditTwoTone
              onClick={() => {
                setShowSubCategoryModal(true)
                setSelectedSubCategory(record)
              }}
            />
          </Tooltip>
        }
        {
          <Tooltip title='Delete Sub Category'>
            <DeleteOutlined onClick={() => showSubCategoryConfirm(record.id)} />
          </Tooltip>
        }
        {
          <Switch
            defaultChecked={matchedSubCategory?.isDefaultAssignee}
            onChange={(e) => handleSubCategorySwitch(e, record?.id)}
          />
        }
      </div>
    )
  }

  const showSubCategoryConfirm = (id) => {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this Sub Category?`,
      content: `When clicked the OK button, this Sub Category will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_SUB_CATEGORY,
            variables: { where: { id } },
          })
          .then(() => {
            refetch()
            openNotification('success', 'Question Deleted Successfully')
          })
          .catch((err) => console.log(err))
      },
    })
  }

  const showCategoryConfirm = (id) => {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this Category?`,
      content: `When clicked the OK button, this category will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_CATEGORY,
            variables: { where: { id } },
          })
          .then(() => {
            refetch()
            openNotification('success', 'Section Deleted Successfully')
          })
          .catch((err) => console.log(err))
      },
    })
  }

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'subCategory',
    },
    {
      title: 'Default Assign To',
      dataIndex: 'defaultAssigne',
      className: 'option-tag',
      render: (text, record) =>
        record.defaultAssigne?.firstName
          ? record.defaultAssigne?.firstName +
            ' ' +
            record.defaultAssigne?.lastName +
            `(${
              record.defaultAssigne?.role === 'SALES_EXECUTIVE'
                ? 'AIF_SALES_EXECUTIVE'
                : record.defaultAssigne?.role
            })`
          : '-',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
    },
  ]

  const handleHideCategoryModal = () => {
    setShowCategoryModal(false)
    setSelectedCategory(undefined)
  }

  const handleUpdateCategory = (values, resetForm) => {
    const { id } = section
    client
      .mutate({
        mutation: UPDATE_CATEGORY,
        variables: { data: values, where: { id } },
      })
      .then((res) => {
        openNotification('success', 'Section Updated Successfully')
        refetch()
        handleHideCategoryModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  const handleHideSubCategoryModal = () => {
    setShowSubCategoryModal(false)
    setSelectedSubCategory(undefined)
  }

  const createSubCategory = (values, resetForm) => {
    const data = {
      ...values,
      index: section?.subCategories?.length + 1,
      categoryId: parseInt(section?.id),
      defaultAssignUser: parseInt(values?.defaultAssignUser),
    }
    client
      .mutate({ mutation: CREATE_SUB_CATEGORY, variables: { data } })
      .then(() => {
        refetch()
        openNotification('success', 'Sub Category Added Successfully')
        handleHideSubCategoryModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  const updateSubCategory = (values, resetForm) => {
    const data = {
      ...values,
      defaultAssignUser: parseInt(values?.defaultAssignUser),
    }
    client
      .mutate({
        mutation: UPDATE_SUB_CATEGORY,
        variables: { data, where: { id: selectedSubCategory?.id } },
      })
      .then((res) => {
        refetch()
        openNotification('success', 'Sub Category Updated Successfully')
        handleHideSubCategoryModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div className='abc'>
        <Collapse
          className='site-collapse-custom-collapse '
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{
                right: isMobile ? '5px' : '10px',
              }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            className='site-collapse-custom-panel ques-container-div'
            header={
              <div className='ant-collapse-header ques-div'>
                <div className='ques-data-div'>
                  <div
                    className='questionnaire-name'
                    style={{
                      fontSize: isMobile ? '13px' : '18px',
                    }}
                  >
                    {name}
                  </div>
                </div>
              </div>
            }
            key='1'
            extra={
              <>
                <div className='action-icons ques-actions-container'>
                  {
                    <Tooltip title='Edit Category'>
                      <EditTwoTone
                        onClick={() => {
                          setShowCategoryModal(true)
                          setSelectedCategory(section)
                        }}
                      />
                    </Tooltip>
                  }
                  {
                    <Tooltip title='Delete Category'>
                      <DeleteOutlined
                        onClick={() => showCategoryConfirm(section.id)}
                      />
                    </Tooltip>
                  }
                  {ShowCategoryModal && (
                    <CreateCategoryModal
                      visible={ShowCategoryModal}
                      onUpdate={handleUpdateCategory}
                      category={section}
                      isEdit={selectedCategory !== undefined}
                      onCancel={() => handleHideCategoryModal()}
                    />
                  )}
                  <DragHandle />
                </div>
              </>
            }
          >
            <div
              className='ques-table-container'
              style={{ textAlign: 'right' }}
            >
              <Button
                key='1'
                type='primary'
                onClick={() => setShowSubCategoryModal(true)}
                style={{ height: '28px', lineHeight: '26px' }}
              >
                Add Sub category
              </Button>
            </div>

            <Table
              pagination={false}
              dataSource={dataSource}
              columns={columns}
              rowKey='index'
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </Panel>
        </Collapse>

        {showSubCategoryModal && (
          <CreateSubCategoryMOdal
            visible={showSubCategoryModal}
            onCreate={createSubCategory}
            onUpdate={updateSubCategory}
            onCancel={handleHideSubCategoryModal}
            isEdit={selectedSubCategory !== undefined}
            subCategory={selectedSubCategory}
            users={users}
          />
        )}
      </div>
    </>
  )
}
export default Section
