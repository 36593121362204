import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, user, ClientCode }) {
  const {
    getUser: { firstName, lastName },
  } = user
  const columns = [
    {
      title: 'Monthly Return Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'Monthly Exposure',
          className: 'report-title main-title',
          children: [
            {
              title: (
                <div className='hidden-client-data'>
                  Client Code:{' '}
                  <span className='hidden-client-code'>{ClientCode}</span>{' '}
                </div>
              ),
              className: 'hidden-client-data-th',
              children: [
                {
                  title: 'Entry Date',
                  dataIndex: 'EntryDate',
                  key: 'EntryDate',
                  className: 'report-text',
                },
              ],
            },
            {
              title: (
                <div className='hidden-client-data'>
                  Client Name:{' '}
                  <span className='hidden-client-code'>{`${firstName} ${lastName}`}</span>
                </div>
              ),
              className: 'hidden-client-data-th',
              children: [
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'Value',
                  className: 'report-numeric',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })

  return (
    <Table
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={false}
      // pagination={{ pageSize: 100, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px - 35px)' }}
    />
  )
}
