import React, { useContext, useEffect, useState } from 'react'
import { InputNumber, Button, Modal, Tooltip, Table } from 'antd'
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { cloneDeep, isEmpty, uniq } from 'lodash'
import PendingAdvisoryModel from 'modules/PendingAdvisory/PendingAdvisoryModel'
import moment from 'moment'
import client from 'apollo'
import { COMPLETE_STEP, UPDATE_BOOK_ADVISORY } from '../graphql/Mutations'
import { AppContext } from 'AppContext'
import {
  GET_BOOKING_ADVISORY,
  GET_NEW_PENDING_ADVISORY,
} from '../graphql/Queries'
import {
  UPDATE_ADVISORY_STATUS,
  UPDATE_ADVISORY_VALUE,
} from 'modules/PendingAdvisory/graphql/Mutation'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import './pendingAdvisory.css'
import Loader from 'components/loaders/Loader'
import { ConfirmAdvisoryModalNew } from './ConfirmAdvisoryModalNew'
import history from 'CustomHistory'
import { GET_CLIENT_FORM } from 'modules/Settings/graphql/Queries'
import Bitrix24Form from '../ClientForm'
import { GET_ALL_NOTIFICATION_FOR_CLIENT } from 'auth/graphql/Queries'
const { confirm } = Modal

const AdvisoryTradeUpdateNew = ({
  type,
  advisoryDate,
  advisoryId,
  accept,
  setAccept,
  dateForAdvisory,
  isMobile,
}) => {
  const [advisoryData, setAdvisoryData] = useState([])
  const data = cloneDeep(advisoryData)
  const [newData, setNewData] = useState()
  const [openTableInModal, setOpenTableInModal] = useState(false)
  const [errorKeys, setErrorKeys] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openFeedback, setOpenFeedback] = useState(false)

  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)

  useEffect(() => {
    data.forEach((d, key) => {
      if (newData?.length && newData[key]) {
        d.TradePrice = newData[key].TradePrice
        d.TradedQuantity = newData[key].TradedQuantity
      } else {
        d.TradedQuantity = +d.Quantity
      }
    })
  }, [data, newData])

  const handleCancel = () => {
    setOpenTableInModal(false)
  }

  const refetch = () => {
    setLoading(true)
    client
      .query({
        query: GET_NEW_PENDING_ADVISORY,
        variables: { where: { advisoryDate: dateForAdvisory } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setAdvisoryData(res?.data?.getNewBookingAdvisory?.currentAdvisoryData)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForAdvisory])

  const reject = () => {
    dispatch({ type: 'IS_RESUBMIT_LOADING', data: true })
    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { isPendingAdvisoryAccepted: false, userId: currentUser?.id },
          where: { id: parseInt(advisoryId) },
        },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: { userId: currentUser?.id, id: parseInt(advisoryId) },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setAccept(false)
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
      })
      .catch((err) => {
        setAccept(false)
        dispatch({ type: 'IS_RESUBMIT_LOADING', data: false })
        console.log(err)
      })
  }

  const onFinish = () => {
    if (data) {
      const statusData = data.map((d) => {
        return {
          advisorId: parseInt(d.AdvisorID),
          clientCode: d.ClientCode,
          status: 'Accept',
        }
      })
      client
        .mutate({
          mutation: UPDATE_ADVISORY_STATUS,
          variables: { data: statusData },
        })
        .then(() => {
          data.forEach((d) => {
            // d.TradeType = d.Trade
            delete d.high
            delete d.low
            delete d.MktPrice
            // delete d.Symbol
            d.AdvisorID = parseInt(d.AdvisorID)
            if (!d.Strike) {
              d.Strike = ''
            } else {
              d.Strike = JSON.stringify(d.Strike)
            }
            // d.Type = d.Trade
            if (d.AdvisoryType) {
              delete d.AdvisoryType
            }
            if (d.AdvisoryDate) {
              delete d.AdvisoryDate
            }
            if (d.Quantity) {
              delete d.Quantity
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_VALUE,
              variables: {
                data: data,
                dateForAdvisory: new Date(dateForAdvisory),
              },
            })
            .then((data) => {
              client
                .mutate({
                  mutation: UPDATE_BOOK_ADVISORY,
                  variables: {
                    data: {
                      isClientAcceptAdvisory: true,
                      userId: currentUser?.id,
                      advisoryAcceptDateAndTime: moment().format(),
                    },
                    where: { id: parseInt(advisoryId) },
                  },
                  refetchQueries: [
                    {
                      query: GET_BOOKING_ADVISORY,
                      variables: {
                        userId: currentUser?.id,
                        id: parseInt(advisoryId),
                      },
                      fetchPolicy: 'network-only',
                    },
                  ],
                })
                .then((res) => {
                  client
                    .query({
                      query: GET_ALL_NOTIFICATION_FOR_CLIENT,
                      fetchPolicy: 'network-only',
                    })
                    .then((newRes) => {
                      dispatch({
                        type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
                        data: newRes?.data?.getNotifications,
                      })

                      setConfirmModal(true)
                      data?.data?.updateAdvisoryValue === true
                        ? openNotification('success', 'Accepted Successfully')
                        : openNotification('error', 'Something went wrong')
                      setOpenTableInModal(false)
                    })
                    .catch((err) => {
                      setAccept(false)
                      console.log(err)
                    })
                })
                .catch((err) => {
                  setAccept(false)
                  console.log(err)
                })
            })
            .catch((err) => {
              setAccept(false)
              console.log(err)
            })
        })
        .catch((err) => {
          setAccept(false)
          console.log(err)
        })
    }
  }

  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      className: 'report-text average-index-column-align',
      render: (text, record) => record?.Script,
      align: 'center',
      width: isMobile ? 180 : 280,
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
      className: 'report-text average-index-column-align text_align',
      width: isMobile ? 120 : 80,
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      className: 'report-text average-index-column-align text_align',
      width: isMobile ? 120 : 80,
    },
    {
      title: 'Traded Quantity',
      dataIndex: 'TradedQuantity',
      key: 'TradedQuantity',
      className: 'report-text average-index-column-align text_align',
      width: isMobile ? 120 : 80,
    },
    {
      title: 'Trade Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
      className: 'report-text average-index-column-align text_align',
      width: isMobile ? 120 : 80,
    },
  ]

  const selectedDateColumns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      width: isMobile ? 180 : 250,
    },
    {
      title: 'Trade',
      dataIndex: 'Trade',
      key: 'Trade',
      width: isMobile ? 120 : 80,
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      width: isMobile ? 120 : 80,
    },
    {
      title: 'Traded Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      width: isMobile ? 120 : 80,
      render: (text, record, key) => {
        return (
          accept && (
            <InputNumber
              // defaultValue={record.Quantity}
              defaultValue={dateForAdvisory ? record.Quantity : ''}
              parser={(value) =>
                value.replace(/[^0-9-]/g, '').replace(/(?!^)-/g, '')
              }
              onChange={(value) => {
                data[key].TradedQuantity = value
                setNewData(data)
              }}
            />
          )
        )
      },
    },
    {
      title: 'Trade Price',
      dataIndex: 'TradePrice',
      key: 'TradePrice',
      width: isMobile ? 120 : 80,
      render: (text, _, key) => {
        return (
          accept && (
            <>
              <div className='advisory_input'>
                <InputNumber
                  type='number'
                  defaultValue={
                    newData?.length &&
                    newData[key] &&
                    newData[key].TradePrice > 0
                      ? newData[key].TradePrice
                      : undefined
                  }
                  onChange={(value) => {
                    if (!isNaN(data[key]?.low) && !isNaN(data[key]?.high)) {
                      if (value < data[key].low || value > data[key].high) {
                        setErrorKeys(uniq([...errorKeys, key]))
                      } else {
                        setErrorKeys(errorKeys?.filter((item) => item !== key))
                      }
                    }
                    data[key].TradePrice = value
                    setNewData(data)
                  }}
                />
                <div>
                  {errorKeys?.includes(key) &&
                    !isNaN(data[key].low) &&
                    !isNaN(data[key].high) && (
                      <>
                        <div className='advisory_error'>
                          Price range is {data[key].low} - {data[key].high}
                        </div>

                        <div style={{ marginTop: '10px' }}>
                          <Tooltip
                            placement='left'
                            visible={
                              errorKeys?.includes(key) &&
                              !isNaN(data[key].low) &&
                              !isNaN(data[key].high)
                            }
                            title={`Price range is ${data[key].low} - ${data[key].high}`}
                          >
                            <InfoCircleOutlined className='error_info_icon' />
                          </Tooltip>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </>
          )
        )
      },
    },
  ]

  const confirmModalShow = () => {
    console.log(isEQFlag)
    const modal = confirm({
      icon: <ExclamationCircleOutlined />,
      centered: true,
      width: `${isMobile ? '100%' : '40%'}`,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <div className='modal_text'>
            {isEQFlag
              ? 'Traded Quantity and Trade Price must be greater than 0'
              : "Traded Quantity and Trade Price Can't Be Empty"}
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_verify_btn'
              type='primary'
              onClick={() => modal.destroy()}
            >
              Ok
            </Button>
          </div>
        </div>
      ),
    })
  }
  let isEQFlag = true
  const showConfirm = () => {
    let flag = true
    data.forEach((data) => {
      const isEQ =
        data?.Script.split(' ')[data?.Script?.split(' ')?.length - 1] === 'EQ'
      if (
        data.TradedQuantity !== 0 &&
        (data.TradePrice <= 0 || typeof data?.TradePrice !== 'number') &&
        !isEQ
      ) {
        flag = false
      }
      if (
        (data.TradedQuantity === 0 || data?.TradedQuantity === null) &&
        !isEQ
      ) {
        // data.TradePrice = 0
        flag = false
      }
      if (data?.TradePrice === null || typeof data?.TradePrice != 'number') {
        flag = false
        isEQFlag = false
      }
    })

    flag ? setOpenTableInModal(true) : confirmModalShow()
  }

  const confirmAdvisory = () => {
    setConfirmLoading(true)
    let userData = {
      isNewAuaAdvisoryGenerate: false,
      isBookingAdvisoryDateSelected: false,
    }
    if (currentUser?.isNewAuaAdvisoryGenerate) {
      userData.newAuaAdvisoryCompletedSteps = 'DONE'
    } else {
      userData.completedSteps = 'DONE'
    }
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          data: { ...userData },
          id: currentUser?.id,
        },
      })
      .then((res) => {
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'DONE',
              from: 'WEB',
            },
          },
        })
        dispatch({
          type: 'CURRENT_USER',
          data: res.data.updateUser,
        })
        dispatch({
          type: 'IS_VERIFICATION_DONE',
          data: true,
        })
        setConfirmLoading(false)
        setConfirmModal(false)
        history.push('/')
      })
      .catch((err) => {
        setConfirmModal(false)
        setConfirmLoading(false)
        console.log(err)
      })
  }

  const confirmUserAdvisory = () => {
    setConfirmLoading(true)
    client
      .query({
        query: GET_CLIENT_FORM,
        variables: { where: { formName: 'feedback_form' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res?.data?.getClientsForm) {
          if (!res?.data?.getClientsForm?.isFilled) {
            setConfirmLoading(false)
            setConfirmModal(false)
            if (!currentUser?.isNewAuaAdvisoryGenerate) {
              setOpenFeedback(true)
            }
          } else {
            confirmAdvisory()
          }
        } else {
          setConfirmLoading(false)
          setConfirmModal(false)
          if (!currentUser?.isNewAuaAdvisoryGenerate) {
            setOpenFeedback(true)
          } else {
            confirmAdvisory()
          }
        }
      })
      .catch((err) => {
        console.log(err)
        setConfirmLoading(false)
      })
  }
  if (loading) return <Loader />

  return (
    <>
      <div className='advisory_table' style={{ marginTop: '10px' }}>
        <Table
          loading={loading}
          bordered
          columns={selectedDateColumns}
          dataSource={advisoryData}
          pagination={false}
          style={{ overflow: 'auto' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'flex-end' : 'center',
          marginTop: '10px',
        }}
      >
        <Button
          // id='confirm-btn'
          className='confirm-next-button'
          onClick={showConfirm}
          disabled={
            !dateForAdvisory ||
            dateForAdvisory === 'Invalid date' ||
            dateForAdvisory === undefined ||
            errorKeys?.length
              ? true
              : false
          }
        >
          Submit
        </Button>
        <Button
          onClick={reject}
          className='yes-next-button'
          // id='cancel-btn'
        >
          Cancel
        </Button>
      </div>

      {openTableInModal && !isEmpty(data) && (
        <PendingAdvisoryModel
          visible={setOpenTableInModal}
          onCancel={() => handleCancel()}
          onOk={() => onFinish()}
          columns={columns}
          data={newData}
        />
      )}

      {confirmModal && (
        <ConfirmAdvisoryModalNew
          visible={true}
          onOk={confirmUserAdvisory}
          confirmLoading={confirmLoading}
        />
      )}

      {openFeedback && (
        <Modal
          className='feedback_form'
          title={`Feedback`}
          visible={openFeedback}
          footer={null}
          onCancel={() => {
            setOpenFeedback(!openFeedback)
          }}
          closable={false}
        >
          <div>
            <Bitrix24Form
              confirmLoading={confirmLoading}
              confirmAdvisory={confirmAdvisory}
              isUpdateUser={true}
              setOpenFeedback={setOpenFeedback}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
export default AdvisoryTradeUpdateNew
