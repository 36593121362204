import React from 'react'
import get from 'lodash/get'
import qs from 'query-string'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import { GET_AVERAGE_INDEX_REPORT } from 'modules/Reports/graphql/Queries'
import AverageIndexTable from 'modules/Reports/AverageIndex/AverageIndexTable'
import 'modules/Reports/index.css'
import { GET_USER } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'

export default function (props) {
  let {
    match: {
      params: { clientCode, clientId },
    },
    history: {
      location: { search },
    },
  } = props

  const parsed = qs.parse(search || {})
  const { strategy = 'ILTS' } = parsed

  const { data, loading, error } = useQuery(GET_AVERAGE_INDEX_REPORT, {
    variables: { where: { clientCode, date: null, strategy } },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spAverageIndexreport')) return <NoData />

  const {
    spAverageIndexreport: [spAverageIndexreport],
  } = data

  return (
    <>
      <CustomMeta title='Nifty Entry' />
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>Average Index Report</div>
          <div className='container_Export_Popover'>
            <DownloadReport
              data={JSON.parse(JSON.stringify(spAverageIndexreport))?.map(
                (item) => {
                  return {
                    EntryDate: item.entrydate,
                    Type: item.Type,
                    ClosingIndexValue: item?.ClosingIndexValue,
                    Quantity: item.Qty,
                    Value: item.Value,
                  }
                }
              )}
              fileName='average-report'
              id='AVG-REPORT-PDF'
            />
          </div>
        </div>
        <div className='report_first_Container'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className='Client_Info_Container'>
              <div className='client_Info'>
                Client Code:&nbsp;
                <span>{clientCode}</span>
              </div>

              <div className='client_Info'>
                Client Name:&nbsp;
                <span>
                  {getUserData?.getUser?.firstName}{' '}
                  {getUserData?.getUser?.lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='report-table-wrapper' id='AVG-REPORT-PDF'>
          <AverageIndexTable
            reportData={spAverageIndexreport}
            ClientCode={clientCode}
            firstName={getUserData?.getUser?.firstName}
            lastName={getUserData?.getUser?.lastName}
          />
        </div>
      </div>
    </>
  )
}
