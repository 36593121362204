import React, { useContext } from 'react'
import { Button } from 'antd'
// import FirstNumber from 'assets/images/new-images/numbers/1.svg'
// import SecondNumber from 'assets/images/new-images/numbers/2.svg'
// import ThirdNumber from 'assets/images/new-images/numbers/3.svg'
// import ForthNumber from 'assets/images/new-images/numbers/4.svg'
// import FifthNumber from 'assets/images/new-images/numbers/5.svg'
// import SixthNumber from 'assets/images/new-images/numbers/6.svg'
// import SeventhNumber from 'assets/images/new-images/numbers/7.svg'
// import EighthNumber from 'assets/images/new-images/numbers/8.svg'
// import NinthNumber from 'assets/images/new-images/numbers/9.svg'
// import TenthNumber from 'assets/images/new-images/numbers/10.svg'
// import ElevenNumber from 'assets/images/new-images/numbers/11.svg'
import CheckIconWhite from 'assets/images/new-images/check-icon-white.svg'
import { convertNumberToCommaSeparate } from 'utils/utilities'

import './pendingAdvisory.css'
import { AppContext } from 'AppContext'

const TermsConditionNew = ({ data, loading, onFinish }) => {
  const {
    state: {
      currentUser: { isNewAuaAdvisoryGenerate },
      isMobile,
    },
  } = useContext(AppContext)
  return (
    <>
      {!isNewAuaAdvisoryGenerate ? (
        <div className='terms_page'>
          <div className='terms_page_title'>
            We are happy to see your interest in Index Long Term Strategy
          </div>
          <div className='terms'>
            <div>A few feature of Index Long Term Strategy are as below:</div>
            <div className='terms_details'>
              <div className='terms_detail_box'>
                {/* <img src={FirstNumber} alt='FirstNumber' /> */}
                <div className='terms_text'>
                  1. This Strategy invests money in the Nifty index.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={SecondNumber} alt='SecondNumber' /> */}
                <div className='terms_text'>
                  2. It is a combination of Equity ETFs, Futures, and Options.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={ThirdNumber} alt='ThirdNumber' /> */}
                <div className='terms_text'>
                  3. You have selected the&nbsp;
                  <span className='highlighted_text'>
                    {data?.getPendingBookingAdvisory?.productRecommended}
                  </span>
                  .
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={ForthNumber} alt='ForthNumber' /> */}
                <div className='terms_text'>
                  4. You want to take AUA of Rs.
                  <span className='highlighted_text'>
                    {convertNumberToCommaSeparate(
                      +data?.getPendingBookingAdvisory?.productExposure
                    )}
                  </span>
                  .
                </div>
              </div>
              {data?.getPendingBookingAdvisory?.productRecommended
                .toLowerCase()
                ?.includes('basic', 'comfort') ? (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp; in Basic Plan or{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productExposure *
                          (50 / 100)
                      )}
                    </span>
                    &nbsp; in Comfort Plan now. It indirectly means that you
                    will save interest on balance fund.
                  </div>
                </div>
              ) : data?.getPendingBookingAdvisory?.productRecommended
                  ?.toLowerCase()
                  ?.includes('power', 'booster') ? (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest Equity or MF of{' '}
                    <span className='highlighted_text'>
                      {' '}
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productStock
                      )}
                    </span>{' '}
                    as as collateral &{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>{' '}
                    now.It indirectly means that you will save interest on{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.saveInterest
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                  </div>
                </div>
              )}

              <div className='terms_detail_box'>
                {/* <img src={SixthNumber} alt='SixthNumber' /> */}
                <div className='terms_text'>
                  6. The total annual cost is around&nbsp;
                  <span className='highlighted_text'>
                    {data?.getPendingBookingAdvisory?.productAnnual}%
                  </span>
                  .
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={SeventhNumber} alt='SeventhNumber' /> */}
                <div className='terms_text'>
                  7. There is no lock-in period of investment in this strategy,
                  still we recommend the logical period of investment is around
                  5 to 10 Years.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={EighthNumber} alt='EighthNumber' /> */}
                <div className='terms_text'>
                  8. You also understand that you will have risk and return on
                  full exposure as below:
                </div>
              </div>
              <div className='alphabet_text_box'>
                <div className='terms_detail_box' style={{ marginTop: '8px' }}>
                  <div>A</div>
                  <div className='terms_text'>
                    Annual Hedging & Financing Cost:&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productHedging}%
                    </span>
                  </div>
                </div>
                <div className='terms_detail_box' style={{ marginTop: '16px' }}>
                  <div>B.</div>
                  <div className='terms_text'>
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productHedging}%
                    </span>
                    &nbsp; + AMC&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productAMC}%
                    </span>
                    &nbsp;= Gross Total Cost&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.grossTotal}%
                    </span>
                  </div>
                </div>
                <div className='terms_detail_box' style={{ marginTop: '16px' }}>
                  <div>C.</div>
                  <div className='terms_text'>
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.grossTotal}%
                    </span>
                    &nbsp; -&nbsp;Interest saved&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productInterest}%
                    </span>
                    &nbsp;= Total Annual Cost&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productAnnual}%
                    </span>
                  </div>
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={NinthNumber} alt='NinthNumber' /> */}
                <div className='terms_text'>
                  9. Please note that the above cost is indicative as per the
                  current market scenario & it may change from time to time.
                </div>
              </div>

              {data?.getPendingBookingAdvisory?.productRecommended
                ?.toLowerCase()
                ?.includes('power', 'booster') ? (
                <div className='terms_detail_box'>
                  {/* <img src={TenthNumber} alt='TenthNumber' /> */}
                  <div className='terms_text'>
                    10. Your payment schedule will be{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp;initially &&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productSIP
                      )}
                    </span>{' '}
                    as monthly SIP.
                  </div>
                </div>
              ) : data?.getPendingBookingAdvisory?.productRecommended
                  ?.toLowerCase()
                  ?.includes('basic', 'comfort') ? (
                <div className='terms_detail_box'>
                  {/* <img src={TenthNumber} alt='TenthNumber' /> */}
                  <div className='terms_text'>
                    10. Your payment schedule will be{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp;initially &&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productSIP
                      )}
                    </span>{' '}
                    as monthly. This SIP will start from the next month in Basic
                    Plan or from 37th Month in Comfort Plan.
                  </div>
                </div>
              ) : (
                ''
              )}

              {/* <div className='terms_detail_box'>
              <img src={TenthNumber} alt='TenthNumber' />
              <div className='terms_text'>
                We advise you to invest above the gross total cost figure every
                month so that the above cost can be managed easily at the
                year-end on a monthly SIP basis.
              </div>
            </div> */}

              {/* <div className='terms_detail_box'>
              <img src={ElevenNumber} alt='ElevenNumber' />
              <div className='terms_text'>
                Your payment schedule will be&nbsp;
                <span className='highlighted_text'>
                  Rs.
                  {convertNumberToCommaSeparate(
                    +data?.getPendingBookingAdvisory?.productInitialInvestment
                  )}
                </span>
                &nbsp;initially &&nbsp;
                <span className='highlighted_text'>
                  Rs.
                  {convertNumberToCommaSeparate(
                    +data?.getPendingBookingAdvisory?.productSIP
                  )}{' '}
                </span>
                monthly.
              </div>
            </div> */}

              <div className='term_note'>
                Let us know if you have any queries. We would like to help you
                with the same. Kindly send your confirmation after reading this
                mail so that we can proceed further.
              </div>
              <div
                className='verify_next_button'
                style={{
                  marginRight: '18px',
                  marginTop: `${isMobile && '20px'}`,
                  marginBottom: isMobile ? '6dvh' : '2px',
                }}
              >
                <Button
                  // id='confirm-btn'
                  className='confirm-next-button'
                  loading={loading}
                  onClick={onFinish}
                >
                  <img src={CheckIconWhite} alt='RightArrowIconBlack' />{' '}
                  &nbsp;Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='terms_page'>
          <div className='terms_page_title'>
            We are happy to see your interest in Index Long Term Strategy
          </div>
          <div className='terms'>
            <div>A few feature of Index Long Term Strategy are as below:</div>
            <div className='terms_details'>
              <div className='terms_detail_box'>
                {/* <img src={FirstNumber} alt='FirstNumber' /> */}
                <div className='terms_text'>
                  1. This Strategy invests money in the Nifty index.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={SecondNumber} alt='SecondNumber' /> */}
                <div className='terms_text'>
                  2. It is a combination of Equity ETFs, Futures, and Options.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={ThirdNumber} alt='ThirdNumber' /> */}
                <div className='terms_text'>
                  3. You have selected the&nbsp;
                  <span className='highlighted_text'>
                    {data?.getPendingBookingAdvisory?.productRecommended}
                  </span>
                  .
                </div>
              </div>

              <div className='terms_detail_box'>
                {/* <img src={ForthNumber} alt='ForthNumber' /> */}
                <div className='terms_text'>
                  4. The total AUA till date is
                  <span className='highlighted_text'>
                    {' '}
                    Rs.
                    {convertNumberToCommaSeparate(
                      +data?.getPendingBookingAdvisory?.currentAua
                    )}
                  </span>
                  ,you are adding an AUA of{' '}
                  <span className='highlighted_text'>
                    Rs.{' '}
                    {convertNumberToCommaSeparate(
                      +data?.getPendingBookingAdvisory?.newAua
                    )}
                  </span>
                  , that will make Total AUA equal to{' '}
                  <span className='highlighted_text'>
                    Rs.{' '}
                    {convertNumberToCommaSeparate(
                      +data?.getPendingBookingAdvisory?.totalAua
                    )}
                  </span>
                </div>
              </div>
              {data?.getPendingBookingAdvisory?.productRecommended
                .toLowerCase()
                ?.includes('basic', 'comfort') ? (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp; in Basic Plan or{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productExposure *
                          (50 / 100)
                      )}
                    </span>
                    &nbsp; in Comfort Plan now. It indirectly means that you
                    will save interest on balance fund.
                  </div>
                </div>
              ) : data?.getPendingBookingAdvisory?.productRecommended
                  ?.toLowerCase()
                  ?.includes('power', 'booster') ? (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest Equity or MF of{' '}
                    <span className='highlighted_text'>
                      {' '}
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productStock
                      )}
                    </span>{' '}
                    as as collateral &{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>{' '}
                    now.It indirectly means that you will save interest on{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.saveInterest
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='terms_detail_box'>
                  {/* <img src={FifthNumber} alt='FifthNumber' /> */}
                  <div className='terms_text'>
                    5. You will invest{' '}
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                  </div>
                </div>
              )}

              <div className='terms_detail_box'>
                {/* <img src={SixthNumber} alt='SixthNumber' /> */}
                <div className='terms_text'>
                  6. The total annual cost is around&nbsp;
                  <span className='highlighted_text'>
                    {data?.getPendingBookingAdvisory?.productAnnual}%
                  </span>
                  .
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={SeventhNumber} alt='SeventhNumber' /> */}
                <div className='terms_text'>
                  7. There is no lock-in period of investment in this strategy,
                  still we recommend the logical period of investment is around
                  5 to 10 Years.
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={EighthNumber} alt='EighthNumber' /> */}
                <div className='terms_text'>
                  8. You also understand that you will have risk and return on
                  full exposure as below:
                </div>
              </div>
              <div className='alphabet_text_box'>
                <div className='terms_detail_box' style={{ marginTop: '8px' }}>
                  <div>A.</div>
                  <div className='terms_text'>
                    Annual Hedging & Financing Cost:&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productHedging}%
                    </span>
                  </div>
                </div>
                <div className='terms_detail_box' style={{ marginTop: '16px' }}>
                  <div>B.</div>
                  <div className='terms_text'>
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productHedging}%
                    </span>
                    &nbsp; + AMC&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productAMC}%
                    </span>
                    &nbsp;= Gross Total Cost&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.grossTotal}%
                    </span>
                  </div>
                </div>
                <div className='terms_detail_box' style={{ marginTop: '16px' }}>
                  <div>C.</div>
                  <div className='terms_text'>
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.grossTotal}%
                    </span>
                    &nbsp; -&nbsp;Interest saved&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productInterest}%
                    </span>
                    &nbsp;= Total Annual Cost&nbsp;
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.productAnnual}%
                    </span>
                  </div>
                </div>
              </div>
              <div className='terms_detail_box'>
                {/* <img src={NinthNumber} alt='NinthNumber' /> */}
                <div className='terms_text'>
                  9. Please note that the above cost is indicative as per the
                  current market scenario & it may change from time to time.
                </div>
              </div>

              {data?.getPendingBookingAdvisory?.productRecommended
                ?.toLowerCase()
                ?.includes('power', 'booster') ? (
                <div className='terms_detail_box'>
                  {/* <img src={TenthNumber} alt='TenthNumber' /> */}
                  <div className='terms_text'>
                    10. Your payment schedule will be{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp;initially &&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productSIP
                      )}
                    </span>{' '}
                    as monthly SIP for this new AUA.
                  </div>
                </div>
              ) : data?.getPendingBookingAdvisory?.productRecommended
                  ?.toLowerCase()
                  ?.includes('basic', 'comfort') ? (
                <div className='terms_detail_box'>
                  {/* <img src={TenthNumber} alt='TenthNumber' /> */}
                  <div className='terms_text'>
                    10. Your payment schedule will be{' '}
                    <span className='highlighted_text'>
                      Rs.{' '}
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory
                          ?.productInitialInvestment
                      )}
                    </span>
                    &nbsp;initially &&nbsp;
                    <span className='highlighted_text'>
                      Rs.
                      {convertNumberToCommaSeparate(
                        +data?.getPendingBookingAdvisory?.productSIP
                      )}
                    </span>{' '}
                    monthly as SIP. This SIP will start from the next month in
                    Basic Plan or from 37th Month in Comfort Plan.
                  </div>
                </div>
              ) : (
                ''
              )}

              {data?.getPendingBookingAdvisory?.productRecommended
                ?.toLowerCase()
                ?.includes('relax') ? (
                <div className='terms_detail_box'>
                  {/* <img src={TenthNumber} alt='ElevenNumber' /> */}
                  <div className='terms_text'>
                    10. You will be charged{' '}
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.newPer}%{' '}
                    </span>{' '}
                    as fees on additional AUA.
                  </div>
                </div>
              ) : (
                <div className='terms_detail_box'>
                  {/* <img src={ElevenNumber} alt='ElevenNumber' /> */}
                  <div className='terms_text'>
                    11. You will be charged{' '}
                    <span className='highlighted_text'>
                      {data?.getPendingBookingAdvisory?.newPer}%{' '}
                    </span>{' '}
                    as fees on additional AUA.
                  </div>
                </div>
              )}

              {/* <div className='terms_detail_box'>
              <img src={TenthNumber} alt='TenthNumber' />
              <div className='terms_text'>
                We advise you to invest above the gross total cost figure every
                month so that the above cost can be managed easily at the
                year-end on a monthly SIP basis.
              </div>
            </div> */}

              {/* <div className='terms_detail_box'>
              <img src={ElevenNumber} alt='ElevenNumber' />
              <div className='terms_text'>
                Your payment schedule will be&nbsp;
                <span className='highlighted_text'>
                  Rs.
                  {convertNumberToCommaSeparate(
                    +data?.getPendingBookingAdvisory?.productInitialInvestment
                  )}
                </span>
                &nbsp;initially &&nbsp;
                <span className='highlighted_text'>
                  Rs.
                  {convertNumberToCommaSeparate(
                    +data?.getPendingBookingAdvisory?.productSIP
                  )}{' '}
                </span>
                monthly.
              </div>
            </div> */}

              <div className='term_note'>
                Let us know if you have any queries. We would like to help you
                with the same. Kindly send your confirmation after reading this
                mail so that we can proceed further.
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: `${isMobile ? 'flex-end' : 'center'}`,
                  marginTop: '10px',
                }}
              >
                <Button
                  // id='confirm-btn'
                  className='confirm-next-button'
                  loading={loading}
                  onClick={onFinish}
                >
                  <img src={CheckIconWhite} alt='RightArrowIconBlack' />{' '}
                  &nbsp;Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default TermsConditionNew
