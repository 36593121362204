import React, { useContext, useEffect, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Modal, Row, Col, Button, DatePicker, Tag } from 'antd'
import {
  GET_BOOKING_ADVISORY,
  GET_NEW_PENDING_ADVISORY,
  GET_PENDING_BOOKED_ADVISORY,
} from './graphql/Queries'
import { AppContext } from 'AppContext'
import moment from 'moment-timezone'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import client from 'apollo'
import {
  CREATE_BOOK_ADVISORY,
  GENERATE_ADVISORY,
  SEND_PENDING_ADVISORY_TERMS_MAIL,
  UPDATE_BOOK_ADVISORY,
} from './graphql/Mutations'
import openNotification from 'utils/Notification'
import { get, sortBy } from 'lodash'
import { UPDATE_ADVISORY_STATUS } from 'modules/PendingAdvisory/graphql/Mutation'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import CustomMeta from 'components/CustomMeta'
import KeyInfo from './KeyInfo'
import TermsConditionNew from './PendingAdvisory/TermsConditionNew'
import PendingAdvisoryIndexNew from './PendingAdvisory/PendingAdvisoryIndexNew'
import AdvisoryTradeUpdateNew from './PendingAdvisory/AdvisoryTradeUpdateNew'
import { GET_SP_ACTIVE_ADVISORY } from 'modules/Settings/graphql/Queries'
import { GET_ALL_NOTIFICATION_FOR_CLIENT } from 'auth/graphql/Queries'
import { GET_ALL_NSE_HOLIDAYS } from 'modules/NSEHoliday/graphql/Query'

const { confirm } = Modal

const PendingBookedAdvisoryNew = ({ back, newEdit }) => {
  const {
    state: { currentUser, showFAQScreen, isMobile, isVerificationDone },
    dispatch,
  } = useContext(AppContext)

  const [btnLoading, setBtnLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [graphData1, setGraphData1] = useState([])
  const [newData, setNewData] = useState({})
  const [newLoading, setNewLoading] = useState(false)
  const [newError, setNewError] = useState()
  const [accept, setAccept] = useState(false)
  const [dateForAdvisory, setDateForAdvisory] = useState('')
  const [advisoryLoading, setAdvisoryLoading] = useState(false)
  const [serverDate, setServerDate] = useState(null)
  let userData = currentUser

  useEffect(() => {
    getNewAdvisory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [SpActiveData, setSpActiveData] = useState()
  const { data: holidayDates } = useQuery(GET_ALL_NSE_HOLIDAYS, {
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    const fetchServerDate = async () => {
      try {
        const response = await fetch(
          'https://timeapi.io/api/Time/current/zone?timeZone=UTC'
        )
        const data = await response.json()
        const fetchedDate = moment.tz(data.dateTime, 'UTC').startOf('day')
        setServerDate(fetchedDate)
      } catch (error) {
        console.error('Error fetching server time:', error)
      }
    }

    fetchServerDate()
  }, [])

  useEffect(() => {
    if (!isVerificationDone) {
      client
        .query({
          query: GET_SP_ACTIVE_ADVISORY,
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (res.data?.sp_GetActiveAdvisory?.redirectTo === 'onBoarding') {
            setIsModalOpen(true)
            setSpActiveData(res.data?.sp_GetActiveAdvisory)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [isVerificationDone])

  const modalContent = (
    <Modal
      className='book_advisory_modal'
      visible={isModalOpen}
      footer={null}
      centered
      width={isMobile ? '100%' : '40%'}
    >
      <div>
        <div>
          <div className='book_advisory_text'>
            Dear {currentUser?.firstName + ' ' + currentUser?.lastName},
          </div>{' '}
          <div className='book_advisory_text test'>
            A new advisory is now available.
          </div>
          <div className='book_advisory_text_description'>
            Would you like to update it?
          </div>
        </div>
      </div>
      <div className='book_advisory_btn'>
        <Button
          className='advisory_cancel_btn'
          onClick={() => setIsModalOpen(false)}
        >
          No
        </Button>
        <Button
          className='advisory_verify_btn'
          type='primary'
          onClick={() => submitAdvisory()}
          loading={advisoryLoading}
        >
          Yes
        </Button>
      </div>
    </Modal>
  )

  const getNewAdvisory = () => {
    setNewLoading(true)
    client
      .query({
        query: GET_NEW_PENDING_ADVISORY,
        variables: { where: { advisoryDate: '' } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: GET_ALL_NOTIFICATION_FOR_CLIENT,
            fetchPolicy: 'network-only',
          })
          .then((newRes) => {
            dispatch({
              type: 'ALL_NOTIFICATIONS_FOR_CLIENT',
              data: newRes?.data?.getNotifications,
            })
            setNewData(res?.data)
            setNewLoading(false)
          })
          .catch((err) => {
            setNewError(err)
            setNewLoading(false)
          })
      })
      .catch((err) => {
        setNewError(err)
        setNewLoading(false)
      })
  }

  let type = ''

  const {
    data: bookedAdvisory,
    loading: bookedAdvisoryLoading,
    error: bookedAdvisoryError,
  } = useQuery(GET_BOOKING_ADVISORY, {
    variables: {
      where: {
        userId: parseInt(currentUser?.id),
        isClientAcceptAdvisory: false,
        isCancelAdvisory: false,
        isClientLogin: true,
        advisorySendType: 'Auto',
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    let showPendingBtn = {}
    if (
      bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted &&
      bookedAdvisory?.getBookAdvisory?.isPendingAdvisoryAccepted &&
      !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory
    ) {
      showPendingBtn = {
        ...showPendingBtn,
        isAdvisoryAccept: true,
        isAdvisoryReject: true,
      }
    } else if (bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted) {
      showPendingBtn = {
        ...showPendingBtn,
        isPendingAccept: true,
        isPendingReject: true,
      }
    } else {
      showPendingBtn = {
        ...showPendingBtn,
        isConfirm: true,
      }
    }
    dispatch({
      type: 'SHOW_FOOTER_BTN',
      data: showPendingBtn,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedAdvisory])

  const { data, loading, error } = useQuery(GET_PENDING_BOOKED_ADVISORY, {
    variables: {
      where: {
        exposure: currentUser?.initialExposure,
        planId: parseInt(currentUser?.plan?.id),
      },
    },
  })

  if (!newLoading && get(newData, 'getNewBookingAdvisory')) {
    type = newData?.getNewBookingAdvisory?.type
  }

  useEffect(() => {
    const data = []
    const mapData2 = []
    if (newData?.getNewBookingAdvisory && !newLoading) {
      const red =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[1] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[1])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[1][key]
          )

      const green =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[2] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[2])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[2][key]
          )

      const nifty =
        newData?.getNewBookingAdvisory?.mapScenario1 &&
        newData?.getNewBookingAdvisory?.mapScenario1[0] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario1[0])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario1[0][key]
          )

      for (let i = 0; i < red?.length; i++) {
        let d = {
          nifty: nifty[i],
          pl: red[i],
          nlv: green[i],
        }

        data.push(d)
      }
      setGraphData(sortBy(data, 'nifty'))
      const red1 =
        newData?.getNewBookingAdvisory?.mapScenario2[1] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[1])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[1][key]
          )

      const green1 =
        newData?.getNewBookingAdvisory?.mapScenario2[2] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[2])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[2][key]
          )

      const nifty1 =
        newData?.getNewBookingAdvisory?.mapScenario2[0] &&
        Object.keys(newData?.getNewBookingAdvisory?.mapScenario2[0])
          .filter((key) => key !== 'Description')
          .map(
            (key, index) => newData?.getNewBookingAdvisory?.mapScenario2[0][key]
          )

      for (let i = 0; i < red1?.length; i++) {
        let d = {
          nifty: nifty1[i],
          pl: red1[i],
          nlv: green1[i],
        }

        mapData2.push(d)
      }
      setGraphData1(sortBy(mapData2, 'nifty'))
    }
  }, [newLoading, newData])

  if (loading || bookedAdvisoryLoading || newLoading) return <Loader />
  if (error || bookedAdvisoryError || newError) return <Page404 />

  const sendTermsMail = () => {
    setBtnLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    client
      .mutate({
        mutation: SEND_PENDING_ADVISORY_TERMS_MAIL,
        variables: {
          data: {
            advisoryId: parseInt(bookedAdvisory?.getBookAdvisory?.id),
            productRecommended:
              data?.getPendingBookingAdvisory?.productRecommended,
            productExposure: parseFloat(
              data?.getPendingBookingAdvisory?.productExposure
            ),
            productInitialInvestment: parseFloat(
              data?.getPendingBookingAdvisory?.productInitialInvestment
            ),
            productSIP: parseFloat(data?.getPendingBookingAdvisory?.productSIP),
            productHedging: parseFloat(
              data?.getPendingBookingAdvisory?.productHedging
            ),
            productInterest: parseFloat(
              data?.getPendingBookingAdvisory?.productInterest
            ),
            productAnnual: parseFloat(
              data?.getPendingBookingAdvisory?.productAnnual
            ),
            productAMC: parseFloat(data?.getPendingBookingAdvisory?.productAMC),
            productStock: parseFloat(
              data?.getPendingBookingAdvisory?.productStock
            ),
            saveInterest: parseFloat(
              data?.getPendingBookingAdvisory?.saveInterest
            ),
            grossTotal: parseFloat(data?.getPendingBookingAdvisory?.grossTotal),
            name: currentUser?.firstName + ' ' + currentUser?.lastName,
            email: currentUser?.email,
          },
        },
      })
      .then((res) => {
        client
          .query({
            query: GET_BOOKING_ADVISORY,
            variables: {
              where: {
                userId: parseInt(currentUser?.id),
                id: parseInt(bookedAdvisory?.getBookAdvisory?.id),
                isClientLogin: true,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((response) => {
            setBtnLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
            openNotification(
              'success',
              res?.data?.sendTermsAndServiceMail?.message
            )
          })
          .catch((err) => {
            console.log(err)
            setBtnLoading(false)
            dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          })
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  const updatePendingAdvisory = () => {
    setBtnLoading(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { isPendingAdvisoryAccepted: true, userId: currentUser?.id },
          where: { id: parseInt(bookedAdvisory?.getBookAdvisory?.id) },
        },
        refetchQueries: [
          {
            query: GET_BOOKING_ADVISORY,
            variables: {
              userId: parseInt(currentUser?.id),
              id: parseInt(bookedAdvisory?.getBookAdvisory?.id),
              isClientLogin: true,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setBtnLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  const onReject = () => {
    let modal
    modal = confirm({
      okText: 'Confirm',
      cancelText: 'Close',
      centered: true,
      okButtonProps: { style: { display: 'none' } },
      cancelButtonProps: { style: { display: 'none' } },
      content: (
        <div>
          <div className='modal_text'>
            Do You Want To Discard The Received Advisory?
          </div>
          <div className='book_advisory_btn'>
            <Button
              className='advisory_cancel_btn'
              onClick={() => modal.destroy()}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              className='advisory_verify_btn'
              type='primary'
              onClick={() => onOk()}
              loading={false}
            >
              Confirm
            </Button>
          </div>
        </div>
      ),
    })

    const onOk = () => {
      modal.destroy()
      setRejectLoading(true)
      const statusData =
        newData?.getNewBookingAdvisory?.currentAdvisoryData.map((d) => {
          return {
            advisorId: parseInt(d.AdvisorID),
            clientCode: d.ClientCode,
            status: 'Reject',
          }
        })
      client
        .mutate({
          mutation: UPDATE_ADVISORY_STATUS,
          variables: { data: statusData },
        })
        .then((res) => {
          client.mutate({
            mutation: UPDATE_BOOK_ADVISORY,
            variables: {
              data: {
                isPendingAdvisoryAccepted: true,
                isCancelAdvisory: true,
                userId: currentUser?.id,
                advisoryCancelBy: currentUser?.id,
              },
              where: { id: parseInt(bookedAdvisory?.getBookAdvisory?.id) },
            },
          })
          let userData = {}
          if (currentUser?.isNewAuaAdvisoryGenerate) {
            userData.newAuaAdvisoryCompletedSteps = 'KYC_FORM'
            userData.isBookingAdvisoryDateSelected = false
            userData.isNewAuaAdvisoryGenerate = false
          } else {
            userData.completedSteps = 'E_MANDATE'
            userData.isBookingAdvisoryDateSelected = false
          }
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: { data: { ...userData }, id: currentUser?.id },
            })
            .then((response) => {
              dispatch({
                type: 'CURRENT_USER',
                data: response.data.updateUser,
              })
              setRejectLoading(false)
              res?.data?.updateAdvisoryStatus === true
                ? openNotification('info', 'Rejected Successfully')
                : openNotification('error', 'Something went wrong')
              back()
            })
            .catch((err) => {
              console.log(err)
              setRejectLoading(false)
            })
        })
        .catch((err) => {
          console.log(err)
          setRejectLoading(false)
        })
    }
  }

  const Submit = () => {
    if (bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted) {
      updatePendingAdvisory()
    } else {
      sendTermsMail()
    }
  }

  const handleGetPendingAdvisory = (e) => {
    setDateForAdvisory(
      moment(e).format('DD-MMM-YYYY') !== 'Invalid date'
        ? moment(e).format('DD-MMM-YYYY')
        : ''
    )
    if (e) {
      setAccept(true)
    } else {
      setAccept(false)
    }
  }

  const disableFutureDates = (current) => {
    if (!serverDate) return false
    return !serverDate
      ? current &&
          (current > moment().endOf('day') ||
            current.day() === 0 ||
            current.day() === 6 ||
            holidayDates?.getHoliday?.some((holiday) =>
              moment(holiday.date).isSame(current, 'day')
            ))
      : (current && current.startOf('day').isAfter(serverDate)) ||
          current.day() === 0 ||
          current.day() === 6 ||
          holidayDates?.getHoliday?.some((holiday) =>
            moment(holiday.date).isSame(current, 'day')
          )
  }

  const submitAdvisory = () => {
    createAdvisory()
  }

  const generate = async () => {
    return client
      .mutate({
        mutation: GENERATE_ADVISORY,
        variables: {
          data: {
            clientCode: currentUser?.clientCode,
            plan: currentUser?.plan?.name,
            exposure: currentUser?.initialExposure,
            initialExposure: currentUser?.initialExposure,
            isNewClient: true,
          },
        },
      })
      .then((res) => {
        console.log('Advisory Generated')
        return true
      })
      .catch((err) => {
        console.log(err)
        setAdvisoryLoading(false)
        return false
      })
  }

  const createAdvisory = async () => {
    setAdvisoryLoading(true)
    generate().then((res) => {
      if (res) {
        client
          .mutate({
            mutation: CREATE_BOOK_ADVISORY,
            variables: {
              data: {
                userId: userData?.id,
                advisoryDate: moment().format(),
                isAdvisoryBooked: true,
                isClientLogin: true,
                isClientAcceptAdvisory: false,
                isTimeUp: false,
                isFromPopup: true,
              },
            },
            refetchQueries: [
              {
                query: GET_BOOKING_ADVISORY,
                variables: {
                  where: {
                    userId: parseInt(currentUser?.id),
                    isClientAcceptAdvisory: false,
                    isCancelAdvisory: false,
                    isClientLogin: true,
                    advisorySendType: 'Auto',
                  },
                },
                fetchPolicy: 'network-only',
              },
            ],
          })
          .then((res) => {
            let data = {
              isBookingAdvisoryDateSelected: true,
              completedSteps: 'CONGRATULATION',
            }

            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  data,
                  id: userData?.id,
                },
              })
              .then((response) => {
                getNewAdvisory()
                setIsModalOpen(false)
                dispatch({
                  type: 'CURRENT_USER',
                  data: response.data.updateUser,
                })
                setAdvisoryLoading(false)
              })
              .catch((error) => {
                console.log(error)
                setAdvisoryLoading(false)
              })
          })
          .catch((err) => {
            console.log(err)
            setAdvisoryLoading(false)
          })
      }
    })
  }

  return (
    <>
      {isModalOpen && SpActiveData !== null && modalContent}
      {!showFAQScreen ? (
        <Row className='row-style'>
          <Col
            lg={
              !currentUser?.isNewAuaAdvisoryGenerate &&
              window.location.pathname === '/verification'
                ? 16
                : 24
            }
            sm={24}
            className={`${
              !currentUser?.isNewAuaAdvisoryGenerate &&
              window.location.pathname === '/verification'
                ? 'col-left disclaimer-col'
                : 'disclaimer-col'
            }? `}
          >
            <div
              style={{
                marginBottom: isMobile ? '10dvh' : '10px',
              }}
            >
              <CustomMeta title='Client Profile 1' />
              <div className='pending_booking_advisory_page'>
                <div className='pending_advisory_logo_and_title'>
                  <div>
                    <div className='pending_advisory_title'>
                      Index Long Term Strategy
                    </div>
                    <div className='pending_advisory_description'>
                      {bookedAdvisory?.getBookAdvisory
                        ?.isTermAndServiceAccepted &&
                      bookedAdvisory?.getBookAdvisory?.isPendingAdvisoryAccepted
                        ? 'Advisory Trade Update'
                        : bookedAdvisory?.getBookAdvisory
                            ?.isTermAndServiceAccepted
                        ? 'Pending Advisory'
                        : 'Terms & Service'}
                    </div>
                  </div>
                  <div>
                    <div className='pending_advisory_title'>
                      {moment(newData?.getNewBookingAdvisory?.createdAt)
                        .subtract(330, 'minutes')
                        .format('DD/MM/YYYY')}
                    </div>
                    <div
                      className='pending_advisory_description'
                      style={{ textAlign: 'end' }}
                    >
                      {moment(newData?.getNewBookingAdvisory?.createdAt)
                        .subtract(330, 'minutes')
                        .format('HH:mm:ss')}
                    </div>
                  </div>
                </div>
                <hr style={{ border: '1px solid #E1E3EA' }} />
                <div
                  className='pending_advisory_logo_and_title'
                  style={{
                    display: `${
                      bookedAdvisory?.getBookAdvisory
                        ?.isTermAndServiceAccepted &&
                      bookedAdvisory?.getBookAdvisory
                        ?.isPendingAdvisoryAccepted &&
                      !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory &&
                      isMobile
                        ? 'block'
                        : 'flex'
                    }`,
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div>
                      <div
                        className='pending_advisory_title'
                        style={{ color: '#AF0000', marginBottom: '8px' }}
                      >
                        {currentUser?.clientCode}
                      </div>
                      <div className='pending_advisory_title'>
                        {currentUser?.firstName + ' ' + currentUser?.lastName}
                      </div>
                    </div>
                    {bookedAdvisory?.getBookAdvisory
                      ?.isTermAndServiceAccepted &&
                      bookedAdvisory?.getBookAdvisory
                        ?.isPendingAdvisoryAccepted &&
                      !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory && (
                        <div style={{ marginLeft: '32px' }}>
                          <div
                            className='pending_advisory_title'
                            style={{ color: '#AF0000', marginBottom: '8px' }}
                          >
                            Advisory Type
                          </div>
                          <div className='pending_advisory_title'>
                            {type === 'New' ? 'Entry' : type}
                          </div>
                        </div>
                      )}
                  </div>

                  <div>
                    {bookedAdvisory?.getBookAdvisory
                      ?.isTermAndServiceAccepted &&
                    bookedAdvisory?.getBookAdvisory
                      ?.isPendingAdvisoryAccepted &&
                    !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory ? (
                      <div
                        style={{
                          display: 'grid',
                          justifyContent: !isMobile && 'flex-end',
                          marginTop: isMobile && '20px',
                        }}
                      >
                        <div
                          style={{ textAlign: `${isMobile ? 'start' : 'end'}` }}
                        >
                          <b>Select Trade Date</b>
                        </div>

                        <div>
                          <DatePicker
                            className='date_picker_new'
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            onChange={(e) => handleGetPendingAdvisory(e)}
                            value={
                              dateForAdvisory ? moment(dateForAdvisory) : ''
                            }
                            disabledDate={disableFutureDates}
                            format={'DD-MM-YYYY'}
                            style={{ width: '100% !important' }}
                          />
                          {(!dateForAdvisory ||
                            dateForAdvisory === 'Invalid date') && (
                            <div>
                              <Tag
                                color='red'
                                style={{
                                  marginTop: '5px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginRight: 0,
                                }}
                              >
                                Please Select Date
                              </Tag>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='pending_advisory_title'>
                          Advisory Type
                        </div>
                        <div
                          className='pending_advisory_description'
                          style={{ textAlign: 'end' }}
                        >
                          {type === 'New' ? 'Entry' : type}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {bookedAdvisory?.getBookAdvisory?.isTermAndServiceAccepted &&
                bookedAdvisory?.getBookAdvisory?.isPendingAdvisoryAccepted &&
                !bookedAdvisory?.getBookAdvisory?.isCancelAdvisory ? (
                  // <AdvisoryTradeUpdate
                  //   type={type}
                  //   advisoryDate={bookedAdvisory?.getBookAdvisory?.advisoryDate}
                  //   advisoryId={bookedAdvisory?.getBookAdvisory?.id}
                  // />
                  <AdvisoryTradeUpdateNew
                    type={type}
                    advisoryDate={bookedAdvisory?.getBookAdvisory?.advisoryDate}
                    advisoryId={bookedAdvisory?.getBookAdvisory?.id}
                    accept={accept}
                    setAccept={setAccept}
                    dateForAdvisory={dateForAdvisory}
                    isMobile={isMobile}
                  />
                ) : bookedAdvisory?.getBookAdvisory
                    ?.isTermAndServiceAccepted ? (
                  <PendingAdvisoryIndexNew
                    type={type}
                    advisoryDate={bookedAdvisory?.getBookAdvisory?.advisoryDate}
                    loading={btnLoading}
                    rejectLoading={rejectLoading}
                    onFinish={Submit}
                    onReject={onReject}
                    advisoryData={newData?.getNewBookingAdvisory}
                    data={graphData}
                    graphData1={graphData1}
                    userData={currentUser}
                    isMobile={isMobile}
                  />
                ) : (
                  <TermsConditionNew
                    data={data}
                    loading={btnLoading}
                    onFinish={Submit}
                  />
                )}
              </div>
            </div>
          </Col>

          {!currentUser?.isNewAuaAdvisoryGenerate &&
            window.location.pathname === '/verification' && (
              <Col lg={8} sm={24} className='col-right disclaimer-col'>
                <KeyInfo
                  newEdit={newEdit}
                  advisoryData={{
                    advisory:
                      newData?.getNewBookingAdvisory?.currentAdvisoryData,
                    id: bookedAdvisory?.getBookAdvisory?.id,
                  }}
                />
              </Col>
            )}
        </Row>
      ) : (
        <showFAQScreen />
      )}
    </>
  )
}
export default PendingBookedAdvisoryNew
