import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Form,
  Button,
  InputNumber,
  Input,
  Select,
  Modal,
  Checkbox,
  Row,
  Col,
} from 'antd'
import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
import moment from 'moment'
import { getName } from 'utils/User'
import openNotification from 'utils/Notification'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import { E_MANDATE, E_MANDATE_BANK_DATA } from 'modules/Users/graphql/Queries'
import { get } from 'lodash'
import {
  COMPLETE_STEP,
  CREATE_MANDATE,
  CREATE_TRANSACTION_VIA_UPI,
} from './graphql/Mutations'
import { GET_SP_AUTO_ADVISE } from './graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import './verification.css'
import UpiPayment from './UpiPayment'
import { GET_TRANSACTION } from 'modules/Transactions/Graphql/Queries'
import KeyInfo from './KeyInfo'

const FormItem = Form.Item
const { Option } = Select

const E_mandateRegistration = ({ next, newEdit }) => {
  const videoRef = useRef(null)
  const [form] = Form.useForm()
  const [loadings, setLoadings] = useState()
  const [bankNames, setBankNames] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [agree, setAgree] = useState(true)
  const [transactionStatus, setTransactionStatus] = useState('')
  const [showQrCode, setShowQrCode] = useState(false)
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [upiTransactionId, setUpiTransactionId] = useState('')
  const [upiInputModel, setUpiInputModel] = useState(true)
  const [amcIdMandate, setAmcId] = useState()

  //upi_payment
  const [openUpiModel, setOpenUpiModel] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  let {
    dispatch,
    state: { currentUser, clientSummaryDetails, isMobile },
  } = useContext(AppContext)
  const {
    id,
    email,
    phone,
    plan,
    initialExposure,
    initialInvestment,
    completedSteps,
    isNewAuaAdvisoryGenerate,
  } = currentUser

  const amcEMandateAmount = [
    25000, 50000, 75000, 100000, 125000, 150000, 200000, 300000, 500000, 900000,
  ]

  useEffect(() => {
    client
      .query({ query: E_MANDATE_BANK_DATA, fetchPolicy: 'network-only' })
      .then((res) => setBankNames(res?.data?.eMandateBankData || []))
  }, [])

  const { loading, error, data } = useQuery(GET_SP_AUTO_ADVISE, {
    variables: {
      where: {
        dontBuyEquity: 'NO',
        exposure: initialExposure,
        planId: parseInt(plan?.id),
        udInvestment: initialInvestment,
      },
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  const spAutoAdvise = get(data, 'spAutoAdvise') || []
  let exposure = spAutoAdvise?.find(
    (item) => item?.Description === 'Exposure'
  )?.Value
  const spData = spAutoAdvise.find(
    (a) => a.Description === 'AMC (including GST)'
  )?.mandateAmount

  // const amcAmount = spAutoAdvise.find(
  //   (a) => a.Description === 'AMC (including GST)'
  // )?.Value

  let amcAmount = 0
  let gstAmount = 0

  spAutoAdvise.map((item) => {
    if (Object.keys(item).includes('GrossAMC')) {
      amcAmount = item?.GrossAMC
      gstAmount = item?.GST
    }
    return true
  })

  let availableEMandateAmount = []
  if (spData) {
    const newValues = amcEMandateAmount.filter((item) => item > spData)
    availableEMandateAmount = [spData, ...newValues]
  }

  // const amcAmount = spAutoAdvise[spAutoAdvise.length - 1].GrossAMC
  // const gstAmount = spAutoAdvise[spAutoAdvise.length - 1].GST
  const totalNetAmount = amcAmount + gstAmount
  const lotSize = spAutoAdvise.find((item) => item['LotSize'])?.LotSize

  const onFinish = (values) => {
    setLoadings(true)
    dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
    const {
      accountType,
      accountNumber,
      bank,
      startDate = moment().format('YYYY-MM-DD'),
      ifscCode,
      amount,
    } = values
    client
      .query({
        query: E_MANDATE,
        variables: {
          data: {
            accountType,
            accountNumber,
            bank,
            startDate,
            ifscCode,
            amount: +amount,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        if (!get(response, 'data.eMandate')) {
          openNotification('error', 'Something went wrong!')
          setLoadings(false)
          dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
          return false
        }
        setLoadings(true)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: true })
        const options = {
          environment: process.env.REACT_APP_DIGIO_ENVIRONMENT,
          callback: function (response) {
            if (response.hasOwnProperty('error_code')) {
              setLoadings(false)
              dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
              return console.log('error occurred in process')
            }
            // openNotification('success', ' Processing complete Successfully')
            let data = {
              accountType,
              accountNumber: accountNumber.toString(),
              bank,
              mandateId: response.digio_doc_id,
              registrationDate: moment().format('YYYY-MM-DD'),
              compareMandateAmount: +amount,
            }
            if (currentUser?.isNewAuaAdvisoryGenerate) {
              data.newAuaAdvisoryCompletedSteps = 'ADVISORY_FEES'
            } else {
              data.completedSteps = 'E_MANDATE'
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id,
                  data: { ...data },
                },
              })
              .then((res) => {
                const currentIndex = clientSummaryDetails.findIndex(
                  (item) => item?.name === 'E-Mandate'
                )
                const updateClientSummaryUi = {
                  name: 'E-Mandate',
                  completedSteps: 'AGREEMENT_SIGN',
                  isDisplayEdit: true,
                  data: {
                    'Bank Name': bank,
                    'Account Number': accountNumber,
                    'ISFC Code': ifscCode,
                    'Account Type': accountType,
                    'EMandate Amount': amount,
                  },
                }
                if (currentIndex !== -1) {
                  clientSummaryDetails[currentIndex] = updateClientSummaryUi
                } else {
                  clientSummaryDetails.push(updateClientSummaryUi)
                }
                dispatch({
                  type: 'CLIENT_SUMMARY_DETAILS',
                  data: clientSummaryDetails,
                })
                client.mutate({
                  mutation: CREATE_MANDATE,
                  variables: {
                    data: {
                      userId: id,
                      ifscCode: ifscCode,
                    },
                  },
                })
                if (!currentUser?.isNewAuaAdvisoryGenerate) {
                  client.mutate({
                    mutation: COMPLETE_STEP,
                    variables: {
                      data: {
                        step: 'E_MANDATE',
                        from: 'WEB',
                      },
                    },
                  })
                }
                dispatch({
                  type: 'CURRENT_USER',
                  data: res.data.updateUser,
                })
                // dispatch({
                //   type: 'IS_VERIFICATION_DONE',
                //   data: true,
                // })
                openNotification(
                  'success',
                  'Successfully Complete E Mandate Registration'
                )
                setLoadings(false)
                dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
                // history.push('/')
                next()
              })
          },
          logo: 'https://www.mylogourl.com/image.jpeg',
          theme: {
            primaryColor: '#AB3498',
            secondaryColor: '#000000',
          },
          is_iframe: true,
          Is_redirection_approach: false,
        }

        let digio = new window.Digio(options)
        digio.init()
        digio.submit(
          response.data.eMandate.id,
          email,
          response?.data?.eMandate?.access_token?.id
        )
      })
      .catch((err) => {
        setLoadings(false)
        dispatch({ type: 'IS_ACCEPT_LOADING', data: false })
      })
  }

  // const videoModal = () => {
  //   setIsModalVisible(true)
  // }

  const handleCancel = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
    setIsModalVisible(false)
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  const upiModel = (amcAmount) => {
    client
      .mutate({
        mutation: CREATE_TRANSACTION_VIA_UPI,
        variables: {
          data: {
            amount: amcAmount,
            amountWithGST: amcAmount + gstAmount,
            // amcId: +data?.id,
            // lotSize: data?.amcQty,
          },
        },
      })
      .then((res) => {
        if (res?.data?.createTransactionViaUpi) {
          setQrCodeUrl(res?.data?.createTransactionViaUpi?.url)
          setShowQrCode(true)

          let upiTransactionId =
            res?.data?.createTransactionViaUpi?.transactionId
          let status = res?.data?.createTransactionViaUpi?.status
          let upiTxnNumber = res?.data?.createTransactionViaUpi?.upiTxnNo

          setUpiTransactionId(upiTransactionId)
          setAmcId(res?.data?.createTransactionViaUpi?.amcId)
          checkQrPaymentSuccess(upiTransactionId, status, upiTxnNumber)
        } else {
          upiModel(amcAmount)
        }
      })
      .catch((err) => console.log({ err }))
    setOpenUpiModel(true)
    setWarningModal(false)
  }

  const checkQrPaymentSuccess = (upiTransactionId, status, upiTxnNumber) => {
    let count = 0

    if (
      (!status ||
        status === 'PENDING' ||
        status === 'FAILURE' ||
        status === null) &&
      (upiTxnNumber === null || upiTxnNumber === undefined)
    ) {
      const callApiAtEvery = setInterval(() => {
        if (upiTransactionId) {
          client
            .query({
              query: GET_TRANSACTION,
              variables: {
                where: { transactionId: upiTransactionId },
              },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              count++
              if (res?.data?.getTransaction?.status === 'SUCCESS') {
                setTransactionStatus('SUCCESS')
                setUpiInputModel(false)
                if (completedSteps !== 'DONE') {
                  let data = {}
                  if (currentUser?.isNewAuaAdvisoryGenerate) {
                    data.newAuaAdvisoryCompletedSteps = 'ADVISORY_FEES'
                  } else {
                    data.completedSteps = 'E_MANDATE'
                  }
                  client
                    .mutate({
                      mutation: UPDATE_USER,
                      variables: { id, data: { ...data } },
                    })
                    .then((res) => {
                      client
                        .mutate({
                          mutation: COMPLETE_STEP,
                          variables: {
                            data: { step: 'E_MANDATE', from: 'WEB' },
                          },
                        })
                        .then((res) => {
                          next()
                        })
                    })
                }
                clearInterval(callApiAtEvery)
              }

              if (
                res?.data?.getTransaction?.status === 'FAILURE' ||
                res?.data?.getTransaction?.status === 'FAILED' ||
                res?.data?.getTransaction?.status === 'SPAM' ||
                res?.data?.getTransaction?.status === 'REJECTED' ||
                res?.data?.getTransaction?.status === 'EXPIRED'
              ) {
                setTransactionStatus('ERROR')
                setUpiInputModel(false)
                clearInterval(callApiAtEvery)
              }

              if (count >= 120) {
                setTransactionStatus('ERROR')
                setUpiInputModel(false)
                clearInterval(callApiAtEvery)
              }
              return
            })
        }
      }, 5000)
    } else if (
      (status === 'FAILED' ||
        status === 'FAILURE' ||
        status === 'SPAM' ||
        status === 'REJECTED' ||
        status === 'EXPIRED') &&
      upiTxnNumber !== null
    ) {
      setTransactionStatus('ERROR')
    } else {
      setTransactionStatus('SUCCESS')
    }
  }

  return (
    <>
      <Row className='row-style'>
        <Col
          lg={
            !isNewAuaAdvisoryGenerate &&
            window.location.pathname === '/verification'
              ? 16
              : 24
          }
          sm={24}
          className={`${
            !isNewAuaAdvisoryGenerate &&
            window.location.pathname === '/verification'
              ? 'col-left disclaimer-col'
              : 'disclaimer-col'
          }? `}
        >
          <div>
            <CustomMeta title='E-Mandate Registration' />
            {!openUpiModel ? (
              <>
                <div
                  className='gx-app-login-wrap title-verification'
                  style={{ overflowX: 'hidden' }}
                >
                  <CustomMeta title='E-Mandate Registration' />
                  <div>
                    <Form
                      form={form}
                      name='register'
                      labelAlign='left'
                      onFinish={onFinish}
                      scrollToFirstError
                      initialValues={{
                        customerName: getName(currentUser),
                        mobileNo: phone,
                        email: email,
                        amount: spData,
                      }}
                      layout='vertical'
                      className='client-detail-form'
                    >
                      <Modal
                        title='Play Video'
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={false}
                        className='video-modal'
                      >
                        <video
                          ref={videoRef}
                          src='https://finideas-india.blr1.digitaloceanspaces.com/E-Mandate-Approval.mp4'
                          type='video/mp4'
                          autoPlay
                          controls
                          width={'100%'}
                        ></video>
                      </Modal>
                      <div className='details-box-for-mandate'>
                        <div className='detail-box-title'>Customer Details</div>
                        <FormItem
                          name='customerName'
                          label='Customer Name'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            className='client-detail-input'
                            placeholder='Customer Name'
                            autoFocus={true}
                            style={{ width: '100%', textAlign: 'center' }}
                            autoComplete='off'
                            disabled
                          />
                        </FormItem>
                        <FormItem
                          name='mobileNo'
                          label='Mobile No'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder='Mobile No'
                            style={{ width: '100%', textAlign: 'center' }}
                            autoComplete='off'
                            disabled
                            className='client-detail-input'
                          />
                        </FormItem>
                        <FormItem
                          name='email'
                          label='Email'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder='Email'
                            style={{ width: '100%', textAlign: 'center' }}
                            autoComplete='off'
                            disabled
                            className='client-detail-input'
                          />
                        </FormItem>
                      </div>
                      <div className='details-box-for-mandate'>
                        <div className='detail-box-title'>Bank Details</div>
                        <Row className='client-profile-row'>
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                            style={{ padding: '0 10px 0 0' }}
                          >
                            <FormItem
                              name='bank'
                              label='Bank'
                              rules={[
                                {
                                  required: true,
                                  message: 'Bank is required!',
                                },
                              ]}
                            >
                              <Select
                                placeholder='Bank Name'
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                showSearch
                                className='display-field'
                              >
                                {bankNames.map((bankName) => (
                                  <Option
                                    key={bankName.id}
                                    value={bankName.name}
                                  >
                                    {bankName.name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                            style={{ padding: '0 0 0 10px' }}
                          >
                            <FormItem
                              name='accountType'
                              label='Account Type'
                              rules={[
                                {
                                  required: true,
                                  message: 'Account Type is required!',
                                },
                              ]}
                            >
                              <Select
                                placeholder='Account Type'
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                className='display-field'
                              >
                                <Option value='SAVINGS'>SAVINGS</Option>
                                <Option value='CURRENT'>CURRENT</Option>
                                <Option value='CREDIT_CASH'>CREDIT CASH</Option>
                                <Option value='OTHER'>OTHER</Option>
                              </Select>
                            </FormItem>
                          </Col>
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                            style={{ padding: '0 10px 0 0' }}
                          >
                            <FormItem
                              name='accountNumber'
                              label='Account Number'
                              rules={[
                                {
                                  required: true,
                                  message: 'Account Number is required!',
                                },
                              ]}
                            >
                              <Input
                                type='number'
                                placeholder='Account Number'
                                style={{ width: '100%', textAlign: 'center' }}
                                autoComplete='off'
                              />
                            </FormItem>
                          </Col>
                          <Col
                            className='gutter-row client-column'
                            lg={12}
                            sm={24}
                            style={{ padding: '0 0 0 10px' }}
                          >
                            <FormItem
                              name='ifscCode'
                              label='IFSC Code'
                              rules={[
                                {
                                  required: true,
                                  message: 'IFSC Code is required!',
                                  pattern: new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$'),
                                },
                              ]}
                            >
                              <Input
                                placeholder='IFSC Code'
                                style={{ width: '100%', textAlign: 'center' }}
                                autoComplete='off'
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      </div>
                      <div className='details-box-for-mandate'>
                        <div className='detail-box-title'>Mandate Details</div>
                        <FormItem
                          name='amount'
                          label='Amount (Maximum amount to debit as fees at a time)'
                          rules={[
                            {
                              required: true,
                              message: 'Account Type is required!',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Amount'
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            className='display-field'
                          >
                            {availableEMandateAmount?.length &&
                              availableEMandateAmount?.map((value) => {
                                return <Option value={value}>{value}</Option>
                              })}
                          </Select>
                        </FormItem>
                      </div>
                      <div>
                        <div
                          className='agree-wrapper'
                          style={{ marginBottom: '20px' }}
                        >
                          <Checkbox onChange={handleAgree} checked={agree} />
                          <p className='agree-content'>
                            I/We hereby declare that the details furnished above
                            are true and correct to the best of my/our knowledge
                            and belief and I/We undertake to inform you of any
                            changes therein, immediately. In case any of the
                            above information is found to be false or untrue or
                            misleading or misrepresenting, I am/We are aware
                            that I/We may be held liable for it.
                          </p>
                        </div>

                        <div
                          className='verify_next_button'
                          style={{
                            marginRight: '18px',
                            marginTop: `${isMobile ? '20px' : '0px'}`,
                          }}
                        >
                          {/* <Button
                            className='show-plan-button'
                            onClick={() => next(1, true)}
                            // id='skip-e-mandate'
                            style={{ margin: '0 8px' }}
                          >
                            skip
                          </Button> */}
                          <Button
                            className='yes-next-button'
                            // id='btn-click'
                            style={{ margin: '0 8px' }}
                            loading={loadings}
                            type='primary'
                            htmlType='submit'
                            disabled={!agree}
                          >
                            Next
                          </Button>
                        </div>
                        <Button
                          hidden={true}
                          style={{ margin: '0 8px' }}
                          type='primary'
                          onClick={() => setWarningModal(true)}
                        >
                          {`Skip And Pay Rs. ${amcAmount + gstAmount}/-`}
                        </Button>
                      </div>
                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          style={{
                            padding: '0 16px',
                            borderRadius: '8px',
                          }}
                          type='primary'
                          onClick={() => videoModal()}
                        >
                          View E-Mandate Demo
                        </Button>
                      </div> */}
                    </Form>
                  </div>
                </div>
              </>
            ) : (
              <UpiPayment
                amount={amcAmount}
                gstAmount={gstAmount}
                exposure={exposure}
                grossTotalAmount={totalNetAmount}
                lotSize={lotSize}
                setTransactionStatus={setTransactionStatus}
                transactionStatus={transactionStatus}
                showQrCode={showQrCode}
                qrCodeUrl={qrCodeUrl}
                upiInputModel={upiInputModel}
                setUpiInputModel={setUpiInputModel}
                transactionId={upiTransactionId}
                amcId={amcIdMandate}
                setQrCodeUrl={setQrCodeUrl}
                setShowQrCode={setShowQrCode}
                checkQrPaymentSuccess={checkQrPaymentSuccess}
                isOnBoard={true}
              />
            )}

            {warningModal && (
              <Modal
                visible={warningModal}
                footer={null}
                className='warning_modal'
                onCancel={() => setWarningModal(false)}
              >
                <div className='payment_warning'>
                  <span>
                    Note:- Payments to Finideas AMC must be made in the name of
                    the Registered KYC person only. Third-party payments are not
                    permitted.
                  </span>
                </div>
                <div className='payment_warning_btn'>
                  <Button type='primary' onClick={() => upiModel(amcAmount)}>
                    Confirm
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        </Col>

        {!isNewAuaAdvisoryGenerate &&
          window.location.pathname === '/verification' && (
            <Col lg={8} sm={24} className='col-right'>
              <KeyInfo newEdit={newEdit} />
            </Col>
          )}
      </Row>
    </>
  )
}

export default E_mandateRegistration
