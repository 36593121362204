import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row, notification, Collapse } from 'antd'

import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_FAQUESTION } from '../graphql/Queries'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'

import client from 'apollo'
import '../verification.css'
import CreateTicketModal from 'modules/Tickets/CreateTicketModal'
import { CREATE_TICKET } from 'modules/Tickets/graphql/Mutations'

import { ReactComponent as AngleSmallUp } from 'assets/images/new-images/angle-small-up.svg'
import { ReactComponent as AngleSmallDown } from 'assets/images/new-images/angle-small-down.svg'
import { ReactComponent as AngleSmallLeft } from 'assets/images/new-images/angle-small-left.svg'

import { AppContext } from 'AppContext'
import { GET_IMAGES } from 'modules/LoginImages/graphql/Query'
import history from 'CustomHistory'

const { Panel } = Collapse

const FAQsModelNew = () => {
  const {
    dispatch,
    state: {
      currentUser: { completedSteps },
      isMobile,
    },
  } = useContext(AppContext)
  const [openCreateTicketModel, setOpenCreateATicketModel] = useState(false)
  const [ticketLoading, setTicketLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const { data, loading, error } = useQuery(GET_ALL_FAQUESTION, {
    fetchPolicy: 'network-only',
  })

  const {
    loading: imageLoading,
    error: imageError,
    data: imageData,
  } = useQuery(GET_IMAGES, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!imageLoading) {
      setImageUrl(imageData?.getAllLoginImages[0]?.imageUrl)
    }
  }, [imageLoading, imageData])

  if (error || imageError) return <Page404 />

  const handleCreateTicket = (values, resetForm) => {
    setTicketLoading(true)
    let files = []
    if (values?.fileUrl) {
      values.fileUrl.forEach((file) => {
        files.push(file.originFileObj)
      })
      values.fileUrl = files
    } else {
      delete values.fileUrl
    }

    client
      .mutate({
        mutation: CREATE_TICKET,
        variables: { data: { ...values } },
      })
      .then((res) => {
        notification.success({
          duration: 2.5,
          message: (
            <>
              Ticket created Successfully
              <br />
              {`Note Your Ticket Number.:${res?.data?.createTicket?.ticketNumber}`}
            </>
          ),
        })
        setOpenCreateATicketModel(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setTicketLoading(false))
  }

  return (
    <>
      {loading || imageLoading ? (
        <Loader />
      ) : (
        <Row className='row-style'>
          <Col sm={24} lg={16} className='col-left disclaimer-col '>
            <div>
              <CustomMeta title='FAQs' />
              <div>
                <div>
                  <div className='faqs_page'>
                    <Button
                      className='back-btn'
                      size='middle'
                      icon={<AngleSmallLeft />}
                      onClick={() => {
                        if (completedSteps !== 'DONE') {
                          dispatch({ type: 'SHOW_FAQ_SCREEN', data: false })
                        } else {
                          history.goBack()
                        }
                      }}
                    >
                      <span className='back-text'>Back</span>
                    </Button>
                  </div>
                  <div className='faqs-questions-div'>
                    <div>
                      <Collapse
                        accordion
                        bordered={false}
                        expandIcon={({ isActive }) =>
                          isActive ? <AngleSmallUp /> : <AngleSmallDown />
                        }
                      >
                        {data?.getAllFAQuestions &&
                          data?.getAllFAQuestions?.map((item, key) => (
                            <Panel
                              className='faq-Container'
                              header={
                                <div
                                  className='ant-collapse-header quedtion-container'
                                  style={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: isMobile ? '5px' : '10px',

                                    top: isMobile ? '0px' : '15px',
                                    textWrap: 'pretty',
                                    width: isMobile ? '85%' : '100%',
                                  }}
                                >
                                  <span
                                    className='faq_questions_answers'
                                    style={{
                                      fontSize: isMobile ? '14px' : '16px',
                                      position: 'absolute',
                                    }}
                                  >
                                    {item?.question}
                                  </span>
                                </div>
                              }
                              key={key}
                              style={{
                                padding: isMobile ? '4px' : '0px',
                              }}
                            >
                              <p className='faq_answer'>{item?.answer}</p>
                            </Panel>
                          ))}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            sm={24}
            lg={8}
            className='col-right disclaimer-col .disclaimer-col-image'
          >
            <div
              style={{
                height: isMobile ? 'auto' : '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={imageUrl} alt='img' />
            </div>
          </Col>
        </Row>
      )}

      {openCreateTicketModel && (
        <CreateTicketModal
          visible={openCreateTicketModel}
          loading={ticketLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setOpenCreateATicketModel(false)}
          fromFaq={true}
        />
      )}
    </>
  )
}

export default FAQsModelNew
