import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { Chart } from 'react-google-charts'
import { NavLink } from 'react-router-dom'
import GraphChart from 'assets/images/new-images/graph.svg'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import history from 'CustomHistory'
import { AppContext } from 'AppContext'

const Overview = ({ clientData, ledgerReport }) => {
  const {
    state: { isClient },
  } = useContext(AppContext)
  const {
    ClientCode,
    ClientName,
    ReportDate,
    TotalExposure,
    PnlAfterInterest,
    NLV,
    NIFTYEntry,
    NIFTY,
    InvestmentDate,
    PnlWithOutInterest,
    InterestSaved,
    StockReceived,
    LedgerBalance,
    AbsReturn,
    AnaualReturnInterest,
    AbsReturnIdx,
    AnualReturnIdx,
    clientId,
    strategy,
  } = clientData

  const options = {
    legend: { position: 'none' },
    tooltip: { isHtml: true },
    hAxis: {
      minValue: 0,
    },
    vAxis: {
      minValue: 0,
      format: 'decimal',
      maxAlternation: 0,
    },
  }

  const modifyData = (data) => {
    const decimalPart = data.toString().split('.')[1]
    let number = +data
    if (decimalPart && decimalPart.length === 1) {
      number = data + 0.01
    }

    return +number.toFixed(2)
  }

  const absoluteChartData = [
    [
      'Element',
      'Absolute Return',
      { role: 'style' },
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    [
      `Strategy ${modifyData(AbsReturn || 0)} %`,
      modifyData(AbsReturn || 0),
      '#3E97FF',
      `<div style="font-weight: bold;width:200px;height:50px;font-size:16px;display:flex;justify-content:center;align-items:center;">Strategy: ${modifyData(
        AbsReturn || 0
      )} %</div>`,
    ],
    [
      `Index ${modifyData(AbsReturnIdx || 0)} %`,
      modifyData(AbsReturnIdx || 0),
      '#50CD89',
      `<div style="font-weight: bold;width:200px;height:50px;font-size:16px;display:flex;justify-content:center;align-items:center;">Index: ${modifyData(
        AbsReturnIdx || 0
      )} %</div>`,
    ],
  ]

  const annualChartData = [
    [
      'Element',
      'Annual Return',
      { role: 'style' },
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    [
      `Strategy ${modifyData(AnaualReturnInterest || 0)} %`,
      modifyData(AnaualReturnInterest || 0),
      '#3E97FF',
      `<div style="font-weight:bold;width:200px;height:50px;font-size:16px;display:flex;justify-content:center;align-items:center;">Strategy : ${modifyData(
        AnaualReturnInterest || 0
      )} %</div>`,
    ],
    [
      `Index ${modifyData(AnualReturnIdx || 0)} %`,
      modifyData(AnualReturnIdx || 0),
      '#50CD89',
      `<div style="font-weight:bold;width:200px;height:50px;font-size:16px;display:flex;justify-content:center;align-items:center;">Index : ${modifyData(
        AnualReturnIdx || 0
      )} %</div>`,
    ],
  ]

  let NetInvestment
  if (ledgerReport?.length) {
    let data = ledgerReport.map((report, key) => {
      return { key, ...report }
    })

    NetInvestment =
      data.slice(data.length - 1)[0].CR_Amount -
      data.slice(data.length - 1)[0].DR_Amount
  }

  const handleChartClick = (chartWrapper) => {
    const chart = chartWrapper.getChart()
    const google = window.google

    google.visualization.events.addListener(chart, 'select', () => {
      const selection = chart.getSelection()
      if (selection.length) {
        const row = selection[0].row
        const clickedElement = absoluteChartData[row + 1][0]
        if (clickedElement.includes('Strategy')) {
          const dynamicPath = `/clients/${clientId}/monthly-exposure/${ClientCode.replace(
            'ILTS',
            ''
          ).trim()}/${PnlAfterInterest}?strategy=${strategy}`
          history.push(dynamicPath)
        }
      }
    })
  }

  const handleAnotherChartClick = (chartWrapper) => {
    const chart = chartWrapper.getChart()
    const google = window.google

    google.visualization.events.addListener(chart, 'select', () => {
      const selection = chart.getSelection()
      if (selection.length) {
        const row = selection[0].row
        const clickedElement = annualChartData[row + 1][0]
        if (clickedElement.includes('Strategy')) {
          const dynamicPath = `/clients/${clientId}/monthly-return/${ClientCode.replace(
            'ILTS',
            ''
          ).trim()}?strategy=${strategy}`
          history.push(dynamicPath)
        }
      }
    })
  }
  const onClickReport = (e) => {
    // if (!isClient) {
    if (e === 'annual') {
      const dynamicPath = `/clients/${clientId}/monthly-return/${ClientCode.replace(
        'ILTS',
        ''
      ).trim()}?strategy=${strategy}`
      history.push(dynamicPath)
    } else {
      const dynamicPath = `/clients/${clientId}/monthly-exposure/${ClientCode.replace(
        'ILTS',
        ''
      ).trim()}/${PnlAfterInterest}?strategy=${strategy}`
      history.push(dynamicPath)
    }
    // }
  }
  return (
    <>
      <div className='report_first_Container'>
        <div className='report_Inner_Container'>
          <div className='Client_Info_Container'>
            <div className='client_Info'>
              Client Code:&nbsp;
              <span>{ClientCode}</span>
            </div>
            <div className='client_Info'>
              Client Name:&nbsp;
              <span>{ClientName}</span>
            </div>
          </div>
          <div className='client_Info'>
            {' '}
            Price Last Updated:&nbsp;
            <span>{ReportDate}</span>
          </div>
        </div>
      </div>

      <div className='performance_Overview'>
        <div className='performance_Overview_Data'>
          <div className='performance_Overview_Data_Heading'>
            Current Exposure{' '}
            <div className='performance_Overview_Data_Values'>
              ₹ {convertNumberToCommaSeparate(Math.round(TotalExposure))}
            </div>
          </div>
          <div className='performance_Overview_Data_Heading'>
            Net Profit{' '}
            <div className='performance_Overview_Data_Values'>
              ₹ {convertNumberToCommaSeparate(Math.round(PnlAfterInterest))}
            </div>
          </div>
          <div className='performance_Overview_Data_Heading'>
            Net Liquidation Value{' '}
            <div className='performance_Overview_Data_Values'>
              ₹ {convertNumberToCommaSeparate(Math.round(NLV))}
            </div>
          </div>
          <div
            className='performance_Overview_Data_Heading'
            style={{ borderBottom: 'none', borderRight: 'none' }}
          >
            Net Investment{' '}
            <div className='performance_Overview_Data_Values'>
              ₹ {convertNumberToCommaSeparate(Math.round(NetInvestment))}
            </div>
          </div>
        </div>
      </div>

      <Row
        className='analysis_graph_row'
        gutter={16}
        style={{ margin: '16px' }}
      >
        <Col className='analysis_graph_col' sm={24} md={12} lg={8}>
          <div className='scenario_analysis_graph'>
            <div
              className='scenario_analysis_graph_Heading'
              onClick={() => {
                onClickReport('annual')
              }}
            >
              <u style={{ cursor: 'pointer', color: '#3E97FF' }}>
                Annual Return
              </u>
            </div>
            <Chart
              height={'400px'}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              chartType='ColumnChart'
              options={options}
              data={annualChartData}
              chartEvents={
                !isClient
                  ? [
                      {
                        eventName: 'ready',
                        callback: ({ chartWrapper }) =>
                          handleAnotherChartClick(chartWrapper),
                      },
                    ]
                  : []
              }
            />
          </div>
        </Col>
        <Col sm={24} md={12} lg={8} className='analysis_graph_col'>
          <div className='scenario_analysis_graph'>
            <div
              className='scenario_analysis_graph_Heading'
              onClick={() => onClickReport()}
            >
              <u style={{ cursor: 'pointer', color: '#3E97FF' }}>
                Absolute Return
              </u>
            </div>
            <Chart
              height={'400px'}
              chartType='ColumnChart'
              options={options}
              data={absoluteChartData}
              chartEvents={
                !isClient
                  ? [
                      {
                        eventName: 'ready',
                        callback: ({ chartWrapper }) =>
                          handleChartClick(chartWrapper),
                      },
                    ]
                  : []
              }
            />
          </div>
        </Col>
        <Col sm={24} md={12} lg={8} className='analysis_graph_col'>
          <div className='scenario_analysis_graph'>
            <div
              className='scenario_analysis_graph_Heading'
              style={{ marginBottom: '25px' }}
            >
              Strategy Performance
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <div>
                <div className='strategy_Performance'>Entry</div>
                <div className='strategy_Performance_Date'>
                  {InvestmentDate}
                </div>
                <div
                  className='strategy_Performance_Price>'
                  style={{
                    color: '#000000',
                  }}
                >
                  <NavLink
                    className='nav-link-redirect'
                    to={`/clients/${clientId}/nifty-entry/${ClientCode.replace(
                      'ILTS',
                      ''
                    ).trim()}?strategy=${strategy ? strategy : 'ILTS'}`}
                  >
                    <u style={{ cursor: 'pointer', color: '#3E97FF' }}>
                      {' '}
                      ₹{convertNumberToCommaSeparate(Math.round(NIFTYEntry))}
                    </u>
                  </NavLink>
                </div>
              </div>
              <div>
                <img src={GraphChart} alt='GraphChart' />
              </div>
              <div>
                <div className='strategy_Performance'>Current</div>
                <div className='strategy_Performance_Date'>{ReportDate}</div>
                <div
                  className='strategy_Performance_Price>'
                  style={{
                    color: '#50CD89',
                  }}
                >
                  ₹{convertNumberToCommaSeparate(Math.round(NIFTY))}
                </div>
              </div>
            </div>
            <hr style={{ margin: '20px 0', border: '1px solid #F1F1F2' }} />

            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    fontSize: ' 14px',
                    fontWeight: ' 400',
                    lineHeight: ' 18px',
                    letterSpacing: ' -0.01em',
                    textAlign: ' left',
                    color: '#858585',
                  }}
                >
                  Profit without Interest
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '14px',
                    textAlign: 'left',
                    color: '#000000',
                  }}
                >
                  ₹{' '}
                  {convertNumberToCommaSeparate(Math.round(PnlWithOutInterest))}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    fontSize: ' 14px',
                    fontWeight: ' 400',
                    lineHeight: ' 18px',
                    letterSpacing: ' -0.01em',
                    textAlign: ' left',
                    color: '#858585',
                  }}
                >
                  Saving of Interest
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '14px',
                    textAlign: 'left',
                    color: '#000000',
                  }}
                >
                  <NavLink
                    className='nav-link-redirect'
                    to={`/clients/${clientId}/saving-of-interest/${ClientCode.replace(
                      'ILTS',
                      ''
                    ).trim()}?strategy=${strategy}`}
                  >
                    <u style={{ cursor: 'pointer', color: '#3E97FF' }}>
                      ₹{' '}
                      {convertNumberToCommaSeparate(Math.round(InterestSaved))}
                    </u>
                  </NavLink>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    fontSize: ' 14px',
                    fontWeight: ' 400',
                    lineHeight: ' 18px',
                    letterSpacing: ' -0.01em',
                    textAlign: ' left',
                    color: '#858585',
                  }}
                >
                  Collateral Value
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '14px',
                    textAlign: 'left',
                    color: '#000000',
                  }}
                >
                  ₹ {convertNumberToCommaSeparate(Math.round(StockReceived))}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div
                  style={{
                    fontSize: ' 14px',
                    fontWeight: ' 400',
                    lineHeight: ' 18px',
                    letterSpacing: ' -0.01em',
                    textAlign: ' left',
                    color: '#858585',
                  }}
                >
                  Ledger Balance
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '14px',
                    textAlign: 'left',
                    color: '#000000',
                  }}
                >
                  ₹ {convertNumberToCommaSeparate(Math.round(LedgerBalance))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Overview
