import React, { useReducer, createContext } from 'react'

const initialState = {
  currentUser: {},
  currentReport: [],
  isAdmin: false,
  isStaff: false,
  isClient: false,
  isAssociate: false,
  isSalesExecutive: false,
  isMasterAssociate: false,
  selectedGroup: undefined,
  selectedStrategy: 'ILTS',
  isVerificationDone: false,
  MaintenanceStatus: {},
  invoiceSetting: {},
  generalSetting: {},
  clientSummaryDetails: [],
  clientRiskScore: 0,
  showReviewPlan: false,
  showFAQScreen: false,
  showFooterBtn: {
    isConfirm: false,
    isPendingAccept: false,
    isPendingReject: false,
    isAdvisoryAccept: false,
    isAdvisoryReject: false,
  },
  isAcceptLoading: false,
  isResubmitLoading: false,
  isFileUpload: false,
  isDisplayMultipleAdvisoryAction: true,
  isMobile: window.innerWidth < 749,
  allNotificationsForClient: { notifications: [], count: 0, notReadCount: 0 },
  selectedAssociate: undefined,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'CURRENT_USER':
      return { ...state, currentUser: action.data }
    case 'CURRENT_REPORT':
      return { ...state, currentReport: action.data }
    case 'SELECTED_GROUP':
      return { ...state, selectedGroup: action.data }
    case 'SELECTED_STRATEGY':
      return { ...state, selectedStrategy: action.data }
    case 'IS_ADMIN':
      return { ...state, isAdmin: action.data }
    case 'IS_STAFF':
      return { ...state, isStaff: action.data }
    case 'IS_MASTER_ASSOCIATE':
      return { ...state, isMasterAssociate: action.data }
    case 'IS_ASSOCIATE':
      return { ...state, isAssociate: action.data }
    case 'IS_SALES_EXECUTIVE':
      return { ...state, isSalesExecutive: action.data }
    case 'IS_CLIENT':
      return { ...state, isClient: action.data }
    case 'IS_VERIFICATION_DONE':
      return { ...state, isVerificationDone: action.data }
    case 'MAINTENANCE_STATUS':
      return { ...state, MaintenanceStatus: action.data }
    case 'INVOICE_SETTING':
      return { ...state, invoiceSetting: action.data }
    case 'GENERAL_SETTING':
      return { ...state, generalSetting: action.data }
    case 'CLIENT_SUMMARY_DETAILS':
      return { ...state, clientSummaryDetails: action.data }
    case 'CLIENT_RISK_SCORE':
      return { ...state, clientRiskScore: action.data }
    case 'SHOW_REVIEW_PLAN':
      return { ...state, showReviewPlan: action.data }
    case 'SHOW_FAQ_SCREEN':
      return { ...state, showFAQScreen: action.data }
    case 'SHOW_FOOTER_BTN':
      return { ...state, showFooterBtn: action.data }
    case 'IS_ACCEPT_LOADING':
      return { ...state, isAcceptLoading: action.data }
    case 'IS_RESUBMIT_LOADING':
      return { ...state, isResubmitLoading: action.data }
    case 'IS_FILE_UPLOAD':
      return { ...state, isFileUpload: action.data }
    case 'IS_DISPLAY_MULTIPLE_ADVISORY_ACTION':
      return { ...state, isDisplayMultipleAdvisoryAction: action.data }
    case 'IS_MOBILE':
      return { ...state, isMobile: action.data }
    case 'ALL_NOTIFICATIONS_FOR_CLIENT':
      return { ...state, allNotificationsForClient: action.data }
    case 'SELECTED_ASSOCIATE':
      return { ...state, selectedAssociate: action.data }
    default:
      return {}
  }
}

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
})

function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
}

const AppContextConsumer = AppContext.Consumer

export { AppContext, AppContextProvider, AppContextConsumer }
