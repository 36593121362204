/* eslint-disable array-callback-return */
import React, { useContext, useState } from 'react'
import { Button, Col, Form, Row, Tabs } from 'antd'
import { NavLink } from 'react-router-dom'

import SystemUsers from 'modules/Users/components/SystemUsers'
import UserClients from 'modules/Users/components/UserClients'
import { userDocuments } from 'utils/User'
import { AppContext } from 'AppContext'
// import AMC from 'modules/Clients/AMC'
// import AMCNew from 'modules/Clients/AMC/AMCNew'
import SIP from 'modules/Clients/SIP'
// import AboutItem from './AboutItem'
// import Widget from '../Widget'
import UserEMandate from 'modules/Users/components/UserEMandate'
import UserQuestionnaire from 'modules/Users/components/UserQuestionnaire'
import { pdfjs } from 'react-pdf'
import { useQuery } from '@apollo/react-hooks'
import RationalStatus from 'components/RationalStatus'
import Loader from 'components/loaders/Loader'
import UploadFileModal from './uploadFileModal'
import { GET_USER_PDF } from 'modules/Plans/graphql/Query'
import DigioAutoRequest from './DigioAutoRequest'
// import AboutItemNew from './AboutItemNew'
import { ReactComponent as File } from 'assets/images/new-images/file.svg'
import { ReactComponent as Marker } from 'assets/images/new-images/marker.svg'
import { ReactComponent as AccountDetails } from 'assets/images/new-images/account-details-icon.svg'
import { ReactComponent as UserDetails } from 'assets/images/new-images/userDetails.svg'
import bankDetails from 'assets/images/new-images/pending-amc-icon.png'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import moment from 'moment'

const TabPane = Tabs.TabPane
export default function AboutNew(props) {
  const {
    details,
    userId,
    isProfile,
    id,
    details: { annualIncome },
  } = props
  const {
    state: {
      isClient,
      isAdmin,
      currentUser: { role },
    },
  } = useContext(AppContext)
  // const aboutList = userDetails(details)
  const documentList = userDocuments(details.kyc, isClient)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfType, setPDFType] = useState({
    type: '',
    notify: '',
    updateField: '',
  })
  const [uploadFileValues, setUploadFileValues] = useState({})

  const [form] = Form.useForm()

  const showModal = (type, notify, updateField) => {
    setIsModalVisible(true)
    setUploadFileValues({})

    setPDFType({
      type,
      notify,
      updateField,
    })
  }
  const { data, loading } = useQuery(GET_USER_PDF, {
    variables: { userId: parseInt(id) },
    fetchPolicy: 'network-only',
  })

  const PDFs = data?.usersPdf
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const importBulkAmcsModalClose = () => {
    setIsModalVisible(false)
  }
  const renderDetailsSection = (title, icon, details) => {
    return (
      <div className='user-details-section'>
        <h3>
          <span className='icon'>{icon}</span>
          <span
            style={{
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              color: '#AF0000',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {title}
          </span>
        </h3>
        <Row className='detail-row'>
          {details.map((detail, index) => {
            return (
              <Col key={index} className='detail-item'>
                <span>{detail.label}</span>
                <p>
                  {detail.label === 'Initial Exposure'
                    ? convertNumberToCommaSeparate(detail.value)
                    : detail.label === 'Phone Number'
                    ? `+91${detail.value}`
                    : detail.value || '-'}
                </p>
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }

  if (loading) return <Loader />
  return (
    <>
      {isModalVisible && (
        <UploadFileModal
          type={pdfType?.type}
          notify={pdfType?.notify}
          updateField={pdfType?.updateField}
          id={id}
          setIsModalVisible={setIsModalVisible}
          importBulkAmcsModalClose={importBulkAmcsModalClose}
          uploadFileValues={uploadFileValues}
          setUploadFileValues={setUploadFileValues}
        />
      )}

      <div className='profile-title'>
        <div>
          <label style={{ fontSize: '24px', marginLeft: '24px' }}>
            User Profile
          </label>
        </div>
        {isAdmin ? (
          <div className='gx-profile-banner-top-right user-profile-edit-button'>
            <NavLink to='/profile/edit' className='nav-text'>
              <span className='gx-link gx-profile-setting'>
                <i className='icon icon-setting gx-fs-lg gx-mr-1 gx-mr-sm-2 gx-d-inline-flex gx-vertical-align-middle setting-icon' />
                <span className='gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0 edit-settings-text'>
                  Edit Settings
                </span>
              </span>
            </NavLink>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <Widget title=''> */}
      <div className='tab-details'>
        <Tabs defaultActiveKey='1'>
          {role === 'ADMIN' && (
            <TabPane tab='CVL File' key='1'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='cvlReportStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'cvlReportStatus',
                            'CVL Status',
                            'cvlReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'cvlReportStatus')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%', marginBottom: '2px' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Risk Assessment' key='2'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='riskAssessment'>
                      <Button
                        onClick={() =>
                          showModal(
                            'riskAssessment',
                            'RiskAssessment Report',
                            'riskSuitabilityReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'riskAssessment')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Suitability' key='3'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='suitabilityReport'>
                      <Button
                        onClick={() =>
                          showModal(
                            'suitabilityReport',
                            'Suitability Report',
                            'suitabilityReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'suitabilityReport')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Agreement Report' key='4'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='agreementReport'>
                      <Button
                        onClick={() =>
                          showModal(
                            'agreementReport',
                            'Agreement Report',
                            'agreementReportStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div className='gx-mb-2'>
                  {PDFs &&
                    PDFs?.length !== 0 &&
                    PDFs.map((pdf, key) => {
                      if (pdf.type === 'agreementReport')
                        return (
                          <RationalStatus
                            pdf={pdf}
                            key={key}
                            id={id}
                            style={{ width: '100%' }}
                          />
                        )
                    })}
                </div>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Invoice' key='5'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='invoiceStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'invoiceStatus',
                            'Invoice Status',
                            'invoiceStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className='gx-mb-2'>
                {PDFs &&
                  PDFs?.length !== 0 &&
                  PDFs.map((pdf, key) => {
                    if (pdf.type === 'invoiceStatus')
                      return (
                        <RationalStatus
                          pdf={pdf}
                          key={key}
                          id={id}
                          style={{ width: '100%' }}
                        />
                      )
                  })}
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Rational' key='6'>
              <div>
                <Form
                  form={form}
                  name='register'
                  className='user-form'
                  scrollToFirstError
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginRight: '30px',
                      marginBottom: '20px',
                    }}
                  >
                    <Form.Item name='rationalStatus'>
                      <Button
                        onClick={() =>
                          showModal(
                            'rationalStatus',
                            'Rational Status',
                            'rationalStatus'
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className='gx-mb-2'>
                {PDFs &&
                  PDFs?.length !== 0 &&
                  PDFs.map((pdf, key) => {
                    if (pdf.type === 'rationalStatus')
                      return (
                        <RationalStatus
                          pdf={pdf}
                          key={key}
                          id={id}
                          style={{ width: '100%' }}
                        />
                      )
                  })}
              </div>
            </TabPane>
          )}
          {!isProfile &&
            details.role === 'CLIENT' &&
            props?.details?.mandateId && (
              <TabPane tab='E-mandate Report' key='7'>
                <div className='gx-mb-2'>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <UserEMandate userId={parseInt(props?.details?.id)} />
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          {/* {details.role === 'CLIENT' && (
            <TabPane tab='AMC' key='8'>
              <div className='gx-mb-2'>
                <Row>
                  <AMCNew {...details} />
                </Row>
              </div>
            </TabPane>
          )} */}
          <TabPane tab='Overview' key='9'>
            <div className='gx-mb-2'>
              {renderDetailsSection(
                'User Details',
                <UserDetails />,
                isClient
                  ? [
                      {
                        label: 'User Name',
                        value: details.userName ? details.userName : '-',
                      },
                      {
                        label: 'Client Code',
                        value: details.clientCode ? details.clientCode : '-',
                      },
                    ]
                  : [
                      {
                        label: 'User Name',
                        value: details.userName ? details.userName : '-',
                      },
                      {
                        label: 'Associate Type',
                        value: details.role ? details.role : '-',
                      },
                      {
                        label: 'Create Date',
                        value: details.createdAt
                          ? moment(details.createdAt).format('DD-MM-YYYY')
                          : '-',
                      },
                    ]
              )}
              {renderDetailsSection(
                'Personal Details',
                <File />,
                isClient
                  ? [
                      {
                        label: 'First Name',
                        value: details.firstName ? details.firstName : '-',
                      },
                      {
                        label: 'Last Name',
                        value: details.lastName ? details.lastName : '-',
                      },
                      {
                        label: 'Date of Birth',
                        value: details.dob
                          ? moment(details.dob).format('DD-MM-YYYY')
                          : '-',
                      },
                      {
                        label: 'Email Address',
                        value: details.email ? details.email : '-',
                      },
                      {
                        label: 'Other Mail',
                        value: details.otherMailIds
                          ? details.otherMailIds
                          : '-',
                      },
                      {
                        label: 'Phone Number',
                        value: details.phone
                          ? details.phone.replace(/\d(?=\d{4})/g, 'X')
                          : '-',
                      },
                    ]
                  : [
                      {
                        label: 'First Name',
                        value: details.firstName ? details.firstName : '-',
                      },
                      {
                        label: 'Last Name',
                        value: details.lastName ? details.lastName : '-',
                      },
                      { label: 'User Name', value: details.userName },
                      { label: 'Email Address', value: details.email },
                      {
                        label: 'Other Mail',
                        value: details.otherMailIds
                          ? details.otherMailIds
                          : '-',
                      },
                      {
                        label: 'Phone Number',
                        value: details.phone
                          ? details.phone.replace(/\d(?=\d{4})/g, 'X')
                          : '-',
                      },
                    ]
              )}
              {renderDetailsSection('Address', <Marker />, [
                {
                  label: 'Address',
                  value: details.address ? details.address : '-',
                },
              ])}
              {!isClient &&
                renderDetailsSection(
                  'Bank Details',
                  <img
                    src={bankDetails}
                    alt='BankDetails'
                    className='company-logo'
                  />,
                  [
                    {
                      label: 'Bank Name',
                      value: details.bank ? details.bank : '-',
                    },
                    {
                      label: 'Account Number',
                      value: details.accountNumber
                        ? details.accountNumber.replace(/\d(?=\d{4})/g, 'x')
                        : '-',
                    },
                    {
                      label: 'IFSC Code ',
                      value: details.ifscCode ? details.ifscCode : '-',
                    },
                    {
                      label: 'Bank Address',
                      value: details.bankAddress ? details.bankAddress : '-',
                    },
                  ]
                )}
              {!isClient &&
                renderDetailsSection('Account Details', <AccountDetails />, [
                  {
                    label: 'Deposit Date',
                    value: details.depositDate
                      ? moment(details.depositDate).format('DD-MM-YYYY')
                      : '-',
                  },
                  {
                    label: 'Deposit Amount',
                    value: details.depositAmount ? details.depositAmount : '-',
                  },
                  {
                    label: 'GSTIN Number',
                    value: details.gstinNumber
                      ? details.gstinNumber.replace(/\w(?=\w{4})/g, 'x')
                      : '-',
                  },
                ])}
              {/* <Row>
                {aboutList.map((about, index) => (
                  <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem {...about} />
                  </Col>
                ))}
              </Row> */}
            </div>
          </TabPane>
          {!isProfile &&
            details.role === 'CLIENT' &&
            props?.details?.plan?.id && (
              <TabPane tab='Questionnaire' key='10'>
                <div className='gx-mb-2'>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <UserQuestionnaire
                        annualIncome={annualIncome}
                        userId={parseInt(props?.details?.id)}
                        planId={props?.details?.plan?.id}
                      />
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          {details.role === 'CLIENT' && (
            <TabPane tab='Documents' key='11'>
              <div className='gx-mb-2'>
                <div className='gx-mb-2'>
                  <Row gutter={16}>
                    <Row gutter={[16, 16]} justify='center'>
                      {documentList.map((doc, index) => {
                        return (
                          <Col span={6} style={{ margin: '10px 0' }}>
                            {doc?.desc?.props?.href !== undefined && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <img
                                    src={doc?.desc?.props?.href}
                                    alt={''}
                                    height={50}
                                    width={50}
                                    style={{ borderRadius: '5px' }}
                                  />
                                </div>
                                <span className='kyc_doc_display'>
                                  {doc.title}
                                </span>
                              </>
                            )}
                          </Col>
                        )
                      })}
                    </Row>
                  </Row>
                </div>

                {/* <Row>
                  {documentList.map((doc, index) => (
                    <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                      <AboutItem {...doc} />
                    </Col>
                  ))}
                </Row> */}
              </div>
            </TabPane>
          )}
          {details.role === 'CLIENT' && (
            <TabPane tab='SIP' key='12'>
              <div className='gx-mb-2'>
                <Row>
                  <SIP {...details} />
                </Row>
              </div>
            </TabPane>
          )}
          {!isProfile && details.role !== 'CLIENT' && (
            <TabPane tab='Users' key='13'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <SystemUsers userId={parseInt(userId)} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {!isProfile && details.role !== 'CLIENT' && (
            <TabPane tab='Clients' key='14'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <UserClients userId={parseInt(userId)} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {role === 'ADMIN' && (
            <TabPane tab='Digio Auto Debit Request' key='15'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <DigioAutoRequest id={id} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
          {/* {!isProfile && !isClient && role === 'ADMIN' && (
            <TabPane tab='Revenue Income' key='15'>
              <div className='gx-mb-2'>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <UserRevenue userId={userId} />
                  </Col>
                </Row>
              </div>
            </TabPane>
          )} */}
        </Tabs>
      </div>

      {/* </Widget> */}
    </>
  )
}
