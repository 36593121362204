import { useEffect, useRef } from 'react'

const useWebSocket = (url) => {
  const wsRef = useRef(null)
  const reconnectInterval = useRef(null)

  useEffect(() => {
    const connectWebSocket = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        return // Already connected
      }

      wsRef.current = new WebSocket(url)

      wsRef.current.onopen = () => {
        clearTimeout(reconnectInterval.current) // Stop reconnection attempts
      }

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data)
          if (data.event === 'deployment-randomhash') {
            // Clear cache & refresh
            caches.keys().then((names) => {
              names.forEach((name) => caches.delete(name))
            })

            window.location.reload(true)
          }
        } catch (error) {}
      }

      wsRef.current.onerror = (error) => {}

      wsRef.current.onclose = () => {
        reconnectInterval.current = setTimeout(connectWebSocket, 10000) // Retry every 3s
      }
    }

    connectWebSocket()

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
      clearTimeout(reconnectInterval.current) // Ensure no memory leaks
    }
  }, [url])

  return wsRef
}

export default useWebSocket
