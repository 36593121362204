import React, { useContext, useState } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { EditTwoTone, DeleteOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_FAQUESTION } from 'modules/Verification/graphql/Queries'
import Page404 from 'components/Page404'
import CreateFAQuestionModel from './CreateFAQuestionModel'
import client from 'apollo'
import {
  CREATE_FAQ_QUESTION,
  DELETE_FAQ_QUESTION,
  UPDATE_FAQ_QUESTION,
} from './graphql/Mutation'
import { GET_FAQ_QUESTION } from './graphql/Query'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'

const FAQuestions = () => {
  const [openModel, setOpenModel] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)
  const [questionId, setQuestionId] = useState(null)
  const [questionData, setQuestionData] = useState({})

  const { data, loading, error } = useQuery(GET_ALL_FAQUESTION, {
    fetchPolicy: 'network-only',
  })
  const {
    state: { isMobile },
  } = useContext(AppContext)
  if (error) return <Page404 />

  const getQuestionById = (id) => {
    setQuestionLoading(true)
    client
      .query({
        query: GET_FAQ_QUESTION,
        variables: { where: { id } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setQuestionLoading(false)
        setQuestionData(res?.data?.getFAQuestion)
        setQuestionId(id)
        setOpenModel(!openModel)
      })
      .catch((err) => {
        console.log(err)
        setQuestionLoading(false)
      })
  }

  const openQuestionModel = (id) => {
    if (id) {
      getQuestionById(id)
    } else {
      setOpenModel(!openModel)
    }
  }

  const handleCancel = () => {
    setOpenModel(false)
    setQuestionId(null)
    setQuestionData({})
  }

  const renderAction = (record) => {
    const { id } = record
    return (
      <div className='action-icons'>
        <Tooltip title='Edit'>
          <EditTwoTone onClick={() => openQuestionModel(id)} />
        </Tooltip>
        <Tooltip title='Delete'>
          <DeleteOutlined onClick={() => deleteQuestion(id)} />
        </Tooltip>
      </div>
    )
  }

  const createQuestion = (data, resetForm) => {
    setSubmitLoading(true)
    client
      .mutate({
        mutation: CREATE_FAQ_QUESTION,
        variables: { data },
        refetchQueries: [
          {
            query: GET_ALL_FAQUESTION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
        openNotification('success', 'Question Created Successfully')
      })
      .catch((err) => {
        console.log(err)
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
        openNotification('error', err)
      })
      .finally(() => {
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
      })
  }

  const updateQuestion = (data, resetForm, id) => {
    setSubmitLoading(true)
    client
      .mutate({
        mutation: UPDATE_FAQ_QUESTION,
        variables: { data, where: { id } },
        refetchQueries: [
          {
            query: GET_ALL_FAQUESTION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
        setQuestionId(null)
        openNotification('success', 'Question Updated Successfully')
      })
      .catch((err) => {
        console.log(err)
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
        setQuestionId(null)
        openNotification('error', err)
      })
      .finally(() => {
        setSubmitLoading(false)
        resetForm()
        setOpenModel(false)
        setQuestionId(null)
      })
  }

  const submitQuestion = (values, resetForm) => {
    if (questionId) {
      updateQuestion(values, resetForm, questionId)
    } else {
      createQuestion(values, resetForm)
    }
  }

  const deleteQuestion = (id) => {
    client
      .mutate({
        mutation: DELETE_FAQ_QUESTION,
        variables: { where: { id } },
        refetchQueries: [
          {
            query: GET_ALL_FAQUESTION,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'FAQuestion Deleted Successfully')
      })
      .catch((err) => {
        console.log(err)
        openNotification('error', err)
      })
  }

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: 100,
    },
  ]

  return (
    <>
      <CustomMeta title='FAQ Questions' />
      <div className='main_dashboard_div'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: isMobile ? '18px' : '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            FAQs
          </div>

          <Button
            onClick={() => openQuestionModel(null)}
            style={{
              margin: '0',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#AF0000',
              background: '#AF00000D',
              border: '1px solid #AF000080',
              height: '40px',
              borderRadius: '8px',
            }}
          >
            Create FAQuestion
          </Button>
        </div>
        <Table
          loading={loading}
          dataSource={data?.getAllFAQuestions}
          columns={columns}
          style={{
            overflow: isMobile ? 'scroll !important' : 'auto',
            height: isMobile ? '75vh' : 'auto',
          }}
        />
      </div>

      {openModel && (
        <CreateFAQuestionModel
          isModalOpen={openModel}
          handleCancel={handleCancel}
          questionId={questionId}
          onCreate={submitQuestion}
          questionLoading={questionLoading}
          submitLoading={submitLoading}
          data={questionData}
        />
      )}
    </>
  )
}
export default FAQuestions
