import { Button, Popover, Table } from 'antd'
import client from 'apollo'
import { DownloadOutlined } from '@ant-design/icons'
import {
  GET_FUTURE_AND_OPTIONS_REPORT_DATA,
  GET_TRADER_FO_REPORT,
} from 'modules/Reports/graphql/Queries'
import React, { useContext, useEffect, useState } from 'react'
// import { Table } from 'antd'
// import { NavLink } from 'react-router-dom'
import { convertNumberToCommaSeparate } from 'utils/utilities'
import { get } from 'lodash'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import { CSVLink } from 'react-csv'
import { AppContext } from 'AppContext'

export default function ({ reportData, clientData, id, StrategyCode }) {
  const {
    state: { currentReport, isMobile },
  } = useContext(AppContext)
  const { ClientCode, ClientName } = clientData
  const [exportDataCsvFO, setExportDataCsvFO] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_TRADER_FO_REPORT,
        variables: { where: { clientCode: ClientCode?.split('ILTS')[0] } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setExportDataCsvFO(res?.data?.spTradeReportFO)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataForExportCSVFO = exportDataCsvFO?.map((item) => {
    return {
      script: item?.Script,
      qty: item?.qty,
      rate: item?.rate,
      entrydate: item?.entrydate,
      ClientCode: item?.ClientCode,
    }
  })
  const columns = [
    {
      title: 'Script',
      dataIndex: 'Script',
      key: 'Script',
      className: 'report-text',
      width: '20%',
    },
    {
      title: 'Quantity',
      dataIndex: 'TotalQty',
      key: 'TotalQty',
      className: 'report-numeric',
      width: 80,
    },
    {
      title: 'Trade Rate',
      dataIndex: 'TradedRate',
      key: 'TradedRate',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
      width: 80,
    },
    {
      title: 'CMP',
      dataIndex: 'CMP',
      key: 'CMP',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record, true),
      width: 80,
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record),
      width: 80,
    },
    {
      title: 'Profit / Loss',
      dataIndex: 'Pnl',
      key: 'Pnl',
      className: 'report-numeric',
      render: (record) => convertNumberToCommaSeparate(record),
      width: 100,
    },
  ]

  let data = reportData.map((report, key) => {
    return { key, ...report }
  })

  const handleDownloadPdf = () => {
    setLoading(true)
    client
      .query({
        query: GET_FUTURE_AND_OPTIONS_REPORT_DATA,
        variables: { where: { id: parseInt(id), strategyCode: StrategyCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        get(res, 'data.getFutureAndOptionReport') &&
          window.open(get(res, 'data.getFutureAndOptionReport'), '_blank')
      })
      .catch((err) => console.log(err))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Pdf
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={reportData}
        className='csv-btn'
        filename={`Trade F&O Report ${ClientCode}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='csv' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <div className='report_first_Container'>
        <div className='report_Inner_Container'>
          <div className='Client_Info_Container'>
            <div className='client_Info'>
              Client Code:&nbsp;
              <span>{ClientCode}</span>
            </div>
            <div className='client_Info'>
              Client Name:&nbsp;
              <span>{ClientName}</span>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {currentReport.includes('FO Report All Export As Button') && (
              <CSVLink
                data={dataForExportCSVFO}
                className='ExportAS'
                style={{ marginRight: '10px' }}
                filename={`Trade F&O Report ${ClientCode}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  type='primary'
                  style={{
                    margin: '0',
                    padding: '4px 8px',
                    fontSize: '13px',
                    fontWeight: '600',
                    lineHeight: '15px',
                    textAlign: 'left',
                    color: '#AF0000',
                    background: '#AF00000D',
                    border: '1px solid #AF000080',
                    height: '30px',
                    borderRadius: '8px',
                  }}
                  disabled={loading}
                >
                  Export Trades
                </Button>
              </CSVLink>
            )}
            <Popover content={content} placement='bottom' trigger='click'>
              <Button
                style={{
                  margin: '0',
                  padding: '4px 8px',
                  fontSize: '13px',
                  fontWeight: '600',
                  lineHeight: '15px',
                  textAlign: 'left',
                  color: '#AF0000',
                  background: '#AF00000D',
                  border: '1px solid #AF000080',
                  height: '30px',
                  borderRadius: '8px',
                }}
                loading={loading}
              >
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>
          </div>
        </div>
      </div>
      <Table
        className='futures-options'
        dataSource={data}
        bordered
        columns={columns}
        scroll={{ x: true, y: 500 }}
        style={{
          overflow: 'auto',
          scrollbarWidth: 'none',
          height: isMobile ? '430px' : '60dvh',
          width: isMobile ? '100%' : 'auto',
        }}
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
    </>
  )
}
