import gql from 'graphql-tag'

export const UPDATE_ADVISORY_VALUE = gql`
  mutation UpdateAdvisoryValue(
    $data: [updateAdvisoryValueData]!
    $dateForAdvisory: String
  ) {
    updateAdvisoryValue(data: $data, DateForAdvisory: $dateForAdvisory)
  }
`

export const UPDATE_ADVISORY_STATUS = gql`
  mutation updateAdvisoryStatus($data: [updateAdvisoryStatusData]!) {
    updateAdvisoryStatus(data: $data)
  }
`
