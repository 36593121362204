import React, { useEffect, useState } from 'react'
import IdleJs from 'idle-js'
import { Modal, Button } from 'antd'

const AutoRefresh = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const refreshPage = () => {
    window.location.reload()
  }

  const onIdle = () => {
    setIsModalOpen(true)
  }

  const closeModal = (refresh) => {
    setIsModalOpen(false)
    if (refresh) {
      refreshPage()
    }
  }

  // Check for app version update
  const checkForNewVersion = async () => {
    try {
      const response = await fetch(`/meta.json?v=${new Date().getTime()}`) // Prevent cache
      const data = await response.json()
      const latestVersion = data.version
      const currentVersion = localStorage.getItem('app_version')

      if (currentVersion && latestVersion !== currentVersion) {
        localStorage.setItem('app_version', latestVersion)
        refreshPage() // Hard refresh
      }
    } catch (error) {
      console.error('Error checking version:', error)
    }
  }

  useEffect(() => {
    // IdleJs setup
    const idle = new IdleJs({
      idle: 5 * 60 * 1000, // 5 minutes
      onIdle,
    })
    idle.start()

    // Check for app version every 3 minutes
    const interval = setInterval(checkForNewVersion, 180000)

    // Check when tab is active
    const handleVisibilityChange = () => {
      if (!document.hidden) checkForNewVersion()
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      idle.stop()
      clearInterval(interval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div>
      <Modal
        visible={isModalOpen}
        footer={null}
        centered
        width={'40%'}
        className='auto_refresh_modal'
      >
        <h2 className='modal_text_heading'>You've been idle for a while!</h2>
        <p
          className='book_advisory_text_sub_text'
          style={{ textAlign: 'center' }}
        >
          Do you want to refresh the page?
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type='primary'
            onClick={() => closeModal(true)}
            style={{ width: '20%' }}
            className='advisory_cancel_btn'
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AutoRefresh
