import React from 'react'
import get from 'lodash/get'
import qs from 'query-string'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import { GET_MONTHLY_EXPOSURE_REPORT } from 'modules/Reports/graphql/Queries'
import MonthlyExposureTable from 'modules/Reports/MonthlyExposure/MonthlyExposureTable'
import 'modules/Reports/index.css'
import { GET_USER } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'

export default function (props) {
  let {
    match: {
      params: { clientCode, pnl, clientId },
    },
    history: {
      location: { search },
    },
  } = props
  const parsed = qs.parse(search || {})
  const { strategy = 'ILTS' } = parsed
  const { data, loading, error } = useQuery(GET_MONTHLY_EXPOSURE_REPORT, {
    variables: {
      where: { clientCode, strategy, ii: pnl ? parseInt(pnl) : undefined },
    },
  })
  const {
    data: getUserData,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery(GET_USER, { variables: { id: clientId } })

  if (loading || getUserLoading) return <Loader />
  if (error || getUserError) return <Page404 error={error} />
  if (!get(data, 'spMonthlyExposureReport')) return <NoData />
  const {
    spMonthlyExposureReport: [spMonthlyExposureReport],
  } = data

  return (
    <>
      <CustomMeta title='Monthly Exposure' />
      <DownloadReport
        data={spMonthlyExposureReport}
        fileName='monthly-exposure'
        id='MONTHLY-EXPOSURE-REPORT-PDF'
      />
      <div className='report-table-wrapper' id='MONTHLY-EXPOSURE-REPORT-PDF'>
        <MonthlyExposureTable
          reportData={spMonthlyExposureReport}
          ClientCode={clientCode}
          user={getUserData}
        />
      </div>
    </>
  )
}
