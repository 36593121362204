import React, { useState, useContext } from 'react'
import { Table, DatePicker, Button, Space, Input } from 'antd'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { GET_REVENUE_INCOME } from 'modules/Users/graphql/Queries'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import client from 'apollo'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'

const { RangePicker } = DatePicker

export default function RevenueIncome() {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    state: {
      isAdmin,
      isStaff,
      isMobile,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(
    isAdmin || isStaff ? {} : { userId: id }
  )

  const { data, loading, error } = useQuery(GET_REVENUE_INCOME, {
    variables: { where: filters, limit, skip, sort },
    fetchPolicy: 'no-cache',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getRevenueIncome.RevenueIncome')) {
    totalCount = data.getRevenueIncome.counter
    tableData = data.getRevenueIncome.RevenueIncome.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
    if (!isAdmin && get(data, 'getRevenueIncome.total')) {
      tableData = [
        ...tableData,
        {
          client: 'Total',
          description: data.getRevenueIncome.total,
        },
      ]
    }
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const columns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Amount :{' '}
          {Math.round(data?.getRevenueIncome?.commissionAmountTotal) || 0}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          sorter: (a, b) => a.clientCode - b.clientCode,
          render: (text, record) => (
            <NavLink to={`/clients/${record?.client?.id}`}>
              {record?.client?.clientCode || '-'}
            </NavLink>
          ),
          width: '120px',
          ...getColumnSearchProps('clientCode'),
        },
        {
          title: 'Client Name',
          dataIndex: 'client',
          key: 'client',
          sorter: (a, b) => a.client - b.client,
          render: (client) => (client === 'Total' ? 'Total' : getName(client)),
          ...getColumnSearchProps('clientName'),
          width: '120px',
        },
        {
          title: 'Associate Name',
          dataIndex: 'associateName',
          key: 'associateName',
          sorter: (a, b) => a.associateName - b.associateName,
          render: (text, record) => (
            <NavLink to={`/users/${record?.user?.id}`}>
              {`${record?.user?.firstName} ${record?.user?.lastName}` || '-'}
            </NavLink>
          ),
          ...getColumnSearchProps('associateName'),
          width: '150px',
        },
        {
          title: 'Type',
          dataIndex: 'role',
          key: 'role',
          sorter: (a, b) => a.role - b.role,
          render: (text, record) =>
            record?.user?.role === 'SALES_EXECUTIVE'
              ? 'AIF_SALES_EXECUTIVE'
              : record?.user?.role,
          width: 120,
        },
        {
          title: 'Current Type',
          dataIndex: 'userType',
          key: 'userType',
          sorter: (a, b) => a.userType - b.userType,
          render: (text, record) =>
            record?.userRevenueDetail?.userType === 'Sales Executive'
              ? 'Aif Sales Executive'
              : record?.userRevenueDetail?.userType || '-',
          width: 120,
        },
        {
          title: 'Amc Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record) => record?.amc?.amount,
          width: 120,
        },
        {
          title: 'Invoice commission',
          dataIndex: 'amc',
          key: 'Invoice commission',
          render: (text, record) =>
            record?.amc?.invoice?.commissionInPer ? 'pre-commissioned' : '-',
          width: 120,
        },
        {
          title: 'Amc Paid date',
          dataIndex: 'paidAt',
          key: 'paidAt',
          sorter: (a, b) => a.paidAt - b.paidAt,
          render: (text, record) =>
            record?.amc?.paidAt
              ? moment(record?.amc?.paidAt).format('Do MMMM YYYY')
              : '',
          ...getColumnDateProps('paidAt'),
          width: '140px',
        },
        {
          title: 'Tax Invoice number',
          dataIndex: 'taxInvoiceNumber',
          key: 'taxInvoiceNumber',
          render: (text, record) =>
            record?.amc?.taxInvoiceNumber ? record?.amc?.taxInvoiceNumber : '-',
          ...getColumnSearchProps('taxInvoiceNumber'),
          width: 170,
        },
        {
          title: 'Commission Ratio(%)',
          dataIndex: 'commissionRatio',
          key: 'commissionRatio',
          sorter: (a, b) => a.commissionRatio - b.commissionRatio,
          render: (text, record) => record?.commissionRatio?.toFixed(2),
          width: 150,
        },
        {
          title: 'Commission Amount',
          dataIndex: 'commissionAmount',
          key: 'commissionAmount',
          sorter: (a, b) => a.commissionAmount - b.commissionAmount,
          render: (commissionAmount) =>
            commissionAmount ? Math.round(commissionAmount) : '-',
          width: 150,
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => a.createdAt - b.createdAt,
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          ...getColumnDateProps('createdAt'),
          width: '140px',
        },
      ],
    },
  ]

  function handleCsvDownload() {
    openNotification(
      'success',
      'Export request received. You will receive an email shortly.'
    )
    client.query({
      query: GET_REVENUE_INCOME,
      variables: {
        where: filters,
        skip,
        sort,
        limit: totalCount,
        isExportCSV: true,
      },
      fetchPolicy: 'network-only',
    })
  }

  function handlePdfDownload() {
    openNotification(
      'success',
      'Export request received. You will receive an email shortly.'
    )
    client.query({
      query: GET_REVENUE_INCOME,
      variables: {
        where: filters,
        skip,
        sort,
        limit: totalCount,
        isExport: true,
      },
      fetchPolicy: 'no-cache',
    })
  }

  return (
    <>
      <CustomMeta title='Revenue Income' />
      <div
        style={{
          padding: `${isMobile ? '20px' : '32px 40px'}`,
          marginBottom: isMobile ? '5vh' : '1px',
        }}
      >
        {/* <PageHeader
          className='box'
          title='Revenue Income'
          extra={[
            <Button
              key='1'
              type='primary'
              loading={PDFLoading}
              onClick={handlePdfDownload}
            >
              Export Pdf
            </Button>,
            <Button
              key='2'
              type='primary'
              loading={CSVLoading}
              onClick={handleCsvDownload}
            >
              Export Csv
            </Button>,
          ]}
        /> */}

        <div
          style={{
            display: `${isMobile ? 'block' : 'flex'}`,
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Revenue Income
          </div>

          <div
            style={{
              display: 'flex',
              gap: '10px',
              marginTop: `${isMobile && '10px'}`,
            }}
          >
            <Button
              onClick={handlePdfDownload}
              style={{
                margin: '0',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '40px',
                borderRadius: '8px',
              }}
            >
              Export PDF
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>

            <Button
              onClick={handleCsvDownload}
              style={{
                margin: '0',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '40px',
                borderRadius: '8px',
              }}
            >
              Export CSV
              <DownloadOutlined color='#AF0000' style={{ fontSize: '16px' }} />
            </Button>
          </div>
        </div>

        <Table
          loading={loading}
          columns={columns}
          dataSource={tableData}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          className={
            !isAdmin && get(data, 'getRevenueIncome.total')
              ? 'user-revenue-table'
              : ''
          }
          pagination={{
            pageSize: limit,
            total: totalCount,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </>
  )
}
