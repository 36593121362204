import React from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import CurrentPositionTable from './CurrentPositionTable'
import { GET_CURRENT_POSITION_REPORT } from '../graphql/Queries'
import '../index.css'
import CustomMeta from 'components/CustomMeta'

export default function () {
  const { data, loading, error } = useQuery(GET_CURRENT_POSITION_REPORT)

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spCurrentPosition')) return <NoData />

  const {
    spCurrentPosition: [spCurrentPosition],
  } = data

  return (
    <>
      <CustomMeta title='Report | Current Position' />
      <DownloadReport
        data={spCurrentPosition.filter(
          (report, key) => key !== 0 && !!report.script
        )}
        fileName='current-position'
        id='CURRENT-REPORT-PDF'
      />
      <div
        className='report-table-wrapper current-position-report'
        id='CURRENT-REPORT-PDF'
      >
        <CurrentPositionTable
          reportData={spCurrentPosition.filter(
            (report, key) => key !== 0 && !!report.script
          )}
        />
      </div>
    </>
  )
}
