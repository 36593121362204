import { Alert, Button, Input, Select, Space } from 'antd'
import client from 'apollo'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import openNotification from 'utils/Notification'
import { UPDATE_TEMPLATE_DATA } from '../graphql/Mutations'
import { GET_TEMPLATE_DATA } from '../graphql/Queries'
import Loader from 'components/loaders/Loader'
import Page404 from 'components/Page404'
import CustomMeta from 'components/CustomMeta'

export default function TicketMailForMate() {
  const [active, setActive] = useState('CREATE_TICKET_MAIL')
  const [loading, setLoading] = useState(false)
  const [templateLoading, setTemplateLoading] = useState(true)
  const [templateError, setTemplateError] = useState(false)

  const [templateHtml, setTemplateHtml] = useState()
  const [subject, setSubject] = useState()
  const [error, setError] = useState(false)

  useEffect(() => {
    client
      .query({
        query: GET_TEMPLATE_DATA,
        variables: { type: active },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setSubject(res?.data?.getTemplateData?.subject)
        setTemplateHtml(res?.data?.getTemplateData?.htmlData)
        setTemplateLoading(false)
      })
      .catch((err) => {
        console.log({ err })
        setTemplateLoading(false)
        setTemplateError(true)
      })
  }, [active])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],

    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s*()$/,
        },
      },
    },
  }

  const options = [
    {
      value: 'CREATE_TICKET_MAIL',
      label: 'CREATE TICKET MAIL',
    },
    {
      value: 'CLOSE_TICKET_MAIL',
      label: 'CLOSE TICKET MAIL',
    },
    // {
    //   value: 'OPEN_TICKET_MAIL',
    //   label: 'OPEN TICKET MAIL',
    // },
    {
      value: 'REVIEW_TICKET_MAIL',
      label: 'REVIEW TICKET MAIL',
    },
    // {
    //   value: 'REPLY_TICKET_MAIL',
    //   label: 'REPLY TICKET MAIL',
    // },
    // {
    //   value: 'IN_PROGRESS_TICKET_MAIL',
    //   label: 'IN PROGRESS TICKET MAIL',
    // },
    {
      value: 'REOPEN_TICKET_MAIL',
      label: 'REOPEN TICKET MAIL',
    },
  ]

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'image',
    'video',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  const handleChange = (value) => {
    setActive(value)
  }
  const handelSubject = (value) => {
    setError(false)
    setSubject(value.target.value)
  }
  const handleBlur = () => {
    if (!subject.trim()) {
      setError(true)
    }
  }

  const handleValue = (content, delta, source, editor) => {
    setTemplateHtml(editor.getHTML())
  }

  const updateCode = () => {
    setLoading(true)
    client
      .mutate({
        mutation: UPDATE_TEMPLATE_DATA,
        variables: { type: active, htmlData: templateHtml, subject: subject },
        refetchQueries: [
          { query: GET_TEMPLATE_DATA, variables: { type: active } },
        ],
      })
      .then((res) => {
        setLoading(false)
        openNotification('success', 'Mail Format Change Successfully')
      })
      .catch((err) => console.log({ err }))
      .finally(() => setLoading(false))
  }
  return (
    <>
      <CustomMeta title='Ticket Mail Format' />
      {templateLoading && !templateError ? (
        <Loader />
      ) : templateError ? (
        <Page404 />
      ) : (
        <div className='main_dashboard_div'>
          <div className='agreement_main_div'>
            <div className='agreement_text'>Ticket Mail Format</div>
          </div>

          <Select
            style={{
              width: 175,
              marginBottom: '20px',
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
            value={active}
            placeholder='Tags Mode'
            onChange={handleChange}
            options={options}
          />
          <Alert
            message={
              <>
                <div>
                  To reference a variable, use the following format:{' '}
                  <strong>{'{clientName}'}</strong>
                  {', '} <strong>{'{ticketNumber}'}</strong>
                  {', '} and <strong>{'{reason}'}</strong>.
                </div>
              </>
            }
            type='warning'
            showIcon
          />
          <Space
            direction='vertical'
            style={{ marginBottom: '15px', width: '100%' }}
          >
            <Input
              addonBefore={'Subject'}
              onChange={handelSubject}
              value={subject}
              status={error ? 'error' : ''}
              onBlur={handleBlur}
            />
            {error && <p style={{ color: 'red' }}>*Subject is required</p>}
          </Space>

          <ReactQuill
            theme='snow'
            value={templateHtml}
            onChange={handleValue}
            modules={modules}
            formats={formats}
          />

          <div className='agreement_btn_div'>
            <Button
              onClick={updateCode}
              type='primary'
              className='agreement_submit_btn'
              loading={loading}
              disabled={error ? true : false}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
