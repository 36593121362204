import gql from 'graphql-tag'

export const GET_NIFTY_CANDLE_CHART = gql`
  query niftyCandleChart {
    niftyCandleChart
  }
`

export const GET_DASHBOARD_COUNTS = gql`
  query dashboardCounters {
    dashboardCounters
  }
`

export const GET_DASHBOARD_SUMMARY = gql`
  query dashboardSummary($where: DashboardWhereInput) {
    dashboardSummary(where: $where)
  }
`

export const GET_REVENUE_INCOME_SUMMARY = gql`
  query getTotalRevenueIncome($where: RevenueIncomeWhereUniqueInput!) {
    getTotalRevenueIncome(where: $where)
  }
`

export const GET_DASHBOARD_USER_PERFORMANCE = gql`
  query userPerformanceCounter($where: UserPerformanceInput) {
    userPerformanceCounter(where: $where)
  }
`

export const GET_DASHBOARD_SIP_AMC = gql`
  query spDashbordSIPAMC($where: SpDashbordSIPAMCInput) {
    spDashbordSIPAMC(where: $where)
  }
`

export const GET_DASHBOARD_SIP_AMC_CLIENT = gql`
  query spDashbordSIPAMCClient($where: SpDashbordSIPAMCClientInput) {
    spDashbordSIPAMCClient(where: $where)
  }
`

export const GET_PROFIT_LOSS_CHART = gql`
  query profitLossPieChart($groupId: Int, $associateId: Int) {
    profitLossPieChart(where: { groupId: $groupId, associateId: $associateId })
  }
`

export const GET_PNL_NLV_EXPOSURE_REPORT = gql`
  query pnlNlvExposureReport {
    pnlNlvExposureReport {
      pnlPercentage
      nlvPercentage
      exposurePercentage
    }
  }
`
export const GET_DASHBOARD_STRATEGY_INDEX_CLIENT = gql`
  query reportsGraph($where: SpiltsreportReportInput!) {
    reportsGraph(where: $where)
  }
`
