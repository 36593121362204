import React, { useContext, useEffect, useState } from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _first from 'lodash/first'
import { useQuery } from '@apollo/react-hooks'
import { Select, Button, Modal, Popover, Tabs } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import sumBy from 'lodash/sumBy'

import client from 'apollo'
import history from 'CustomHistory'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import Ledger from 'modules/Reports/ILTS/Tables/Ledger'
import {
  GET_ILTS_P_REPORT,
  GET_ILTS_REPORT,
} from 'modules/Reports/graphql/Queries'
import OpenPosition from 'modules/Reports/ILTS/Tables/OpenPosition'
import { GET_CC_EMAIL, GET_STRATEGY } from 'modules/Settings/graphql/Queries'
import 'modules/Reports/index.css'
import moment from 'moment'
import { AppContext } from 'AppContext'
import { CSVLink } from 'react-csv'
import PerformanceEmail from './components/PerformanceEmail'
import { sendMePdf } from 'utils/Graph'
import MailIcon from 'assets/images/new-images/mailIcon.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import FilterBackButton from 'assets/images/new-images/filter-back-button.svg'

import StockReport from 'modules/Reports/ILTS/Tables/stockReport'
import get from 'lodash/get'
import Overview from 'modules/Reports/ILTS/Tables/overview'
import CustomMeta from 'components/CustomMeta'

const { Option } = Select
const TabPane = Tabs.TabPane

export default function (props) {
  const {
    state: { currentUser, isMobile },
  } = useContext(AppContext)
  const [strategy, setStrategy] = useState('1005')
  const [sendMeLoading, setSendMeLoading] = useState(false)
  const [strategyList, setStrategyList] = useState(undefined)
  const [openSendMail, setOpenSendMail] = useState(false)
  const [invoiceCreateData, setInvoiceCreateData] = useState()
  const [performanceReportLink, setPerformanceReportLink] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('Overview')
  const [dataLoading, setLoading] = useState()

  let {
    match: {
      params: { clientCode, clientId },
    },
  } = props

  const { data, loading, error } = useQuery(GET_ILTS_REPORT, {
    variables: {
      where: { clientCode, date: null, strategy: strategy ? strategy : '1005' },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    client
      .query({
        query: GET_STRATEGY,
        variables: { where: { clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (_get(res, 'data.getStrategy')) {
          setStrategyList(_get(res, 'data.getStrategy'))
          if (res.data.getStrategy.length === 1) {
            setStrategy(res.data.getStrategy[0].StrategyCode)
          }
        }
      })
      .catch(() => setStrategyList([]))
  }, [clientCode])

  let spIltsreport
  let maskedSpILTSReportData
  let futuresOptionsReport = {}
  let stockReport = {}
  let ledgerReport = {}
  // let hypothetical_Ledger_Report = {}

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]
    maskedSpILTSReportData = data.spIltsreport[0]
    const reportFutures = spIltsreport.filter(
      (report, key) => report.Script && !report.Script.endsWith(' EQ')
    )
    futuresOptionsReport = reportFutures.map((reportFuture) => {
      return {
        ...reportFuture,
        TotalQty: Math.round(reportFuture.TotalQty),
        TradedRate: reportFuture.TradedRate,
        CMP: reportFuture.CMP,
        Value: Math.round(reportFuture.Value),
        Pnl: Math.round(reportFuture.Pnl),
      }
    })
    futuresOptionsReport = [
      ...futuresOptionsReport,
      {
        Script: 'Total',
        TotalQty: sumBy(futuresOptionsReport, 'TotalQty'),
        Value: sumBy(futuresOptionsReport, 'Value'),
        Pnl: sumBy(futuresOptionsReport, 'Pnl'),
      },
    ]

    const reportStocks = spIltsreport.filter(
      (report, key) => report.Script && report.Script.endsWith(' EQ')
    )
    stockReport = reportStocks.map((reportStock) => {
      return {
        ...reportStock,
        TotalQty: Math.round(reportStock.TotalQty),
        TradedRate: reportStock.TradedRate,
        CMP: reportStock.CMP,
        Value: Math.round(reportStock.Value),
        Pnl: Math.round(reportStock.Pnl),
      }
    })
    stockReport = [
      ...stockReport,
      {
        Script: 'Total',
        TotalQty: sumBy(stockReport, 'TotalQty'),
        Value: sumBy(stockReport, 'Value'),
        Pnl: sumBy(stockReport, 'Pnl'),
      },
    ]
    // const hypothetical_Ledger = spIltsreport.filter(
    //   (report, key) =>
    //     key !== 0 &&
    //     key !== 1 &&
    //     key !== 2 &&
    //     !report.Script &&
    //     report.isHypothetical === 'True'
    // )

    const ledgers = spIltsreport.filter(
      (report, key) =>
        key !== 0 &&
        key !== 1 &&
        key !== 2 &&
        !report.Script &&
        !report.isHypothetical
    )
    ledgerReport = ledgers.map((reportLedger) => {
      return {
        ...reportLedger,
        Entrydate: moment(reportLedger?.Entrydate).format('DD-MMM-YYYY'),
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })

    // hypothetical_Ledger_Report = hypothetical_Ledger.map((reportLedger) => {
    //   return {
    //     ...reportLedger,
    //     DR_Amount: Math.round(reportLedger.DR_Amount),
    //     CR_Amount: Math.round(reportLedger.CR_Amount),
    //   }
    // })
    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        CumAmount:
          sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
        remark: '',
      },

      // {
      //   Entrydate: 'Net Value',
      //   DR_Amount:
      //     sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
      //   CR_Amount: '',
      //   remark: '',
      // },
    ]

    // hypothetical_Ledger_Report = [
    //   ...hypothetical_Ledger_Report,
    //   {
    //     Entrydate: 'Total',
    //     DR_Amount: sumBy(hypothetical_Ledger_Report, 'DR_Amount'),
    //     CR_Amount: sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
    //     CumAmount:
    //       sumBy(hypothetical_Ledger_Report, 'DR_Amount') -
    //       sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
    //     remark: '',
    //   },
    // ]
  }

  const renderSendMail = () => {
    setButtonLoading(true)
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: { where: { id: parseInt(clientId) } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setButtonLoading(true)
        client
          .query({
            query: GET_CC_EMAIL,
            variables: { clientCode },
          })
          .then((res) => {
            setButtonLoading(false)
            data.emailId = res?.data?.getCCEmails?.email?.split(',')
            data.ccMailIds = res?.data?.getCCEmails?.HKeyEmail?.split(',')
            setInvoiceCreateData(data)
            setOpenSendMail(true)
          })
          .catch((err) => console.log(err))
          .finally(() => setButtonLoading(false))
        setPerformanceReportLink(res?.data?.getIltspreport)
        setButtonLoading(false)
      })
  }

  const spILTSReportData = _map(spIltsreport, (report, key) => {
    if (key === 0) return report
    const clonedReport = { ...report }
    if (clonedReport.ClientCode) delete clonedReport.ClientCode
    if (clonedReport.isHypothetical === 'True') return null
    return clonedReport
  }).filter(Boolean)

  const handleDownloadPdf = (isMasked) => {
    setLoading(true)
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: {
          where: { id: parseInt(clientId), isMasked, strategyCode: strategy },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setLoading(false)
        get(res, 'data.getIltspreport') &&
          window.open(get(res, 'data.getIltspreport'), '_blank')
      })
      .catch((err) => console.log(err))
  }

  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={() => handleDownloadPdf(false)}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>
      <div
        onClick={() => handleDownloadPdf(true)}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
          width: '112px',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> Masked
        PDF
      </div>
      <div
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (currentUser.role === 'ADMIN') {
            console.log('iniffff')
            renderSendMail()
          } else {
            console.log('inelssss')
            setSendMeLoading(true)
            sendMePdf(clientId).then(() => setSendMeLoading(false))
          }
        }}
      >
        <img
          src={MailIcon}
          style={{
            marginRight: '10px',
            cursor: `${sendMeLoading ? 'not-allowed' : 'pointer'}`,
          }}
          alt='mail'
        />{' '}
        Mail
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={Array.isArray(spILTSReportData) ? spILTSReportData : []}
        className='csv-btn'
        filename={`ILTS-PERFORMANCE-REPORT-${clientCode}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={
          Array.isArray(maskedSpILTSReportData)
            ? maskedSpILTSReportData
                .filter((item) => item.isHypothetical !== 'True')
                .map((item, key) => {
                  const clonedItem = { ...item }
                  if (key === 0) {
                    clonedItem.ClientCode = 'XXXXXXXXXX'
                    clonedItem.ClientName = 'XXXXXXXXXX'
                  } else if (clonedItem.ClientCode) {
                    delete clonedItem.ClientCode
                  }
                  return clonedItem
                })
            : []
        }
        className='csv-btn'
        filename={`ILTS-PERFORMANCE-REPORT-XXXXXXXXXX.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
            width: '121px',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Masked Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <CustomMeta title='Client Position' />
      <div
        className='main_dashboard_div'
        style={{ overflow: 'hidden', maxHeight: 'none' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            gap: '10px',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <div onClick={() => history.goBack()}>
              <img
                src={FilterBackButton}
                alt='FilterBackButton'
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div
              style={{
                fontSize: isMobile ? '18px' : '24px',
                fontWeight: '600',
                lineHeight: '26px',
                letterSpacing: '-0.02em',
                textAlign: 'left',
                color: '#181C32',
              }}
            >
              {activeTab}
            </div>
          </div>
          {_first(spIltsreport)?.InvestmentDate !== undefined ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                onClick={() =>
                  history.push(
                    `/clients/${clientId}/position/${clientCode}/${clientId}/comparison-of-fund/${clientCode}`
                  )
                }
                style={{
                  margin: '0',
                  padding: isMobile ? '0 14px' : '0 16px',
                  fontSize: isMobile ? '12px' : '14px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  background: '#AF0000',
                  height: '40px',
                  borderRadius: '8px',
                }}
              >
                View Comparison
              </Button>
              <Popover content={content} placement='bottom' trigger='click'>
                <Button
                  loading={dataLoading}
                  style={{
                    margin: '0',
                    padding: isMobile ? '0 14px' : '0 16px',
                    fontSize: isMobile ? '12px' : '14px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#AF0000',
                    background: '#AF00000D',
                    border: '1px solid #AF000080',
                    height: '40px',
                    borderRadius: '8px',
                  }}
                >
                  Export{' '}
                  <DownloadOutlined
                    color='#AF0000'
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </Popover>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue={strategy ? strategy : 'ILTS'}
                style={{ width: 80 }}
                onChange={(e) => setStrategy(e)}
                loading={!strategyList}
                placeholder='Select Strategy'
                className='display-field'
              >
                {_map(strategyList, (strategy, key) => {
                  const { StrategyName, StrategyCode } = strategy
                  return (
                    <Option value={StrategyCode} key={key}>
                      {StrategyName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          ) : (
            ''
          )}
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Page404 error={error} />
        ) : !_get(data, 'spIltsreport') || !_first(spIltsreport) ? (
          <NoData />
        ) : _first(spIltsreport)?.InvestmentDate === undefined ? (
          <NoData />
        ) : (
          <div
            className='report-table-wrapper'
            id='ILTS-REPORT-PDF'
            style={{ overflow: 'hidden' }}
          >
            <Tabs onChange={(e) => setActiveTab(e)} defaultActiveKey='Overview'>
              <TabPane tab='Overview' key='Overview'>
                <Overview
                  clientData={{ ..._first(spIltsreport), clientId, strategy }}
                  ledgerReport={ledgerReport}
                />
              </TabPane>
              <TabPane tab='Stock Holding Report' key='Stock Holding Report'>
                <StockReport
                  reportData={stockReport}
                  clientData={_first(spIltsreport)}
                  id={clientId}
                  StrategyCode={strategy}
                />
              </TabPane>
              <TabPane
                tab='Futures & Options Report'
                key='Futures & Options Report'
              >
                <OpenPosition
                  reportData={futuresOptionsReport}
                  clientData={_first(spIltsreport)}
                  id={clientId}
                  StrategyCode={strategy}
                />
              </TabPane>
              <TabPane tab='Ledger Report' key='Ledger Report'>
                <Ledger
                  isLedger={true}
                  reportData={ledgerReport}
                  clientData={_first(spIltsreport)}
                  id={clientId}
                  StrategyCode={strategy}
                />
              </TabPane>
              {/* <TabPane tab='Ledger Beta Report' key='Ledger Beta Report'>
                <Ledger
                  isLedger={false}
                  reportData={hypothetical_Ledger_Report}
                  clientData={_first(spIltsreport)}
                  id={clientId}
                  StrategyCode={strategy}
                />
              </TabPane> */}
            </Tabs>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              This is an indicative report. Please check your back-office for
              final report
            </div>
          </div>
        )}
        {openSendMail && (
          <Modal
            className='feedback_form'
            visible={openSendMail}
            width={1200}
            onCancel={() => setOpenSendMail(false)}
            footer={null}
            title='Performance Mail Body'
          >
            <PerformanceEmail
              buttonLoading={buttonLoading}
              invoiceCreateData={invoiceCreateData}
              setOpenReactQuill={setOpenSendMail}
              clientCode={clientCode}
              performanceReportLink={performanceReportLink}
            />
          </Modal>
        )}
      </div>
    </>
  )
}
