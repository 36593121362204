import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Table,
  Checkbox,
  Input,
  Space,
  DatePicker,
  Tooltip,
  Modal,
} from 'antd'
import { SearchOutlined, DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_BOOKED_ADVISORY } from './graphql/Query'
import Page404 from 'components/Page404'
import client from 'apollo'
import { CUSTOM_CSV_DOWNLOAD } from 'modules/Reports/graphql/Queries'
import { get, isEmpty, uniq } from 'lodash'
import moment from 'moment'
import { advisorySendTypeFilters, advisoryTypeFilter } from 'utils/User'
import { GET_ALL_NSE_HOLIDAYS } from 'modules/NSEHoliday/graphql/Query'
import { AppContext } from 'AppContext'
import { UPDATE_BOOK_ADVISORY } from 'modules/Verification/graphql/Mutations'
import openNotification from 'utils/Notification'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import CustomMeta from 'components/CustomMeta'

const { RangePicker } = DatePicker

const BookAdvisory = () => {
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [csvLoading, setCsvLoading] = useState(false)
  const [filters, setFilters] = useState({ advisorySendType: ['Auto'] })
  const [openModal, setOpenModal] = useState(false)
  const [advisory, setAdvisory] = useState({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [date, setDate] = useState()
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const {
    state: {
      currentUser: { id },
      isMobile,
    },
  } = useContext(AppContext)

  useEffect(() => {
    client
      .query({
        query: GET_ALL_BOOKED_ADVISORY,
        variables: { where: { ...filters }, limit, skip },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setData(res?.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.message.split(':')[1] !== 'NO_USER_FOUND') {
          setError(err.message.split(':')[1])
          setLoading(false)
        }
      })
  }, [filters, limit, skip])

  const {
    data: holidayDates,
    loading: holidayLoading,
    error: holidayError,
  } = useQuery(GET_ALL_NSE_HOLIDAYS, {
    fetchPolicy: 'network-only',
  })
  let totalCount = 0
  if (!loading && get(data, 'getAllBookAdvisory.bookingAdvisory')) {
    totalCount = data.getAllBookAdvisory?.count
  }

  let dates = []
  if (!loading && get(holidayDates, 'getHoliday')) {
    dates = holidayDates?.getHoliday?.map((item) => item?.date)
  }

  if (error || holidayError) return <Page404 />

  const handleTableChange = (pagination, filter, sorter) => {
    if (filter) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      if (filter?.advisoryType !== null) {
        tempFilters['advisoryType'] = filter.advisoryType
      } else {
        delete tempFilters.advisoryType
      }

      if (filter?.advisorySendType !== null) {
        tempFilters['advisorySendType'] = filter.advisorySendType
      } else {
        tempFilters['advisorySendType'] = ['Auto']
      }
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const handlePagination = (page) => {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  const handlePageSizeChange = (current, size) => {
    setLimit(size)
  }

  const handleSearch = (value, confirm, filedName) => {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const handleReset = (clearFilters, filedName) => {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    delete tempFilters[filedName]
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  const getColumnAmountProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const fromIndex = `${dataIndex}From`
      const toIndex = `${dataIndex}To`

      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Min ${dataIndex}`}
            type='number'
            value={selectedKeys[0] && selectedKeys[0][fromIndex]}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onChange={(e) =>
              setSelectedKeys([
                {
                  ...selectedKeys[0],
                  [fromIndex]: e.target.value,
                },
              ])
            }
          />
          <Input
            placeholder={`Max ${dataIndex}`}
            type='number'
            value={selectedKeys[0] && selectedKeys[0][toIndex]}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onChange={(e) =>
              setSelectedKeys([
                {
                  ...selectedKeys[0],
                  [toIndex]: e.target.value,
                },
              ])
            }
          />
          <Space>
            <Button
              type='primary'
              disabled={!selectedKeys[0]?.amcAmountTo}
              onClick={() => {
                let tempFilters
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[fromIndex] = parseFloat(selectedKeys[0][fromIndex])
                tempFilters[toIndex] = parseFloat(selectedKeys[0][toIndex])

                setFilters(tempFilters)
                setCurrentPage(1)
              }}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                let tempFilters
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[fromIndex]
                delete tempFilters[toIndex]

                setFilters(tempFilters)
                setCurrentPage(1)
              }}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
  })

  let array = []
  const disabledDate = (current) => {
    const isWeekend = current.day() === 0 || current.day() === 6
    const holiday = dates.some((date) => moment(date).isSame(current, 'day'))
    const isToday = current.isSame(moment(), 'day')
    const isSelected = current.isSame(moment(advisory?.advisoryDate), 'day')

    if (moment(current) >= moment() && array.length < 4) {
      if (!isWeekend && !holiday && !isToday && !isSelected) {
        array.push(moment(current).format('DD/MM'))
        array = uniq(array)
      }
    }

    return !array?.includes(moment(current).format('DD/MM'))
  }

  const handleDateChange = (e) => {
    setDate(moment(e))
  }

  const handleOpenModal = (advisory, isDeleted) => {
    setAdvisory({ ...advisory, isDeleted })
    setOpenModal(true)
  }
  const renderAction = (data) => {
    if (!data?.isClientAcceptAdvisory && !data?.isCancelAdvisory) {
      return (
        <div className='action-icons'>
          <Tooltip title='Edit Book Advisory Slot'>
            <EditTwoTone onClick={() => handleOpenModal(data, false)} />
          </Tooltip>
          <Tooltip title='Cancel Book Advisory Slot'>
            <DeleteOutlined onClick={() => handleOpenModal(data, true)} />
          </Tooltip>
        </div>
      )
    }
  }

  let columns = [
    {
      title: 'Client Name',
      render: (text, record) =>
        record?.clientId?.firstName + ' ' + record?.clientId?.lastName,
      width: 200,
      ...getColumnSearchProps('clientName'),
    },
    {
      title: 'Client Code',
      render: (text, record) => record?.clientId?.clientCode,
      width: 110,
      ...getColumnSearchProps('clientCode'),
    },
    {
      title: 'Advisory Date',
      dataIndex: 'advisoryDate',
      key: 'advisoryDate',
      width: 150,
      render: (text, record) =>
        text ? moment(text).format('DD-MM-YYYY') : '-',
      ...getColumnDateProps('advisoryDate'),
    },
    {
      title: 'Advisory Submitted By',
      dataIndex: 'advisorySubmittedUser',
      key: 'advisorySubmittedUser',
      width: 200,
      render: (text, record) =>
        record?.advisorySubmittedUser
          ? record?.advisorySubmittedUser?.firstName +
            ' ' +
            record?.advisorySubmittedUser?.lastName
          : '-',
      ...getColumnSearchProps('advisorySubmittedBy'),
    },
    {
      title: 'Advisory Type',
      dataIndex: 'advisoryType',
      key: 'advisoryType',
      width: 120,
      render: (text, record) => (text ? text : '-'),
      filters: advisoryTypeFilter,
    },
    {
      title: 'Advisory Create Type',
      dataIndex: 'advisorySendType',
      key: 'advisorySendType',
      render: (text, record) => (text ? text : '-'),
      width: 120,
      filters: advisorySendTypeFilters,
    },
    {
      title: 'UMRN NO/Transaction Id',
      dataIndex: 'paymentType',
      key: 'paymentType',
      render: (text, record) => (text ? text : '-'),
      width: 250,
      ...getColumnSearchProps('paymentType'),
    },
    {
      title: 'Amc Amount',
      dataIndex: 'amcAmount',
      key: 'amcAmount',
      render: (text, record) => (text ? text : '-'),
      width: 100,
      ...getColumnAmountProps('amcAmount'),
    },
    {
      title: 'Advisory Send Date',
      dataIndex: 'advisorySendDateAndTime',
      key: 'advisorySendDateAndTime',
      width: 180,
      render: (text, record) =>
        text ? moment(text).format('DD-MM-YYYY hh:mm:ss') : '-',
      ...getColumnDateProps('advisorySendDate'),
    },
    {
      title: 'Advisory Submitted Date',
      dataIndex: 'advisoryAcceptDateAndTime',
      key: 'advisoryAcceptDateAndTime',
      width: 180,
      render: (text, record) =>
        text ? moment(text).format('DD-MM-YYYY hh:mm:ss') : '-',
      ...getColumnDateProps('advisoryAcceptDate'),
    },
    {
      title: 'Advisory Booked',
      dataIndex: 'isAdvisoryBooked',
      key: 'isAdvisoryBooked',
      width: 100,
      render: (text, record) => (
        <Checkbox disabled checked={record?.isAdvisoryBooked} />
      ),
    },
    {
      title: 'Advisory Accepted',
      dataIndex: 'isClientAcceptAdvisory',
      key: 'isClientAcceptAdvisory',
      width: 100,
      render: (text, record) => (
        <Checkbox disabled checked={record?.isClientAcceptAdvisory} />
      ),
    },
    {
      title: 'Advisory Cancelled By',
      dataIndex: 'advisoryCancelByUser',
      key: 'advisoryCancelByUser',
      width: 200,
      render: (text, record) =>
        record?.advisoryCancelByUser
          ? record?.advisoryCancelByUser?.firstName +
            ' ' +
            record?.advisoryCancelByUser?.lastName
          : '-',
    },
    {
      title: 'Advisory Updated By',
      dataIndex: 'advisoryUpdateByUser',
      key: 'advisoryUpdateByUser',
      width: 200,
      render: (text, record) =>
        record?.advisoryUpdateByUser
          ? record?.advisoryUpdateByUser?.firstName +
            ' ' +
            record?.advisoryUpdateByUser?.lastName
          : '-',
    },
    {
      title: 'Advisory Cancelled',
      dataIndex: 'isCancelAdvisory',
      key: 'isCancelAdvisory',
      width: 100,
      render: (text, record) => (
        <Checkbox disabled checked={record?.isCancelAdvisory} />
      ),
    },
  ]

  if (filters?.advisorySendType?.includes('Auto')) {
    columns.push({
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => renderAction(record),
    })
  }

  const handleCsvLDownload = () => {
    setCsvLoading(true)
    client
      .query({
        query: GET_ALL_BOOKED_ADVISORY,
        variables: { where: { ...filters }, limit: totalCount, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res?.data?.getAllBookAdvisory?.bookingAdvisory?.map(
                  (item) => {
                    return {
                      ...item,
                      advisoryDate: item?.advisoryDate
                        ? moment(item?.advisoryDate).format('DD-MM-YYYY')
                        : '',
                      advisorySubmitBy:
                        item?.advisorySubmittedUser?.firstName &&
                        item?.advisorySubmittedUser?.lastName
                          ? item?.advisorySubmittedUser?.firstName +
                            ' ' +
                            item?.advisorySubmittedUser?.lastName
                          : '',
                      advisorySendDateAndTime: item?.advisorySendDateAndTime
                        ? moment(item?.advisorySendDateAndTime).format(
                            'DD-MM-YYYY hh:mm:ss'
                          )
                        : '',
                      advisoryAcceptDateAndTime: item?.advisoryAcceptDateAndTime
                        ? moment(item?.advisoryAcceptDateAndTime).format(
                            'DD-MM-YYYY hh:mm:ss'
                          )
                        : '',
                      advisoryCancelBy:
                        item?.advisoryCancelByUser?.firstName &&
                        item?.advisoryCancelByUser?.lastName
                          ? item?.advisoryCancelByUser?.firstName +
                            ' ' +
                            item?.advisoryCancelByUser?.lastName
                          : '',
                      advisoryUpdateBy:
                        item?.advisoryUpdateByUser?.firstName &&
                        item?.advisoryUpdateByUser?.lastName
                          ? item?.advisoryUpdateByUser?.firstName +
                            ' ' +
                            item?.advisoryUpdateByUser?.lastName
                          : '',
                      isAdvisoryBooked: item?.isAdvisoryBooked ? 'Yes' : 'No',
                      isClientAcceptAdvisory: item?.isClientAcceptAdvisory
                        ? 'Yes'
                        : 'No',
                      isCancelAdvisory: item?.isCancelAdvisory ? 'Yes' : 'No',
                    }
                  }
                ),
                fields: [
                  { label: 'Client First Name', value: 'clientId.firstName' },
                  { label: 'Client Last Name', value: 'clientId.lastName' },
                  { label: 'Client Code', value: 'clientId.clientCode' },
                  { label: 'Advisory Date', value: 'advisoryDate' },
                  { label: 'Advisory Submitted By', value: 'advisorySubmitBy' },
                  { label: 'Advisory Type', value: 'advisoryType' },
                  { label: 'Advisory Create Type', value: 'advisorySendType' },
                  { label: 'UMRN NO/Transaction Id', value: 'paymentType' },
                  { label: 'Amc Amount', value: 'amcAmount' },
                  {
                    label: 'Advisory Send Date',
                    value: 'advisorySendDateAndTime',
                  },
                  {
                    label: 'Advisory Accept Date',
                    value: 'advisoryAcceptDateAndTime',
                  },
                  { label: 'Advisory Booked', value: 'isAdvisoryBooked' },
                  {
                    label: 'Advisory Accepted',
                    value: 'isClientAcceptAdvisory',
                  },
                  { label: 'Advisory Cancelled By', value: 'advisoryCancelBy' },
                  { label: 'Advisory Updated By', value: 'advisoryUpdateBy' },
                  { label: 'Advisory Cancelled', value: 'isCancelAdvisory' },
                ],
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCsvLoading(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLimit(10)
        setSkip(0)
        setCsvLoading(false)
      })
  }

  const onFinish = (advisory) => {
    setBtnLoading(true)
    let updatedData = { userId: advisory?.userId }
    if (advisory?.isDeleted) {
      updatedData = {
        ...updatedData,
        isCancelAdvisory: true,
        advisoryCancelBy: id,
      }
    } else {
      updatedData = {
        ...updatedData,
        advisoryDate: moment(date ? date : advisory?.advisoryDate).format(),
      }
    }
    client
      .mutate({
        mutation: UPDATE_BOOK_ADVISORY,
        variables: {
          data: { ...updatedData },
          where: { id: parseInt(advisory?.id) },
        },
        refetchQueries: [
          {
            query: GET_ALL_BOOKED_ADVISORY,
            variables: { where: { ...filters }, limit, skip },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        if (advisory?.isDeleted) {
          client
            .mutate({
              mutation: UPDATE_USER,
              variables: {
                data: {
                  isBookingAdvisoryDateSelected: false,
                  completedSteps: 'E_MANDATE',
                },
                id: advisory?.userId,
              },
            })
            .then((re) => {
              openNotification(
                'success',
                'Advisory slot cancelled successfully'
              )
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          openNotification('success', 'Advisory slot updated successfully')
        }
        setBtnLoading(false)
        setAdvisory({})
        setOpenModal(false)
      })
      .catch((err) => {
        console.log(err)
        setBtnLoading(false)
        setAdvisory({})
        setOpenModal(false)
      })
  }

  return (
    <>
      <CustomMeta title='Auto Advisory' />
      <div style={{ padding: `${isMobile ? '10px' : '32px 40px'}` }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '26px',
              letterSpacing: '-0.02em',
              textAlign: 'left',
              color: '#181C32',
            }}
          >
            Auto Advisory
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              loading={csvLoading}
              onClick={handleCsvLDownload}
              style={{
                margin: '0',
                padding: '8px 16px',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#AF0000',
                background: '#AF00000D',
                border: '1px solid #AF000080',
                height: '40px',
                borderRadius: '8px',
              }}
            >
              Export Csv
            </Button>
          </div>
        </div>
        <Table
          loading={loading || holidayLoading}
          columns={columns}
          onChange={handleTableChange}
          dataSource={data?.getAllBookAdvisory?.bookingAdvisory}
          pagination={{
            pageSize: limit,
            total: totalCount,
            onChange: handlePagination,
            current: currentPage,
            onShowSizeChange: handlePageSizeChange,
          }}
          scroll={{
            y: true,
          }}
        />

        {openModal && (
          <Modal
            className='book_advisory_modal'
            visible={openModal}
            footer={null}
          >
            {!advisory?.isDeleted ? (
              <div className='edit_advisory_date_div'>
                <div>
                  Would you like to change your book advisory slot?
                  <br /> Kindly select the slot for advisory
                  <DatePicker
                    className='advisory_select_date'
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{
                      borderRadius: '8px',
                      marginLeft: '20px',
                      marginTop: '5px',
                    }}
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    format={'DD-MM-YYYY'}
                    value={date ? date : moment(advisory?.advisoryDate)}
                    allowClear={false}
                    dropdownClassName='custom-dropdown-class'
                  />
                </div>
              </div>
            ) : (
              <div>
                <b className='modal_text'>Do you want cancel advisory slot?</b>
              </div>
            )}
            <div className='book_advisory_btn'>
              <Button
                type='primary'
                onClick={() => onFinish(advisory)}
                loading={btnLoading}
              >
                Yes
              </Button>
              <Button onClick={() => setOpenModal(false)} disabled={btnLoading}>
                Cancel
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}
export default BookAdvisory
