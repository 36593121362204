import { Button, Popover, Select, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import Overview from './overview'
import StockReport from './stockReport'
import { AppContext } from 'AppContext'
import { GET_STRATEGY } from 'modules/Settings/graphql/Queries'
import client from 'apollo'
import { useQuery } from '@apollo/react-hooks'

import sumBy from 'lodash/sumBy'
import first from 'lodash/first'
import _map from 'lodash/map'
import _get from 'lodash/get'
import {
  GET_ILTS_P_REPORT,
  GET_ILTS_REPORT,
  // GET_TRADER_EQ_REPORT,
  // GET_TRADER_FO_REPORT,
  SEND_ME_PDF_V2,
} from 'modules/Reports/graphql/Queries'
import OpenPosition from './OpenPosition'
import Ledger from './Ledger'
import history from 'CustomHistory'
import MailIcon from 'assets/images/new-images/mailIcon.svg'
import PdfIcon from 'assets/images/new-images/pdficon.svg'
import ExcelIcon from 'assets/images/new-images/excelIcon.svg'
import openNotification from 'utils/Notification'
import get from 'lodash/get'
import { CSVLink } from 'react-csv'

const TabPane = Tabs.TabPane
const { Option } = Select

export default function ({ reportData }) {
  const [strategy, setStrategy] = useState('1005')
  const [strategyList, setStrategyList] = useState(undefined)
  const [activeTab, setActiveTab] = useState('Overview')
  const {
    state: {
      currentUser: { clientCode, id },
      isMobile,
    },
  } = useContext(AppContext)
  const { data, loading } = useQuery(GET_ILTS_REPORT, {
    variables: { where: { clientCode, date: null, strategy } },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    client
      .query({
        query: GET_STRATEGY,
        variables: { where: { clientCode } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (_get(res, 'data.getStrategy')) {
          setStrategyList(_get(res, 'data.getStrategy'))
          if (res.data.getStrategy.length === 1) {
            setStrategy(res.data.getStrategy[0].StrategyCode)
          }
        }
      })
      .catch(() => setStrategyList([]))
  }, [clientCode])

  let spIltsreport
  let futuresOptionsReport = {}
  let stockReport = {}
  let ledgerReport = {}
  // let hypothetical_Ledger_Report = {}

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]
    const reportFutures = spIltsreport.filter(
      (report, key) => report.Script && !report.Script.endsWith(' EQ')
    )
    futuresOptionsReport = reportFutures.map((reportFuture) => {
      return {
        ...reportFuture,
        TotalQty: Math.round(reportFuture.TotalQty),
        TradedRate: Math.round(reportFuture.TradedRate),
        CMP: Math.round(reportFuture.CMP),
        Value: Math.round(reportFuture.Value),
        Pnl: Math.round(reportFuture.Pnl),
      }
    })
    futuresOptionsReport = [
      ...futuresOptionsReport,
      {
        Script: 'Total',
        TotalQty: sumBy(futuresOptionsReport, 'TotalQty'),
        Value: sumBy(futuresOptionsReport, 'Value'),
        Pnl: sumBy(futuresOptionsReport, 'Pnl'),
      },
    ]

    const reportStocks = spIltsreport.filter(
      (report, key) => report.Script && report.Script.endsWith(' EQ')
    )
    stockReport = reportStocks.map((reportStock) => {
      return {
        ...reportStock,
        TotalQty: Math.round(reportStock.TotalQty),
        TradedRate: Math.round(reportStock.TradedRate),
        CMP: Math.round(reportStock.CMP),
        Value: Math.round(reportStock.Value),
        Pnl: Math.round(reportStock.Pnl),
      }
    })
    stockReport = [
      ...stockReport,
      {
        Script: 'Total',
        TotalQty: sumBy(stockReport, 'TotalQty'),
        Value: sumBy(stockReport, 'Value'),
        Pnl: sumBy(stockReport, 'Pnl'),
      },
    ]

    // const hypothetical_Ledger = spIltsreport.filter(
    //   (report, key) =>
    //     key !== 0 &&
    //     key !== 1 &&
    //     key !== 2 &&
    //     !report.Script &&
    //     report.isHypothetical === 'True'
    // )
    const ledgers = spIltsreport.filter(
      (report, key) =>
        key !== 0 &&
        key !== 1 &&
        key !== 2 &&
        !report.Script &&
        !report.isHypothetical
    )
    ledgerReport = ledgers.map((reportLedger) => {
      return {
        ...reportLedger,
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })

    // hypothetical_Ledger_Report = hypothetical_Ledger.map((reportLedger) => {
    //   return {
    //     ...reportLedger,
    //     DR_Amount: Math.round(reportLedger.DR_Amount),
    //     CR_Amount: Math.round(reportLedger.CR_Amount),
    //   }
    // })

    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        CumAmount:
          sumBy(ledgerReport, 'DR_Amount') - sumBy(ledgerReport, 'CR_Amount'),

        remark: '',
      },
    ]

    // hypothetical_Ledger_Report = [
    //   ...hypothetical_Ledger_Report,
    //   {
    //     Entrydate: 'Total',
    //     DR_Amount: sumBy(hypothetical_Ledger_Report, 'DR_Amount'),
    //     CR_Amount: sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
    //     CumAmount:
    //       sumBy(hypothetical_Ledger_Report, 'DR_Amount') -
    //       sumBy(hypothetical_Ledger_Report, 'CR_Amount'),
    //     remark: '',
    //   },
    // ]
  }

  const spILTSReportData =
    spIltsreport &&
    _map(
      spIltsreport.filter((report) => report.isHypothetical !== 'True'),
      (report, key) => {
        if (key === 0) return report

        const clonedReport = { ...report }
        if (clonedReport.ClientCode) delete clonedReport.ClientCode

        return clonedReport
      }
    )

  const handleDownloadPdf = () => {
    client
      .query({
        query: GET_ILTS_P_REPORT,
        variables: { where: { id: parseInt(id), strategyCode: strategy } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        get(res, 'data.getIltspreport') &&
          window.open(get(res, 'data.getIltspreport'), '_blank')
      })
      .catch((err) => console.log(err))
  }
  const content = (
    <div style={{ width: '100px' }}>
      <div
        onClick={handleDownloadPdf}
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
      >
        <img src={PdfIcon} style={{ marginRight: '10px' }} alt='pdf' /> PDF
      </div>
      <div
        style={{
          marginBottom: `${isMobile ? '0px' : '10px'}`,
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '24px',
          textAlign: 'left',
          color: ' #000',
          cursor: 'pointer',
        }}
        onClick={() => {
          client
            .query({
              query: GET_ILTS_P_REPORT,
              variables: { where: { id: parseInt(id) } },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              client
                .query({
                  query: SEND_ME_PDF_V2,
                  variables: {
                    data: {
                      url: res?.data?.getIltspreport,
                      useSendInBlue: true,
                    },
                  },
                  fetchPolicy: 'network-only',
                })
                .then(() => openNotification('success', 'Mail has been sent!'))
            })
            .catch((err) => console.log(err))
        }}
      >
        <img src={MailIcon} style={{ marginRight: '10px' }} alt='mail' /> Mail
      </div>

      <CSVLink
        style={{ paddingRight: 0 }}
        data={spILTSReportData}
        className='csv-btn'
        filename={`ILTS-PERFORMANCE-REPORT-${clientCode}}.csv`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            marginBottom: `${isMobile ? '0px' : '10px'}`,
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'left',
            color: ' #000',
            cursor: 'pointer',
          }}
        >
          <img src={ExcelIcon} style={{ marginRight: '10px' }} alt='excel' />{' '}
          Excel
        </div>
      </CSVLink>
    </div>
  )

  return (
    <>
      <div className='report_Container'>
        <div className='container_Header'>
          <div className='report_Heading'>{activeTab}</div>
          <div className='container_Export_Popover'>
            <Button
              onClick={() =>
                history.push(
                  `/reports/performance/${id}/comparison-of-fund/${clientCode}`
                )
              }
              style={{
                color: '#FFFFFF',
                background: '#AF0000',
              }}
            >
              View Comparison
            </Button>
            <Popover content={content} placement='bottom' trigger='click'>
              <Button>
                Export{' '}
                <DownloadOutlined
                  color='#AF0000'
                  style={{ fontSize: '16px' }}
                />
              </Button>
            </Popover>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              defaultValue='1005'
              placeholder='Select Strategy'
              className='display-field-report'
              onChange={(e) => setStrategy(e)}
              loading={!strategyList}
            >
              {_map(strategyList, (strategy, key) => {
                const { StrategyName, StrategyCode } = strategy
                return (
                  <Option value={StrategyCode} key={key}>
                    {StrategyName}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <Tabs onChange={(e) => setActiveTab(e)} defaultActiveKey='Overview'>
            <TabPane tab='Overview' key='Overview'>
              <Overview
                clientData={{ ...reportData, clientId: id, strategy }}
                ledgerReport={ledgerReport}
              />
            </TabPane>
            <TabPane tab='Stock Holding Report' key='Stock Holding Report'>
              <StockReport
                reportData={stockReport}
                clientData={first(spIltsreport)}
                id={id}
              />
            </TabPane>
            <TabPane
              tab='Futures & Options Report'
              key='Futures & Options Report'
            >
              <OpenPosition
                reportData={futuresOptionsReport}
                clientData={first(spIltsreport)}
                id={id}
              />
            </TabPane>
            <TabPane tab='Ledger Report' key='Ledger Report'>
              <Ledger
                isLedger={true}
                reportData={ledgerReport}
                clientData={first(spIltsreport)}
                id={id}
              />
            </TabPane>
            {/* <TabPane tab='Ledger Beta Report' key='Ledger Beta Report'>
              <Ledger
                isLedger={false}
                reportData={hypothetical_Ledger_Report}
                clientData={first(spIltsreport)}
                id={id}
              />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  )
}
